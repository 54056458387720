import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import definitions from "../../../../../common/definitions.json";
import { useApiToken } from "../../../../../common/apiUtils";
import SearchInput from "./common/SearchInput";
import { fetchDynamicSelectData } from "../../../../../common/apiUtils";
import { CloseIcon, DropDownArrow } from "../../../../../assets/svgs";

/**
 * DynamicMultiSelect: A dynamic dropdown component for selecting multiple items.
 *
 * Operation flow:
 * 1. Initialize state and memoize field data
 * 2. Fetch initial data based on props.value
 * 3. Load options when dropdown opens
 * 4. Handle user interactions (focus, search, selection, removal)
 * 5. Update parent component on selection change
 * 6. Render dropdown with current selections and options list
 */

const SelectedItem = React.memo(({ item, disabled, onRemove, formatId }) => {
  return (
    <span
      key={item.id}
      className="inline-flex items-center px-2 py-1 m-1 rounded bg-zinc-600 text-zinc-200"
      title={`${item.name !== "Untitled" ? "Name: " + item.name + "\n" : ""}${item.id}`}
    >
      {item.name}
      <span className="text-zinc-400 italic ml-1">{` (${formatId(item.id)})`}</span>
      {!disabled && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove(item.id);
          }}
          className="ml-1 text-zinc-300 hover:text-red-500"
        >
          <CloseIcon />
        </button>
      )}
    </span>
  );
});

const DynamicMultiSelect = React.memo(
  (props) => {
    const {
      name,
      sandboxId,
      pageData,
      fullWidth,
      disabled,
      onChange,
      required,
      retro = false,
      useArrayFormat = false,
      value,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [cachedOptions, setCachedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoadedInitialOptions, setHasLoadedInitialOptions] = useState(false);
    const [hasLoadedInitialValues, setHasLoadedInitialValues] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const token = useApiToken();
    const dropdownRef = useRef(null);

    const formatId = (id) => {
      return id && id.length > 20 ? `...${id.slice(-12)}` : id;
    };

    const loadFieldData = useMemo(() => {
      if (!pageData?.DynamicSelectFields || !name) return null;
      const fieldData = pageData.DynamicSelectFields[name];
      if (!fieldData) return null;

      const { tableData, responseDataName, idKey } = fieldData;

      return {
        tableData: tableData,
        responseDataName: responseDataName,
        isDynamic: !!tableData,
        idKey: idKey || "item_id",
        get_endpoint: tableData
          ? definitions?.schemas[tableData]?.endpoints?.find((endpoint) => endpoint.method === "get")
          : undefined,
      };
    }, [pageData, name, definitions]);

    const formatSelectedItems = useCallback(
      (items) => {
        const idKey = loadFieldData.idKey;
        if (useArrayFormat) {
          return items.map((item) => ({ [idKey]: item.id }));
        } else {
          // Return an array of objects, each with idKey as the key and item.id as the value
          return items.map((item) => ({ [idKey]: item.id }));
        }
      },
      [useArrayFormat, loadFieldData]
    );

    const selectedItems = useMemo(() => {
      if (!value || !loadFieldData) return [];
      return value.map((item) => {
        const cachedItem = cachedOptions.find((option) => option.id === item[loadFieldData.idKey]);
        return cachedItem || { id: item[loadFieldData.idKey], name: "Loading..." };
      });
    }, [value, loadFieldData, cachedOptions]);

    const fetchOptions = useCallback(
      async (searchValue = "", selectedIds = []) => {
        if (!token || !loadFieldData?.get_endpoint) {
          console.log("fetchOptions: Missing token or get_endpoint");
          return;
        }

        setIsLoading(true);
        try {
          const response = await fetchDynamicSelectData(
            token,
            loadFieldData.idKey,
            loadFieldData.get_endpoint,
            { sandbox_id: sandboxId },
            searchValue,
            null,
            selectedIds
          );

          const transformedData =
            response[loadFieldData.responseDataName]?.map((item) => ({
              id: item[loadFieldData.idKey],
              name: item.name || "Untitled",
            })) || [];

          setFilteredOptions(transformedData);
          setCachedOptions((prevOptions) => {
            const newOptions = [...prevOptions, ...transformedData];
            return Array.from(new Set(newOptions.map(JSON.stringify))).map(JSON.parse);
          });

          if (!searchValue) {
            setHasLoadedInitialOptions(true);
          }

          return transformedData;
        } catch (error) {
          console.error("Error fetching options:", error);
          setFilteredOptions([]);
          return [];
        } finally {
          setIsLoading(false);
        }
      },
      [token, loadFieldData, sandboxId]
    );

    // Effect to load initial values
    useEffect(() => {
      const loadInitialValues = async () => {
        if (value && loadFieldData && token && !hasLoadedInitialValues) {
          const selectedIds = value.map((item) => item[loadFieldData.idKey]).filter(Boolean);

          if (selectedIds.length > 0) {
            const missingIds = selectedIds.filter((id) => !cachedOptions.some((option) => option.id === id));

            if (missingIds.length > 0) {
              const fetchedItems = await fetchOptions("", missingIds);
              if (fetchedItems && fetchedItems.length > 0) {
                setCachedOptions((prevOptions) => {
                  const newOptions = [...prevOptions, ...fetchedItems];
                  return Array.from(new Set(newOptions.map(JSON.stringify))).map(JSON.parse);
                });
              }
            }
          }
          setHasLoadedInitialValues(true);
        }
      };

      loadInitialValues();
    }, [value, loadFieldData, hasLoadedInitialValues, cachedOptions, fetchOptions, token]);

    useEffect(() => {
      if (isOpen && !hasLoadedInitialOptions) {
        fetchOptions();
      }
    }, [isOpen, hasLoadedInitialOptions, fetchOptions]);

    const handleSearch = useCallback(
      (newSearchValue) => {
        if (!isOpen) return;
        setSearchValue(newSearchValue);
        fetchOptions(newSearchValue);
      },
      [isOpen, fetchOptions]
    );

    const clearSearch = useCallback(() => {
      setSearchValue("");
      setFilteredOptions([]);
      if (hasLoadedInitialOptions) {
        fetchOptions();
      }
    }, [hasLoadedInitialOptions, fetchOptions]);

    const toggleItem = useCallback(
      (id) => {
        const isSelected = selectedItems.some((item) => item.id === id);
        let updatedItems;
        if (isSelected) {
          updatedItems = selectedItems.filter((item) => item.id !== id);
        } else {
          const itemToAdd = cachedOptions.find((option) => option.id === id);
          if (itemToAdd) {
            updatedItems = [...selectedItems, itemToAdd];
          } else {
            return; // Item not found, do nothing
          }
        }
        const formattedValue = formatSelectedItems(updatedItems);
        onChange({ target: { value: formattedValue, name } });
        setIsOpen(false);
        clearSearch();
      },
      [selectedItems, cachedOptions, formatSelectedItems, onChange, name, clearSearch]
    );

    const removeItem = useCallback(
      (id) => {
        const updatedItems = selectedItems.filter((item) => item.id !== id);
        const formattedValue = formatSelectedItems(updatedItems);
        onChange({ target: { value: formattedValue, name } });
      },
      [selectedItems, formatSelectedItems, onChange, name]
    );

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
          clearSearch();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [clearSearch]);

    return (
      <div ref={dropdownRef} className={`relative w-full mb-4 ${fullWidth ? "w-full" : ""}`}>
        {retro && (
          <span className="absolute top-[-1rem] left-0 text-gray-400 text-xs z-1 px-1">
            {name}
            {required && <span className="text-red-500">*</span>}
          </span>
        )}
        <div
          className={`w-full text-white border border-zinc-600 rounded-md p-2
                    focus:outline-none focus:ring-2 focus:ring-violet-500
                    placeholder-zinc-400 bg-opacity-20 bg-zinc-700
                    backdrop-filter backdrop-blur-sm
                    flex flex-wrap items-start cursor-pointer ${disabled ? "opacity-50" : ""}`}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <div className="flex-grow text-left overflow-hidden flex flex-wrap gap-2">
            {selectedItems.length > 0 ? (
              selectedItems.map((item) => (
                <SelectedItem key={item.id} item={item} disabled={disabled} onRemove={removeItem} formatId={formatId} />
              ))
            ) : (
              <span className={`${disabled ? "text-zinc-500" : "text-zinc-400"} italic block truncate`}>
                {disabled ? `No ${name} selected` : `Please select a ${name}`}
              </span>
            )}
          </div>
          <div className="absolute top-2 right-2">
            <DropDownArrow
              className={`${disabled ? "text-zinc-500" : "text-zinc-300"} ${isOpen ? "rotate-180" : ""}`}
            />
          </div>
        </div>
        {isOpen && !disabled && (
          <div className="absolute z-50 w-full mt-1 bg-zinc-800 rounded-md shadow-lg border border-zinc-600">
            <ul className="max-h-72 overflow-y-auto">
              <li className="p-1">
                <SearchInput onSearch={handleSearch} value={searchValue} />
              </li>
              {isLoading ? (
                <li className="p-2 text-sm text-gray-500">Loading...</li>
              ) : filteredOptions.length > 0 ? (
                filteredOptions.map((option) => {
                  const isSelected = selectedItems.some((item) => item.id === option.id);
                  return (
                    <li
                      key={option.id}
                      className={`p-2 text-sm cursor-pointer ${
                        isSelected ? "bg-zinc-600" : "text-gray-300 hover:bg-zinc-600"
                      }`}
                      onClick={() => toggleItem(option.id)}
                    >
                      {option.name}
                      <span className="text-gray-500 italic ml-1">{` (${formatId(option.id)})`}</span>
                    </li>
                  );
                })
              ) : (
                <li className="p-2 text-sm text-gray-500">No results found</li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    const shouldUpdate =
      prevProps.value !== nextProps.value ||
      prevProps.disabled !== nextProps.disabled ||
      prevProps.sandboxId !== nextProps.sandboxId ||
      prevProps.pageData !== nextProps.pageData;
    return !shouldUpdate;
  }
);

export default DynamicMultiSelect;
