import { makeStyles } from '@mui/styles';


export const usePortalAdminTableStyles = makeStyles((theme) => {
    return {
        root: {
            width: "100%",
            "& button": {
              marginLeft: "1rem",
            },
        },
        table: {
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
            },
        },
        tableHeader: {
            padding: "8px 8px 0px 8px",
        },
        tableHeaderToolbar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        tableDetailsContainer: {
            marginTop: "2rem",
            padding: "1rem",
            color: theme.palette.text.primary,
            background: theme.palette.background.paper,
            borderRadius: "5px",
        },
        tablesDetailContainer: {
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.spacing(2),
        },
        tableDetailView: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginBottom: theme.spacing(2),
        },
        tableDetailViewContentsButtonBar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(2),
        },
        tableDetailForm: {
            display: "flex",
            flexWrap: "wrap",
            gap: "25px 20px",
        },
        snackBar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    };
});