import JsonField from "./JsonField";
import BooleanField from "./BooleanField";
import ReadOnlyField from "./ReadOnlyField";
import NumberField from "./NumberField";
import RepeaterField from "./RepeaterField";
import TextField from "./TextField";
import SingleDynamicSelect from "./SingleDynamicSelect";
import BasicSelect from "./BasicSelect";
import Tooltip from "./Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function FormField({
  field,
  rowKey,
  fieldGroup,
  onChange,
  originalValue,
  description,
  disabled,
}) {
  function checkDisabled(field) {
    if (disabled) {
      return true;
    }
    if (field.editable === false) {
      return true;
    }
    if (field.alwaysDisabled) {
      return true;
    }
    if (field.field === rowKey && field.field !== "platform") {
      return false;
    }
    return false;
  }

  function handleValueChange(e, field) {
    // I swear the checkbox is cranky and needs this.
    const newVal =
      field.type === "checkbox" ? e.target.checked : e.target.value;
    const newFieldGroup = { ...fieldGroup, [field]: newVal };
    onChange(newFieldGroup);
  }

  switch (field?.type) {
    case "basicSelect":
      return (
        <BasicSelect
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          options={field.options}
        />
      );
    case "metadata":
      return (
        <ReadOnlyField
          type={field.type}
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          inputProps={{ maxLength: 500 }}
          description={field.info ?? description}
        />
      );
    default:
      return (
        <TextField
          type={field.type}
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={fieldGroup[field.field]}
          disabled={checkDisabled(field)}
          onChange={(e) => handleValueChange(e, field.field)}
          inputProps={{ maxLength: 500 }}
          description={field.info ?? description}
        />
      );
  }
}
