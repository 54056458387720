export const columns = [
  {
    field: "host_label_id",
    headerName: "Host Label ID",
    required: true,
    editable: true,
    flex: 1,
    type: "metadata",
  },
  {
    field: "label",
    headerName: "Label value",
    required: true,
    minWidth: 200,
    editable: true,
    flex: 3,
  },
  {
    field: "host_id",
    headerName: "Host",
    required: true,
    flex: 1,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    fullUrl: "v1/armada/host",
    filterOnClient: true,
    idKey: "host_id",
    displayValue: "public_hostname",
    defaultOptions: [],
  },
];

export class NewEntry {}
