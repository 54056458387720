import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import TerraformControlPanel from "../TerraformControlPanel/TerraformControlPanel";

export default function ArmadaTerraform() {

  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  return (
    <>
      <TerraformControlPanel/>
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
