import React, { useState } from "react";
import dayjs from "dayjs";

const CustomDateTimeInput = ({ label, name, value, onChange, required, disabled }) => {
  const [error, setError] = useState("");

  const validateDate = (date) => {
    if (date && dayjs(date).isAfter(dayjs())) {
      setError("Date cannot be in the future");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value ? dayjs(e.target.value).format("YYYY-MM-DDTHH:mm:ssZ") : "";
    if (validateDate(newValue)) {
      onChange({ target: { name, value: newValue } });
    }
  };

  const handleNowClick = () => {
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
    if (validateDate(now)) {
      onChange({ target: { name, value: now } });
    }
  };

  const handleClearClick = () => {
    setError("");
    onChange({ target: { name, value: "" } });
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-zinc-300 mb-1">{label}</label>
      <div className="flex flex-col">
        <div className="flex">
          <input
            type="datetime-local"
            name={name}
            value={value ? dayjs(value).format("YYYY-MM-DDTHH:mm") : ""}
            onChange={handleChange}
            required={required}
            disabled={disabled}
            className="flex-grow text-white border border-zinc-600 rounded-l-md p-2  placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm"
          />
          <button
            type="button"
            onClick={handleNowClick}
            disabled={disabled}
            className="bg-zinc-700 text-white px-4 py-2 hover:bg-zinc-600 "
          >
            Now
          </button>
          <button
            type="button"
            onClick={handleClearClick}
            disabled={disabled}
            className="bg-zinc-700 text-white px-4 py-2 rounded-r-md hover:bg-zinc-600 "
          >
            Clear
          </button>
        </div>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default CustomDateTimeInput;
