import { SHA256 } from "crypto-js";

let COLOR_SEED = 21;

export function generateColor(text) {
  const hash = SHA256(text + COLOR_SEED.toString()).toString();

  // Hue ranges remain the same (muted colors)
  const hueRanges = [
    [60, 180],
    [200, 280],
    [320, 360],
  ];
  const hueRangeIndex = parseInt(hash.substr(0, 8), 16) % hueRanges.length;
  const [minHue, maxHue] = hueRanges[hueRangeIndex];
  const hueRange = maxHue - minHue;
  const hue = minHue + (parseInt(hash.substr(8, 8), 16) % hueRange);

  // Slightly increase saturation range (25-45%)
  const saturation = 25 + (parseInt(hash.substr(16, 2), 16) % 20);

  // Increase lightness range by about 15% (30-50%)
  const lightness = 30 + (parseInt(hash.substr(18, 2), 16) % 20);

  // Adjust color shifts for the second color
  const hueShift = 10 + (parseInt(hash.substr(20, 2), 16) % 20);
  const satShift = -5 + (parseInt(hash.substr(22, 2), 16) % 10);
  const lightShift = -5 + (parseInt(hash.substr(24, 2), 16) % 10);

  let color1 = hslToHex(hue, saturation, lightness);
  let color2 = hslToHex(
    (hue + hueShift) % 360,
    Math.max(20, Math.min(50, saturation + satShift)),
    Math.max(25, Math.min(55, lightness + lightShift))
  );

  // Adjust luminance threshold to 0.6 to maintain white text legibility
  if (calculateLuminance(color1) > 0.6) {
    color1 = darkenColor(color1);
  }
  if (calculateLuminance(color2) > 0.6) {
    color2 = darkenColor(color2);
  }

  return `linear-gradient(45deg, ${color1}, ${color2})`;
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function calculateLuminance(hex) {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
}

function darkenColor(hex) {
  let rgb = parseInt(hex.slice(1), 16);
  let r = (rgb >> 16) & 0xff;
  let g = (rgb >> 8) & 0xff;
  let b = (rgb >> 0) & 0xff;

  // Reduce the darkening effect
  r = Math.max(0, r - 30);
  g = Math.max(0, g - 30);
  b = Math.max(0, b - 30);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function changeColorSeed(newSeed) {
  COLOR_SEED = newSeed;
}
