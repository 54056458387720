import { createContext, useState, useContext, useEffect } from "react";
import {
  readCookie,
  createCookie,
  parseJwt,
  eraseCookie,
} from "../../common/helpers";
import { useHistory } from "react-router-dom";

const redirect = window.location.origin;

const REACT_APP_AUTH0_DOMAIN =
  process.env.NODE_ENV === "production"
    ? window.env.REACT_APP_AUTH0_DOMAIN
    : process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_API_CLIENT_ID =
  process.env.NODE_ENV === "production"
    ? window.env.REACT_APP_API_CLIENT_ID
    : process.env.REACT_APP_API_CLIENT_ID;
const REACT_APP_API_IDENTIFIER =
  process.env.NODE_ENV === "production"
    ? window.env.REACT_APP_API_IDENTIFIER
    : process.env.REACT_APP_API_IDENTIFIER;

const authUrl = `https://${REACT_APP_AUTH0_DOMAIN}/authorize?response_type=code&client_id=${REACT_APP_API_CLIENT_ID}&redirect_uri=${redirect}&audience=${REACT_APP_API_IDENTIFIER}&scope=openid%20profile%20email`;

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const code = params.get("code");

  const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState();

  async function getAccessTokenSilently() {
    // https://{yourDomain}/userinfo to validate token
    // Authorization: "Bearer {ACCESS_TOKEN}";
    if (token) return token;
    else window.location.href = authUrl;
  }

  function getAccessToken() {
    // https://{yourDomain}/userinfo to validate token
    // Authorization: "Bearer {ACCESS_TOKEN}";
    if (token) return token;
    else window.location.href = authUrl;
  }

  function logout() {
    eraseCookie("authSession");
    eraseCookie("userToken");
    window.location.href = `https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${REACT_APP_API_CLIENT_ID}&returnTo=${redirect}`;
  }

  function loginWithRedirect(redirectUrl) {
    window.location.href = authUrl;
  }

  async function handleRedirectCode() {
    setIsLoading(true);
    try {
      let body = {
        grant_type: "authorization_code",
        client_id: REACT_APP_API_CLIENT_ID,
        code: code,
        redirect_uri: redirect,
        audience: REACT_APP_API_IDENTIFIER,
      };

      let formBody = [];

      for (const property in body) {
        formBody.push(property + "=" + body[property]);
      }

      let attempt = await fetch(
        `https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          }),
          body: formBody.join("&"),
        }
      );

      const auth = await attempt.json();

      if (auth.access_token) {
        createCookie("authSession", auth.access_token, 30);
        createCookie("userToken", auth.id_token, 30);
        createCookie(
          "authExpires",
          Date.now() + (auth.expires_in - 60) * 1000,
          30
        );
        setUser(parseJwt(readCookie("userToken")));
        setToken(auth.access_token);

        setIsAuthenticated(true);
        setIsLoading(false);

        const restorePath = localStorage.getItem("restorePath");
        if (restorePath) history.push(restorePath);
        localStorage.removeItem("restorePath");
      }
    } catch (error) {
      console.log(error);
      console.log("Authorization Failed. Redirecting to Login.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const expires_at = readCookie("authExpires");
    const currentTime = Date.now();
    const authToken = readCookie("authSession");

    const tokenExpired = expires_at && currentTime > parseInt(expires_at);

    if ((!authToken && !code) || tokenExpired) {
      localStorage.setItem("restorePath", window.location.pathname);
      eraseCookie("authSession");
      eraseCookie("authExpires");
      window.location.href = authUrl;
    }

    if (code) {
      handleRedirectCode();
    }

    if (authToken && !tokenExpired) {
      setToken(authToken);
      setUser(parseJwt(readCookie("userToken")));
      setIsAuthenticated(true);
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        getAccessTokenSilently,
        getAccessToken,
        user,
        logout,
        loginWithRedirect,
        isAuthenticated,
        isLoading,
      }}
    >
      {token && children}
    </AuthContext.Provider>
  );
};
