import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import definitions from "../../../../../common/definitions.json";
import { useApiToken } from "../../../../../common/apiUtils";
import SearchInput from "./common/SearchInput";
import { fetchTableData } from "../../../../../common/apiUtils";
import { CloseIcon, DropDownArrow } from "../../../../../assets/svgs";

/**
 * CustomSingleDynamicSelect: A dynamic dropdown component for selecting a single item.
 *
 * Operation flow:
 * 1. Initialize state and memoize field data
 * 2. Fetch initial data based on props.value
 * 3. Load options when dropdown opens
 * 4. Handle user interactions (focus, search, selection, removal)
 * 5. Update parent component on selection change
 * 6. Render dropdown with current selection and options list
 */

const DynamicSelect = React.memo((props) => {
  const { name, sandboxId, pageData, fullWidth, disabled, onChange, required, retro = true } = props;

  const token = useApiToken();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownSelection, setDropdownSelection] = useState(null);
  const [cachedOptions, setCachedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialOptions, setInitialOptions] = useState([]);

  const formatId = (id) => {
    return id && id.length > 20 ? `...${id.slice(-12)}` : id;
  };

  const loadFieldData = useMemo(() => {
    if (!pageData?.DynamicSelectFields || !name) return null;

    const fieldData = pageData.DynamicSelectFields[name];
    if (!fieldData) return null;

    const { tableData, responseDataName, idKey } = fieldData;

    return {
      tableData: tableData,
      responseDataName: responseDataName,
      isDynamic: !!tableData,
      idKey: idKey,
      get_endpoint: tableData
        ? definitions?.schemas[tableData]?.endpoints?.find((endpoint) => endpoint.method === "get")
        : undefined,
    };
  }, [pageData, name, definitions]);

  useEffect(() => {
    const fetchData = async () => {
      if (props.row_key) {
        setCachedOptions([]);
      }

      if (!props.value || !props.value.id) {
        setDropdownSelection(null);
        return;
      }

      if (!token) {
        return;
      }

      const endpoint = loadFieldData?.get_endpoint;
      if (!endpoint) return;

      setIsLoading(true);

      try {
        const response = await fetchTableData(
          token,
          loadFieldData?.idKey,
          endpoint,
          { sandbox_id: sandboxId },
          "",
          props.value,
          []
        );

        const transformedData =
          response[loadFieldData?.responseDataName]?.map((item) => ({
            id: item[loadFieldData?.idKey],
            name: item.name || "Untitled",
          })) || [];

        setCachedOptions(transformedData);
        const matchingItem = transformedData.find((item) => item.id === props.value.id);
        if (matchingItem) {
          setDropdownSelection(matchingItem);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFilteredOptions([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [props.row_key, props.value, loadFieldData, token, sandboxId]);

  useEffect(() => {
    if (isOpen) {
      const fetchInitialOptions = async () => {
        if (!token || !loadFieldData?.get_endpoint) return;

        try {
          const response = await fetchTableData(
            token,
            loadFieldData.idKey,
            loadFieldData.get_endpoint,
            { sandbox_id: sandboxId, pageSize: 50 },
            "",
            null,
            []
          );

          const transformedData =
            response[loadFieldData.responseDataName]?.map((item) => ({
              id: item[loadFieldData.idKey],
              name: item.name || "Untitled",
            })) || [];

          setInitialOptions(transformedData);
          setFilteredOptions(transformedData);
        } catch (error) {
          console.error("Error fetching initial options:", error);
          setInitialOptions([]);
          setFilteredOptions([]);
        }
      };

      fetchInitialOptions();
    }
  }, [isOpen, loadFieldData, token, sandboxId]);

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSearch = useCallback(
    async (searchValue) => {
      if (!isOpen) return;

      // First, try to filter the initial options locally
      const localFiltered = initialOptions.filter((option) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      if (localFiltered.length > 0) {
        setFilteredOptions(localFiltered);
      } else {
        // If no local results, fetch from API
        if (!token || !loadFieldData?.get_endpoint) return;

        try {
          const response = await fetchTableData(
            token,
            loadFieldData.idKey,
            loadFieldData.get_endpoint,
            { sandbox_id: sandboxId },
            searchValue,
            null,
            dropdownSelection ? [dropdownSelection.id] : []
          );

          const transformedData =
            response[loadFieldData.responseDataName]?.map((item) => ({
              id: item[loadFieldData.idKey],
              name: item.name || "Untitled",
            })) || [];

          setFilteredOptions(transformedData);

          // Update cachedOptions with new results
          setCachedOptions((prev) => {
            const newOptions = [...prev, ...transformedData];
            return Array.from(new Set(newOptions.map(JSON.stringify))).map(JSON.parse);
          });
        } catch (error) {
          console.error("Error fetching search results:", error);
          setFilteredOptions([]);
        }
      }
    },
    [isOpen, initialOptions, loadFieldData, token, sandboxId, dropdownSelection]
  );

  const toggleItem = useCallback(
    (id) => {
      const selectedItem =
        cachedOptions.find((option) => option.id === id) || filteredOptions.find((option) => option.id === id);

      setDropdownSelection(selectedItem);
      setIsOpen(false); // This will close the dropdown

      if (onChange) {
        onChange({ target: { value: selectedItem ? selectedItem.id : null, name } });
      }
    },
    [cachedOptions, filteredOptions, name, onChange]
  );

  const removeItem = useCallback(
    (id) => {
      if (!id) {
        return;
      }
      setDropdownSelection(null);

      if (onChange) {
        onChange({ target: { value: null, name } });
      }
    },
    [onChange, name]
  );

  useEffect(() => {
    if (props.value?.id && !isOpen) {
      const matchingItem = filteredOptions.find((item) => item.id === props.value.id);
      if (matchingItem) {
        setDropdownSelection(matchingItem);
      }
    }
  }, [props.value, filteredOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative w-full mb-4 ${fullWidth ? "w-full" : ""}`}>
      {retro && (
        <span className="absolute top-[-1rem] left-0 text-gray-400 text-xs z-1 px-1">
          {name}
          {required && <span className="text-red-500">*</span>}
        </span>
      )}
      <div
        className={`w-full text-white border border-zinc-600 rounded-md p-2
                    focus:outline-none focus:ring-2 focus:ring-violet-500
                    placeholder-zinc-400 bg-opacity-20 bg-zinc-700
                    backdrop-filter backdrop-blur-sm
                    flex items-center justify-between cursor-pointer
                    ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {isLoading ? (
          <div className="flex items-center justify-center text-zinc-400 animate-pulse">Loading...</div>
        ) : (
          <>
            <div className="flex-grow text-left overflow-hidden">
              {dropdownSelection ? (
                <span
                  className="text-zinc-200 block truncate"
                  title={`${dropdownSelection.name !== "Untitled" ? "Name: " + dropdownSelection.name + "\n" : ""}${
                    dropdownSelection.id
                  }`}
                >
                  {dropdownSelection.name}
                  <span className="text-zinc-400 italic ml-1">{` (${formatId(dropdownSelection.id)})`}</span>
                </span>
              ) : (
                <span className={`${disabled ? "text-zinc-500" : "text-zinc-400"} italic block truncate`}>
                  {disabled ? `No ${name} selected` : `Please select a ${name}`}
                </span>
              )}
            </div>
            <div className="flex items-center ml-2">
              {!disabled && dropdownSelection && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeItem(dropdownSelection.id);
                  }}
                  className="mr-2 text-zinc-400 hover:text-red-500"
                >
                  <CloseIcon />
                </button>
              )}
              <DropDownArrow
                className={`${disabled ? "text-zinc-500" : "text-zinc-300"} ${isOpen ? "rotate-180" : ""}`}
              />
            </div>
          </>
        )}
      </div>
      {isOpen && !disabled && (
        <div className="absolute z-50 w-full mt-1 bg-zinc-800 rounded-md shadow-lg border border-zinc-600">
          <ul className="py-1">
            <li className="px-2 py-1">
              <SearchInput onSearch={handleSearch} />
            </li>
            <div className="max-h-72 overflow-y-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <li
                    key={option.id}
                    className={`px-2 py-2 text-sm cursor-pointer ${
                      dropdownSelection?.id === option.id
                        ? "bg-violet-600 text-white"
                        : "text-zinc-200 hover:bg-zinc-700"
                    }`}
                    onClick={() => toggleItem(option.id)}
                  >
                    {option.name}
                    <span className="text-zinc-400 italic ml-1">{` (${formatId(option.id)})`}</span>
                  </li>
                ))
              ) : (
                <li className="px-2 py-2 text-sm text-zinc-400">No results found</li>
              )}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
});

export default DynamicSelect;
