import { useState, useEffect, Fragment } from "react";
import RHFieldWrapper from "./RHFieldWrapper";
import FormField from "components/common/RHEditableTable_v2/FormField";
import { PlusIcon, MinusIcon } from "assets/svgs";

export default function RepeaterField({
  label,
  name,
  value,
  disabled,
  onChange,
  required,
  sx,
  isFullWidthField,
  description,
  columns,
  parentDataKey,
  selectedItem,
  editedDetails,
  setEditedDetails,
  editDetail,
  sandbox,
  handleValidationError,
  rowKey,
  forceRemountKey,
  setForceRemountKey,
}) {
  const [localSelectedItem, setLocalSelectedItem] = useState();

  function addRow() {
    setLocalSelectedItem((item) => {
      const emptyRow = columns.reduce((acc, col) => {
        acc[col.field] = "";
        return acc;
      }, {});

      const newObject = structuredClone(item);
      newObject?.[name].push(emptyRow);
      return newObject;
    });
  }

  function deleteRow(rowIndex) {
    const newObject = structuredClone(editedDetails);
    newObject?.[name].splice(rowIndex, 1);
    setEditedDetails(newObject);
    setForceRemountKey(Date.now());
  }

  function getFieldInfo(key) {
    return columns.find((col) => col.field === key);
  }

  function sortValues([a, b], [c, d]) {
    // Sorts alphabetically by key, but does the pk first
    if (a === rowKey) return -1;
    if (c === rowKey) return 1;
    return a.localeCompare(c);
  }

  useEffect(() => {
    if (!value) {
      addRow();
    }
  }, [value]);

  useEffect(() => {
    // Don't want to submit empty rows when a new row is added, so we manage state internally on this component and pass it to the FormField to be managed as the source of truth there.
    setLocalSelectedItem(editedDetails);
  }, [editedDetails]);

  return (
    <RHFieldWrapper label={label} fullWidth={true} inactive={disabled} descripton={description}>
      <form>
        {localSelectedItem?.[name] &&
          localSelectedItem?.[name].map((row, i) => (
            <Fragment key={label + i}>
              <div className="flex justify-start flex-wrap items-center">
                {row &&
                  Object.entries(row)
                    .sort(sortValues)
                    .map(([key, val], j) => (
                      <div className="flex p-2 min-w-[350px]" key={"repeater" + key + forceRemountKey}>
                        <div className="flex w-full">
                          <FormField
                            selectedItem={localSelectedItem}
                            editedDetails={row}
                            setEditedDetails={setEditedDetails}
                            editDetail={editDetail}
                            field={getFieldInfo(key)}
                            rowKey={getFieldInfo(key).field}
                            arrayKey={name}
                            sandbox={sandbox}
                            handleValidationError={handleValidationError}
                            customEditDetailArgs={{ rowIndex: i, rowKey: row[rowKey] }}
                            fullWidth={true}
                            parentRowKey={rowKey}
                          />
                        </div>
                      </div>
                    ))}
                <div className={`flex items-center ml-4 ${disabled ? "pointer-events-none opacity-30" : ""}`}>
                  <div
                    className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer mr-2 "
                    onClick={() => deleteRow(i, row[rowKey])}
                  >
                    <MinusIcon />
                  </div>
                  {i === localSelectedItem?.[name].length - 1 && (
                    <div
                      className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer"
                      onClick={addRow}
                    >
                      <PlusIcon />
                    </div>
                  )}
                </div>
              </div>
              {localSelectedItem?.[name].length > 1 && (
                <div className="px-5 pt-4 pb-5">
                  <hr />
                </div>
              )}
            </Fragment>
          ))}
      </form>
    </RHFieldWrapper>
  );
}
