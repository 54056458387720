import { createContext, useContext, useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useHistory, Prompt } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

const RHMessageContext = createContext();

const useMessageBar = () => useContext(RHMessageContext);

const RHMessageProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // ['success', 'info', 'warning', 'error'
  const [message, setMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const history = useHistory();

  const showMessage = (msg, severity = "success") => {
    setMessage(msg);
    setSeverity(severity);
    setOpen(true);
  };

  const closeMessage = () => {
    setOpen(false);
    setSeverity("success");
    setMessage("");
  };

  const handleLeaveAnyway = () => {
    setIsDialogOpen(false);
    setIsDirty(false);
  };

  const handleStay = () => {
    setIsDialogOpen(false);
  };

  // I think there's a bug here where the dirty checker is not in sync with this useeffect. Doug to revisit. Commenting this out for now.
  // useEffect(() => {
  //   if (!isDialogOpen && !isDirty) {
  //     history.push("/");
  //   }
  // }, [isDialogOpen]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isDirty) {
        setIsDialogOpen(true);
        return false;
      }
      return true;
    });
    return () => unblock();
  }, [history, isDirty]);

  return (
    <RHMessageContext.Provider value={{ showMessage: showMessage, closeMessage: closeMessage, setIsDirty: setIsDirty }}>
      <Dialog open={isDialogOpen}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>You have may unsaved changes. Do you want to leave without saving?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleLeaveAnyway}>
            Leave Anyway
          </Button>
          <Button variant="contained" onClick={handleStay}>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
      {children}
      {open && (
        <Snackbar open={open} autoHideDuration={5000} onClose={closeMessage}>
          <Alert onClose={closeMessage} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </RHMessageContext.Provider>
  );
};

export { RHMessageProvider, RHMessageContext, useMessageBar };
