import ComputerIcon from "@mui/icons-material/Computer";
import CloudIcon from "@mui/icons-material/Cloud";

export default function MachineClassType({ value, virtualMachine, noText }) {
  switch (value) {
    case "physical":
      return <ComputerIcon />;
    case "virtual":
      return <CloudIcon />;
    default:
      if (virtualMachine) {
        return (
          <span>
            <CloudIcon />
            {noText ? null : value}
          </span>
        );
      }
      return (
        <span>
          <ComputerIcon />
          {noText ? null : value}
        </span>
      );
  }
}
