import { v4 as uuid } from "uuid";

export const orgAccountRowKey = "account_email";

export const orgAccountColumns = [
    {
        field: "account_email",
        headerName: "Account Email",
        flex: 1, 
    },
    {
        field: "account_id",
        headerName: "Account ID",
        flex: 1,
        hideable: false,
    }
  ];
  
  export class orgAccountTableEntry {
    constructor(org_id) {
        this.org_id = org_id;
    }    
    account_email = "";
    first_name = "";
    last_name = "";
    nickname = "";
    password = "";
  }
  
  export const orgAccountPermissionsRowKey = "account_permission_id";

  export const orgAccountPermissionsColumns = [
    {
        field: "account_permission_id",
        headerName: "Account Permission ID",
        flex: 1,
    },
    {
        field: "permission_id",
        headerName: "Permission ID",
        flex: 1,
        hideable: false,
    },
    {
        field: "account_id",
        headerName: "Account ID",
        flex: 1
    },
    {
        field: "org_id",
        headerName: "Org ID",
        flex: 1,
        hidden: true,

    },
    {
        field: "product_id",
        headerName: "Product ID",
        flex: 1,
        hidden: true,
    },
    {
        field: "sandbox_id",
        headerName: "Sandbox ID",
        flex: 1,
        hidden: true,
    },
    {
        field: "org_name",
        headerName: "Org Name",
        flex: 1
    },
    {
        field: "product_name",
        headerName: "Product Name",
        flex: 1
    },
    {
        field: "sandbox_name",
        headerName: "Sandbox Name",
        flex: 1
    },
  ];
  
  export class orgAccountPermissionsTableEntry {
    constructor(org_id) {
        this.assigned_org_id = org_id;
        this.account_permission_id = uuid();
    }
    account_id = "";    
    account_email = "";
    org_id = "";
    product_id = "";
    sandbox_id = "";
    permission_id = "";
  }

  export const orgAccountPermissionGroupsRowKey = "group_id";

  export const orgAccountPermissionGroupsColumns = [
    {
        field: "group_id",
        headerName: "Group ID",
        flex: 1,
        hideable: false,
    },
    {
        field: "org_group_name",
        headerName: "Org Group Name",
        flex: 1
    }
  ];
  
  export class orgAccountPermissionGroupsTableEntry {
    constructor(org_id) {
        this.org_id = org_id;
    }
    group_id = "";    
    org_group_name = "";
  }


  export const portalPermissionsRowKey = "permission_id";

  export const portalPermissionsColumns = [
    {
        field: "display_name",
        headerName: "Display Name",
        flex: 1
    },
    {
        field: "permission_id",
        headerName: "Permission ID",
        flex: 1,
        hideable: false,
    },
    {
        field: "type",
        headerName: "Type",
        flex: 1
    },
    {
        field: "scope",
        headerName: "Scope",
        flex: 1
    },
    {
        field: "access",
        headerName: "Access",
        flex: 1
    },
    {
        field: "description",
        headerName: "Description",
        flex: 1
    },
    {
        field: "level",
        headerName: "Level",
        flex: 1
    },
  ];
  
  export class portalPermissionsTableEntry {
    constructor(org_id) {
        this.org_id = org_id;
    }
    permission_id = "";
  }

  export const permissionGroupRowKey = "";

  export const permissionGroupColumns = [
    {
        field: "org_group_name",
        headerName: "Org Group Name",
        flex: 1
    },
    {
        field: "group_id",
        headerName: "Group ID",
        flex: 1,
        hideable: false,
    },
    {
        field: "org_id",
        headerName: "Org ID",
        flex: 1
    },
    {
        field: "org_name",
        headerName: "Org Name",
        flex: 1
    }
  ];
  
  export class permissionGroupTableEntry {
    constructor(org_id) {
        this.org_id = org_id;
    }
    group_id = "";    
    org_group_name = "";
  }

  export const portalPermissionGroupPermissionsRowKey = "group_permission_id";

  export const portalPermissionGroupPermissionsColumns = [
    {
        field: "group_permission_id",
        headerName: "Group Permission ID",
        flex: 1,
        hideable: false,
    },
    {
        field: "permission_id",
        headerName: "Permission ID",
        flex: 1
    },
    {
        field: "org_id",
        headerName: "Org ID",
        flex: 1
    },
    {
        field: "product_id",
        headerName: "Product ID",
        flex: 1
    },
    {
        field: "sandbox_id",
        headerName: "Sandbox ID",
        flex: 1
    },
    {
        field: "org_name",
        headerName: "Org Name",
        flex: 1
    },
    {
        field: "product_name",
        headerName: "Product Name",
        flex: 1
    },
    {
        field: "sandbox_name",
        headerName: "Sandbox Name",
        flex: 1
    },
  ];
  
  export class portalPermissionGroupPermissionsTableEntry {
    constructor(org_id, group_id) {
        this.org_id = org_id;
        this.group_id = group_id;
    }
    permission_id = "";
    org_id = "";
    product_id = "";
    sandbox_id = "";
  }