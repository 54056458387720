export const initialState = {};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_STATE":
      return {
        ...state,
        [action.payload.stateKey]: action.payload.value,
      };
    case "INIT_STATE":
      return {
        [action.payload.stateKey]: action.payload.value,
      };
    case "RESET_DATA":
      return initialState;
    default:
      return state;
  }
};

export const initialMutationsState = {};

export const mutationsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_MUTATIONS_REGISTRY":
      return {
        ...state,
        [action.payload.stateKey]: action.payload.value,
      };
    case "INIT_MUTATIONS_REGISTRY":
      return {
        [action.payload.stateKey]: action.payload.value,
      };
    case "RESET_DATA":
      return initialMutationsState;
    default:
      return state;
  }
};
