import React from "react";
import { CircularProgress } from "@mui/material";

const RHComponentLoader = ({ size, label }) => {
  return (
    <div className="p-[15px] box-border rounded-sm border border-white/50 flex justify-center items-center relative w-full">
      <CircularProgress size={size} />
      <div className="text-[12px] pl-5 uppercase text-white w-full">LOADING {label}</div>
    </div>
  );
};

export default RHComponentLoader;
