export const columns = [
  {
    field: "simple_product_assignment_id",
    headerName: "Simple Product Assignment ID",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 3,
    type: "metadata",
  },
  {
    field: "name",
    headerName: "Game - Environment",
    required: true,
    maxWidth: 300,
    minWidth: 200,
    editable: true,
    flex: 3,
  },
  {
    field: "host_id",
    headerName: "Host",
    required: true,
    minWidth: 200,
    flex: 3,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    fullUrl: "v1/armada/host",
    filterOnClient: true,
    idKey: "host_id",
    displayValue: "public_hostname",
    defaultOptions: [],
  },
  {
    field: "count",
    headerName: "Instance Count",
    required: true,
    editable: true,
    flex: 1,
    min: 0,
    maxWidth: 150,
    minWidth: 150,
    type: "number",
  },
];

export class NewEntry {}
