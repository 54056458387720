import React, { useEffect, useState, useCallback } from "react";

export default function Tooltip({
  title,
  placement = "left",
  disableInteractive = false,
  className,
  children,
}) {
  const [tooltipId, setToolTipId] = useState("");
  useEffect(() => {
    setToolTipId(`tooltip-${Math.random().toString(36).substring(7)}`);
    return () => {
      if (tooltipId && document.getElementById(tooltipId)) {
        const tooltip = document.getElementById(tooltipId);
        document.body.removeChild(tooltip);
      }
    };
  }, []);

  const handleMouseEnter = useCallback(
    (e) => {
      if (tooltipId && !document.getElementById(tooltipId)) {
        let tooltip = document.createElement("span");
        tooltip.id = tooltipId;
        tooltip.style.position = "absolute";
        tooltip.style.display = "none";
        tooltip.innerHTML = title;
        document.body.appendChild(tooltip);
        if (tooltip) {
          const rect = e.currentTarget.getBoundingClientRect();
          tooltip.style.display = "block";
          tooltip.style.zIndex = "100";
          tooltip.style.position = "absolute";
          tooltip.style.backgroundColor = "rgba(75,85,99,0.7)";
          tooltip.style.borderRadius = "4px";
          tooltip.style.transform = "scale(0.8)";
          tooltip.style.transition = "all 0.2s";
          tooltip.style.transformOrigin = "center right";
          tooltip.style.opacity = "0";
          tooltip.className = `rounded-sm p-2 text-xs text-white ${className}`;
          if (placement === "top") {
            tooltip.style.left = `${rect.left + window.scrollX}px`;
            tooltip.style.top = `${
              rect.top + window.scrollY - tooltip.offsetHeight
            }px`;
          } else if (placement === "bottom") {
            tooltip.style.left = `${rect.left + window.scrollX}px`;
            tooltip.style.top = `${rect.bottom + window.scrollY}px`;
          } else if (placement === "left") {
            tooltip.style.left = `${
              rect.left + window.scrollX - tooltip.offsetWidth
            }px`;
            tooltip.style.top = `${rect.top + window.scrollY}px`;
          } else if (placement === "right") {
            tooltip.style.left = `${rect.right + window.scrollX}px`;
            tooltip.style.top = `${rect.top + window.scrollY}px`;
          }

          const timeout = setTimeout(() => {
            tooltip.style.opacity = "1";
            tooltip.style.transform = "scale(1)";
            clearTimeout(timeout);
          }, 0);
        }
      }
    },
    [tooltipId]
  );

  const handleMousLeave = useCallback(
    (e) => {
      const tooltip = document.getElementById(tooltipId);
      if (tooltip) {
        tooltip.style.transform = "scale(0.8)";
        tooltip.style.opacity = "0";

        const timeout = setTimeout(() => {
          tooltip.remove();
          clearTimeout(timeout);
        }, 200);
      }
    },
    [tooltipId]
  );

  const modifiedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMousLeave,
      });
    }
    return child;
  });

  return title ? modifiedChildren : children;
}
