import { createContext, useContext, useState } from "react";
import { isEqual } from "lodash";

export const useLayout = () => useContext(LayoutContext);

function getTableSettings(pageKey, settingKey) {
  const tableSettings = JSON.parse(localStorage.getItem("tableSettings")) || {};
  return tableSettings[pageKey]?.[settingKey];
}

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [tableSize, setLayoutFn] = useState(
    getTableSettings("global", "tableSize")
  );

  const [defaultDrawerWidth, setDefaultDrawerWidthFn] = useState(
    getTableSettings("global", "defaultDrawerWidth") ?? 400
  );

  const setLayout = (pageKey, settingKey, newValue) => {
    const tableSettings =
      JSON.parse(localStorage.getItem("tableSettings")) || {};
    const currentPageSettings = tableSettings[pageKey] || {};
    const currentValue = currentPageSettings[settingKey];

    const hasChanged = !isEqual(newValue, currentValue);

    if (hasChanged) {
      tableSettings[pageKey] = {
        ...currentPageSettings,
        [settingKey]: newValue,
      };

      localStorage.setItem("tableSettings", JSON.stringify(tableSettings));
      console.log(settingKey);
      if (settingKey === "tableSize") {
        setLayoutFn(newValue);
      } else if (settingKey === "defaultDrawerWidth") {
        setDefaultDrawerWidthFn(newValue);
      }
    } else {
      console.log(`${settingKey} unchanged, not updating local storage`);
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        tableSize,
        setLayout,
        defaultDrawerWidth,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
