import { useState, useContext, useEffect } from "react";
import { useSandboxStyles } from "./styles";
import AccountContext from "contexts/AccountContext";
import Snackbar from "@mui/material/Snackbar";
import { kvColumns, kvTableRowKey, kvTableEntry } from "./definitions";
import { API_BASE, useApiToken } from "common/apiUtils";
import RHEditableTable from "components/common/RHEditableTable";

export default function RHKVConfigPage() {
  const classes = useSandboxStyles();
  const token = useApiToken();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const { currentStateData } = useContext(AccountContext);

  const API_NAME = "kv";
  const ROW_KEY = kvTableRowKey;
  const COLUMNS = kvColumns;

  async function fetchDirectly(args, params) {
    let paramsString;

    if (params) {
      const searchParams = new URLSearchParams(Object.entries(params));
      paramsString = "?" + searchParams.toString();
    }
    let response = await fetch(
      `${API_BASE}/${
        args?.fullUrl ??
        `${args.endpoint}${args.dataId ? "/" + args.dataId : ""}`
      }${paramsString ?? ""}`,
      {
        method: args.method,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: args.body ? JSON.stringify(args.body) : null,
      }
    );
    if (response?.ok) {
      return response.status === 204 ? [] : await response.json();
    } else {
      let newError = new Error("Something went wrong");
      newError.response = {
        data: { desc: JSON.stringify(await response.json()) },
      };
      throw newError;
    }
  }

  function customFilterFunc(field) {
    return field.field !== "last_modified_timestamp";
  }

  async function readData() {
    let response = await fetchDirectly({
      fullUrl: `/v1/sandbox/${currentStateData?.sandbox?.sandboxId}/kv`,
      method: "GET",
    });
    setData(response);
    setIsLoading(false);
  }

  async function updateHandler(
    apiName,
    onDataRefresh,
    rowKey,
    sandboxId,
    editedItem,
    customArgs
  ) {
    const item = await fetchDirectly({
      fullUrl: `/v1/sandbox/${currentStateData?.sandbox?.sandboxId}/kv/${rowKey}`,
      method: "PUT",
      body: editedItem,
    });
    await readData();
    return item;
  }

  async function createHandler(
    apiName,
    onDataRefresh,
    sandboxId,
    editedItem,
    customArgs
  ) {
    console.log(editedItem);
    const item = await fetchDirectly({
      endpoint: `/v1/sandbox/${currentStateData?.sandbox?.sandboxId}/kv`,
      method: "POST",
      body: editedItem,
    });
    await readData();
    return item;
  }

  async function deleteHandler(
    apiName,
    onDataRefresh,
    rowKey,
    sandboxId,
    selectedItem,
    customArgs
  ) {
    const item = await fetchDirectly({
      fullUrl: `/v1/sandbox/${currentStateData?.sandbox?.sandboxId}/kv/${rowKey}`,
      method: "DELETE",
    });
    await readData();
    console.log(item);
    return item;
  }

  useEffect(() => {
    // Only call readData if sandboxId is available and the token is not null
    if (currentStateData?.sandbox?.sandboxId && token) {
      readData();
    }
  }, [currentStateData, token]);

  return isLoading ? null : (
    <>
      <RHEditableTable
        apiName={API_NAME}
        rowKey={ROW_KEY}
        columns={COLUMNS}
        tableData={data}
        sandbox={currentStateData?.sandbox}
        textFieldFilter={customFilterFunc}
        className={classes}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        editableInline={false}
        newModel={kvTableEntry}
        newModelArgs={[currentStateData?.sandbox?.sandboxId]}
        updateHandler={updateHandler}
        createHandler={createHandler}
        deleteHandler={deleteHandler}
      />
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
