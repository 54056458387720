import React, { useState } from "react";
import MachineClassType from "views/armada/MachineClassType";

export function summarizeMachineClass(mc, datacenter_provider_friendly) {
  const dp = datacenter_provider_friendly ? `${datacenter_provider_friendly}.` : "";
  const cpu_count = mc.cpu_count ? `.${mc.cpu_count}` : "";
  const memory_size_gigabytes = mc.memory_size_gigabytes ? `x${mc.memory_size_gigabytes}` : "";
  const cpu_info = mc.cpu_info ? `.${mc.cpu_info}` : "";
  return `${dp}${mc.name}${cpu_count}${memory_size_gigabytes}${cpu_info}`;
}

export const columns = [
  {
    field: "datacenter_provider_id",
    headerName: "Datacenter Provider",
    minWidth: 200,
    flex: 1,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    fullUrl: "v1/armada/datacenter_provider",
    filterOnClient: true,
    idKey: "datacenter_provider_id",
    displayValue: "name",
    defaultOptions: [],
    valueGetter: (params) => params.row.datacenter_provider_friendly,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
    renderCell: (params) => <MachineClassType value={params.value} />,
  },
  {
    field: "summary",
    headerName: "Summary",
    required: false,
    editable: false,
    flex: 1,
    renderCell: (params) => (
      <span>
        <MachineClassType value={params.row.type} noText={true} />
        {summarizeMachineClass(params.row, params.row.datacenter_provider_friendly)}
      </span>
    ),
  },
  {
    field: "machine_class_id",
    headerName: "Machine Class ID",
    minWidth: 200,
    type: "metadata",
    flex: 1,
  },
  {
    field: "monthly_price",
    headerName: "Monthly Price",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "hourly_price",
    headerName: "Hourly Price",
    minWidth: 200,
    min: 0,
    flex: 1,
  },
  {
    field: "cpu_count",
    headerName: "CPU Count",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "cpu_info",
    headerName: "CPU Info",
    minWidth: 200,
    flex: 1,
    align: "left",
  },
  {
    field: "cpu_type",
    headerName: "CPU Type",
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
  },
  {
    field: "cpu_sockets",
    headerName: "CPU Sockets",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "cpu_cores",
    headerName: "CPU Cores",
    minWidth: 200,
    editable: true,
    type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "cpu_threads",
    headerName: "CPU Threads",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "memory_size_gigabytes",
    headerName: "Memory Size (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "memory_type",
    headerName: "Memory Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "memory_speed_rating",
    headerName: "Memory Speed Rating",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "memory_timings",
    headerName: "Memory Timings",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_type",
    headerName: "Disk Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_size_gigabytes",
    headerName: "Disk Size (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "disk_sequnetial_read",
    headerName: "Disk Sequential Read",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_sequential_write",
    headerName: "Disk Sequential Write",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_random_read",
    headerName: "Disk Random Read",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_random_write",
    headerName: "Disk Random Write",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_iops",
    headerName: "Disk IOPS",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "disk_latency_ms",
    headerName: "Disk Latency (ms)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "network_gbps",
    headerName: "Network (Gbps)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "gpu_type",
    headerName: "GPU Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "gpu_count",
    headerName: "GPU Count",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "gpu_memory_gigabytes",
    headerName: "GPU Memory (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  { field: "datacenter_provider", nullify: true, alwaysHidden: true },
  { field: "datacenter_provider_friendly", nullify: true, alwaysHidden: true },
];

export class NewEntry {}
