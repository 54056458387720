import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RHAccordion = ({ summary, children }) => {
  const [expanded, setExpanded] = useState(true); // Default to expanded

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleSummaryClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={handleSummaryClick} expandIcon={<ExpandMoreIcon onClick={handleExpandClick} />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{ bgcolor: "background.paper" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default RHAccordion;
