import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ToolbarExportIcon, ToolbarResetIcon, HistoryIcon, ToolbarInfoIcon } from "assets/svgs";
import debounce from "lodash.debounce";
import CustomSearchBar from "./CustomSearchBar";

export default function ToolBar({
  handleAddNew,
  handleDelete,
  handleCopy,
  selectedItem,
  classes,
  hideHeader,
  title,
  hideSearchBoxTitle,
  onImport,
  onExport,
  onDocs,
  onClearSettings,
  loadDefaults,
  onFilterChangeHandler,
  enableSearchByName,
  onHistory,
  historyView,
  enableSmartSearch,
}) {
  const editorTitle = useMemo(() => title ?? apiName.toUpperCase(), [title]);
  const [inputValue, setInputValue] = useState("");

  const location = useLocation();
  const url = location.pathname; // Get current URL path
  const basePath = url.substring(0, url.lastIndexOf("/")); // Extract base path excluding the last segment
  const lastSegment = url.substring(url.lastIndexOf("/") + 1); // Extract the last segment

  // Check if the last segment ends with '-history' and remove it
  const newSegment = lastSegment.endsWith("-history") ? lastSegment.slice(0, -"-history".length) : lastSegment;

  const debouncedOnFilterChange = useCallback(
    debounce((newValue) => onFilterChangeHandler(newValue), 500),
    [onFilterChangeHandler],
  );

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    useRef.current = event.target.value;
    setInputValue(newValue);
    debouncedOnFilterChange(newValue);
  };

  const navigateBack = (event) => {
    try {
      const newUrl = `${basePath}/${newSegment}`; // Construct the new URL without '-history'

      console.log("Navigating to: ", newUrl); // Log the new URL
      window.location.href = newUrl; // Change the current page to the new URL
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  return (
    <div className="bg-[#1e1e1e] border-none pl-3 pr-3 pt-3 rounded-tl-md rounded-tr-md text-white font-light">
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between pb-5">
          {!hideHeader && !historyView && <h2 className="text-xl">{`${editorTitle} Editor`}</h2>}
          {historyView && <h2 className="text-xl">{`${editorTitle}`}</h2>}
          {!historyView && (
            <div className="flex gap-1">
              {loadDefaults && renderLoadDefaultsButton(loadDefaults, `Load Defaults`)}
              {renderButton(handleDelete, "Delete selected row", selectedItem, "Delete")}
              {renderButton(handleCopy, "Copy selected row", selectedItem, "Copy")}
              {handleAddNew && renderAddNewButton(handleAddNew, `Create ${editorTitle}`)}
            </div>
          )}
          {historyView && (
            <div className="flex gap-1">
              {renderNavigateBack(navigateBack, `Back to ${newSegment}`, `Back to ${newSegment}`)}
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-row justify-between">
          <CustomSearchBar
            name="search"
            title={lastSegment.includes("history") ? "Search by Name or ID" : "Search by Name"}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Search..."
            enabled={enableSearchByName}
            enableSmartSearch={enableSmartSearch}
          />
          {renderExtraButtons(onImport, onExport, onDocs, onClearSettings, onHistory)}
        </div>
      </div>
    </div>
  );
}

const renderButton = (handler, tooltipTitle, selectedItem, buttonText) =>
  handler && (
    <button
      onClick={handler}
      disabled={!selectedItem}
      title={tooltipTitle}
      className={`px-4 py-1 border uppercase border-purple text-purple rounded transition duration-150 ease-in-out text-xs
                 ${selectedItem ? "hover:bg-purple hover:text-white" : "opacity-50"}`}
    >
      {buttonText}
    </button>
  );

const renderAddNewButton = (handler, tooltipTitle) => (
  <button
    onClick={handler}
    title={tooltipTitle}
    className="px-4 py-2 bg-purple text-white hover:bg-purple rounded transition duration-150 ease-in-out text-xs"
  >
    + NEW
  </button>
);

const renderNavigateBack = (handler, tooltipTitle, text) => (
  <button
    onClick={handler}
    title={tooltipTitle}
    className="px-4 py-2 bg-purple text-white hover:bg-purple rounded transition duration-150 ease-in-out text-xs uppercase"
  >
    {text}
  </button>
);

const renderLoadDefaultsButton = (handler, tooltipTitle) => (
  <button
    onClick={handler}
    title={tooltipTitle}
    className="px-4 py-2 border border-purple text-purple hover:bg-purple hover:text-white rounded transition duration-150 ease-in-out text-xs"
  >
    Load Defaults
  </button>
);

const renderExtraButtons = (onImport, onExport, onDocs, onClearSettings, onHistory) => (
  <div className="flex font-sans font-medium text-white space-x-2">
    {onClearSettings && (
      <button
        onClick={onClearSettings}
        className="flex items-center text-purple hover:opacity-90 p-2 rounded transition-opacity duration-200 ease-in-out"
      >
        <ToolbarResetIcon className="h-5 w-5" fill="currentColor" />
        <span className="ml-1 text-xs uppercase tracking-wide">Reset View</span>
      </button>
    )}
    {onExport && (
      <button
        onClick={onExport}
        className="hidden md:flex items-center text-purple hover:opacity-90 p-2 rounded transition-opacity duration-200 ease-in-out"
      >
        <ToolbarExportIcon className="h-5 w-5" fill="currentColor" />
        <span className="ml-1 text-xs uppercase tracking-wide">Export</span>
      </button>
    )}
    {onDocs && (
      <button
        onClick={onDocs}
        className="hidden md:flex items-center text-purple hover:opacity-90 p-2 rounded transition-opacity duration-200 ease-in-out"
      >
        <ToolbarInfoIcon className="h-5 w-5" fill="currentColor" />
        <span className="ml-1 text-xs uppercase tracking-wide">Documentation</span>
      </button>
    )}
    {onHistory && (
      <button
        onClick={onHistory}
        className="flex items-center text-purple hover:opacity-90 p-2 rounded transition-opacity duration-200 ease-in-out"
      >
        <HistoryIcon className="h-5 w-5" fill="currentColor" />
        <span className="ml-1 text-xs uppercase tracking-wide">History</span>
      </button>
    )}
  </div>
);
