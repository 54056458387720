import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContentText-root": {},
}));

export default function RHProductClientSecretDialog(props) {
  const { onClose, data, open } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClose = (evt, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    onClose();
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <CustomDialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Client Secret
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography gutterBottom>
          Warning. This is the only time the secret will be displayed. Store it in a secure location.
        </Typography>
        <DialogContentText gutterBottom>
          {data ? (
            <div className="bg-black/10 p-2">
              <code className="break-words">{data}</code>
            </div>
          ) : (
            <>Error: unable to create key</>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {data && (
          <Button autoFocus onClick={copyToClipboard}>
            Copy Secret
          </Button>
        )}
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <>
            <div style={{ textAlign: "center" }}>{"secret copied to clipboard!"}</div>
          </>
        }
      />
    </CustomDialog>
  );
}
