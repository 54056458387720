import { useEffect, useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useOrgAccountPermissionsStyles } from "./styles";
import { orgAccountPermissionsColumns, orgAccountPermissionGroupsColumns, permissionGroupColumns } from "./definitions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import RHAddAccountPermission from "./RHAddAccountPermission";
import RHAddAccountOrgGroup from "./RHAddAccountOrgGroup";
import AccountContext from "contexts/AccountContext";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";

function RHOrgAccountPermissions(props) {
  const classes = useOrgAccountPermissionsStyles();

  const { uiState, setUiState, orgId, selectedOrgAccount, setSelectedOrgAccount, account } = props;

  const { setIsDirty } = useMessageBar();

  const [selectedOrgAccountPermission, setSelectedOrgAccountPermission] = useState(null);
  const [selectedOrgAccountPermissionGroup, setSelectedOrgAccountPermissionGroup] = useState(null);
  const [orgAccountPermissions, setOrgAccountPermissions] = useState([]);
  const [orgAccountPermissionGroups, setOrgAccountPermissionGroups] = useState([]);
  const [showAddAccountPermissions, setShowAddAccountPermissions] = useState(false);
  const [showAddAccountPermissionGroups, setShowAddAccountPermissionGroups] = useState(false);
  const [orgAccountPermissionColumnVisibility, setOrgAccountPermissionColumnVisibility] = useState({
    account_permission_id: false,
    permission_id: true,
    account_id: false,
    org_id: false,
    org_name: true,
    product_id: false,
    product_name: true,
    sandbox_id: false,
    sandbox_name: true,
  });

  const { orgsData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();

  async function fetchAccountPermissionsData() {
    if (selectedOrgAccount) {
      try {
        const response = await fetch(`${API_BASE}/v1/account-permissions/${selectedOrgAccount}/${orgId}`, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        });

        const accOrgPerms = await response.json();
        let result = addOrgProductSandboxNames(accOrgPerms);

        setOrgAccountPermissions(result);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setOrgAccountPermissions([]);
        } else {
          console.error(error);
        }
      }
    }
  }

  function addOrgProductSandboxNames(rows) {
    if (rows && rows.length > 0) {
      const updatedPeople = rows.map((row) => {
        return {
          ...row,
          ["org_name"]: row.org_id && orgsData[row.org_id],
          ["product_name"]: row.product_id && orgsData[row.product_id],
          ["sandbox_name"]: row.sandbox_id && orgsData[row.sandbox_id],
        };
      });
      return updatedPeople;
    } else {
      return [];
    }
  }

  async function fetchAccountPermissionGroupsData() {
    if (selectedOrgAccount) {
      try {
        const response = await fetch(
          `${API_BASE}/v1/${orgId}/permissions-org-group-account/account/${selectedOrgAccount}`,
          {
            headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
          }
        );

        const accOrgGroupPerms = await response.json();
        setOrgAccountPermissionGroups(accOrgGroupPerms);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setOrgAccountPermissionGroups([]);
        } else {
          console.error(error);
        }
      }
    }
  }

  useEffect(() => {
    if (selectedOrgAccount) {
      fetchAccountPermissionsData();
      fetchAccountPermissionGroupsData();
    }
  }, [uiState, setShowAddAccountPermissionGroups, setShowAddAccountPermissions]);

  const handleSelectedOrgAccountPermission = (selection) => {
    setSelectedOrgAccountPermission(selection[0]);
  };

  const handleSelectedOrgAccountPermissionGroup = (selection) => {
    setSelectedOrgAccountPermissionGroup(selection[0]);
  };

  const handleCancelAddAccountPermission = () => {
    setShowAddAccountPermissions(false);
    setIsDirty(false);
  };

  const handleCancelAddAccountPermissionGroup = () => {
    setShowAddAccountPermissionGroups(false);
    setIsDirty(false);
  };

  const handleAddAccountPermission = () => {
    setShowAddAccountPermissions(true);
    setSelectedOrgAccountPermissionGroup(null);
    setIsDirty(true);
  };

  const handleAddAccountPermissionGroup = () => {
    setShowAddAccountPermissionGroups(true);
    setIsDirty(true);
  };

  const handleAddAccountPermissionCancel = () => {
    setUiState("");
    setSelectedOrgAccount(null);
    setIsDirty(false);
  };

  const handleDeleteAccountPermission = async () => {
    if (selectedOrgAccountPermission) {
      try {
        let permission_id = orgAccountPermissions.find(
          (perm) => perm.account_permission_id === selectedOrgAccountPermission
        ).permission_id;

        await fetch(`${API_BASE}/v1/account-permissions/${orgId}/${selectedOrgAccount}/${permission_id}`, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
          method: "DELETE",
        });
        fetchAccountPermissionsData();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setOrgAccountPermissions([]);
        } else {
          console.error(error);
        }
      }
    }
  };

  const handleDeleteAccountPermissionGroup = async () => {
    if (selectedOrgAccountPermissionGroup) {
      try {
        await fetch(
          `${API_BASE}/v1/${orgId}/permissions-org-group-account/${selectedOrgAccountPermissionGroup}/${selectedOrgAccount}`,
          {
            headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
            method: "DELETE",
          }
        );

        fetchAccountPermissionGroupsData();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setOrgAccountPermissionGroups([]);
        } else {
          console.error(error);
        }
      }
    }
  };

  const handleAccountPermissionSave = () => {
    setShowAddAccountPermissions(false);
    fetchAccountPermissionsData();
  };

  const handleAccountPermissionGroupSave = () => {
    setShowAddAccountPermissionGroups(false);
    fetchAccountPermissionGroupsData();
  };

  function customAccountPermissionToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button className={classes.buttons} startIcon={<AddIcon />} onClick={handleAddAccountPermission}>
          Add Permission
        </Button>
        {selectedOrgAccountPermission && (
          <Button className={classes.buttons} startIcon={<DeleteIcon />} onClick={handleDeleteAccountPermission}>
            Delete Permission
          </Button>
        )}
      </GridToolbarContainer>
    );
  }

  const renderAccountPermissionsDataGrid = () => {
    if (showAddAccountPermissionGroups) {
      return <></>;
    }
    if (showAddAccountPermissions) {
      return (
        <>
          <Box className={classes.addToolbar}>
            <Button className={classes.buttons} variant="contained" onClick={handleCancelAddAccountPermission}>
              Back to Account
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" color="text.secondary">
              Add Account Permission
            </Typography>
            <RHAddAccountPermission
              accountId={selectedOrgAccount}
              orgId={orgId}
              saveHandler={handleAccountPermissionSave}
            />
          </Box>
        </>
      );
    }
    return (
      <>
        <Typography variant="h6" color="text.secondary">
          Account Permissions:
        </Typography>
        <DataGrid
          className={classes.table}
          autoHeight
          rows={orgAccountPermissions}
          columns={orgAccountPermissionsColumns}
          getRowId={(row) => row.account_permission_id}
          selectionModel={selectedOrgAccountPermission}
          onRowSelectionModelChange={handleSelectedOrgAccountPermission}
          columnVisibilityModel={orgAccountPermissionColumnVisibility}
          onColumnVisibilityModelChange={(newModel) => setOrgAccountPermissionColumnVisibility(newModel)}
          slots={{
            toolbar: customAccountPermissionToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
                rowsPerPageOptions: [5, 10, 20],
              },
            },
          }}
          sx={{
            bgcolor: "background.paper",
            border: "none",
            minWidth: 0,
            p: 1,
          }}
        />
      </>
    );
  };

  function customAccountPermissionGroupToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button className={classes.buttons} startIcon={<AddIcon />} onClick={handleAddAccountPermissionGroup}>
          Add Permission Group
        </Button>
        {selectedOrgAccountPermissionGroup && (
          <Button className={classes.buttons} startIcon={<DeleteIcon />} onClick={handleDeleteAccountPermissionGroup}>
            Delete Permission Group
          </Button>
        )}
      </GridToolbarContainer>
    );
  }

  const renderAccountPermissionGroupsDataGrid = () => {
    if (showAddAccountPermissions) {
      return <></>;
    }
    if (showAddAccountPermissionGroups) {
      return (
        <>
          <Box className={classes.addToolbar}>
            <Button className={classes.buttons} variant="contained" onClick={handleCancelAddAccountPermissionGroup}>
              Back to Account
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" color="text.secondary">
              Add Account Permission Group
            </Typography>
            <RHAddAccountOrgGroup
              accountId={selectedOrgAccount}
              orgId={orgId}
              saveHandler={handleAccountPermissionGroupSave}
            />
          </Box>
        </>
      );
    }
    return (
      <>
        <Typography variant="h6" color="text.secondary">
          Account Permission Groups:
        </Typography>
        <DataGrid
          className={classes.table}
          autoHeight
          rows={orgAccountPermissionGroups}
          columns={orgAccountPermissionGroupsColumns}
          getRowId={(row) => row.group_id}
          selectionModel={selectedOrgAccountPermissionGroup}
          onRowSelectionModelChange={handleSelectedOrgAccountPermissionGroup}
          slots={{
            toolbar: customAccountPermissionGroupToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
                rowsPerPageOptions: [5, 10, 20],
              },
            },
          }}
          sx={{
            bgcolor: "background.paper",
            border: "none",
            minWidth: 0,
            p: 1,
          }}
        />
      </>
    );
  };

  return (
    <Box className={classes.addAccountPermissionsContainer}>
      <Box className={classes.addAccountPermissionsButtonBar}>
        {!(showAddAccountPermissions || showAddAccountPermissionGroups) && (
          <Button className={classes.buttons} variant="contained" onClick={handleAddAccountPermissionCancel}>
            Back to Accounts
          </Button>
        )}
      </Box>
      <Box className={classes.addAccountPermnissionForum}>
        <Typography variant="h5" color="text.secondary">
          User: {account.account_email + ", ID: " + account.account_id}
        </Typography>
        {renderAccountPermissionsDataGrid()}
        {renderAccountPermissionGroupsDataGrid()}
      </Box>
    </Box>
  );
}

export default RHOrgAccountPermissions;
