// const API_BASE = "http://localhost:8000/v1/armada";

const API_BASE =
  process.env.NODE_ENV === "production"
    ? `${window.env.REACT_APP_API_BASE_PATH}/v1/armada`
    : `${process.env.REACT_APP_API_BASE_PATH}/v1/armada`;

export async function fetchDirectly(args, params) {
  let paramsString;

  if (params) {
    const searchParams = new URLSearchParams(Object.entries(params));
    paramsString = "?" + searchParams.toString();
  }

  let response = await fetch(
    `${API_BASE}/${args?.fullUrl ?? `${args.endpoint}${args.dataId ? "/" + args.dataId : ""}`}${paramsString ?? ""}`,
    {
      method: args.method,
      headers: new Headers({
        Authorization: `Bearer ${args?.token}`,
        "Content-Type": "application/json",
      }),
      body: args.body ? JSON.stringify(args.body) : null,
    }
  );
  if (response?.ok) {
    return response.status === 204 ? [] : await response.json();
  } else {
    let newError = new Error("Something went wrong");
    newError.response = { data: { desc: JSON.stringify(await response.json()) } };
    throw newError;
  }
}

export function csvToArray(csv) {
  const lines = csv.split(/\r\n|\n/);
  const headers = lines[0].split(",");
  lines.shift();

  return lines.reduce((acc, curr) => {
    const row = curr.split(",");
    const obj = {};

    for (const j in headers) {
      obj[headers[j]] = row[j];
    }

    return [...acc, obj];
  }, []);
}

export function arrayToCsv(dataArray) {
  if (dataArray.length === 0) {
    return "";
  }

  const headers = Object.keys(dataArray[0]).filter((header) => {
    return !header.includes("friendly") && header !== "labels" && header !== "simple_product_assignments";
  });

  const csv = headers.join(",") + "\n";

  const rows = dataArray.map((obj) => {
    return headers
      .reduce((acc, key) => {
        if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
          return acc;
        }
        return [...acc, obj[key]];
      }, [])
      .join(",");
  });

  return csv + rows.join("\n");
}
