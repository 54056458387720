import { makeStyles } from "@mui/styles";

export const useEnvironmentStyles = makeStyles((theme) => {
  return {
    rootGameConfigPage: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    gameConfigFormControls: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        padding: theme.spacing(1),
      },
      "& .MuiTextField-root": {
        marginBottom: theme.spacing(2),
      },
    },
    gameConfigToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > :not(:last-child)": {
        marginRight: theme.spacing(0), // Adjust the spacing as needed
      },
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    gameConfigSwitchLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: "400",
      paddingBottom: "10px",
    },
    tableDetailForm: {
      display: "flex",
      flexWrap: "wrap",
      gap: "25px 20px",
    },
    alertContainer: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 999,
    },
    onOff: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };
});
