export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "organization",
    headerName: "Organization",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "product",
    headerName: "Product",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "environment",
    headerName: "Environment",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },

];

export class NewEntry {
  name = "";
}
