import { GridToolbarQuickFilter, GridToolbarFilterButton, GridToolbarExport } from "@mui/x-data-grid";
import { Box, Typography, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { UploadIcon, ExportIcon } from "../../../assets/svgs";

export default function ToolBar({
  handleAddNew,
  handleDelete,
  selectedItem,
  classes,
  hideHeader,
  friendlyName,
  title,
  hideSearchBoxTitle,
  onImport,
  onExport,
  extraActions,
}) {
  return (
    <Box className={classes.tableHeader}>
      <Box className={classes.tableHeaderToolbar}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
          {!hideHeader && <Typography variant="h4">{`${title ?? apiName.toUpperCase()} Editor`}</Typography>}
          {!hideSearchBoxTitle && (
            <Typography variant="div" sx={{ my: 1 }}>
              Search by name
            </Typography>
          )}
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
            {onImport && (
              <>
                <label className="items-center rounded text-purple cursor-pointer flex font-sans font-[size: 0.8125rem] font-medium justify-center leading-none ml-4 py-[4px] px-[5px] uppercase hover:bg-opacity-10 hover:bg-purple transition duration-250">
                  <UploadIcon fill="#9d69ff" width="18px" /> <p style={{ margin: "0 0 0 3px" }}>Import</p>
                  <input style={{ display: "none" }} type="file" accept=".csv" id="fileContents" onChange={onImport} />
                </label>
                <button
                  className="items-center rounded text-purple cursor-pointer flex font-sans font-[size: 0.8125rem] font-medium justify-center leading-none ml-4 py-[4px] px-[5px] uppercase hover:bg-opacity-10 hover:bg-purple transition duration 250"
                  onClick={onExport}
                >
                  <ExportIcon fill="#9d69ff" width="16px" /> <p style={{ margin: "0 0 0 3px" }}>Export</p>
                </button>
              </>
            )}
            <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
              {
                <Tooltip title="Delete selected row">
                  <span>
                    <Button onClick={handleDelete} variant="outlined" disabled={!selectedItem}>
                      Delete
                    </Button>
                  </span>
                </Tooltip>
              }
              <Tooltip title={`Create empty ${title ?? title.toUpperCase()} row`}>
                <Button onClick={handleAddNew} variant="contained">{`+ New ${
                  friendlyName ?? title.toUpperCase()
                }`}</Button>
              </Tooltip>
              {extraActions}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
