const TabBar = ({
  tabs,
  activeTab,
  setActiveTab,
  setSelectedRow,
  setRowSelectionModel,
}) => {
  return (
    <div
      className="overflow-x-auto scrollbar-hide max-h-12 flex"
      style={{ maxHeight: "30px" }}
    >
      {tabs.map((tab, index) => (
        <button
          key={tab + index}
          className={`px-2 py-2 max-h-12 whitespace-nowrap font-medium rounded-tr-sm overflow-hidden text-overflow-ellipsis  ${
            activeTab === tab
              ? "bg-zinc-600 text-white "
              : "text-gray-300 hover:bg-zinc-700 hover:text-white bg-800-950"
          }`}
          onClick={() => {
            setActiveTab(tab);
            setSelectedRow && setSelectedRow(null);
            setRowSelectionModel && setRowSelectionModel({});
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabBar;
