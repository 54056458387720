export const restructureObject = (obj) => {
  const newObj = { ...obj }; // Copy the original object

  Object.keys(obj).forEach((key) => {
    if (key.includes(".")) {
      const parts = key.split(".");
      let current = newObj;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          // Merge with existing data instead of overwriting
          current[part] = { ...current[part], ...obj[key] };
        } else {
          // Create or merge with existing object
          current[part] = current[part] ? { ...current[part] } : {};
          current = current[part];
        }
      });

      delete newObj[key];
    }
  });

  return newObj;
};

// Recursively normalizes and removes keys from an object based on specified conditions.
export const normalizeAndRemoveKeys = (obj, doNotSendKeys = [], nullableChildren = [], requiredKeys) => {
  Object.keys(obj).forEach((key) => {
    if (shouldRemoveKey(obj, key, doNotSendKeys, nullableChildren, requiredKeys)) {
      delete obj[key]; // Remove the key-value pair if conditions are met
    } else if (isObject(obj[key])) {
      normalizeAndRemoveKeys(obj[key], doNotSendKeys, nullableChildren, requiredKeys); // Recurse into sub-objects
    }
  });
};

// Determines if a key should be removed based on its value and the provided rules.
const shouldRemoveKey = (obj, key, nullableChildren, doNotSendKeys, requiredKeys) => {
  //Never remove required keys

  // Remove key if it's in the list of keys to never send
  if (doNotSendKeys.includes(key)) {
    return true;
  }

  // UGH
  if (requiredKeys.includes(key) && key !== "setting_type_unique_id") {
    return false;
  }

  // Never remove keys that have actual values in them
  const value = obj[key];
  if (value !== null && value !== undefined && value !== "") {
    return false;
  }

  // If the value is empty-ish, and the key is allowed to be nullable, set to null and do not remove
  if (nullableChildren.includes(key)) {
    obj[key] = null;
    return false;
  }

  // The value is empty-ish, but the key is not allowed to be null - remove the key
  return true;
};

// Checks if a value is an object and not null for safe recursion.
const isObject = (value) => typeof value === "object" && value !== null;

export const normalizeObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      obj[key] = null; // Normalize to null
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      normalizeObject(obj[key]); // Recurse into sub-objects
    }
  });
};

function transformValue(value, DynamicSelectFields, key) {
  // Check if value should be transformed based on DynamicSelectFields
  if (DynamicSelectFields[key]?.payloadType === "string") {
    if (Array.isArray(value)) {
      return value.map((item) => transformItem(item));
    } else if (value && typeof value === "object") {
      return [transformItem(value)];
    }
  } else if (Array.isArray(value)) {
    return value.map((item) => {
      if (item && typeof item === "object") {
        transformObjectProperties(item, DynamicSelectFields);
      }
      return item;
    });
  } else if (value && typeof value === "object") {
    transformObjectProperties(value, DynamicSelectFields);
  }

  return value;
}

function transformItem(item) {
  if (item && typeof item === "object") {
    return Object.values(item)[0];
  }
  return item;
}

export const transformObjectProperties = (obj, DynamicSelectFields) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null && DynamicSelectFields) {
      obj[key] = transformValue(value, DynamicSelectFields, key);
    }
  });
};

export const prepareJsonForTransport = (editedItem, columns = []) => {
  const jsonCols = columns.filter((x) => x.type == "json");
  jsonCols.forEach((x) => {
    if (editedItem[x.field] && typeof editedItem[x.field] !== "object") {
      let isValid = false;
      try {
        const editedItemCopy = { ...editedItem };
        editedItemCopy[x.field] = JSON.parse(editedItem[x.field]);
        isValid = true;
      } catch (error) {
        console.error(error);
      }
      if (isValid) {
        editedItem[x.field] = JSON.parse(editedItem[x.field]);
      } else return;
    }
  });
};

export const updateNullableParentsInPayload = (editedItem, nullableParents = []) => {
  if (!Array.isArray(nullableParents)) return;

  nullableParents.forEach((parentKey) => {
    if (Object.hasOwn(editedItem, parentKey)) {
      const value = editedItem[parentKey];

      // Check if the value is an object and not null
      if (typeof value === "object" && value !== null) {
        // Determine if the object has any non-null and non-empty properties
        const hasNonEmptyProperties = Object.keys(value).some((key) => {
          const propertyValue = value[key];
          return (
            propertyValue !== null &&
            propertyValue !== undefined &&
            (typeof propertyValue !== "object" || Object.keys(propertyValue).length > 0)
          );
        });

        // Check if 'custom_data' is present and empty
        const hasCustomData = Object.hasOwn(value, "custom_data");
        const isCustomDataEmpty =
          hasCustomData &&
          (value.custom_data === null ||
            (typeof value.custom_data === "object" && Object.keys(value.custom_data).length === 0));

        // Nullify parentKey if there are no non-empty properties or if only 'custom_data' is present and it's empty
        if (!hasNonEmptyProperties || (hasCustomData && isCustomDataEmpty && Object.keys(value).length === 1)) {
          editedItem[parentKey] = null;
        }
      } else if (value === null) {
        // If the value is already null, ensure it remains so
        editedItem[parentKey] = null;
      }
    } else {
      // If the property doesn't exist, ensure it is set to null
      editedItem[parentKey] = null;
    }
  });
};
