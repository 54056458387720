import TabBar from "./TabBar";
import DropdownMenu from "./DropdownMenu";

function MenuBar({
  tabs,
  buttons,
  activeTab,
  setActiveTab,
  setSelectedRow,
  setRowSelectionModel,
}) {
  return (
    <div
      className="bg-zinc-800 sticky top-0 z-50 text-white flex items-center justify-between text-xs h-[50px] scrollbar-hide w-full"
      style={{ maxHeight: "30px" }}
    >
      {tabs && (
        <div className="overflow-x-auto scrollbar-hide flex flex-grow items-center space-x-4">
          <TabBar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setSelectedRow={setSelectedRow}
            setRowSelectionModel={setRowSelectionModel}
          />
        </div>
      )}
      <div className="flex items-center px-2 ml-auto">
        {buttons &&
          buttons.map(({ name, icon, fn, args = [], type }, i) => {
            if (fn) {
              return (
                <button
                  key={i + name + "menubar"}
                  title={name}
                  onClick={() => fn(...args)}
                  className={`text-white font-medium  text-sm text-center inline-flex items-center z-10${
                    name === "RowSelector" ? "p-0" : "pr-2"
                  }`}
                >
                  {icon}
                </button>
              );
            } else
              return (
                <div
                  key={i + name + "menubar"}
                  title={name}
                  className={`text-white font-medium text-sm text-center inline-flex items-center z-10 ${
                    name === "RowSelector" ? "p-0" : "pr-2"
                  }`}
                >
                  {icon}
                </div>
              );
          })}
      </div>
    </div>
  );
}

export default MenuBar;
