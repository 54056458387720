import { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField/TextField";
import Switch from "@mui/material/Switch/Switch";
import Button from "@mui/material/Button/Button";
import AccountContext from "../../contexts/AccountContext";
import { useAuth } from "../../contexts/AuthContext";
import RHMultiSelectDropdown from "../../components/common/RHMultiSelectDropdown";
import RHLoadingMask from "../../components/common/RHLoadingMask";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import RHNumberField from "../../components/common/RHNumberField";
import RHEnhancedAutocompleteField from "../../components/common/RHEnhancedAutocompleteField";
import { useLocation } from "react-router-dom";
import { fetchDirectly } from "common/apiUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => {
  return {
    rootGameConfigPage: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    gameConfigFormControls: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        padding: theme.spacing(1),
      },
      "& .MuiTextField-root": {
        marginBottom: theme.spacing(2),
      },
    },
    gameConfigToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > :not(:last-child)": {
        marginRight: theme.spacing(2), // Adjust the spacing as needed
      },
      paddingTop: theme.spacing(1),
    },
    gameConfigSwitchLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: "400",
      paddingBottom: "10px",
    },
    alertContainer: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 999,
    },
  };
});

export default function RHGameConfigPage() {
  const classes = useStyles();
  const [gameConfig, setGameConfig] = useState({});
  const [initialGameConfig, setInitialGameConfig] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData, permissionData } = useContext(AccountContext);
  const [saveStatus, setSaveStatus] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const query = useQuery();
  // const sectionId = query.get("sectionId");
  const sectionId = location.pathname.split("/").pop();
  const [forceRemountKey, setForceRemountKey] = useState(0);

  useEffect(() => {
    currentStateData?.sandbox?.sandboxId && fetchData();
  }, [currentStateData]);

  async function fetchData() {
    try {
      const gameConfig = await fetchDirectly({
        fullURL: `/sandbox/${currentStateData?.sandbox?.sandboxId}/game-config`,
        method: "GET",
        token: await getAccessTokenSilently(),
      });
      setGameConfig(gameConfig.data);
      setInitialGameConfig(gameConfig.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const handleChange = (event) => {
    let { name, value, type } = event.target;

    if (type === "checkbox") {
      value = event.target.checked;
    }

    const key = name.split(".")[0];
    if (gameConfig[key] === null) {
      //refactor this to update the rest of the null object's properties
    }

    if (name.startsWith("nintendo.")) {
      if (!gameConfig.nintendo) {
        gameConfig.nintendo = {};
      }
      if (!gameConfig.nintendo.environments && name.includes(".environments.")) {
        gameConfig.nintendo.environments = {};
      }
    }

    let newGameConfig = setProperty(gameConfig, name, value);
    setGameConfig(newGameConfig);
    setIsDirty(true);
  };

  const handleArrayChange = (event) => {
    let { name, value } = event.target;

    let newGameConfig = setProperty(gameConfig, name, value.split(","));
    setGameConfig(newGameConfig);
    setIsDirty(true);
  };

  const setProperty = (obj, path, value) => {
    const [head, ...rest] = path.split(".");

    return {
      ...obj,
      [head]: rest.length ? setProperty(obj[head], rest.join("."), value) : value,
    };
  };

  const getProperty = (obj, path) => {
    const [head, ...rest] = path.split(".");
    if (!obj.hasOwnProperty(head)) {
      return undefined;
    }
    if (rest.length === 0) {
      return obj[head];
    }
    return getProperty(obj[head], rest.join("."));
  };

  const handleSave = async () => {
    try {
      await fetchDirectly({
        fullURL: `/sandbox/${currentStateData?.sandbox?.sandboxId}/game-config`,
        method: "PUT",
        token: await getAccessTokenSilently(),
        body: gameConfig,
      });
      setSaveStatus("success");
      fetchData();
      setIsDirty(false);
      setIsEditMode(false);
    } catch (error) {
      let errorMessage = error.request.response;
      setSaveStatus("error" + errorMessage);
    }
  };

  const handleCancel = () => {
    setForceRemountKey((forceRemountKey) => forceRemountKey + 1);
    setIsEditMode(false);
    setIsDirty(false);
    setGameConfig(initialGameConfig);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const capitalizedSectionId = sectionId ? sectionId.charAt(0).toUpperCase() + sectionId.slice(1) : "General";

  useEffect(() => {
    if (saveStatus) {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const renderGameConfigForm = () => {
    return (
      <Box>
        <Box className={classes.gameConfigToolbar} sx={{ paddingBottom: "36px" }}>
          <Typography variant="h5" color="textPrimary">
            {capitalizedSectionId}
          </Typography>
          {(permissionData?.isGlobalAdmin || currentStateData?.sandbox.permissions.includes("sandbox:config:edit")) && (
            <Box display="flex" gap={2}>
              {isEditMode ? (
                <>
                  <Button variant="contained" color="primary" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" disabled={!isDirty} onClick={() => handleSave()}>
                    Save
                  </Button>
                </>
              ) : (
                <Button variant="contained" color="primary" onClick={() => handleEdit()}>
                  Edit
                </Button>
              )}
            </Box>
          )}
        </Box>

        <Box className={classes.rootGameConfigPage}>
          <Box>
            <Typography variant="subtitle1" color="textPrimary" style={{ marginBottom: "30px" }}>
              {capitalizedSectionId} Configuration
            </Typography>
            {sectionId === "general" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <RHEnhancedAutocompleteField
                    key={forceRemountKey}
                    name="default_role_id"
                    label="Default Role"
                    required={true}
                    isDynamic={true}
                    advancedSearch={false}
                    api={"role"}
                    idKey={"role_id"}
                    displayValue={"name"}
                    responseDataName={"roles"}
                    defaultOptions={null}
                    multiSelect={false}
                    inputProps={{ maxLength: 50 }}
                    value={gameConfig?.default_role_id || ""}
                    row_key={0}
                    disabled={!isEditMode}
                    sandboxId={currentStateData?.sandbox?.sandboxId}
                    onChange={handleChange}
                    sx={{
                      boxSizing: "border-box",
                      height: "56px",
                      maxWidth: "360px",
                      minWidth: "250px",
                      width: "100%",
                      paddingBottom: "5px",
                      marginBottom: "20px",
                    }}
                  />
                  <TextField
                    label="Inventory session loot (comma separated list)"
                    name="inventory_session_loot"
                    required
                    value={gameConfig?.inventory_session_loot || ""}
                    onChange={handleArrayChange}
                    disabled={!isEditMode}
                    helperText="List of loot IDs to grant to players when they start a new inventory session"
                    style={{ paddingBottom: "5px" }}
                  />

                  <TextField
                    label="Coupon vendor id"
                    name="coupon_vendor_id"
                    value={gameConfig?.coupon_vendor_id || ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                    helperText="Vendor ID that lists all enabled coupons"
                    style={{ paddingBottom: "5px" }}
                  />
                  <TextField
                    label="Email reward loot (comma separated list)"
                    name="email_reward_loot"
                    required
                    value={gameConfig?.email_reward_loot || ""}
                    onChange={handleArrayChange}
                    disabled={!isEditMode}
                    helperText="List of loot IDs to grant to players when they have an email"
                    style={{ paddingBottom: "5px" }}
                  />

                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Allow client to update inventory"
                      name="allow_client_to_update_inventory"
                      checked={
                        gameConfig?.allow_client_to_update_inventory
                          ? gameConfig?.allow_client_to_update_inventory
                          : false
                      }
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />

                    <Tooltip title="Whether or not to allow the client source to update inventory" placement="top">
                      <Typography variant="subtitle2" color="textPrimary">
                        Allow client to update inventory
                      </Typography>
                    </Tooltip>
                  </Box>

                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Basic Enable Login"
                      name="basic.enable_login"
                      checked={gameConfig?.basic ? gameConfig?.basic.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Basic Logins
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Anon Enable Login"
                      name="anon.enable_login"
                      checked={gameConfig?.anon ? gameConfig?.anon.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Anon Logins
                    </Typography>
                  </Box>

                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Coupon enable"
                      name="coupon_enable"
                      checked={gameConfig?.coupon_enable ? gameConfig?.coupon_enable : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />

                    <Tooltip title="Are coupons allowed to be used for purchasing Loot?" placement="top">
                      <Typography variant="subtitle2" color="textPrimary">
                        Coupon enable
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            )}
            {sectionId === "social" && (
              <Box>
                {" "}
                <Box className={classes.gameConfigFormControls}>
                  <RHNumberField
                    required
                    label="Friend limit"
                    name="social.friend_limit"
                    value={gameConfig?.social ? gameConfig?.social.friend_limit : 0}
                    disabled={!isEditMode}
                    change={handleChange}
                  />
                  <RHNumberField
                    label="Block limit"
                    name="social.block_limit"
                    required
                    value={gameConfig?.social ? gameConfig?.social.block_limit : 0}
                    disabled={!isEditMode}
                    change={handleChange}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "epic" && (
              <Box>
                <Box className={classes.gameConfigSwitchLabel}>
                  <Switch
                    label="Enable Login"
                    name="epic.enable_login"
                    checked={gameConfig?.epic ? gameConfig?.epic.enable_login : false}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Typography variant="subtitle2" color="textPrimary">
                    Enable Login
                  </Typography>
                </Box>
                <Box className={classes.gameConfigFormControls}>
                  <TextField
                    label="Client id"
                    name="epic.client_id"
                    value={gameConfig?.epic ? gameConfig?.epic.client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Product id"
                    name="epic.product_id"
                    value={gameConfig?.epic ? gameConfig?.epic.product_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Sandbox id"
                    name="epic.sandbox_id"
                    value={gameConfig?.epic ? gameConfig?.epic.sandbox_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <RHMultiSelectDropdown
                    label="Epic Scopes"
                    name="epic.scopes"
                    configPath="epic.scopes"
                    gameConfig={gameConfig}
                    setGameConfig={setGameConfig}
                    setIsDirty={setIsDirty}
                    isEditMode={isEditMode}
                    defaultValues={["presence", "basic_profile", "friends_list"]}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "steam" && (
              <Box>
                <Box className={classes.gameConfigSwitchLabel}>
                  <Switch
                    label="Enable Login"
                    name="steam.enable_login"
                    checked={gameConfig?.steam ? gameConfig?.steam.enable_login : false}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Typography variant="subtitle2" color="textPrimary">
                    Enable Login
                  </Typography>
                </Box>
                <Box className={classes.gameConfigFormControls}>
                  <TextField
                    required
                    label="Appid"
                    name="steam.appid"
                    value={gameConfig?.steam ? gameConfig?.steam.appid : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
                <Box className={classes.gameConfigSwitchLabel}>
                  <Switch
                    label="Wallet Refunds Trigger Ban"
                    name="steam.wallet_refunds_trigger_ban"
                    checked={gameConfig?.steam ? gameConfig?.steam.wallet_refunds_trigger_ban : false}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Typography variant="subtitle2" color="textPrimary">
                    Wallet Refunds Trigger Ban
                  </Typography>
                </Box>
                <Box className={classes.gameConfigSwitchLabel}>
                  <Switch
                    label="Enforce VAC Bans"
                    name="steam.enforce_vac_bans"
                    checked={gameConfig?.steam ? gameConfig?.steam.enforce_vac_bans : false}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Typography variant="subtitle2" color="textPrimary">
                    Enforce VAC Bans
                  </Typography>
                </Box>
                <Box className={classes.gameConfigSwitchLabel}>
                  <Switch
                    label="Enforce Publisher Bans"
                    name="steam.enforce_publisher_bans"
                    checked={gameConfig?.steam ? gameConfig?.steam.enforce_publisher_bans : false}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Typography variant="subtitle2" color="textPrimary">
                    Enforce Publisher Bans
                  </Typography>
                </Box>
              </Box>
            )}
            {sectionId === "playstation" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="playstation.enable_login"
                      checked={gameConfig?.playstation ? gameConfig?.playstation.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <RHEnhancedAutocompleteField
                    key={forceRemountKey}
                    name="playstation.environment"
                    label="Environment"
                    required={true}
                    isDynamic={false}
                    advancedSearch={false}
                    api={null}
                    idKey={null}
                    displayValue={null}
                    responseDataName={null}
                    defaultOptions={[
                      { id: "sp-int", title: "sp-int" },
                      { id: "prod-qa", title: "prod-qa" },
                      { id: "np", title: "np" },
                    ]}
                    multiSelect={false}
                    inputProps={{ maxLength: 50 }}
                    value={gameConfig?.playstation ? gameConfig?.playstation.environment : ""}
                    row_key={0}
                    disabled={!isEditMode}
                    sandboxId={currentStateData?.sandbox?.sandboxId}
                    onChange={handleChange}
                    sx={{
                      boxSizing: "border-box",
                      height: "56px",
                      maxWidth: "360px",
                      minWidth: "250px",
                      width: "100%",
                    }}
                  />
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS4 use entitlements v1 api"
                      name="playstation.ps4_use_entitlements_v1_api"
                      checked={gameConfig?.playstation ? gameConfig?.playstation.ps4_use_entitlements_v1_api : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS4 use entitlements v1 api
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS5 use entitlements v1 api"
                      name="playstation.ps5_use_entitlements_v1_api"
                      checked={gameConfig?.playstation ? gameConfig?.playstation.ps5_use_entitlements_v1_api : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS5 use entitlements v1 api
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS4 use auth v1 api"
                      name="playstation.ps4_use_auth_v1_api"
                      checked={gameConfig?.playstation ? gameConfig?.playstation.ps4_use_auth_v1_api : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />

                    <Typography variant="subtitle2" color="textPrimary">
                      PS4 use auth v1 api
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS5 use auth v1 api"
                      name="playstation.ps5_use_auth_v1_api"
                      checked={gameConfig?.playstation ? gameConfig?.playstation.ps5_use_auth_v1_api : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS5 use auth v1 api
                    </Typography>
                  </Box>
                  <TextField
                    label="PSN Web Client ID"
                    name="playstation.psn_web_client_id"
                    value={gameConfig?.playstation ? gameConfig?.playstation.psn_web_client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="PS4 Client ID"
                    name="playstation.ps4_client_id"
                    value={gameConfig?.playstation ? gameConfig?.playstation.ps4_client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="PS5 Client ID"
                    name="playstation.ps5_client_id"
                    value={gameConfig?.playstation ? gameConfig?.playstation.ps5_client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS4 V1 Portal Token Details Enable"
                      name="playstation.ps4_v1_portal_token_details_enable"
                      checked={
                        gameConfig?.playstation ? gameConfig?.playstation.ps4_v1_portal_token_details_enable : false
                      }
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS4 V1 Portal Token Details Enable
                    </Typography>
                  </Box>

                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS4 V3 Portal Token Details Enable"
                      name="playstation.ps4_v3_portal_token_details_enable"
                      checked={
                        gameConfig?.playstation ? gameConfig?.playstation.ps4_v3_portal_token_details_enable : false
                      }
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS4 V3 Portal Token Details Enable
                    </Typography>
                  </Box>

                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="PS5 V3 Portal Token Details Enable"
                      name="playstation.ps5_v3_portal_token_details_enable"
                      checked={
                        gameConfig?.playstation ? gameConfig?.playstation.ps5_v3_portal_token_details_enable : false
                      }
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      PS5 V3 Portal Token Details Enable
                    </Typography>
                  </Box>
                  <TextField
                    label="EU Service Labels"
                    name="playstation.eu_service_labels"
                    value={gameConfig?.playstation ? gameConfig?.playstation.eu_service_labels : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="NA Service Labels"
                    name="playstation.na_service_labels"
                    value={gameConfig?.playstation ? gameConfig?.playstation.na_service_labels : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "nintendo" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  {["dd1", "dp1", "sd1", "sp1", "jd1", "lp1"].map((envKey) => (
                    <Box key={envKey}>
                      <Typography variant="h6" color="textPrimary">
                        {envKey.toUpperCase()}
                      </Typography>

                      <Box className={classes.gameConfigSwitchLabel}>
                        <Switch
                          label="Enable Login"
                          name={`nintendo.environments.${envKey}.enable_login`}
                          checked={
                            gameConfig?.nintendo
                              ? gameConfig?.nintendo?.environments?.[envKey]?.enable_login || false
                              : false
                          }
                          onChange={handleChange}
                          disabled={!isEditMode}
                        />
                        <Typography variant="subtitle2" color="textPrimary">
                          Enable Logins
                        </Typography>
                      </Box>

                      <TextField
                        label="Client ID"
                        name={`nintendo.environments.${envKey}.client_id`}
                        value={gameConfig?.nintendo?.environments?.[envKey]?.client_id || ""}
                        onChange={handleChange}
                        disabled={!isEditMode}
                      />

                      <TextField
                        label="Auth Key"
                        name={`nintendo.environments.${envKey}.auth_key`}
                        value={gameConfig?.nintendo?.environments?.[envKey]?.auth_key || ""}
                        onChange={handleChange}
                        disabled={!isEditMode}
                      />
                    </Box>
                  ))}
                  <TextField
                    label="App ID"
                    name="nintendo.app_id"
                    value={gameConfig?.nintendo?.app_id || ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}

            {sectionId === "twitch" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="twitch.enable_login"
                      checked={gameConfig?.twitch ? gameConfig?.twitch.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <TextField
                    label="Twitch Client ID"
                    name="twitch.client_id"
                    value={gameConfig?.twitch ? gameConfig?.twitch.client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Twitch Game ID"
                    name="twitch.game_id"
                    value={gameConfig?.twitch ? gameConfig?.twitch.game_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "amazon" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="amazon.enable_login"
                      checked={gameConfig?.amazon ? gameConfig?.amazon.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <TextField
                    label="Amazon Client ID"
                    name="amazon.client_id"
                    value={gameConfig?.amazon ? gameConfig?.amazon.client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <RHMultiSelectDropdown
                    label="Amazon Scopes"
                    name="amazon.scopes"
                    configPath="amazon.scopes"
                    gameConfig={gameConfig}
                    setGameConfig={setGameConfig}
                    setIsDirty={setIsDirty}
                    isEditMode={isEditMode}
                    defaultValues={["profile:user_id", "games::prime"]}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "google" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="google.enable_login"
                      checked={gameConfig?.google ? gameConfig?.google.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <TextField
                    label="Google Client ID"
                    name="google.client_id"
                    value={gameConfig?.google ? gameConfig?.google.client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "apple" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="apple.enable_login"
                      checked={gameConfig?.apple ? gameConfig?.apple.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <TextField
                    label="Apple Web Client ID"
                    name="apple.web_client_id"
                    value={gameConfig?.apple ? gameConfig?.apple.web_client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Apple Web Auth Key ID"
                    name="apple.web_auth_key_id"
                    value={gameConfig?.apple ? gameConfig?.apple.web_auth_key_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "xbox" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Enable Login"
                      name="xbox.enable_login"
                      checked={gameConfig?.xbox ? gameConfig?.xbox.enable_login : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Enable Login
                    </Typography>
                  </Box>
                  <TextField
                    label="Xbox Web Client ID"
                    name="xbox.web_client_id"
                    value={gameConfig?.xbox ? gameConfig?.xbox.web_client_id : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "vivox" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="Vivox Enable"
                      name="vivox.enable"
                      checked={gameConfig?.vivox ? gameConfig?.vivox.enable : false}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      Vivox Enable
                    </Typography>
                  </Box>
                  <TextField
                    label="Vivox Server"
                    name="vivox.server"
                    value={gameConfig?.vivox ? gameConfig?.vivox.server : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Vivox Key Issuer"
                    name="vivox.key_issuer"
                    value={gameConfig?.vivox ? gameConfig?.vivox.key_issuer : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    required
                    label="Vivox Key Time"
                    name="vivox.key_time"
                    value={gameConfig?.vivox ? gameConfig?.vivox.key_time : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </Box>
              </Box>
            )}
            {sectionId === "legacy" && (
              <Box>
                <Box className={classes.gameConfigFormControls}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Legacy Configuration
                  </Typography>
                  <Typography variant="subtitle2" color="textPrimary">
                    These settings control the legacy configuration of the game
                  </Typography>
                  <TextField
                    label="Allowed Game Versions"
                    name="legacy.allowed_game_versions"
                    value={gameConfig?.legacy ? gameConfig?.legacy.allowed_game_versions || "" : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    label="Experiments"
                    name="legacy.experiments"
                    value={gameConfig?.legacy ? gameConfig?.legacy.experiments || "" : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <RHMultiSelectDropdown
                    label="Game Bits"
                    name="legacy.game_bits"
                    configPath="legacy.game_bits"
                    gameConfig={gameConfig}
                    setGameConfig={setGameConfig}
                    setIsDirty={setIsDirty}
                    isEditMode={isEditMode}
                    defaultValues={[
                      "USE_LOCAL_HOME_MAP",
                      "CHAT_NOTIFY_CHANGES",
                      "CLAN_CAN_CHANGE_SITEID",
                      "GLOBAL_SERVER_DB",
                      "USE_SPECTATOR",
                      "GB_ALLOW_LIVE_SPECTATE",
                    ]}
                  />
                  <RHNumberField
                    required
                    label="Max Party Size"
                    name="legacy.max_party_size"
                    value={gameConfig?.legacy ? gameConfig?.legacy.max_party_size || 0 : 0}
                    disabled={!isEditMode}
                    change={handleChange}
                  />
                  <RHNumberField
                    label="Max Party Info Count"
                    required
                    name="legacy.max_party_info_count"
                    value={gameConfig?.legacy ? gameConfig?.legacy.max_party_info_count || 0 : 0}
                    disabled={!isEditMode}
                    change={handleChange}
                  />
                  <TextField
                    label="Default Map ID"
                    name="legacy.default_map_game_id"
                    value={gameConfig?.legacy ? gameConfig?.legacy.default_map_game_id || 0 : 0}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <TextField
                    required
                    label="Vivox Key Time"
                    name="vivox.key_time"
                    value={gameConfig?.vivox ? gameConfig?.vivox.key_time : ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="EAC Enabled"
                      name="legacy.eac_enabled"
                      checked={!!(gameConfig?.legacy ? gameConfig?.legacy.eac_enabled : false)}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      EAC Enabled
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="EAC Check Ban Status at Login"
                      name="legacy.eac_check_ban_status_at_login"
                      checked={!!(gameConfig?.legacy ? gameConfig?.legacy.eac_check_ban_status_at_login : false)}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      EAC Check Ban Status at Login
                    </Typography>
                  </Box>
                  <Box className={classes.gameConfigSwitchLabel}>
                    <Switch
                      label="EAC Submit Player Reports"
                      name="legacy.eac_submit_player_reports"
                      checked={!!(gameConfig?.legacy ? gameConfig?.legacy.eac_submit_player_reports : false)}
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                      EAC Submit Player Reports
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          <Box className={classes.alertContainer}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              {saveStatus === "success" && (
                <Alert severity="success" onClose={() => setSaveStatus(null)}>
                  Game Configuration saved!
                </Alert>
              )}
              {saveStatus && saveStatus.startsWith("error") && (
                <Alert severity="error" onClose={() => setSaveStatus(null)}>
                  Error saving game configuration: {saveStatus}
                </Alert>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  if (isLoading) {
    return <RHLoadingMask />;
  }

  return isLoading ? <RHLoadingMask /> : renderGameConfigForm();
}
