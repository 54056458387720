import React, { useState, useRef, useEffect } from "react";
import { TableSizeIcon, OpenDrawerIcon, RowMenuIcon } from "assets/svgs";
import { useLayout } from "contexts/LayoutContext";

function useOutsideAlerter(ref, closeFunction) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, closeFunction]);
}

export default function DropdownMenu({ type, onRowSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { setLayout } = useLayout();

  useOutsideAlerter(wrapperRef, () => setIsOpen(false));

  const itemMappings = {
    TableSize: [
      {
        name: "Compact",
        onClick: () => {
          setLayout("global", "tableSize", "text-xs");
        },
      },
      {
        name: "Condensed",
        onClick: () => {
          setLayout("global", "tableSize", "text-sm");
        },
      },
      {
        name: "Standard",
        onClick: () => {
          setLayout("global", "tableSize", "text-base");
        },
      },
    ],
    OpenDrawer: [{ name: "Toggle Drawer", onClick: () => toggleRightDrawer() }],
    RowSelector: [
      { name: "Select", onClick: onRowSelect },
      { name: "Copy", onClick: () => console.log("Copy selected") },
      { name: "Delete", onClick: () => console.log("Delete selected") },
      {
        name: "Export JSON",
        onClick: () => console.log("Export JSON selected"),
      },
    ],
  };

  const items = itemMappings[type] || [];

  const IconComponent =
    type === "OpenDrawer"
      ? OpenDrawerIcon
      : type === "TableSize"
      ? TableSizeIcon
      : RowMenuIcon;

  // Determine header text based on type
  const headerText =
    type === "TableSize"
      ? "Table Size"
      : type === "OpenDrawer"
      ? "Drawer Options"
      : type === "RowSelector"
      ? "Row Actions"
      : "";

  return (
    <div
      ref={wrapperRef}
      className={`relative flex items-center justify-center ${
        type === "RowSelector" ? "pr-0" : "pr-1"
      }`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`text-white font-medium rounded-lg text-sm text-center inline-flex items-center z-10 custom-hover-item ${
          type === "RowSelector" ? "p-0" : "px-1 py-1 mt-1 mb-1"
        }`}
      >
        <IconComponent />
      </button>

      {isOpen && (
        <div
          className={`origin-top-right absolute border border-1 border-white/10  w-48 rounded-md bg-dropDown ring-1 ring-black ring-opacity-5 z-10 ${
            type === "RowSelector" ? "left-7 top-0" : "top-9 right-1"
          }`}
        >
          <div className="py-1 m-1" role="none">
            {headerText && (
              <>
                <div className="px-2">
                  <span className="text-white text-sm font-medium">
                    {headerText}
                  </span>
                </div>
              </>
            )}
          </div>
          <hr className="border-white/10" />
          <div className=" m-1" role="none">
            {items.map((item, index) => (
              <a
                key={index}
                href="#"
                className="text-white/70 block p-2 text-sm custom-hover-item z-11 w-full rounded"
                onClick={(e) => {
                  e.preventDefault();
                  item.onClick();
                  setIsOpen(false);
                }}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
