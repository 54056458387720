import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => {
    return {
        rootRHOrgClusterPage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            flex: 1,
        }
    }
})

export default function RHOrgClusterPage() {
    const classes = useStyles();

    return (
        <Box className={classes.rootRHOrgClusterPage}>
            <Typography variant="h4" color="textPrimary">Org Cluster</Typography>
        </Box>
    )
}