import { useState, useContext, useEffect } from "react";
import AccountContext from "contexts/AccountContext";
import { useOrgUserManagementStyles } from "./styles";
import RHLoadingMask from "components/common/RHLoadingMask";
import { orgAccountColumns } from "./definitions";
import { DataGrid } from "@mui/x-data-grid";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RHOrgAccountPermissions from "./RHOrgAccountPermissions";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildIcon from "@mui/icons-material/Build";
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";
import { Typography } from "@mui/material";
import { Card, CardHeader, CardContent, Avatar } from "@mui/material";
import { List, ListItem, ListItemText, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function RHOrgUserManagement({ onEditState, hasEditPermissions }) {
  const classes = useOrgUserManagementStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [orgAccounts, setOrgAccounts] = useState([]);
  const [uiState, setUiState] = useState("");
  const { showMessage, setIsDirty } = useMessageBar();

  const { currentStateData } = useContext(AccountContext);

  const { getAccessTokenSilently } = useAuth();
  const [selectedOrgAccount, setSelectedOrgAccount] = useState();
  const [selectedOrgAccountInfo, setSelectedOrgAccountInfo] = useState();
  const [editSelectedOrgAccountInfo, setEditSelectedOrgAccountInfo] = useState();

  const [accountEmail, setAccountEmail] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const updateUiState = (state) => {
    setUiState(state);
    onEditState(state !== "");
  };

  async function fetchOrgAccountsData() {
    try {
      const response = await fetch(`${API_BASE}/v1/account-org/${currentStateData?.org?.orgId}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
      });
      const orgAcc = await response.json();

      setOrgAccounts(orgAcc);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function fetchAccountExpandData(accountId) {
    try {
      const response = await fetch(`${API_BASE}/v1/account_expand/${accountId}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
      });
      const orgAcc = await response.json();

      setSelectedOrgAccountInfo(orgAcc);
      setEditSelectedOrgAccountInfo(false);
      setIsLoading(false);
    } catch (error) {
      console.error("got this error", error);
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    try {
      await fetch(`${API_BASE}/v1/org/${currentStateData?.org?.orgId}/account-org/${selectedOrgAccount}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "DELETE",
      });

      const updatedAccounts = orgAccounts.filter((account) => selectedOrgAccount !== account.account_id);
      setOrgAccounts(updatedAccounts);
      setSelectedOrgAccount(null);
      showMessage(`User deleted successfully`, "success");
    } catch (error) {
      console.error(error);
      showMessage(`Failed to delete user: ${error.message}`, "error");
    }
  }

  async function saveOrgSelectedAccountInfo() {
    try {
      let accountUpdate = {
        name: name,
        nickname: nickname,
      };

      await fetch(`${API_BASE}/v1/account/${selectedOrgAccount}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify(accountUpdate),
      });

      showMessage(`User updated successfully`, "success");
      resetAccountForm();
      setEditSelectedOrgAccountInfo(false);
      await fetchAccountExpandData(selectedOrgAccount);
    } catch (error) {
      console.error(error);
      showMessage(`Failed to update user: ${error.message}`, "error");
    }
  }

  useEffect(() => {
    if (currentStateData?.org?.orgId) {
      fetchOrgAccountsData();
    }
  }, [currentStateData]);

  useEffect(() => {
    checkEnableSave();
  }, [accountEmail, firstName, lastName, nickname, password]);

  const handleAddUser = () => {
    setIsDirty(true);
    updateUiState("addAccount");
  };

  const handleAddUserPermission = () => {
    updateUiState("modifyAccountPermission");
  };

  const handleSelectedOrgAccount = (newSelection) => {
    let selectedAccountId = newSelection[0];
    setSelectedOrgAccount(selectedAccountId);
    fetchAccountExpandData(selectedAccountId);
  };

  const handlePasswordReset = async () => {
    try {
      await fetch(`${API_BASE}/v1/account_pwd_reset/${selectedOrgAccount}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "POST",
      });
      resetAccountForm();
      showMessage(`Password reset email sent successfully`, "success");
    } catch (error) {
      console.error(error);
      showMessage(`Failed to send password reset email: ${error.message}`, "error");
    }
  };

  const handleAddUserSave = async () => {
    try {
      let newAccount = {
        org_id: currentStateData?.org?.orgId,
        account_email: accountEmail,
        first_name: firstName,
        last_name: lastName,
        nickname: nickname,
        password: password,
      };

      const response = await fetch(`${API_BASE}/v1/org/${currentStateData?.org?.orgId}/account-org`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(newAccount),
      });
      const savedAccount = await response.json();

      showMessage(`User ${accountEmail} added successfully`, "success");
      resetAccountForm();
      await fetchOrgAccountsData();
      setSelectedOrgAccount(savedAccount.account_id);
      setIsDirty(false);
      updateUiState("modifyAccountPermission");
    } catch (error) {
      console.error(error);
      showMessage(`Failed to add user ${accountEmail}`, "error");
    }
  };

  const resetAccountForm = () => {
    //reset forum
    setAccountEmail("");
    setFirstName("");
    setLastName("");
    setNickname("");
    setPassword("");
    setName("");
  };

  const handleAddUserCancel = () => {
    setIsDirty(false);
    updateUiState("");
    setSelectedOrgAccount(null);
    resetAccountForm();
  };

  const checkEnableSave = () => {
    // Check for a valid email format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(accountEmail);

    // Check if first name, last name, and nickname have some value provided
    const areNamesValid = firstName && lastName && nickname;

    // Check if the password meets the specified requirements
    const isPasswordValid =
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password);

    // Set enableSave to true if all criteria are met
    if (isEmailValid && areNamesValid && isPasswordValid) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  };

  const handleAccountEmailChange = (event) => {
    setAccountEmail(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEditOrgSelectedAccountInfo = () => {
    setName(selectedOrgAccountInfo.name);
    setNickname(selectedOrgAccountInfo.nickname);
    setEditSelectedOrgAccountInfo(true);
  };

  const handleCancelOrgSelectedAccountInfo = () => {
    resetAccountForm();
    setEditSelectedOrgAccountInfo(false);
  };

  const handleSaveOrgSelectedAccountInfo = () => {
    saveOrgSelectedAccountInfo();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderAddAccountUI = (enableSave, accountEmail, firstName, lastName, nickname, password) => {
    return (
      <Box className={classes.addAccountContainer}>
        <Box className={classes.addAccountButtonBar}>
          <Button className={classes.buttons} variant="contained" onClick={handleAddUserSave} disabled={!enableSave}>
            Save
          </Button>
          <Button className={classes.buttons} variant="contained" onClick={handleAddUserCancel}>
            Cancel
          </Button>
        </Box>
        <Box className={classes.addAccountForum}>
          <Typography variant="body1" color="text.secondary">
            * Required Field
          </Typography>
          <TextField label="Account Email *" value={accountEmail} onChange={handleAccountEmailChange} />
          <TextField label="First Name *" value={firstName} onChange={handleFirstNameChange} />
          <TextField label="Last Name *" value={lastName} onChange={handleLastNameChange} />
          <TextField label="Nickname *" value={nickname} onChange={handleNicknameChange} />
          <TextField
            label="Password *"
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="body1" color="text.secondary">
            Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter,
            one number, and one special character.
          </Typography>
        </Box>
      </Box>
    );
  };

  function CustomAccountToolbar() {
    if (hasEditPermissions) {
      return (
        <GridToolbarContainer>
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton />
          <Button className={classes.buttons} startIcon={<AddIcon />} onClick={handleAddUser}>
            Add User
          </Button>
          {selectedOrgAccount && (
            <>
              <Button className={classes.buttons} startIcon={<DeleteIcon />} onClick={handleDelete}>
                Delete User
              </Button>
              <Button className={classes.buttons} startIcon={<BuildIcon />} onClick={handleAddUserPermission}>
                Modify Permissions
              </Button>
              <Button className={classes.buttons} startIcon={<BuildIcon />} onClick={handlePasswordReset}>
                Send Password Reset
              </Button>
            </>
          )}
        </GridToolbarContainer>
      );
    }

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const renderUsersGrid = () => {
    return (
      <DataGrid
        className={classes.table}
        autoHeight
        rows={orgAccounts}
        columns={orgAccountColumns}
        getRowId={(row) => row.account_id}
        selectionModel={selectedOrgAccount}
        onRowSelectionModelChange={handleSelectedOrgAccount}
        slots={{
          toolbar: CustomAccountToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          bgcolor: "background.paper",
          border: "none",
          minWidth: 0,
          p: 1,
        }}
      />
    );
  };

  const renderSelectedOrgAccount = () => {
    return (
      <Card>
        <CardHeader
          avatar={<Avatar alt={selectedOrgAccountInfo.nickname} src={selectedOrgAccountInfo.avatar} />}
          title={selectedOrgAccountInfo.name}
          subheader={`${selectedOrgAccountInfo.nickname}`}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textSecondary">
                    <strong>Email:</strong> {selectedOrgAccountInfo.account_email}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textSecondary">
                    <strong>Account ID:</strong> {selectedOrgAccountInfo.account_id}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textSecondary">
                    <strong>Last IP:</strong> {selectedOrgAccountInfo.last_ip}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textSecondary">
                    <strong>Last Login:</strong> {new Date(selectedOrgAccountInfo.last_login).toLocaleString()}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  };

  const renderEditSelectedOrgAccount = () => {
    return (
      <Box className={classes.addAccountForum}>
        <Typography variant="body1" color="text.secondary">
          * Required Field
        </Typography>
        <TextField label="Name *" value={name} onChange={handleNameChange} />
        <TextField label="Nickname *" value={nickname} onChange={handleNicknameChange} />
      </Box>
    );
  };

  if (isLoading) {
    return <RHLoadingMask />;
  }

  return (
    <Box className={classes.rootConfigPage}>
      {uiState === "" && <>{renderUsersGrid()}</>}
      {uiState === "addAccount" &&
        renderAddAccountUI(enableSave, accountEmail, firstName, lastName, nickname, password)}
      {uiState === "modifyAccountPermission" && (
        <RHOrgAccountPermissions
          uiState={uiState}
          setUiState={updateUiState}
          orgId={currentStateData?.org?.orgId}
          selectedOrgAccount={selectedOrgAccount}
          setSelectedOrgAccount={setSelectedOrgAccount}
          account={orgAccounts.find((account) => account.account_id === selectedOrgAccount)}
        />
      )}
      {selectedOrgAccountInfo && !editSelectedOrgAccountInfo && uiState === "" && (
        <>
          <Box className={classes.editAccountButtonBar}>
            <Button className={classes.buttons} variant="contained" onClick={handleEditOrgSelectedAccountInfo}>
              Edit
            </Button>
          </Box>
          {renderSelectedOrgAccount()}
        </>
      )}
      {selectedOrgAccountInfo && editSelectedOrgAccountInfo && (
        <>
          <Box className={classes.editAccountButtonBar}>
            <Button className={classes.buttons} variant="contained" onClick={handleSaveOrgSelectedAccountInfo}>
              Save
            </Button>
            <Button className={classes.buttons} variant="contained" onClick={handleCancelOrgSelectedAccountInfo}>
              Cancel
            </Button>
          </Box>
          {renderEditSelectedOrgAccount()}
        </>
      )}
    </Box>
  );
}
