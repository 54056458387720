export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "datacenter_provider_id",
    headerName: "Datacenter Provider ID",
    minWidth: 200,
    type: "metadata",
    flex: 2,
  },
];

export class NewEntry {
  name = "";
}
