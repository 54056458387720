import DataTableFilter from "./DataTableFilter";
import { PlusIcon } from "../../../../assets/svgs";

// Main DataTableToolbar component
export default function DataTableToolbar({
  table,
  globalFilter,
  setGlobalFilter,
  buttons,
}) {
  return (
    // Main container for the toolbar
    <div className="flex items-center justify-between bg-[#121212] w-full py-2 px-2 border-l border-r border-zinc-800">
      {/* Filter component */}
      <DataTableFilter value={globalFilter} onChange={setGlobalFilter} />

      {/* Dynamic buttons */}
      <div className="flex gap-2">
        {buttons &&
          buttons.map(({ name, fn, args = [] }, i) => (
            <button
              key={i + name + "menubar"}
              title={name}
              onClick={() => fn(...args)}
              className="bg-violet-600 text-white font-medium rounded-sm text-sm text-center inline-flex items-center z-10 px-1 py-1 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500"
            >
              <PlusIcon width="16" fill="currentColor" />
              {/* <span>{name}</span> */}
            </button>
          ))}
      </div>
    </div>
  );
}
