export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  }
];

export class NewEntry {
  name = "";
}
