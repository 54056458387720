import React from "react";
import splashLogo from "../../assets/rally-here-R-logo-400x400.png";

export default function RHDynamicLoadingMask({ loadMessage = "Looking for data...", showError = false }) {
  return (
    <div className="flex flex-col items-center justify-start bg-zinc-900 text-center h-screen text-white space-y-4">
      <div className="relative flex items-center justify-center mt-8">
        {showError ? (
          <svg
            className="w-14 h-14 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        ) : (
          <>
            <div className="animate-spin rounded-full h-14 w-14 border-t-2 border-b-2 border-violet-500"></div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full overflow-hidden flex items-center justify-center">
              <img src={splashLogo} alt="Logo" className="w-full" />
            </div>
          </>
        )}
      </div>
      <p className="text-sm max-w-[80%] text-white font-normal leading-relaxed font-sans">{loadMessage}</p>
    </div>
  );
}
