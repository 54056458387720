import { useState, useContext, useEffect, useMemo } from "react";
import { usePortalQuery } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { useLayout } from "contexts/LayoutContext";
import BasicSelect from "components/common/FormField/BasicSelect";
import BasicMultiSelect from "components/common/FormField/BasicMultiSelect";
import { ChevronIcon } from "assets/svgs";

const environmentLevels = ["environment"];
const sandboxLevels = ["sandbox"];
const productLevels = ["product", "clients", "policies"].concat(
  environmentLevels,
  sandboxLevels
);
const orgLevels = [
  "org",
  "account",
  "accountPermissions",
  "accountOrgPermissions",
  "accountOrg",
  "permissionsOrgGroup",
  "permissionsOrgGroupAccount",
].concat(productLevels);

export default function PermissionsEditor({
  originalValue,
  fieldGroup,
  onChange,
  rowKey,
  permissionsKey,
  editPermitted,
}) {
  const { getAccessToken } = useAuth();
  const { currentStateData } = useContext(AccountContext);
  const { tableSize } = useLayout();
  const [showSummary, setShowSummary] = useState(false);
  const [permissionSummary, setPermissionSummary] = useState();
  const [selectedProduct, setSelectedProductFn] = useState();
  const [selectedEnvironment, setSelectedEnvironment] = useState();
  const [selectedSandbox, setSelectedSandbox] = useState();

  function filterArchived(permission) {
    // This check ignores the dirty permissions that are not saved yet
    if (
      (!permission.org_name && permission.org_id) ||
      (!permission.sandbox_name && permission.sandbox_id) ||
      (!permission.environment_name && permission.environment_id) ||
      (!permission.product_name && permission.product_id)
    ) {
      return true;
    }

    const levelName = Object.keys(permission).find((key) =>
      key.endsWith("_name")
    );
    const level = levelName.replace("_name", "");
    const levelIdKey = `${level}_id`;

    if (level === "product") {
      const product = currentStateData?.org?.products.find(
        (product) => product.productId === permission[levelIdKey]
      );

      if (!product || product?.archive) {
        return false;
      }
    }

    let plural = level === "environment" ? "environments" : "sandboxes";

    if (level === "sandbox" || level === "environment") {
      let isArchive = true;
      for (const product of currentStateData?.org?.products) {
        const entity = product?.[plural]?.find(
          (entity) => entity[`${level}Id`] === permission[levelIdKey]
        );

        if (entity) {
          if (entity && !entity?.archive) {
            isArchive = false;
          }
        }
      }

      return !isArchive;
    }

    return true;
  }

  const accountPermissions = useMemo(() => {
    if (!fieldGroup) return [];
    return permissionsKey
      ? fieldGroup?.[permissionsKey].filter(filterArchived)
      : fieldGroup.filter(filterArchived);
  }, [fieldGroup, permissionsKey]);

  const permissionsQuery = usePortalQuery({
    schema: "PortalPermission",
    token: getAccessToken(),
  });

  function getParentId(id, singular, plural) {
    let productId;
    for (const product of currentStateData?.org?.products) {
      productId = product?.productId;
      for (const child of product?.[plural]) {
        if (child?.[`${singular}Id`] === id) {
          return productId;
        }
      }
    }
    return;
  }

  function locatePermission(id, level) {
    let productId;
    switch (level) {
      case "org":
        setSelectedProduct(null);
        setSelectedSandbox(null);
        setSelectedEnvironment(null);
        break;
      case "product":
        setSelectedSandbox(null);
        setSelectedEnvironment(null);
        setSelectedProduct(id);
        break;
      case "sandbox":
        productId = getParentId(id, "sandbox", "sandboxes");
        setSelectedSandbox(id);
        setSelectedProduct(productId, false);
        break;
      case "environment":
        productId = getParentId(id, "environment", "environments");
        setSelectedProduct(productId, false);
        setSelectedEnvironment(id);
        break;
    }
  }

  const org_permissions = useMemo(() => {
    if (accountPermissions) {
      return accountPermissions.reduce((acc, curr) => {
        if (
          curr?.org_id === currentStateData?.org?.orgId &&
          curr.permission_id !== "globalAdmin:*:*"
        ) {
          acc.push(curr?.permission_id);
        }
        return acc;
      }, []);
    } else return [];
  }, [accountPermissions]);

  const product_permissions = useMemo(() => {
    if (accountPermissions) {
      const products = currentStateData?.org?.products.map(
        (product) => product?.productId
      );

      return accountPermissions.reduce((acc, curr) => {
        if (
          products.includes(curr?.product_id) &&
          curr?.product_id === selectedProduct
        ) {
          acc.push(curr.permission_id);
        }
        return acc;
      }, []);
    } else return [];
  }, [selectedProduct, accountPermissions]);

  const sandbox_permissions = useMemo(() => {
    if (accountPermissions) {
      const sandboxes = currentStateData?.org?.products
        .find((product) => product?.productId === selectedProduct)
        ?.sandboxes?.map((sandbox) => sandbox?.sandboxId);

      return sandboxes
        ? accountPermissions.reduce((acc, curr) => {
            if (
              sandboxes.includes(curr?.sandbox_id) &&
              curr?.sandbox_id === selectedSandbox
            ) {
              acc.push(curr.permission_id);
            }
            return acc;
          }, [])
        : [];
    } else return [];
  }, [selectedProduct, selectedSandbox, accountPermissions]);

  const environment_permissions = useMemo(() => {
    if (accountPermissions) {
      const environments = currentStateData?.org?.products
        .find((product) => product?.productId === selectedProduct)
        ?.environments?.map((environment) => environment?.environmentId);

      return environments
        ? accountPermissions.reduce((acc, curr) => {
            if (
              environments.includes(curr?.environment_id) &&
              curr?.environment_id === selectedEnvironment
            ) {
              acc.push(curr.permission_id);
            }
            return acc;
          }, [])
        : [];
    } else return [];
  }, [selectedProduct, selectedEnvironment, accountPermissions]);

  // Trim out all level permissions that already exist and replace them with the new permissions formatted correctly
  function handlePermissionChange(permissions, levelObj) {
    const [level, levelValue] = Object.entries(levelObj)[0];

    const trimmedPermissions = accountPermissions.filter(
      (p) => p[level] !== levelValue
    );

    const permissionsArr = permissions.map((permission) => {
      return {
        ...levelObj,
        permission_id: permission,
      };
    });

    if (permissionsKey) {
      onChange({
        ...fieldGroup,
        [permissionsKey]: trimmedPermissions.concat(permissionsArr),
      });
    } else {
      onChange(trimmedPermissions.concat(permissionsArr));
    }
  }

  function setSelectedProduct(productId, reset = true) {
    // reset is for when a product is changed in a dropdown. I want to maintain both
    //sandbox/environment state when a user clicks a summary element link
    setSelectedProductFn(productId);
    if (reset) {
      setSelectedSandbox(null);
      setSelectedEnvironment(null);
    }
  }

  function generatePermissionSummary() {
    const perms = accountPermissions.reduce((acc, curr) => {
      const levelKey = Object.keys(curr).find((key) => key.endsWith("_name"));
      const level = levelKey.replace("_name", "");
      if (curr.permission_id !== "globalAdmin:*:*") {
        const summaryKey =
          level === "org" ? "Organization" : `${curr[levelKey]}`;
        if (!acc[summaryKey]) {
          acc[summaryKey] = {
            permissions: [curr.permission_id],
            id: curr[`${level}_id`],
            level: level,
          };
        } else {
          acc[summaryKey]?.permissions.push(curr.permission_id);
        }
      }
      return acc;
    }, {});

    const sortedPerms = Object.entries(perms).sort((a, b) => {
      const levelOrder = { org: 0, product: 1, sandbox: 2, environment: 3 };
      return levelOrder[a[1].level] - levelOrder[b[1].level];
    });

    setPermissionSummary(Object.fromEntries(sortedPerms));
  }

  useEffect(() => {
    setSelectedProduct();
    setSelectedSandbox();
    setSelectedEnvironment();
  }, [originalValue]);

  useEffect(() => {
    if (
      accountPermissions &&
      accountPermissions.every(
        (perm) =>
          (perm.org_name && perm.org_id) ||
          (perm.sandbox_name && perm.sandbox_id) ||
          (perm.environment_name && perm.environment_id) ||
          (perm.product_name && perm.product_id)
      )
    ) {
      generatePermissionSummary();
    }
  }, [accountPermissions]);

  return (
    <div className="pb-52">
      <div className={`cursor-pointer ${tableSize}`}>
        <p
          className="flex justify-end items-center"
          onClick={() => setShowSummary(!showSummary)}
        >
          View Summary
          <span className={`transition-all ${showSummary ? "" : "rotate-180"}`}>
            {<ChevronIcon className="w-5 h-5" />}
          </span>
        </p>
        <div
          className={`text-white grid transition-all rounded mb-4 px-3 ${
            showSummary
              ? "grid-rows-[1fr] py-3 border border-white/10"
              : "grid-rows-[0fr] border-transparent"
          }`}
        >
          <div className="overflow-hidden">
            {permissionSummary && Object.keys(permissionSummary).length > 0 ? (
              Object.keys(permissionSummary).map((summary) => (
                <div
                  className="cursor-pointer flex gap-2 mb-2 last:mb-0 border-b border-white/10 pb-2 last:border-none last:pb-0 font-bold"
                  key={summary}
                  onClick={() =>
                    locatePermission(
                      permissionSummary[summary].id,
                      permissionSummary[summary].level
                    )
                  }
                >
                  <div className="w-20 text-right pr-2 capitalize border-r border-white/10">
                    {permissionSummary[summary].level}
                  </div>
                  <div>{`${summary}: `}</div>
                  <div className="font-normal flex flex-wrap">
                    {`${permissionSummary[summary].permissions.join(", ")}`}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-white">No permissions found</p>
            )}
          </div>
        </div>
      </div>
      {currentStateData?.org && permissionsQuery.data && (
        <div
          className={`pb-4 pt-0 border-b border-white/10 ${
            editPermitted ? "" : "pointer-events-none"
          }`}
        >
          <BasicMultiSelect
            options={permissionsQuery.data
              ?.filter((perm) => orgLevels.includes(perm.type))
              ?.map((permission) => ({
                id: permission.permission_id,
                title: permission.permission_id,
              }))}
            onChange={(e) =>
              handlePermissionChange(e.target.value, {
                org_id: currentStateData?.org?.orgId,
              })
            }
            value={org_permissions}
            name="org permissions"
            label={`${currentStateData?.org?.orgName} Organization Permissions`}
          />
        </div>
      )}
      <div className="pt-4 pb-2 border-b border-white/10">
        <BasicSelect
          label="Products"
          name="product"
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          options={currentStateData?.org?.products
            ?.filter((product) => !product.archive)
            ?.map((product) => ({
              id: product.productId,
              title: product.productName,
            }))}
          placeholder="Select a product"
          description="Select a product to view permissions"
        />

        {selectedProduct && (
          <div
            className={`pt-0 pb-2 ${
              editPermitted ? "" : "pointer-events-none"
            }`}
          >
            <BasicMultiSelect
              options={permissionsQuery.data
                ?.filter((perm) => productLevels.includes(perm.type))
                ?.map((permission) => ({
                  id: permission.permission_id,
                  title: permission.permission_id,
                }))}
              value={product_permissions}
              name="product permissions"
              onChange={(e) =>
                handlePermissionChange(e.target.value, {
                  product_id: selectedProduct,
                })
              }
              className={`${editPermitted ? "" : "pointer-events-none"}`}
            />
          </div>
        )}
      </div>
      {selectedProduct && (
        <div className="pt-4 pb-2 border-b border-white/10">
          <BasicSelect
            label="Sandboxes"
            name="sandbox"
            value={selectedSandbox}
            onChange={(e) => setSelectedSandbox(e.target.value)}
            options={currentStateData?.org?.products
              .find((product) => product.productId === selectedProduct)
              .sandboxes?.filter((sandbox) => !sandbox.archive)
              ?.map((sandbox) => ({
                id: sandbox.sandboxId,
                title: sandbox.sandboxName,
              }))}
            placeholder="Select a sandbox"
            description="Select a sandbox to view permissions"
          />
          {selectedSandbox && (
            <div
              className={`pb-2 pt-0 ${
                editPermitted ? "" : "pointer-events-none"
              }`}
            >
              <BasicMultiSelect
                options={permissionsQuery.data
                  ?.filter((perm) => sandboxLevels.includes(perm.type))
                  ?.map((permission) => ({
                    id: permission.permission_id,
                    title: permission.permission_id,
                  }))}
                value={sandbox_permissions}
                name="sandbox permissions"
                onChange={(e) =>
                  handlePermissionChange(e.target.value, {
                    sandbox_id: selectedSandbox,
                  })
                }
                className={`${editPermitted ? "" : "pointer-events-none"}`}
              />
            </div>
          )}
        </div>
      )}
      {selectedProduct && (
        <div className="pt-4 pb-2 border-b border-white/10">
          <BasicSelect
            label="Environments"
            name="environment"
            value={selectedEnvironment}
            onChange={(e) => setSelectedEnvironment(e.target.value)}
            options={currentStateData?.org?.products
              .find((product) => product.productId === selectedProduct)
              .environments?.filter((environment) => !environment.archive)
              ?.map((environment) => ({
                id: environment.environmentId,
                title: environment.environmentName,
              }))}
            placeholder="Select an environment"
            description="Select an environment to view permissions"
          />
          {selectedEnvironment && (
            <div
              className={`pb-2 pt-0 ${
                editPermitted ? "" : "pointer-events-none"
              }`}
            >
              <BasicMultiSelect
                options={permissionsQuery.data
                  ?.filter((perm) => environmentLevels.includes(perm.type))
                  ?.map((permission) => ({
                    id: permission.permission_id,
                    title: permission.permission_id,
                  }))}
                value={environment_permissions}
                name="environment permissions"
                onChange={(e) =>
                  handlePermissionChange(e.target.value, {
                    environment_id: selectedEnvironment,
                  })
                }
                className={`${editPermitted ? "" : "pointer-events-none"}`}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
