import { useState, useRef, useEffect } from "react";
import Tooltip from "components/common/FormField/Tooltip";
import { useLayout } from "contexts/LayoutContext";
import { CloseIcon, DropDownArrow } from "assets/svgs";

// Updated Chip component
const Chip = ({ children, onDelete }) => (
  <span className="inline-flex items-center px-2 py-1 m-1 rounded bg-zinc-600 text-zinc-200">
    {children}
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete();
      }}
      className="ml-1 text-zinc-300 hover:text-red-500"
    >
      <CloseIcon />
    </button>
  </span>
);

const BasicMultiSelect = ({
  label,
  name,
  value,
  onChange,
  options = [],
  required,
  disabled,
  placeholder,
  description,
}) => {
  const { tableSize } = useLayout();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionId) => {
    const newValue = value.includes(optionId)
      ? value.filter((id) => id !== optionId)
      : [...value, optionId];
    onChange({ target: { name, value: newValue } });
  };

  const handleRemoveChip = (optionId) => {
    console.log("optionId", optionId);
    const newValue = value.filter((id) => id !== optionId);
    onChange({ target: { name, value: newValue } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div className="container-query-formfield">
        <div className="cq-wrapper">
          <label
            className={`block font-medium text-zinc-300 font-lato ${
              tableSize === "text-xs" || tableSize === "text-sm"
                ? "text-xs"
                : "text-sm"
            }`}
          >
            <Tooltip title={description} placement="left" disableInteractive>
              <p className="mb-1 cq-label">
                <span>{`${label ? label : ""} ${required ? "*" : ""}`}</span>
              </p>
            </Tooltip>
          </label>
          <div
            className={`w-full text-white border border-zinc-600 rounded p-2 
                    focus:outline-none focus:ring-2 focus:ring-violet-500 
                    placeholder-zinc-400 bg-opacity-20 bg-zinc-700 
                    backdrop-filter backdrop-blur-sm
                    flex flex-wrap items-start cursor-pointer ${
                      disabled ? "opacity-50" : ""
                    }`}
            onClick={() => !disabled && handleToggle()}
          >
            <div
              className={`flex-grow text-left overflow-hidden flex flex-wrap gap-2 ${tableSize}`}
            >
              {value.length > 0 ? (
                value.map((optionId) => {
                  const option = options.find((opt) => opt.id === optionId);
                  return (
                    option && (
                      <Chip
                        key={optionId}
                        onDelete={() => handleRemoveChip(optionId)}
                      >
                        {option.title}
                      </Chip>
                    )
                  );
                })
              ) : (
                <span
                  className={`${
                    disabled ? "text-zinc-500" : "text-zinc-400"
                  } block truncate`}
                >
                  {disabled
                    ? `No ${name} selected`
                    : placeholder || `Please select ${name}`}
                </span>
              )}
            </div>
            <div className="absolute top-2 right-2">
              <DropDownArrow
                className={`${disabled ? "text-zinc-500" : "text-zinc-300"} ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpen && !disabled && (
        <div className="absolute z-50 w-full mt-1 bg-zinc-800 rounded-md shadow-lg border border-zinc-600">
          <ul className="max-h-60 overflow-y-auto">
            {options.map((option) => (
              <li
                key={option.id}
                className={`p-2 text-sm cursor-pointer ${
                  value.includes(option.id)
                    ? "bg-zinc-600"
                    : "text-gray-300 hover:bg-zinc-600"
                }`}
                onClick={() => handleOptionClick(option.id)}
              >
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

BasicMultiSelect.defaultProps = {
  options: [],
  required: false,
  disabled: false,
  value: [],
};

export default BasicMultiSelect;
