export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "vm_sku",
    headerName: "SKU",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "preferred",
    headerName: "Preferred",
    minWidth: 200,
    editable: true,
    flex: 1,
    type: "boolean",
    nullable: false,
    default: false,
  }
];

export class NewEntry {
  name = "";
}
