import React, { useState, useEffect } from "react";

const ChipInput = ({ label, name, value, onChange, required, disabled, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);

  useEffect(() => {
    // Ensure value is always treated as an array
    setChips(Array.isArray(value) ? value : value ? [value] : []);
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    if (inputValue.trim()) {
      const newChips = [...chips, inputValue.trim()];
      setChips(newChips);
      setInputValue("");
      onChange(newChips);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      e.preventDefault();
      handleInputBlur();
    }
  };

  const removeChip = (index) => {
    const newChips = chips.filter((_, i) => i !== index);
    setChips(newChips);
    onChange(newChips);
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap gap-2 p-2 bg-zinc-800 border border-zinc-600 rounded-md focus-within:ring-2 focus-within:ring-violet-500">
        {chips.map((chip, index) => (
          <div key={index} className="flex items-center bg-zinc-700 text-white px-2 py-1 rounded-md">
            <span>{chip}</span>
            <button
              type="button"
              onClick={() => removeChip(index)}
              className="ml-2 text-zinc-400 hover:text-white focus:outline-none"
            >
              ×
            </button>
          </div>
        ))}
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          className="flex-grow bg-transparent text-white focus:outline-none"
        />
      </div>
    </div>
  );
};

ChipInput.defaultProps = {
  required: false,
  disabled: false,
  value: [],
};

export default ChipInput;
