import { IconButton, Snackbar } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import { useState, useEffect } from "react";
import definitions from "../../common/definitions.json";
import { apiRequest, useApiToken } from "../../common/apiUtils";

export default function RHDataOnlyField(props) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displayValue, setDisplayValue] = useState(props.value);
  const [accountData, setAccountData] = useState({});
  const { label, inactive, name } = props;
  const token = useApiToken();

  const [accountInfo, setAccountInfo] = useState({
    email: props.value,
    id: "",
    showEmail: true,
  });

  useEffect(() => {
    if (props.name === "last_modified_timestamp") {
      if (props.value) {
        const valueFormatted = new Date(props.value);

        setDisplayValue(
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(valueFormatted)
        );
      } else {
        setDisplayValue("");
      }
    } else {
      setDisplayValue(props.value);
    }

    setAccountData({});
  }, [props.value, props.name]);

  const fetchData = async () => {
    if (name === "last_modified_account_id" || name === "account_id") {
      const getEndpoint = definitions.schemas?.Account?.endpoints?.find((e) => e.method === "get");
      if (getEndpoint) {
        try {
          const replacements = { account_identifier: props.value };
          const fetchedData = await apiRequest(token, getEndpoint, replacements);
          setAccountData(fetchedData); // Store fetched data
          // Set initial display value to account_email if it exists
          setDisplayValue(fetchedData.account_email || fetchedData.account_id || props.value);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const toggleDisplayValue = () => {
    setDisplayValue((prevValue) =>
      prevValue === accountData.account_email ? accountData.account_id : accountData.account_email
    );
  };

  const onIconClick = () => {
    if (Object.keys(accountData).length > 0) {
      // If data is already fetched, toggle the displayed value
      toggleDisplayValue();
    } else {
      // If no data is fetched yet, fetch the data
      fetchData();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(displayValue)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div
      className={`cursor-copy relative box-border inline-block ml-[-1px] mt-[-7px] min-w-[252px] ${
        props.fullWidth ? "max-w-full" : "max-w-[354px]"
      } w-full`}
    >
      <fieldset
        className={`flex flex-col items-center ${
          inactive ? "bg-black/30" : "bg-[#1E1E1E]"
        } rounded box-border text-white/50 font-roboto font-normal h-[63px] w-full`}
      >
        <legend className="px-[6px] text-[0.75em]">{label}</legend>
        <div
          onClick={copyToClipboard}
          className={`flex flex-col items-start justify-center px-1.5 ${
            inactive ? "bg-black/30" : "bg-[#1E1E1E]"
          } rounded box-border text-white/50 font-roboto font-normal h-[63px] w-full`}
        >
          {displayValue}
        </div>
      </fieldset>

      {(name === "last_modified_account_id" || name === "account_id") && (
        <Tooltip title="Switch Account Info" placement="right">
          <IconButton
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className=""
            onClick={onIconClick}
          >
            <PersonIcon fontSize="small" style={{ color: "#ffffff80" }} />
          </IconButton>
        </Tooltip>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <>
            <div className="text-center">{`${displayValue}`}</div>
            <div className="text-center">{"copied to clipboard!"}</div>
          </>
        }
      />
    </div>
  );
}
