import { TextField, Tooltip, InputAdornment, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import RHJsonField from "../RHJsonField";
import RHAutocompleteField from "../RHAutocompleteField";
import RHEnhancedAutocompleteField from "../RHEnhancedAutocompleteField";
import RHBooleanField from "../RHBooleanField";
import RHDataOnlyField from "../RHDataOnlyField";
import RHNumberField from "../RHNumberField";
import RepeaterField from "../RepeaterField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  getGeneralFormStylesSx,
  getGeneralDisabledFormStylesSx,
  getGeneralAlwaysDisabledFormStylesSx,
} from "./tableHelpers";
import RHTextField from "../RHTextField";
import SingleDynamicSelect from "../DynamicSelect/SingleDynamicSelect";
import RHDynamicSelectField from "../RHDynamicSelectField";

export default function FormField({
  forceRemountKey,
  selectedItem,
  editedDetails,
  setEditedDetails,
  editDetail,
  field,
  parentKey,
  rowKey,
  newItemCreated,
  sandbox,
  handleValidationError,
  hasError,
  pageData,
  fullWidth,
  customEditDetailArgs,
  arrayKey,
  parentRowKey,
  setForceRemountKey,
}) {
  if (!selectedItem) {
    return;
  }

  const handleEditDetailValue = (e, parentKey) => {
    // Handling a special case for a datepicker that returns a dayjs object.
    if (Object.hasOwn(e, "$d")) {
      e.target = {};
      e.target.value = dayjs(e.$d).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      e.target.name = field.field; // Ensure 'field' is defined and accessible here.
      e.target.type = "date";
    }

    let { name, value, checked, type } = e.target;

    setEditedDetails((values) => {
      let convertedValue = value;

      if (value) {
        if (field.type === "number") {
          // Preserve the string value during editing
          if (value === "." || value.endsWith(".") || value.startsWith(".")) {
            convertedValue = value; // Keep as string to allow continued editing
          } else if (!isNaN(parseFloat(value)) && isFinite(value)) {
            convertedValue = parseFloat(value); // Convert to float only if it's a valid number
          }
        } else if (field.type === "integer") {
          // Only convert to integer if it's a valid integer string
          if (/^-?\d+$/.test(value)) {
            convertedValue = parseInt(value, 10);
          }
        }
      }

      if (arrayKey && customEditDetailArgs) {
        // In this case, selectedItem is an ephemeral object that is maintained in the RepeaterField component. Because of the many different types of edited, partially edited, and source of truth "immutable" objects, we need to handle this case separately and it didn't seem prudent to introduce a new variable name for this.
        const newArray = selectedItem[arrayKey].map((row, i) => {
          const condition = customEditDetailArgs?.rowKey
            ? customEditDetailArgs.rowKey === row[parentRowKey]
            : i === customEditDetailArgs.rowIndex;

          if (condition) {
            return {
              ...row,
              [name]: type === "checkbox" ? checked : convertedValue,
            };
          }
          return row;
        });

        return {
          ...selectedItem,
          [arrayKey]: newArray,
        };
      }
      if (parentKey) {
        return {
          ...values,
          [parentKey]: {
            ...values[parentKey],
            [name.toLowerCase()]: type === "checkbox" ? checked : convertedValue,
          },
        };
      } else {
        const update = {
          ...values,
          [name.toLowerCase()]: type === "checkbox" ? checked : convertedValue,
        };
        return update;
      }
    });
  };

  function checkDisabled(field) {
    if (field.editable === false) {
      return true;
    }
    if (field.alwaysDisabled) {
      return true;
    }
    if (field.field === rowKey && field.field !== "platform") {
      return !editDetail;
    } else if (newItemCreated) {
      return false;
      //return field.hasOwnProperty("editable") ? !field.editable : false;
    } else {
      if (editDetail && !newItemCreated) {
        if (field.hasOwnProperty("editable")) {
          return !field.editable;
        } else return false;
      } else return true;
    }
  }

  const valueWithDefault = (val, defaultVal) => {
    if (val == "") return val;
    if (!val && !defaultVal) return "";
    return val || defaultVal;
  };

  function setAutocompleteValue(field, parentKey) {
    let value;
    if (parentKey) {
      value = valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field]);
    } else {
      value = valueWithDefault(editedDetails[field.field], selectedItem[field.field]);
    }

    return { title: value !== null ? value : "", id: value !== null ? value : "" };
  }

  function getCheckedValueForBooleanField(parentKey, field, editedDetails, selectedItem) {
    const defaultValue = false; // Default value if undefined
    const rawValue = parentKey
      ? editedDetails[parentKey]?.[field.field] ?? selectedItem[parentKey]?.[field.field]
      : editedDetails[field.field] ?? selectedItem[field.field];

    const booleanValue = rawValue === undefined ? defaultValue : Boolean(rawValue);

    return booleanValue;
  }

  // Assuming this is inside a function component or a relevant method
  const getValue = () => {
    let calculatedValue = "";

    if (parentKey && editedDetails[parentKey]) {
      calculatedValue = valueWithDefault(
        editedDetails[parentKey]?.[field.field],
        selectedItem[parentKey] ? selectedItem[parentKey]?.[field.field] : null
      );
    } else if (editedDetails && selectedItem) {
      calculatedValue = valueWithDefault(editedDetails[field.field], selectedItem[field.field]);
    }

    return calculatedValue;
  };

  switch (field?.type) {
    case "repeater":
      return (
        <RepeaterField
          id={field.field + field.headerName.replaceAll(" ", "")}
          label={field.headerName}
          name={field.field}
          required={field.required}
          rowKey={field.rowKey}
          disabled={checkDisabled(field)}
          value={editedDetails[field.field]}
          hasError={hasError}
          isEdit={editDetail}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          description={field.info}
          columns={field.children}
          selectedItem={selectedItem}
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          editDetail={editDetail}
          sandbox={sandbox}
          forceRemountKey={forceRemountKey}
          setForceRemountKey={setForceRemountKey}
        />
      );

    case "json":
      return (
        <RHJsonField
          id={field.field + field.headerName.replaceAll(" ", "")}
          label={field.headerName}
          name={field.field}
          required={field.required}
          rowKey={selectedItem[rowKey]}
          disabled={checkDisabled(field)}
          value={editedDetails[field.field]}
          hasError={hasError}
          isEdit={editDetail}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          description={field.info}
          fullWidth={fullWidth}
        />
      );
    case "select":
      return (
        <RHTextField
          label={field.headerName}
          name={field.field}
          select
          required={field.required}
          description={field.info}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inputProps={{ maxLength: 500 }}
          hasError={hasError}
          handleValidationError={handleValidationError}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          fullWidth={fullWidth}
        >
          {field.options?.map((option, i) => (
            <MenuItem value={option.value} key={field.field + option.label + "MenuItem" + i}>
              {option.label}
            </MenuItem>
          ))}
        </RHTextField>
      );

    case "autocomplete":
      return (
        <RHAutocompleteField
          name={field.field}
          description={field.info}
          required={field.required}
          valueRef={field.valueRef}
          label={field.headerName}
          api={field.api}
          lookupId={field.lookupId}
          preLoaded={field.preLoad}
          preLoadedOptions={field.preLoad ? field.options : null}
          value={setAutocompleteValue(field, parentKey)}
          disabled={checkDisabled(field)}
          sandboxId={sandbox?.sandboxId}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          readNameParamLoc={field.readNameParamLoc}
          responseDataName={field.responseDataName}
          readDirectly={field.readDirectly}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          fullWidth={fullWidth}
        />
      );
    case "enhancedautocomplete":
      return (
        <RHEnhancedAutocompleteField
          key={forceRemountKey}
          name={field.field}
          description={field.info}
          label={field.headerName}
          required={field.required}
          isDynamic={field.isDynamic}
          advancedSearch={false}
          api={field.api}
          idKey={field.idKey}
          displayValue={field.displayValue}
          responseDataName={field.responseDataName}
          defaultOptions={field.defaultOptions}
          multiSelect={field.multiSelect}
          inputProps={{ maxLength: 500 }}
          fullUrl={field?.fullUrl}
          filterOnClient={field?.filterOnClient}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          row_key={editedDetails?.[rowKey]}
          disabled={checkDisabled(field)}
          sandboxId={sandbox?.sandboxId}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          fullWidth={fullWidth}
        />
      );
    case "dynamicselectfield":
      if (!pageData) {
        pageData = {
          Columns: "",
          DynamicSelectFields: {
            [field.field]: {
              responseDataName: field.responseDataName,
              idKey: field.idKey,
              tableData: field.tableData,
            },
          },
        };
      }
      const isMultiSelect =
        (parentKey && pageData.Columns[parentKey]?.children[field.field]?.type === "array") ||
        pageData.Columns[field.field]?.type === "array";

      const isStaticSelect = !pageData?.DynamicSelectFields[field.field]?.tableData;

      const DynamicSelectComponent = isMultiSelect || isStaticSelect ? RHDynamicSelectField : SingleDynamicSelect;

      return (
        <DynamicSelectComponent
          row_key={editedDetails?.[rowKey]}
          name={field.field}
          pageData={pageData}
          description={field.info}
          required={field.required}
          valueRef={field.valueRef}
          label={field.headerName}
          api={field.api}
          lookupId={field.lookupId}
          preLoaded={field.preLoad}
          hasError={hasError}
          preLoadedOptions={field.preLoad ? field.options : null}
          value={setAutocompleteValue(field, parentKey)}
          disabled={checkDisabled(field)}
          sandboxId={sandbox?.sandboxId}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          readNameParamLoc={field.readNameParamLoc}
          responseDataName={field.responseDataName}
          readDirectly={field.readDirectly}
          selectedItem={selectedItem}
          parent_key={parentKey}
          fullWidth={fullWidth}
          sx={
            checkDisabled(field)
              ? getGeneralDisabledFormStylesSx(field, pageData)
              : getGeneralFormStylesSx(field, false)
          }
        />
      );
    case "boolean":
      const checkedValue = getCheckedValueForBooleanField(parentKey, field, editedDetails, selectedItem);

      return (
        <RHBooleanField
          checked={checkedValue}
          label={field.headerName}
          name={field.field}
          required={field.required}
          description={field.info}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          //React's warning told me to do this. I think it's terrible, but it makes the warning happy.
          inactive={!editDetail ? !newItemCreated : undefined}
        />
      );
    case "number":
      return (
        <RHNumberField
          label={field.headerName}
          name={field.field}
          description={field.info}
          value={getValue()}
          change={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inactive={!editDetail ? !newItemCreated : undefined}
          required={field.required}
          hasError={hasError}
          step={field.step}
          min={field.min}
          max={field.max}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          inputType={"number"}
          fullWidth={fullWidth}
        />
      );
    case "integer":
      return (
        <RHNumberField
          label={field.headerName}
          name={field.field}
          description={field.info}
          value={getValue()}
          change={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inactive={!editDetail ? !newItemCreated : undefined}
          required={field.required}
          hasError={hasError}
          step={field.step}
          min={field.min}
          max={field.max}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          inputType={"integer"}
          fullWidth={fullWidth}
        />
      );
    case "metadata":
      return (
        <RHDataOnlyField
          key={forceRemountKey}
          label={
            <Tooltip title={`Search ${field.headerName} - ${field.info}`} placement="left" disableInteractive>
              <span>{field.headerName}</span>
            </Tooltip>
          }
          name={field.field}
          description={field.info}
          required={field.required}
          hasError={hasError}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inputProps={{ maxLength: 500 }}
          sx={getGeneralAlwaysDisabledFormStylesSx(field)}
          fullWidth={fullWidth}
        />
      );
    case "date":
      return (
        <Tooltip title={field.info || ""} placement="top">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={
                <Tooltip title={`Search ${field.headerName} - ${field.info}`} placement="left" disableInteractive>
                  {field.headerName}
                </Tooltip>
              }
              value={dayjs(
                parentKey
                  ? valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field])
                  : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
              )}
              onChange={(e) => handleEditDetailValue(e, parentKey)}
              disabled={checkDisabled(field)}
              sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
            />
          </LocalizationProvider>
        </Tooltip>
      );
    case "stringarray":
      return (
        <TextField
          label={
            <Tooltip title={`Search ${field.headerName} - ${field.info}`} placement="left" disableInteractive>
              <span>{field.headerName}</span>
            </Tooltip>
          }
          name={field.field}
          required={field.required}
          value={
            parentKey
              ? Array.isArray(editedDetails[parentKey]?.[field.field])
                ? editedDetails[parentKey][field.field].join(", ")
                : editedDetails[parentKey]?.[field.field] ?? selectedItem[parentKey]?.[field.field] ?? ""
              : Array.isArray(editedDetails?.[field.field])
              ? editedDetails[field.field].join(", ")
              : editedDetails?.[field.field] ?? selectedItem?.[field.field] ?? ""
          }
          disabled={checkDisabled(field)}
          onChange={(e) => {
            let newValue = e.target.value.split(",").map((item) => item.trim());
            if (newValue.length === 1 && newValue[0] === "") {
              newValue = [];
            }
            handleEditDetailValue({ target: { value: newValue, name: e.target.name } }, parentKey);
          }}
          inputProps={{ maxLength: 500 }}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          helperText="Enter items separated by commas"
        />
      );

    default:
      return (
        <RHTextField
          label={
            <Tooltip title={`Search ${field.headerName} - ${field.info}`} placement="left" disableInteractive>
              <span>{field.headerName}</span>
            </Tooltip>
          }
          name={field.field}
          required={field.required}
          hasError={hasError}
          value={
            parentKey && editedDetails[parentKey] && selectedItem[parentKey]
              ? valueWithDefault(editedDetails[parentKey]?.[field.field], selectedItem[parentKey]?.[field.field])
              : editedDetails && selectedItem
              ? valueWithDefault(editedDetails[field.field], selectedItem[field.field])
              : ""
          }
          disabled={checkDisabled(field)}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          inputProps={{ maxLength: 500 }}
          handleValidationError={handleValidationError}
          description={field.info}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        />
      );
  }
}
