import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import RHFieldWrapper from "./RHFieldWrapper";
import AccountContext from "../../contexts/AccountContext";
import { apiRequest, useApiToken } from "../../common/apiUtils";
import { useDataContext } from "../../contexts/DataContext";

const newStyles = {
  variables: {
    light: {
      diffViewerBackground: "#fff",
      diffViewerColor: "#212529",
      addedBackground: "#e6ffed",
      addedColor: "#24292e",
      removedBackground: "#ffeef0",
      removedColor: "#24292e",
      wordAddedBackground: "#acf2bd",
      wordRemovedBackground: "#fdb8c0",
      addedGutterBackground: "#cdffd8",
      removedGutterBackground: "#ffdce0",
      gutterBackground: "#f7f7f7",
      gutterBackgroundDark: "#f3f1f1",
      highlightBackground: "#fffbdd",
      highlightGutterBackground: "#fff5b1",
      codeFoldGutterBackground: "#dbedff",
      codeFoldBackground: "#f1f8ff",
      emptyLineBackground: "#fafbfc",
      gutterColor: "#212529",
      addedGutterColor: "#212529",
      removedGutterColor: "#212529",
      codeFoldContentColor: "#212529",
      diffViewerTitleBackground: "#fafbfc",
      diffViewerTitleColor: "#212529",
      diffViewerTitleBorderColor: "#eee",
    },
    dark: {
      diffViewerBackground: "#1e1e1e",
      diffViewerColor: "#FFF",
      addedBackground: "#055d6760",
      addedColor: "white",
      removedBackground: "#7d383f60",
      removedColor: "white",
      wordAddedBackground: "#055d67",
      wordRemovedBackground: "#7d383f",
      addedGutterBackground: "#034148",
      removedGutterBackground: "#632b30",
      gutterBackground: "#2c2f3a",
      gutterBackgroundDark: "#262933",
      highlightBackground: "#2a3967",
      highlightGutterBackground: "#2d4077",
      codeFoldGutterBackground: "#21232b",
      codeFoldBackground: "#1e1e1e",
      emptyLineBackground: "#363946",
      gutterColor: "#464c67",
      addedGutterColor: "#8c8c8c",
      removedGutterColor: "#8c8c8c",
      codeFoldContentColor: "#555a7b",
      diffViewerTitleBackground: "#2f323e",
      diffViewerTitleColor: "#555a7b",
      diffViewerTitleBorderColor: "#353846",
    },
  },
};

export default function HistoryView(props) {
  const { selectedSchemaObject, version_id, transaction_id, filter } = props;
  const { dataContext } = useDataContext();
  const { currentStateData } = useContext(AccountContext);
  const token = useApiToken();

  const schemaObjects = dataContext.schemaObjects;
  const schemaObject = schemaObjects[selectedSchemaObject];
  const getEndpoint = useMemo(() => {
    if (schemaObject?.base === "TransactionData") {
      return {
        path: `/v1/history/sandbox/${currentStateData?.sandbox?.sandboxId}/${schemaObject?.version}`,
        method: "get",
      };
    }
    return schemaObject?.fetchAllEndpoint;
  }, [schemaObject, currentStateData?.sandbox?.sandboxId]);

  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [previousVersion, setPreviousVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getTransactions = async (version_id, getEndpoint, sandboxId) => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append(filter, version_id);
      queryParams.append("sort_order", "desc");
      const extraOptions = { queryParams: queryParams.toString() };
      const response = await apiRequest(
        token,
        getEndpoint,
        { sandbox_id: sandboxId },
        {},
        {},
        extraOptions
      );
      return response.transactions;
    } catch (error) {
      console.error("Error fetching transactions", error);
      return null;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const findVersions = async () => {
    if (!version_id || !getEndpoint || !currentStateData?.sandbox?.sandboxId) {
      setError("Missing required data for fetching versions");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const transactions = await getTransactions(
        version_id,
        getEndpoint,
        currentStateData?.sandbox?.sandboxId
      );
      if (!transactions || transactions.length === 0) {
        setError("No transactions found");
        return;
      }

      if (transactions) {
        const currentTransactionIndex = transactions.findIndex(
          (t) => t.transaction_id === transaction_id
        );

        if (
          currentTransactionIndex === -1 ||
          transactions[currentTransactionIndex].operation === "create"
        ) {
          const transaction = transactions[currentTransactionIndex] || {};
          setCurrentTransaction(transaction);
          setCurrentVersion(JSON.stringify(transaction.version, null, 2));
          setPreviousVersion(JSON.stringify(transaction.version, null, 2));
        } else {
          const currentTransaction = transactions[currentTransactionIndex];
          const previousTransaction =
            transactions[currentTransactionIndex + 1] || currentTransaction;

          setCurrentTransaction(currentTransaction);
          setCurrentVersion(
            JSON.stringify(currentTransaction.version, null, 2)
          );
          setPreviousVersion(
            JSON.stringify(previousTransaction.version, null, 2)
          );
        }
      }
    } catch (error) {
      console.error("Error in findVersions:", error);
      setError("Failed to fetch version data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    findVersions();
  }, [
    version_id,
    getEndpoint,
    currentStateData?.sandbox?.sandboxId,
    transaction_id,
    token,
  ]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex flex-col gap-4 ">
      <div>
        {currentTransaction && (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 items-start p-1">
            <div className="text-xs w-full max-w-[8rem] md:max-w-xs ">
              <div className="flex items-center gap-2 mb-1">
                <span className="">Account Name:</span>
              </div>
              <span
                className="font-mono block"
                onClick={() => copyToClipboard(currentTransaction.account_id)}
              >
                {currentTransaction.account_id}
              </span>
            </div>
            <div className="text-xs w-full max-w-[8rem] md:max-w-xs ">
              <div className="flex items-center gap-2 mb-1">
                <span className="">Operation:</span>
              </div>
              <span className="font-mono capitalize ">
                {currentTransaction.operation}
              </span>
            </div>
            <div className="text-xs w-full max-w-[8rem] md:max-w-xs ">
              <div className="flex items-center gap-2 mb-1">
                <span className="">Transaction Id:</span>
              </div>
              <span className="font-mono ">
                {currentTransaction.transaction_id}
              </span>
            </div>

            <div className="text-xs w-full max-w-[8rem] md:max-w-xs ">
              <div className="flex items-center gap-2 mb-1">
                <span className="">Issued Date:</span>
              </div>
              <span className="font-mono  ">
                {currentTransaction.issued_at}
              </span>
            </div>
          </div>
        )}
        <div className="relative text-sm">
          <div className="overflow-auto">
            <pre className="w-full bg-gray-800 text-gray-100 p-1 rounded-lg text-sm">
              {currentVersion && previousVersion && (
                <RHFieldWrapper
                  label="Version Diff"
                  inactive={false}
                  fullWidth={true}
                  autoInactiveChild={false}
                  required={false}
                  description={
                    "Diff between the current and previous versions of the record."
                  }
                >
                  <ReactDiffViewer
                    styles={newStyles}
                    oldValue={previousVersion}
                    newValue={currentVersion}
                    splitView={false}
                    useDarkTheme={true}
                    showDiffOnly={true}
                    hideLineNumbers={true}
                    compareMethod={DiffMethod.WORDS}
                    extraLinesSurroundingDiff={0}
                    onLineNumberClick={null}
                  />
                </RHFieldWrapper>
              )}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
