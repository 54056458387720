import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";

const useStyles = makeStyles((theme) => {
  return {
    RHOrgPermissionGroupAddPermission: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    autocomplete: {
      marginBottom: theme.spacing(3),
    },
    actionBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
  };
});

function RHOrgPermissionGroupAddPermission(props) {
  const classes = useStyles();
  const { selectedGroup, orgId, saveHandler } = props;

  const { getAccessTokenSilently } = useAuth();
  const { showMessage } = useMessageBar();

  const [permissionLevels, setPermissionLevels] = useState([]);
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState(null);
  const [allPermissionLevels, setAllPermissionLevels] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const formatLevelNames = (levels) => {
    const formattedLevels = levels.map((permission) => {
      if (permission.level_type === "product") {
        permission.level_type_display = "\xA0\xA0\xA0- " + permission.level_type;
      } else if (permission.level_type === "sandbox") {
        permission.level_type_display = "\xA0\xA0\xA0\xA0\xA0\xA0- " + permission.level_type;
      } else {
        permission.level_type_display = permission.level_type;
      }
      return permission;
    });
    return formattedLevels;
  };

  async function fetchPermissionLevelsData() {
    if (orgId) {
      try {
        const response = await fetch(`${API_BASE}/v1/org-structure/${orgId}`, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        });

        const permissionLevels = await response.json();
        formatLevelNames(permissionLevels);
        setPermissionLevels(permissionLevels);
        setAllPermissionLevels(permissionLevels);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPermissionLevels([]);
          setAllPermissionLevels([]);
        } else {
          console.error(error);
        }
      }
    }
  }

  async function fetchPermissionsData() {
    try {
      const response = await fetch(`${API_BASE}/v1/portal-permissions`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
      });

      const permissions = await response.json();
      const filteredPermissions = permissions.filter((permission) => permission.level !== "glb");
      setPermissions(filteredPermissions);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setOrgAccountPermissions([]);
      } else {
        console.error(error);
      }
    }
  }

  async function handleSaveOrgGroupPermission() {
    try {
      const newOrgGroupPermission = {
        group_permission_id: uuid(),
        group_id: selectedGroup,
        level: selectedPermissionLevel.level_type,
        level_id: selectedPermissionLevel.level,
        permission_id: selectedPermission.permission_id,
      };

      await fetch(`${API_BASE}/v1/${orgId}/permissions-org-group-permission`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(newOrgGroupPermission),
      });

      showMessage(
        `Permission ${newOrgGroupPermission.permission_id} added to Permission Group ${newOrgGroupPermission.group_id}`,
        "success"
      );
      saveHandler();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setOrgAccountPermissions([]);
      } else {
        console.error(error);
      }
      let message = error.response ? error.response.data.detail : error.message;
      showMessage(`Failed to add Permission to Permission Group: ${message}`, "error");
    }
  }

  const filterLevels = (selectedPermission) => {
    if (selectedPermission) {
      let showLevels = [];
      if (selectedPermission.level === "product_up") {
        showLevels = ["glb", "org", "product"];
      } else if (selectedPermission.level === "sandbox_up") {
        showLevels = ["glb", "org", "product", "sandbox"];
      } else if (selectedPermission.level === "environment_up") {
        showLevels = ["glb", "org", "product", "sandbox", "environment"];
      } else {
        showLevels.push(selectedPermission.level);
      }

      const filteredLevels = allPermissionLevels.filter((level) => showLevels.includes(level.level_type));
      setPermissionLevels(filteredLevels);
    }
  };

  const handleLevelSelection = (event, value) => {
    setSelectedPermissionLevel(value);
  };

  const handlePermissionSelection = (event, value) => {
    filterLevels(value);
    setSelectedPermissionLevel(null);
    setSelectedPermission(value);
  };

  useEffect(() => {
    if (selectedPermissionLevel && selectedPermission) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }, [selectedPermissionLevel, selectedPermission]);

  useEffect(() => {
    fetchPermissionsData();
    fetchPermissionLevelsData();
  }, []);

  return (
    <Box className={classes.RHOrgPermissionGroupAddPermission}>
      <Autocomplete
        className={classes.autocomplete}
        value={selectedPermission}
        onChange={handlePermissionSelection}
        id="permission-selection"
        options={permissions}
        getOptionLabel={(permission) => permission.permission_id || ""}
        renderOption={(props, permission) => (
          <li {...props}>
            <div>
              <span style={{ fontWeight: "bold" }}>{permission.permission_id}</span>
              <br />
              <small style={{ fontStyle: "italic", fontSize: "0.8em", paddingLeft: "16px" }}>
                {permission.description ? permission.description : "no description provided"}
              </small>
            </div>
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="Permission *" variant="outlined" />}
      />
      <Autocomplete
        className={classes.autocomplete}
        value={selectedPermissionLevel}
        onChange={handleLevelSelection}
        id="level-selection"
        options={permissionLevels}
        getOptionLabel={(level) => level.level_type_display + " - " + level.level_name || ""}
        renderInput={(params) => <TextField {...params} label="Permission Level *" variant="outlined" />}
      />
      <Box className={classes.actionBar}>
        <Button variant="contained" color="primary" onClick={handleSaveOrgGroupPermission} disabled={!saveEnabled}>
          Save Group Permission
        </Button>
      </Box>
    </Box>
  );
}

export default RHOrgPermissionGroupAddPermission;
