import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import definitions from "common/definitions.json";
import { useApiToken } from "common/apiUtils";
import SelectedItems from "../SelectedItems";
import SearchInput from "../SearchInput";
import { fetchTableData } from "common/apiUtils";
import { CloseIcon, DropDownArrow } from "assets/svgs";

const SingleDynamicSelect = React.memo((props) => {
  const {
    name,
    label,
    sandboxId,
    pageData,
    selectedItem,
    fullWidth,
    disabled,
    onChange,
    required,
  } = props; // Added required prop

  const token = useApiToken();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownSelection, setDropdownSelection] = useState(null); // Renamed from selectedItems
  const [isFetching, setIsFetching] = useState(false); // Flag for fetching state
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState(false); // Track initial fetch state
  const [isInitialFetchInProgress, setIsInitialFetchInProgress] =
    useState(false); // Track initial fetch state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state
  const [cachedOptions, setCachedOptions] = useState([]); // Initialize cachedOptions
  const [fetchedOptions, setFetchedOptions] = useState([]); // State for fetched options
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  const formatId = (id) => {
    return id.length > 20 ? `...${id.slice(-12)}` : id;
  };

  const loadFieldData = useMemo(() => {
    if (!pageData?.DynamicSelectFields || !name) return null;

    const fieldData = pageData.DynamicSelectFields[name];
    if (!fieldData) return null;

    const { tableData, responseDataName, idKey } = fieldData;

    return {
      tableData: tableData,
      responseDataName: responseDataName,
      isDynamic: !!tableData,
      idKey: idKey,
      get_endpoint: tableData
        ? definitions?.schemas[tableData]?.endpoints?.find(
            (endpoint) => endpoint.method === "get"
          )
        : undefined,
    };
  }, [pageData, name, definitions]);

  const isQueryEnabled = useMemo(
    () =>
      !!sandboxId &&
      !!token &&
      loadFieldData?.isDynamic &&
      !!loadFieldData?.get_endpoint,
    [sandboxId, token, loadFieldData]
  );

  // Fetch initial data based on row_key and value
  useEffect(() => {
    const fetchData = async () => {
      // Reset cachedOptions when row_key changes
      if (props.row_key) {
        setCachedOptions([]); // Reset cachedOptions
      }

      // If value is empty, do not fetch data
      if (!props.value?.id) {
        setDropdownSelection(null); // Clear dropdownSelection
        return; // Exit if value is empty
      }

      // Always fetch new data if row_key or value has changed
      if (!token) {
        return; // Exit if token is not available
      }

      const endpoint = loadFieldData?.get_endpoint;
      if (!endpoint) return;

      setIsInitialFetchInProgress(true); // Set fetching flag
      setIsLoading(true); // Set loading state to true

      try {
        const response = await fetchTableData(
          token,
          loadFieldData?.idKey,
          endpoint,
          { sandbox_id: sandboxId },
          "", // No inputValue for initial fetch
          props.value,
          [] // No selectedItems for initial fetch
        );

        const transformedData =
          response[loadFieldData?.responseDataName]?.map((item) => ({
            id: item[loadFieldData?.idKey],
            name: item.name || "Untitled", // Add "Untitled" as fallback
          })) || [];

        setCachedOptions(transformedData); // Set cachedOptions
        const matchingItem = transformedData.find(
          (item) => item.id === props.value.id
        );
        if (matchingItem) {
          setDropdownSelection(matchingItem); // Set dropdownSelection
          setInputValue(matchingItem.name || matchingItem.id); // Ensure id is shown if name is not available
          setIsLoading(false); // Set loading state to false when data is ready
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Authentication error: Check your token.");
        }
        setFilteredOptions([]);
      } finally {
        setIsInitialFetchInProgress(false); // Reset fetching flag
      }
    };

    fetchData();
  }, [props.row_key, props.value.id, loadFieldData, token, sandboxId]); // Ensure value.id is included

  // Fetch options when dropdown is opened
  useEffect(() => {
    if (isDropdownOpen) {
      // Skip fetching if cachedOptions are already populated
      if (cachedOptions.length > 0) {
      }

      const fetchOptions = async () => {
        if (!token) {
          return; // Exit if token is not available
        }

        const endpoint = loadFieldData?.get_endpoint;
        if (!endpoint) return;

        try {
          const response = await fetchTableData(
            token,
            loadFieldData?.idKey,
            endpoint,
            { sandbox_id: sandboxId, pageSize: 50 }, // Request first 50 options
            "", // No inputValue for initial fetch
            null, // Do not pass props.value for dropdown fetch
            [] // No selectedItems for initial fetch
          );

          const transformedData =
            response[loadFieldData?.responseDataName]?.map((item) => ({
              id: item[loadFieldData?.idKey],
              name: item.name || "Untitled", // Add "Untitled" as fallback
            })) || [];

          // Combine cachedOptions with fetched options
          const combinedOptions = [
            ...cachedOptions,
            ...transformedData.filter(
              (option) =>
                !cachedOptions.some((cached) => cached.id === option.id)
            ), // Avoid duplicates
          ];
          setFilteredOptions(combinedOptions); // Update filtered options with combined options
          setFetchedOptions(transformedData); // Store fetched options
        } catch (error) {
          setFilteredOptions([]);
        }
      };

      fetchOptions();
    }
  }, [isDropdownOpen, loadFieldData, token, sandboxId, cachedOptions]); // Remove props.value from dependencies

  useEffect(() => {}, [filteredOptions]);

  const handleFocus = useCallback(() => {
    setIsDropdownOpen(true); // Open dropdown
    setIsOpen(true); // Ensure dropdown is open
  }, []);

  const handleSearch = useCallback(
    (searchValue) => {
      // Perform name search only after dropdown is opened
      if (isDropdownOpen) {
        const fetchSearchResults = async () => {
          if (!token) {
            return; // Exit if token is not available
          }

          const response = await fetchTableData(
            token,
            loadFieldData?.idKey,
            loadFieldData?.get_endpoint,
            { sandbox_id: sandboxId },
            searchValue, // Use searchValue for name search
            null,
            dropdownSelection ? [dropdownSelection.id] : []
          );

          const transformedData =
            response[loadFieldData?.responseDataName]?.map((item) => ({
              id: item[loadFieldData?.idKey],
              name: item.name || "Untitled", // Add "Untitled" as fallback
            })) || [];

          // Combine cachedOptions with search results
          const combinedResults = [...cachedOptions, ...transformedData];
          const uniqueResults = combinedResults.reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          setFilteredOptions(uniqueResults); // Update filtered options with combined results
          // Remove automatic selection logic here
        };

        fetchSearchResults(); // Call the async function
      }
    },
    [
      isDropdownOpen,
      loadFieldData,
      token,
      sandboxId,
      props.value,
      dropdownSelection,
      cachedOptions,
    ] // Add cachedOptions to dependencies
  );

  const toggleItem = useCallback(
    (id) => {
      const selectedItem =
        cachedOptions.find((option) => option.id === id) ||
        filteredOptions.find((option) => option.id === id);

      setDropdownSelection(selectedItem);
      // Set input value to selected item's name or id if name is not available
      setInputValue(selectedItem ? selectedItem.name || selectedItem.id : "");
      setIsOpen(false); // Close dropdown after selection

      // Call onChange when the item is toggled
      if (onChange) {
        onChange({
          target: { value: selectedItem ? selectedItem.id : null, name },
        }); // Pass the selected item's id
      }
    },
    [cachedOptions, filteredOptions, name, onChange]
  );

  const removeItem = useCallback(
    (id) => {
      if (!id) {
        return; // Exit if ID is undefined
      }
      setDropdownSelection(null); // Clear dropdownSelection
      setInputValue(""); // Clear inputValue

      // Call onChange when the item is removed
      if (onChange) {
        onChange({ target: { value: null, name } }); // Pass null when removed
      }
    },
    [onChange, name]
  );

  // Ensure the correct ID is passed to removeItem
  const handleRemove = (id) => {
    if (id) {
      removeItem(id);
    }
  };

  useEffect(() => {
    // Set initial inputValue based on the value prop when the component mounts
    if (props.value?.id && !isDropdownOpen) {
      const matchingItem = filteredOptions.find(
        (item) => item.id === props.value.id
      );
      if (matchingItem) {
        setInputValue(matchingItem.name || matchingItem.id); // Ensure id is shown if name is not available
        setDropdownSelection(matchingItem);
      }
    }
  }, [props.value, filteredOptions]); // Ensure this runs when value or filteredOptions change

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {}, [inputValue, dropdownSelection]);

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full mb-4 ${fullWidth ? "w-full" : ""} border ${
        disabled ? "border-zinc-600" : "border-gray-500"
      }`}
      onClick={handleFocus}
    >
      <span className="absolute top-[-1rem] left-0 text-gray-400 text-xs z-1 px-1">
        {label}
        {required && <span className="text-red-500">*</span>}
      </span>
      {isLoading ? (
        <div className="h-14 flex items-center justify-center cursor-pointer text-gray-500 animate-pulse">
          Loading...
        </div>
      ) : (
        <div className="h-14 flex items-center justify-between cursor-pointer px-3">
          <div className="flex-grow text-left overflow-hidden">
            {dropdownSelection ? (
              <span
                className={`text-gray-300 ${
                  disabled ? "text-gray-500" : ""
                } block`}
                title={`${
                  dropdownSelection.name !== "Untitled"
                    ? "Name: " + dropdownSelection.name + "\n"
                    : ""
                }${dropdownSelection.id}`}
              >
                {dropdownSelection.name}
                <span className="text-gray-500 italic ml-1">{` (${formatId(
                  dropdownSelection.id
                )})`}</span>
              </span>
            ) : (
              <span
                className={`${
                  disabled ? "text-gray-500" : "text-gray-300"
                } italic block truncate`}
              >
                {disabled ? `No ${label} selected` : `Please select a ${label}`}
              </span>
            )}
          </div>
          <div className="flex items-center ml-2">
            {!disabled && dropdownSelection && (
              <button
                onClick={() => removeItem(dropdownSelection.id)}
                className="mr-2"
              >
                <CloseIcon />
              </button>
            )}
            <DropDownArrow
              className={`${disabled ? "text-gray-500" : "text-gray-300"}`}
            />
          </div>
        </div>
      )}
      {isOpen && !disabled && (
        <div className="relative">
          <ul className="absolute z-50 w-full mt-1 bg-zinc-900 rounded-md shadow-lg border border-zinc-700">
            <li className="p-1">
              <SearchInput onSearch={handleSearch} />
            </li>
            <div className="max-h-72 overflow-y-auto">
              {filteredOptions.map((option) => (
                <li
                  key={option.id}
                  className={`p-2 text-sm cursor-pointer ${
                    dropdownSelection?.id === option.id
                      ? "bg-zinc-600"
                      : "text-gray-300 hover:bg-zinc-600"
                  }`}
                  onClick={() => toggleItem(option.id)}
                >
                  {option.name}
                  <span className="text-gray-500 italic ml-1">{` (${formatId(
                    option.id
                  )})`}</span>
                </li>
              ))}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
});

export default SingleDynamicSelect;
