import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchDirectly, csvToArray, arrayToCsv } from "../helpers";
import ArmadaTable from "../ArmadaTable";
import Snackbar from "@mui/material/Snackbar";
import { useAuth } from "contexts/AuthContext";
import { createColumnDefs, NewEntry } from "./colDefs";
import { columns as childColumns, NewEntry as NewChildEntry } from "../ArmadaProductAssignments/colDefs";
import { columns as hostLabelColumns, NewEntry as NewHostLabelEntry } from "../ArmadaHostLabels/colDefs";
import { summarizeMachineClass } from "../ArmadaMachineClasses/colDefs";
import { TextField, FormControl, Autocomplete, Tooltip, Box, Modal, Typography, Button, Checkbox } from "@mui/material";

const ApplyLabelsForEnvironment = ({ onApply, onCancel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: "20px",
    paddingTop: "20px",
  };

  function handleOnCancel() {
    setValue("");
    setIsOpen(false);
    if (onCancel) onCancel();
  }

  function handleOnApply() {
    setIsOpen(false);
    if (onApply) onApply(value);
    setValue("");
  }

  return (
    <>
      <Tooltip title="Apply labels for environment">
        <Button onClick={() => setIsOpen(true)} variant="contained">
          Apply Labels for Environment
        </Button>
      </Tooltip>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{
              fontSize: "24px",
              color: "#fff",
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginBottom: "10px",
            }}
          >
            Apply Labels for Environment
          </Typography>
          <TextField
            label="Environment ID"
            fullWidth
            value={value}
            onChange={(event) => setValue(event.target.value)}
          ></TextField>
          <Button onClick={handleOnCancel} variant="contained" style={{ marginTop: "20px" }}>
            Cancel
          </Button>
          <Button onClick={handleOnApply} variant="contained" style={{ marginTop: "20px", marginLeft: "10px" }}>
            Apply
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default function ArmadaHosts() {
  const ROW_KEY = "host_id";
  const ENDPOINT = "host";
  const CHILD_ROW_KEY = "simple_product_assignment_id";
  const CHILD_ENDPOINT = "simple_product_assignment";
  const HOST_LABEL_ROW_KEY = "host_label_id";
  const HOST_LABEL_ENDPOINT = "host_label";
  const HOST_LABEL_DELETE_ENDPOINT = "host_label";

  const { getAccessTokenSilently } = useAuth();

  const [selectedHostRow, setSelectedHostRow] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const [data, setData] = useState();
  const [hostUpLoadData, setHostUploadData] = useState();
  const [childData, setChildData] = useState();
  const [hostLabelData, setHostLabelData] = useState();

  async function readMachineClassData() {
    let response = await fetchDirectly({
      endpoint: "machine_class",
      method: "GET",
      token: await getAccessTokenSilently(),
    });
    return response.reduce((acc, curr) => {
      acc[curr.machine_class_id] = curr;
      return acc;
    }, {});
  }

  const columns = createColumnDefs();

  // The host response has nested objects and allows for more mutations that we want to allow for on this UI. This function will mutate the host object to only include the fields we want to allow for editing.
  function mutateHost(host) {
    return Object.entries(host).reduce((acc, [key, value]) => {
      if (key === "machine_class") {
        return {
          ...acc,
          machine_class_id: value.machine_class_id,
          machine_class_friendly: summarizeMachineClass(value),
        };
      }
      if (typeof value === "object" && value[`${key}_id`]) {
        return {
          ...acc,
          [`${key}_id`]: value[`${key}_id`],
          [`${key}_friendly`]: value.name ?? value.description ?? value.purchase_order,
        };
      }
      if (key === "machine_class_overrides") {
        return {
          ...acc,
          machine_class_overrides_id: value.machine_class_id,
          machine_class_overrides_friendly: value.name ?? value.description,
        };
      }
      return { ...acc, [key]: value };
    }, {});
  }

  async function readData() {
    let response = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "GET",
      token: await getAccessTokenSilently(),
    });

    const mutatedResponse = response.map((host) => mutateHost(host));
    const machineClassLookup = await readMachineClassData();
    for (const host of mutatedResponse) {
      if (host.machine_class_id) host.machine_class_type = machineClassLookup[host.machine_class_id]?.type ?? "Unknown";
    }
    setData(mutatedResponse);
  }

  async function updateHandler(editedItem, noRead = false) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "PATCH",
      token: await getAccessTokenSilently(),
      body: Object.entries(editedItem).reduce((acc, [key, value]) => {
        if (key === ROW_KEY) return acc;
        return { ...acc, [key]: value };
      }, {}),
      dataId: editedItem[ROW_KEY],
    });
    if (!noRead) await readData();
    return item;
  }

  async function createHandler(editedItem, noRead = false) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "POST",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    if (!noRead) await readData();
    return item;
  }

  async function deleteHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.host_id,
    });
    await readData();
    return item;
  }

  async function onImport(event) {
    var reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const hostsObj = csvToArray(evt.target.result);
        updateHosts(hostsObj);
      }
    };
  }

  async function onExport() {
    const csv = arrayToCsv(data);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "hosts.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  async function updateHosts(hostsObj) {
    let responses = [];
    for (let i = 0; i < hostsObj.length; i++) {
      const trimmedObj = Object.entries(hostsObj[i]).reduce((acc, [key, value]) => {
        if (value === "") return acc;
        return { ...acc, [key]: value };
      }, {});
      try {
        const item = !trimmedObj?.host_id
          ? await createHandler(trimmedObj, true)
          : await updateHandler(trimmedObj, true);
        responses.push({ public_hostname: item.public_hostname, status: "success" });
      } catch (err) {
        responses.push({
          public_hostname: trimmedObj.public_hostname,
          status: "error",
          detail: err?.response?.data?.desc,
        });
      }
    }

    await readData();
    alert(JSON.stringify(responses));
  }

  function createHostColVisibilityModel() {
    var keepers = [
      ROW_KEY,
      "internal_hostname",
      "type",
      "public_hostname",
      "internal_ipv4",
      "public_ipv4",
      "geographic_location_id",
      "datacenter_location_id",
      "datacenter_provider_id",
      "datacenter_contract_id",
      "machine_class_id",
      "machine_image_id",
      "labels",
    ];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((dict, el) => ((dict[el] = false), dict), {});
    return hiders;
  }

  function customFilterFunc(field) {
    return ![].includes(field.field);
  }

  const location = useLocation();

  function createDefaultFilterModel() {
    return {
      items: [...new URLSearchParams(location.search).entries()].map(([key, value]) => ({
        field: "labels",
        operator: "contains",
        value: `${key}=${value}`,
      })),
    };
  }

  async function readSpaData() {
    let response = await fetchDirectly(
      {
        endpoint: CHILD_ENDPOINT,
        method: "GET",
        token: await getAccessTokenSilently(),
      },
      { host_ids: selectedHostRow?.host_id ?? "" },
    );
    setChildData(response);
  }

  async function updateSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: CHILD_ENDPOINT,
      method: "PATCH",
      body: editedItem,
      dataId: editedItem[CHILD_ROW_KEY],
      token: await getAccessTokenSilently(),
    });
    await readSpaData();
    return item;
  }

  async function createSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: CHILD_ENDPOINT,
      method: "POST",
      body: editedItem,
      token: await getAccessTokenSilently(),
    });
    await readSpaData();
    return item;
  }

  async function deleteSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.simple_product_assignment_id,
    });
    await readSpaData();
    return item;
  }

  function createSpaColVisibilityModel() {
    var keepers = [ROW_KEY, "name", "count", "host_id"];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((acc, curr) => ((acc[curr] = false), acc), {});
    return hiders;
  }

  function customSpaFilterFunc(field) {
    return ![].includes(field.field);
  }

  async function readHostLabelData() {
    let response = await fetchDirectly(
      {
        endpoint: HOST_LABEL_ENDPOINT,
        method: "GET",
        token: await getAccessTokenSilently(),
      },
      { host_ids: selectedHostRow?.host_id ?? "" },
    );
    setHostLabelData(response);
  }

  async function updateHostLabelHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: HOST_LABEL_ENDPOINT,
      method: "PATCH",
      body: editedItem,
      dataId: editedItem[HOST_LABEL_ROW_KEY],
      token: await getAccessTokenSilently(),
    });
    await readHostLabelData();
    await readData();
    return item;
  }

  async function createHostLabelHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: HOST_LABEL_ENDPOINT,
      method: "POST",
      body: editedItem,
      token: await getAccessTokenSilently(),
    });
    await readHostLabelData();
    await readData();
    return item;
  }

  async function deleteHostLabelHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: HOST_LABEL_DELETE_ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.host_label_id,
    });
    await readHostLabelData();
    await readData();
    return item;
  }

  function createHostLabelColVisibilityModel() {
    var keepers = [ROW_KEY, "label", "host_id"];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((acc, curr) => ((acc[curr] = false), acc), {});
    return hiders;
  }

  function customHostLabelFilterFunc(field) {
    return ![].includes(field.field);
  }

  function newHostLabelEntryFunc() {
    const baseline = new NewHostLabelEntry();
    baseline.host_id = selectedHostRow?.host_id ?? "";
    return baseline;
  }

  async function applyLabelsForEnvironment(environmentId) {
    const response = await fetchDirectly({
      endpoint: `${ENDPOINT}/${selectedHostRow?.host_id}/apply-environment-labels/${environmentId}`,
      method: "POST",
      token: await getAccessTokenSilently(),
    });
    await readHostLabelData();
    await readData();
    return response;
  }

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (selectedHostRow) {
      readSpaData();
      readHostLabelData();
    }
  }, [selectedHostRow]);

  return !data ? null : (
    <>
      <ArmadaTable
        title="Host"
        friendlyName="Host"
        friendlyNameKey="public_hostname"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data && data}
        textFieldFilter={customFilterFunc}
        setSnackBarState={setSnackBarState}
        retrieveSelectedRows={setSelectedHostRow}
        setSnackBar={setSnackBar}
        newModel={NewEntry}
        updateHandler={updateHandler}
        createHandler={createHandler}
        deleteHandler={deleteHandler}
        retrieveNewItemAsSelectedRow
        onImport={onImport}
        onExport={onExport}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
          columns: {
            columnVisibilityModel: createHostColVisibilityModel(),
          },
          sorting: {
            sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
          },
          filter: {
            filterModel: createDefaultFilterModel(),
          },
        }}
      />
      {selectedHostRow && (
        <ArmadaTable
          title="Simple Product Assignment"
          friendlyName="Simple Product Assignment"
          friendlyNameKey="name"
          rowKey={CHILD_ROW_KEY}
          columns={childColumns}
          tableData={childData && childData}
          textFieldFilter={customSpaFilterFunc}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          newModel={NewChildEntry}
          updateHandler={updateSpaHandler}
          createHandler={createSpaHandler}
          deleteHandler={deleteSpaHandler}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            columns: {
              columnVisibilityModel: createSpaColVisibilityModel(),
            },
            sorting: {
              sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
            },
          }}
        />
      )}
      {selectedHostRow && (
        <ArmadaTable
          title="Host Labels"
          friendlyName="Host Labels"
          friendlyNameKey="label"
          rowKey={HOST_LABEL_ROW_KEY}
          columns={hostLabelColumns}
          tableData={hostLabelData && hostLabelData}
          textFieldFilter={customHostLabelFilterFunc}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          newModel={newHostLabelEntryFunc}
          updateHandler={updateHostLabelHandler}
          createHandler={createHostLabelHandler}
          deleteHandler={deleteHostLabelHandler}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            columns: {
              columnVisibilityModel: createHostLabelColVisibilityModel(),
            },
            sorting: {
              sortModel: [{ field: "label", sort: "desc" }],
            },
          }}
          extraToolbarActions={<ApplyLabelsForEnvironment onApply={applyLabelsForEnvironment} />}
        />
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
