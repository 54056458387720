import { formatTimestamp } from "../../../common/dateUtils";
import { getGridStringOperators } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";

export const xpTableRowKey = "xp_id";

export class xpTableEntry {
  constructor(sandbox_id, account_id) {
    this.sandbox_id = sandbox_id;
    this.last_modified_account_id = account_id;
    this.xp_id = uuid();
  }
  xp_thresholds = [];
  last_modified_timestamp = new Date().toISOString();
}

export const xpTableColumns = [
  {
    field: "xp_id",
    headerName: "XP Table Id",
    flex: 2,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    editable: true,
    hideable: false,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    editable: true,
    alwaysHidden: false,
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    type: "json",
    editable: true,
    alwaysHidden: true,
  },
  {
    field: "legacy_xp_id",
    headerName: "Legacy ID",
    flex: 2,
    type: "metadata",
    hideable: false,
    alwaysHidden: false,
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 1,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    type: "metadata",
  },
];

export const xpLevelTableRowKey = "xp_level_threshold_id";

export class xpLevelTableEntry {
  constructor(sandbox_id, xp_id) {
    this.sandbox_id = sandbox_id;
    this.xp_id = xp_id;
  }
  xp_level_threshold = 0;
  customData = {};
}

export const xpLevelTableColumns = [
  {
    field: "xp_level_threshold_id",
    headerName: "XP Level Threshold ID",
    flex: 1,
    editable: false,
    hideable: false,
    type: "metadata",
  },
  {
    field: "xp_level_threshold",
    headerName: "XP Threshold",
    flex: 1,
    required: true,
    type: "number",
    align: "left",
    editable: true,
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    alwaysHidden: true,
    type: "json",
    editable: true,
  },
];

//KV configs
export const kvTableRowKey = "key";

export const kvColumns = [
  {
    field: "key",
    headerName: "Key",
    flex: 3,
    editable: "createOnly",
    hideable: false,
    required: true,
  },
  { field: "value", headerName: "Value", flex: 4, editable: true, required: true },
  {
    field: "notes",
    headerName: "Notes",
    flex: 4,
    editable: true,
    fullWidth: true,
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    type: "metadata",
  },
];

export class kvTableEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  key = "";
  value = "";
  last_modified_timestamp = new Date().toISOString();
}

//Setting Types configs
export const pstTableRowKey = "setting_type_unique_id";

export const pstColumns = [
  {
    field: "setting_type_unique_id",
    headerName: "Unique ID",
    flex: 2,
    editable: false,
    hideable: false,
    required: true,
    alwaysHidden: true,
    type: "metadata",
  },
  {
    field: "type",
    headerName: "Type",
    flex: 3,
    editable: "createOnly",
    hideable: false,
    required: true,
  },
  {
    field: "version",
    headerName: "Version",
    flex: 1,
    editable: "createOnly",
    type: "number",
    required: true,
  },
  {
    field: "key_regex",
    headerName: "Key Regex",
    flex: 1,
    editable: true,
    hideable: true,
    required: false,
    alwaysHidden: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    editable: true,
    hideable: true,
    required: false,
    alwaysHidden: false,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    editable: true,
    hideable: true,
    required: false,
    alwaysHidden: true,
  },
  {
    field: "value_jsonschema",
    headerName: "Schema",
    flex: 1,
    editable: true,
    required: true,
    type: "json",
    alwaysHidden: true,
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    type: "json",
    alwaysHidden: true,
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 2,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
];

export class pstTableEntry {
  constructor(sandbox_id) {
    this.setting_type_unique_id = uuid();
    this.sandbox_id = sandbox_id;
  }
  type = "";
  version = 1;
  value_jsonschema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    type: "object",
    additionalProperties: true,
  };
  key_regex = "^.+$";
  custom_data = {};
  last_modified_timestamp = new Date().toISOString();
}

//Catalog Item configs
export const itemTableRowKey = "item_id";

export const itemColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 4,
    editable: true,
    hideable: false,
    required: true,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    editable: true,
    hideable: true,
    fullWidth: false,
    required: false,
  },
  {
    field: "item_type",
    headerName: "Type",
    flex: 2,
    editable: true,
    required: false,
    hideable: true,
    type: "enhancedautocomplete",
    advancedSearch: false,
    multiSelect: false,
    isDynamic: false,
    api: "",
    idKey: "",
    displayValue: "",
    responseDataName: "",
    defaultOptions: [
      { id: "unit", title: "Unit" },
      { id: "recipe", title: "Recipe" },
      { id: "recipe_with_price_reduction", title: "Recipe w/ price reduction" },
      { id: "entitlement", title: "Entitlement" },
    ],
  },
  {
    field: "item_id",
    headerName: "ID",
    flex: 2,
    alwaysDisabled: true,
    type: "metadata",
  },
  {
    field: "entitled_loot_id",
    headerName: "Entitled Loot",
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    isDynamic: true,
    api: "loot",
    idKey: "loot_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "level_vendor_id",
    headerName: "Level Vendor",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    filterOnClient: true,
    api: "vendor",
    idKey: "vendor_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "level_xp_table_id",
    headerName: "XP Table",
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    isDynamic: true,
    api: "xp",
    idKey: "xp_id",
    filterOnClient: true,
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "inventory_bucket_use_rule_set_id",
    headerName: "Inv Bucket Rule Set",
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    isDynamic: true,
    filterOnClient: true,
    api: "inventory-bucket-use-rule-set",
    idKey: "rule_set_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "legacy_item_id",
    headerName: "Legacy ID",
    flex: 2,
    alwaysDisabled: true,
    type: "metadata",
  },
  {
    field: "coupon_discount_currency_item_id",
    headerName: "Coupon Discount Currency Item",
    flex: 2,
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    advancedSearch: false,
    alwaysHidden: true,
    multiSelect: false,
    isDynamic: true,
    api: "loot",
    idKey: "loot_id",
    displayValue: "name",
    responseDataName: "data",
    defaultOptions: [],
  },
  {
    field: "coupon_consume_on_use",
    headerName: "Coupon Consume On Use",
    flex: 2,
    editable: true,
    hideable: true,
    fullWidth: false,
  },
  {
    field: "coupon_discount_percentage",
    headerName: "Coupon Discount %",
    flex: 2,
    editable: true,
    hideable: true,
    fullWidth: false,
  },

  {
    field: "coupon_discount_loot",
    headerName: "Coupon Discount Loot ",
    flex: 2,
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    advancedSearch: false,
    alwaysHidden: true,
    multiSelect: true,
    isDynamic: true,
    api: "loot",
    idKey: "loot_id",
    displayValue: "name",
    responseDataName: "data",
    defaultOptions: [],
  },
  {
    field: "coupon_discount_currency_item_name",
    headerName: "Coupon Discount Currency Item",
    flex: 2,
    editable: false,
    hideable: true,
  },

  {
    field: "inventory_bucket_use_rule_set_name",
    headerName: "Inv Bucket Rule Set",
    flex: 2,
    editable: false,
    hideable: true,
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 4,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    type: "json",
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
  },
];

export class itemTableEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
    this.item_id = uuid();
  }
  item_id = "";
  last_modified_timestamp = new Date().toISOString();
  custom_data = {};
  item_type = "unit";
}

//Loot configs
export const lootTableRowKey = "loot_id";

export const lootColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 3,
    editable: true,
    required: true,
    hideable: false,
    filterOperators: getGridStringOperators().filter((op) => ["contains"].includes(op.value)),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 3,
    editable: true,
    fullWidth: false,
    required: false,
    filterable: false,
  },
  {
    field: "loot_id",
    headerName: "Loot ID",
    flex: 2,
    alwaysDisabled: true,
    type: "metadata",
    filterable: false,
  },
  {
    field: "vendor_id",
    headerName: "Vendor",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    required: true,
    editable: true,
    isDynamic: true,
    filterOnClient: true,
    api: "vendor",
    idKey: "vendor_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "sub_vendor_id",
    headerName: "Sub Vendor",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    filterOnClient: true,
    api: "vendor",
    idKey: "vendor_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "item_id",
    headerName: "Item",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    api: "item",
    idKey: "item_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "item_name",
    headerName: "Item Name",
    flex: 3,
    alwaysDisabled: true,
    filterable: false,
  },
  {
    field: "sub_vendor_name",
    headerName: "Sub Vendor Name",
    flex: 3,
    alwaysDisabled: true,
    filterable: false,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    type: "number",
    flex: 2,
    editable: true,
    filterable: false,
  },
  {
    field: "inventory_selector_type",
    headerName: "Inv Selector Type",
    flex: 2,
    editable: true,
    required: true,
    type: "select",
    options: [
      { value: "own", label: "Own" },
      { value: "rent", label: "Rent" },
      { value: "rent_timeframe_locked", label: "Rent Timeframe Locked" },
      { value: "own_transient", label: "Own Transient" },
      {
        value: "inherit_entitlement_inventory",
        label: "Inherit Entitlement Inventory",
      },
    ],
    filterable: false,
  },
  {
    field: "inventory_operation",
    headerName: "Inv Operation",
    flex: 2,
    editable: true,
    type: "select",
    required: true,
    options: [
      { value: "add", label: "Add" },
      { value: "subtract", label: "Subtract" },
      { value: "set", label: "Set" },
      {
        value: "check_greater_than_or_equal",
        label: "Check Greater Than or Equal",
      },
      { value: "check_less_than", label: "Check Less Than" },
      {
        value: "check_greater_than_or_equal_and_subtract",
        label: "Check Greater Than or Equal and Subtract",
      },
    ],
    filterable: false,
  },
  {
    field: "active",
    headerName: "Active",
    flex: 1,
    editable: true,
    type: "boolean",
    filterable: false,
  },
  {
    field: "legacy_loot_id",
    headerName: "Legacy ID",
    flex: 1,
    alwaysDisabled: true,
    type: "metadata",
  },
  {
    field: "sort_order",
    headerName: "Sort Order",
    flex: 2,
    type: "number",
    editable: true,
    filterable: false,
  },
  {
    field: "drop_weight",
    headerName: "Drop Weight",
    flex: 2,
    type: "number",
    editable: true,
    filterable: false,
  },
  {
    field: "fill_in_new_order",
    headerName: "Fill In New Order",
    flex: 2,
    editable: true,
    type: "boolean",
    filterable: false,
  },
  {
    field: "allow_partial_bundles",
    headerName: "Allow Partial Bundles",
    flex: 2,
    editable: true,
    type: "boolean",
    defaultVal: true,
    filterable: false,
  },
  {
    field: "required_item_id",
    headerName: "Required Item",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    api: "item",
    idKey: "item_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "required_item_count",
    headerName: "Required Item Count",
    flex: 2,
    type: "number",
    editable: true,
    filterable: false,
  },
  {
    field: "stack_limit",
    headerName: "Stack Limit",
    flex: 2,
    type: "number",
    editable: true,
    filterable: false,
  },
  {
    field: "quantity_type",
    headerName: "Quantity Type",
    flex: 2,
    editable: true,
    type: "select",
    options: [
      { value: "relative", label: "Relative" },
      { value: "absolute", label: "Absolute" },
    ],
    filterable: false,
  },
  {
    field: "quantity_multi_inventory_item_id",
    headerName: "Quantity Multi Inventory Item",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    api: "item",
    idKey: "item_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "is_claimable_by_client",
    headerName: "Is Claimable By Client",
    flex: 2,
    editable: true,
    type: "boolean",
    filterable: false,
  },
  // //?{ field: 'time_frame_id', headerName: 'Time Frame', flex: 2, editable: true, hideable: false },
  {
    field: "use_inventory_bucket",
    headerName: "Use Inventory Bucket",
    flex: 2,
    editable: true,
    type: "select",
    options: [
      { value: "none", label: "None" },
      { value: "free", label: "Free" },
      { value: "sweat", label: "Sweat" },
      { value: "anon", label: "Anon" },
      { value: "amazon", label: "Amazon" },
      { value: "steam", label: "Steam" },
      { value: "psn", label: "PSN" },
      { value: "xbox_live", label: "Xbox Live" },
      { value: "google", label: "Google" },
      { value: "twitch", label: "Twitch" },
      { value: "nintendo_switch", label: "Nintendo Switch" },
      { value: "apple", label: "Apple" },
      { value: "nintendo", label: "Nintendo" },
      { value: "epic", label: "Epic" },
      { value: "google_play", label: "Google Play" },
      { value: "nintendo_ppid", label: "Nintendo Pid" },
    ],
    filterable: false,
  },
  {
    field: "xp_quantity_transform_type",
    headerName: "XP Quantity Transform Type",
    flex: 2,
    editable: true,
    type: "select",
    options: [
      { value: "none", field: "None" },
      {
        value: "from_current_xp_to_target_additional_level_min_xp",
        label: "From current XP to target additional level min xp",
      },
      {
        value: "from_current_level_min_xp_to_target_additional_level_min_xp",
        label: "From current levl min XP to target additional level min xp",
      },
      {
        value: "from_zero_to_target_exact_level_min_xp",
        label: "From zero to target exact level min XP",
      },
    ],
    filterable: false,
  },
  {
    field: "current_price_point_id",
    headerName: "Current Price Point",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    filterable: false,
    api: "price-point",
    idKey: "price_point_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "pre_sale_price_point_id",
    headerName: "Pre Sale Price Point",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    filterable: false,
    api: "price-point",
    idKey: "price_point_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "hard_quantity_maximum",
    headerName: "Hard Quantity Maximum",
    flex: 2,
    editable: true,
    filterable: false,
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    type: "json",
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
    filterable: false,
  },
  {
    field: "last_modified_timestamp",
    headerName: "Last Modified",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    type: "metadata",
    filterable: false,
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    hideable: true,
    filterable: false,
    type: "metadata",
    filterable: false,
  },
];

export class lootTableEntry {
  constructor(sandbox_id, vendor_id) {
    this.sandbox_id = sandbox_id;
    if (vendor_id !== undefined) {
      this.vendor_id = vendor_id;
    }
  }
  quantity = 1;
  inventory_selector_type = "own";
  inventory_operation = "add";
  active = true;
  sort_order = 0;
  drop_weight = 0;
  fill_in_new_order = false;
  allow_partial_bundles = true;
  required_item_count = 0;
  stack_limit = 0;
  quantity_type = "relative";
  is_claimable_by_client = false;
  use_inventory_bucket = "none";
  hard_quantity_maximum = 0;
  custom_data = {};
}

//Audit configs
export const auditApi = "audits";
export const auditTableRowKey = "audit_id";
export const auditTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
  columns: {
    columnVisibilityModel: {
      last_modified_timestamp: false,
      last_modified_account_id: false,
    },
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
};
export const auditTableColumns = [
  {
    field: "audit_id",
    headerName: "Audit ID",
    flex: 3,
    editable: false,
    hideable: false,
  },
  {
    field: "audit_ref_type",
    headerName: "Audit Ref Type",
    flex: 3,
    editable: true,
  },
  {
    field: "account_id",
    headerName: "Account ID",
    flex: 3,
    editable: false,
  },
  {
    field: "modified_timestamp",
    headerName: "Modified Timestamp",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "audit_path",
    headerName: "Audit Path",
    flex: 3,
    editable: true,
  },
  {
    field: "patch",
    headerName: "Patch",
    flex: 3,
    editable: true,
    type: "json",
  },
];

export class auditTableEntry {
  constructor(sandbox_id, account_id) {
    this.sandbox_id = sandbox_id;
    this.account_id = account_id;
  }
  audit_id = "";
  audit_ref_type = "";
  audit_ref_id = "";
  audit_path = "";
  patch = "";
  modified_timestamp = new Date().toISOString();
}

//Vendor configs
export const vendorRowKey = "vendor_id";
export const vendorColumns = [
  {
    field: "vendor_id",
    headerName: "Vendor ID",
    flex: 1,
    editable: false,
    minWidth: 150,
    filterable: false,
    type: "metadata",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    editable: true,
    hideable: false,
    required: true,
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((op) => ["contains"].includes(op.value)),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    editable: true,
    hideable: true,
    sortable: false,
    required: false,
    minWidth: 150,
    filterable: false,
  },
  {
    field: "vendor_type",
    headerName: "Type",
    flex: 1,
    editable: true,
    hideable: true,
    type: "select",
    minWidth: 150,
    required: true,
    filterable: false,
    options: [
      { value: "recipe", label: "Recipe" },
      { value: "randomly_sampled", label: "Randomly Sampled" },
    ],
  },
  {
    field: "legacy_vendor_id",
    headerName: "Legacy ID",
    filterable: false,
    flex: 2,
    alwaysDisabled: true,
    type: "metadata",
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    hideable: true,
    filterable: false,
    type: "metadata",
  },
  {
    field: "last_modified_timestamp",
    headerName: "Last Modified",
    flex: 3,
    editable: false,
    hideable: true,
    filterable: false,
    type: "metadata",
  },
];
export class vendorEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  vendor_type = "";
  loot = [];
}

//RuleSet configs
export const ruleSetRowKey = "rule_set_id";
export const ruleSetColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    hideable: false,
    required: true,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    required: false,
  },
  { field: "rule_set_id", headerName: "ID", flex: 1, alwaysDisabled: true, type: "metadata" },
  {
    field: "legacy_rule_set_id",
    headerName: "Legacy ID",
    flex: 1,
    width: "25px",
    type: "metadata",
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 1,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    type: "json",
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
  },
];
export class ruleSetEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  rules = [];
  customData = {};
}

export const ruleRowKey = "psuedo_id";
export const ruleColumns = [
  {
    field: "bucket",
    headerName: "Bucket",
    editable: true,
    flex: 1,
    required: true,
    hideable: false,
    type: "select",
    options: [
      { value: "anon", label: "Anon" },
      { value: "amazon", label: "Amazon" },
      { value: "apple", label: "Apple" },
      { value: "epic", label: "Epic" },
      { value: "free", label: "Free" },
      { value: "google", label: "Google" },
      { value: "google_play", label: "Google Play" },
      { value: "nintendo", label: "Nintendo" },
      { value: "nintendo_ppid", label: "Nintendo PPID" },
      { value: "nintendo_switch", label: "Nintendo Switch" },
      { value: "none", label: "None" },
      { value: "psn", label: "PSN" },
      { value: "steam", label: "Steam" },
      { value: "sweat", label: "Sweat" },
      { value: "twitch", label: "Twitch" },
      { value: "xbox_live", label: "Xbox Live" },
    ],
  },
  {
    field: "bucket_use",
    headerName: "Bucket Use",
    editable: true,
    flex: 1,
    required: true,
    hideable: false,
    type: "select",
    options: [
      { value: "anon", label: "Anon" },
      { value: "amazon", label: "Amazon" },
      { value: "apple", label: "Apple" },
      { value: "epic", label: "Epic" },
      { value: "free", label: "Free" },
      { value: "google", label: "Google" },
      { value: "google_play", label: "Google Play" },
      { value: "nintendo", label: "Nintendo" },
      { value: "nintendo_ppid", label: "Nintendo PPID" },
      { value: "nintendo_switch", label: "Nintendo Switch" },
      { value: "none", label: "None" },
      { value: "psn", label: "PSN" },
      { value: "steam", label: "Steam" },
      { value: "sweat", label: "Sweat" },
      { value: "twitch", label: "Twitch" },
      { value: "xbox_live", label: "Xbox Live" },
    ],
  },
  {
    field: "sort_order",
    headerName: "Sort Order",
    description: `Sort order applies to Bucket Use items within a Bucket. 
      Setting Sort Order will adjust a Bucket Use items location in the list, 
      but items are automatically re-ordered on save`,
    flex: 1,
    hideable: false,
    editable: true,
    type: "number",
    headerAlign: "left",
    align: "left",
    min: 0,
    required: true,
  },
];

export class ruleEntry {
  constructor(sandbox_id, rule_set_id) {
    this.sandbox_id = sandbox_id;
    this.rule_set_id = rule_set_id;
  }
  bucket = "none";
  bucket_use = "none";
  sort_order = 0;
}

//PricePoint configs
export const pricePointApi = "price_point";
export const pricePointRowKey = "price_point_id";
export const pricePointColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    hideable: false,
    required: true,
  },
  { field: "description", headerName: "Description", flex: 1, required: false },
  { field: "strict_flag", headerName: "Strict", flex: 1, type: "boolean" },
  { field: "cap_flag", headerName: "Cap", flex: 1, type: "boolean" },
  { field: "price_point_id", headerName: "ID", flex: 1, type: "metadata" },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 1,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    type: "json",
    valueGetter: (params) => {
      return JSON.stringify(params.value);
    },
  },
];

export class pricePointEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  custom_data = {};
}

export const breakPointRowKey = "price_break_point_id";
export const breakPointColumns = [
  {
    field: "price_break_point_id",
    headerName: "Breakpoint ID",
    type: "metadata",
    editable: false,
    flex: 2,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    type: "number",
    editable: true,
    required: true,
    advancedSearch: false,
    multiSelect: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "currencies",
    headerName: "Currencies",
    type: "repeater",
    rowKey: "price_break_point_currency_id",
    alwaysHidden: true,
    children: [
      {
        field: "price_break_point_currency_id",
        headerName: "Currency ID",
        type: "metadata",
      },
      {
        field: "price",
        headerName: "Price",
        flex: 2,
        type: "number",
        editable: true,
        required: true,
      },
      {
        field: "price_item_id",
        headerName: "Pricepoint Item ID",
        flex: 2,
        type: "dynamicselectfield",
        api: "item",
        idKey: "item_id",
        displayValue: "name",
        responseDataName: "data",
        editable: true,
        required: true,
        tableData: "ItemCollection",
      },
    ],
  },
];

export class breakPointEntry {
  currencies = [
    {
      price_break_point_currency_id: "",
      price: "",
      price_item_id: "",
    },
  ];
  quantity = "";
}

//Roles
export const rolesApi = "roles";
export const rolesTableRowKey = "role_id";
export const rolesTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: {
      description: false,
      last_modified_timestamp: false,
      last_modified_account_id: false,
    },
  },
  sorting: {
    sortModel: [{ field: "legacy_role_id", sort: "asc" }],
  },
};

export class rolesTableEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  auth_bypass = "";
  custom_data = {};
}

export const rolesTableColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    editable: true,
    hideable: false,
    required: false,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    editable: true,
    hideable: false,
    required: false,
    alwaysHidden: true,
  },
  {
    field: "role_id",
    headerName: "Role ID",
    flex: 2,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "legacy_role_id",
    headerName: "Legacy Role ID",
    flex: 2,
    editable: "createOnly",
    hideable: true,
    editable: false,
    alwaysHidden: false,
    type: "metadata",
  },
  {
    field: "auth_bypass",
    headerName: "Auth Bypass",
    flex: 2,
    editable: true,
    hideable: true,
    type: "select",
    options: [
      { value: "none", label: "None" },
      { value: "locked", label: "Locked" },
      { value: "limited", label: "Limited" },
    ],
  },
  {
    field: "login_loot_rewards",
    headerName: "Login Loot Rewards",
    flex: 2,
    editable: true,
    required: false,
    type: "enhancedautocomplete",
    advancedSearch: false,
    alwaysHidden: true,
    multiSelect: true,
    isDynamic: true,
    api: "loot",
    idKey: "loot_id",
    displayValue: "name",
    responseDataName: "data",
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    hideable: true,
    alwaysHidden: true,
    type: "json",
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 3,
    editable: false,
    hideable: true,
    type: "metadata",
  },
];

//Entitlement SKU
export const eSKUApi = "entitlement_sku";
export const eSKUTableRowKey = "entitlement_sku_id";
export const eSKUTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: {
      last_modified_timestamp: false,
      last_modified_account_id: false,
    },
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
};

export class eSKUTableEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  custom_data = {};
}

export const eSKUTableColumns = [
  {
    field: "sku",
    headerName: "SKU",
    flex: 2,
    editable: true,
    hideable: false,
    required: true,
  },
  {
    field: "platform",
    headerName: "Platform",
    flex: 2,
    editable: true,
    required: true,
    hideable: true,
    type: "enhancedautocomplete",
    advancedSearch: false,
    multiSelect: false,
    isDynamic: false,
    api: "",
    idKey: "",
    displayValue: "",
    responseDataName: "",
    defaultOptions: [
      { id: "Anon", title: "Anon" },
      { id: "Basic", title: "Basic" },
      { id: "XboxLive", title: "XboxLive" },
      { id: "PSN", title: "PSN" },
      { id: "NintendoNAID", title: "NintendoNAID" },
      { id: "NintendoSwitch", title: "NintendoSwitch" },
      { id: "NintendoPPID", title: "NintendoPPID" },
      { id: "Google", title: "Google" },
      { id: "GooglePlay", title: "GooglePlay" },
      { id: "Apple", title: "Apple" },
      { id: "Epic", title: "Epic" },
      { id: "Steam", title: "Steam" },
      { id: "Amazon", title: "Amazon" },
      { id: "Twitch", title: "Twitch" },
    ],
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    editable: true,
    hideable: true,
    required: false,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    editable: true,
    hideable: true,
    required: false,
  },
  {
    field: "loot_entitlement",
    headerName: "Loot Entitlement",
    flex: 2,
    editable: true,
    hideable: true,
    type: "parent",
    minWidth: 150,
    children: [
      {
        field: "loot_id",
        headerName: "Loot ID",
        flex: 2,
        editable: true,
        required: true,
        type: "enhancedautocomplete",
        advancedSearch: false,
        alwaysHidden: true,
        multiSelect: false,
        isDynamic: true,
        api: "loot",
        idKey: "loot_id",
        displayValue: "name",
        responseDataName: "data",
        defaultOptions: [],
      },
      {
        field: "type",
        headerName: "Type",
        flex: 2,
        editable: true,
        required: true,
        type: "enhancedautocomplete",
        advancedSearch: false,
        alwaysHidden: true,
        multiSelect: false,
        isDynamic: false,
        api: "",
        idKey: "",
        displayValue: "",
        responseDataName: "",
        defaultOptions: [
          { id: "consumable", title: "Consumable" },
          { id: "durable", title: "Durable" },
          { id: "durable_single_use", title: "Durable Single Use" },
        ],
      },
    ],
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    flex: 4,
    editable: true,
    hideable: true,
    alwaysHidden: true,
    type: "json",
  },
  {
    field: "entitlement_sku_id",
    headerName: "Entitlement SKU ID",
    flex: 1,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "sandbox_id",
    headerName: "Sandbox ID",
    flex: 3,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    hideable: true,
    type: "metadata",
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 3,
    editable: false,
    hideable: true,
    type: "metadata",
  },
];

//Custom Endpoints
export const customEndpointsApi = "custom_endpoints";
export const customEndpointsRowKey = "custom_endpoint_unique_id";

export class customEndpointsTableEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  custom_headers = {};
  endpoint_id = "";
  http_verb = "GET";
  url = "";
}

export const customEndpointsColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    editable: true,
    required: false,
  },
  {
    field: "endpoint_id",
    headerName: "Endpoint ID",
    flex: 2,
    editable: "createOnly",
    required: true,
    hideable: false,
  },
  {
    field: "http_verb",
    headerName: "HTTP Verb",
    flex: 1,
    editable: true,
    required: true,
    hideable: true,
    type: "select",
    options: [
      { value: "GET", label: "GET" },
      { value: "POST", label: "POST" },
      { value: "PUT", label: "PUT" },
      { value: "DELETE", label: "DELETE" },
      { value: "HEAD", label: "HEAD" },
      { value: "PATCH", label: "PATCH" },
    ],
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    editable: true,
    required: false,
  },
  {
    field: "custom_headers",
    headerName: "Custom Headers",
    flex: 4,
    editable: true,
    alwaysHidden: true,
    type: "json",
  },
  {
    field: "custom_endpoint_unique_id",
    headerName: "Custom Endpoint Unique ID",
    flex: 1,
    editable: false,
    type: "metadata",
  },
  {
    field: "url",
    headerName: "URL",
    flex: 3,
    editable: true,
    required: true,
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    type: "metadata",
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 2,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
];

export const gameEventsColumns = [
  {
    field: "event_name",
    headerName: "Event Name",
    required: true,
    flex: 2,
    editable: "createOnly",
  },
  {
    field: "name",
    headerName: "Name",
    editable: true,
    flex: 2,
  },
  {
    field: "description",
    headerName: "Description",
    editable: true,
    required: true,
    flex: 2,
  },
  {
    field: "state",
    headerName: "State",
    editable: true,
    required: true,
    flex: 2,

    hideable: true,
    type: "select",
    options: [
      { value: "enabled", label: "ENABLED" },
      { value: "disabled", label: "DISABLED" },
      { value: "deny", label: "DENY" },
    ],
  },
  {
    field: "value_json",
    headerName: "Value JSON",
    editable: true,
    hideable: true,
    alwaysHidden: true,
    type: "json",
  },
  {
    field: "custom_data",
    headerName: "Custom Data",
    editable: true,
    hideable: true,
    alwaysHidden: true,
    type: "json",
  },
  {
    field: "last_modified_account_id",
    headerName: "Last Modified Account ID",
    flex: 2,
    editable: false,
    type: "metadata",
  },
  {
    field: "created_timestamp",
    headerName: "Created",
    flex: 2,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 2,
    editable: false,
    type: "metadata",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
];

export class gameEventsEntry {
  constructor(sandbox_id) {
    this.sandbox_id = sandbox_id;
  }
  state = "disabled";
  custom_data = {};
  value_json = {};
}

export const gameEventsRowKey = "event_name";
