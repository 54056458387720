import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";

const RHTextField = ({
  handleValidationError,
  value,
  description,
  hasError,
  type: fieldType,
  name: fieldName,
  onChange: fieldOnChange,
  ...otherProps
}) => {
  const [error, setError] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const validateURL = (url) => {
    const re = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return re.test(url);
  };

  const validateUUID = (uuid) => {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return re.test(uuid);
  };

  const validateIPAddress = (ip) => {
    const ipv4 =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipv6 = /^([0-9a-f]{1,4}:){7}[0-9a-f]{1,4}$/i;
    return ipv4.test(ip) || ipv6.test(ip);
  };

  const validationMap = {
    email: validateEmail,
    url: validateURL,
    uuid: validateUUID,
    ip: validateIPAddress,
  };

  const handleOnChange = (event) => {
    const newValue = event.target.value;
    setLocalValue(newValue);

    let isError = false;
    if (newValue.trim() === "") {
      setError(false);
    } else {
      isError = fieldType in validationMap && !validationMap[fieldType](newValue);
      setError(isError);
    }

    if (fieldOnChange) {
      fieldOnChange(event);
    }

    if (handleValidationError) {
      const helperText = isError ? getHelperText(isError, fieldType) : "";
      handleValidationError(fieldName, isError, helperText);
    }
  };

  const handleBlur = () => {
    // Ensure localValue is a string before calling trim()
    const valueAsString = typeof localValue === "string" ? localValue : String(localValue);

    if (valueAsString.trim() !== "") {
      const isError = fieldType in validationMap && !validationMap[fieldType](valueAsString);
      setError(isError);
      if (handleValidationError) {
        const helperText = isError ? getHelperText(isError, fieldType) : "";
        handleValidationError(fieldName, isError, helperText);
      }
    }
  };

  useEffect(() => {
    setLocalValue(value); // Update the state when the prop value changes
  }, [value]);

  const getHelperText = (isError, type) => {
    if (isError ? isError : error) {
      switch (type) {
        case "email":
          return "Please enter a valid email";
        case "url":
          return "Please enter a valid URL";
        case "uuid":
          return "Please enter a valid UUID";
        case "ip":
          return "Please enter a valid IP address";
        default:
          return "Invalid input";
      }
    }
    return "";
  };

  const isErrorPresent = error || hasError;

  return (
    <TextField
      {...otherProps}
      name={fieldName}
      value={localValue}
      onChange={handleOnChange}
      onBlur={handleBlur}
      error={isErrorPresent}
      helperText={getHelperText()}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default RHTextField;
