import { Box, Drawer, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EnvironmentEdit from "./EnvironmentEdit";
import SandboxEdit from "../sandbox/SandboxEdit";
import SandboxCopy from "../sandbox/SandboxCopy";

function EditDialog(props) {
  const { type, onClose, openRequest, onSave } = props;

  const handleClose = (evt, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    onClose();
  };

  const handleOnSave = (type, name) => {
    let prefix = type ? "type" : "";
    prefix = type && name ? `"${name}" ${type}` : prefix;
    const msg = prefix ? `Changes saved to ${prefix}` : "Sucessfully saved";
    if (onSave) {
      onSave(msg);
    }
  };

  const title =
    type === "environment" ? "Environment Editor" : type === "sandbox-copy" ? "Sandbox Copy" : "Sandbox Editor";

  return (
    <>
      <div style={{}}>
        <>
          <Drawer onClose={handleClose} open={openRequest.open} anchor="right" disableRestoreFocus>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                minWidth: "600px",
                maxWidth: "600px",
              }}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {title}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <>
                {type === "environment" ? (
                  <EnvironmentEdit
                    isAddNew={!openRequest.environment}
                    onSave={handleOnSave}
                    onDialogClose={handleClose}
                    inputEnvironment={openRequest.environment}
                    isDialog={true}
                  />
                ) : type == "sandbox-copy" ? (
                  <SandboxCopy
                    isAddNew={!openRequest.sandbox}
                    onSave={handleOnSave}
                    onDialogClose={handleClose}
                    inputSandbox={openRequest.sandbox}
                    isDialog={true}
                  />
                ) : (
                  <SandboxEdit
                    isAddNew={!openRequest.sandbox}
                    onSave={handleOnSave}
                    onDialogClose={handleClose}
                    inputSandbox={openRequest.sandbox}
                    isDialog={true}
                  />
                )}
              </>
            </Box>
            <Box sx={{ height: "100%", backgroundColor: (theme) => theme.palette.background.paper }}></Box>
          </Drawer>
        </>
      </div>
    </>
  );
}

export default EditDialog;
