import { flexRender } from "@tanstack/react-table";

const DataTableRow = ({
  row,
  isSelected,
  onClick,
  enableMultiRowSelection,
}) => {
  const rowClass = isSelected
    ? "selected-item font-medium"
    : "custom-hover-row text-white/70";

  return (
    <tr className={rowClass}>
      {enableMultiRowSelection && (
        <td className="border border-white/10 text-center w-8">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={(e) => {
              row.toggleSelected(e.target.checked);
              onClick && onClick(row);
            }}
          />
        </td>
      )}
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          className="px-2 py-1 border border-white/10 whitespace-nowrap overflow-hidden text-ellipsis cursor-default"
          onClick={() => {
            row.toggleSelected(!isSelected);
            onClick && onClick(row);
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

export default DataTableRow;
