import { useState, useContext, useEffect, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import {
  useQuery,
  useMutation,
  keepPreviousData,
  queryOptions,
} from "@tanstack/react-query";

import definitions from "common/definitions.json";

export const API_BASE =
  process.env.NODE_ENV === "production"
    ? window.env.REACT_APP_API_BASE_PATH
    : process.env.REACT_APP_API_BASE_PATH;

export const apiRequest = async (
  token,
  endpoint,
  replacements,
  body = null,
  extraHeaders = {},
  extraOptions = {},
  pageData = {}
) => {
  let url = API_BASE + endpoint?.path;

  if (extraOptions.queryParams) {
    let processedParams = extraOptions.queryParams;
    if (typeof extraOptions.queryParams === "object") {
      processedParams = Object.entries(extraOptions.queryParams).reduce(
        (acc, [key, val], i) => {
          if (val) {
            acc += `${key}=${val}${
              i < Object.keys(extraOptions.queryParams).length - 1 ? "&" : ""
            }`;
          }
          return acc;
        },
        ""
      );
    } else {
      processedParams = new URLSearchParams(
        extraOptions.queryParams
      ).toString();
    }

    url += `?${processedParams}`;
  }

  // Extract placeholders from the URL
  const placeholderMatches = url.match(/\{[^\}]+\}/g) || [];

  for (const placeholder of placeholderMatches) {
    let key = placeholder.slice(1, -1);

    // Special ugh for xp_threshold_id
    if (key === "xp_threshold_id") {
      key = "xp_level_threshold_id";
    }
    // Special ugh x2 for sandbox_id
    if (key === "sandbox_identifier") {
      key = "sandbox_id";
    }
    // Special ugh x3 for inventory_bucket_rule_set_id
    if (key === "inventory_bucket_use_rule_set_id") {
      key = "rule_set_id";
    }

    let value =
      body?.[key] ||
      replacements?.[key] ||
      pageData.SelectedRecord?.[key] ||
      pageData?.[key];

    if (value) {
      url = url.replace(placeholder, value);
    }
  }

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...extraHeaders,
  });

  const options = {
    method: endpoint?.method.toUpperCase(),
    headers: headers,
    ...extraOptions.fetchOptions,
  };

  if (
    body &&
    ["PUT", "PATCH", "DELETE"].includes(endpoint?.method.toUpperCase())
  ) {
    options.body = JSON.stringify(body);
  }

  if (body && endpoint?.method.toUpperCase() === "POST") {
    let modifiedBody = body;

    //ugh
    if (pageData.PostDataArrayName === "data") {
      modifiedBody = { data: [body] };
    }

    options.body = JSON.stringify(modifiedBody);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMsg = "An error occurred while fetching data";

      // Check if the response has content before attempting to parse as JSON
      if (response.headers.get("content-type")?.includes("application/json")) {
        try {
          const errorData = await response.json();
          errorMsg = errorData.message || JSON.stringify(errorData); // Customize based on your API's error structure
        } catch (errorParsing) {
          console.error("Error parsing error response:", errorParsing);
        }
      }

      throw {
        message: `apiRequest: Network response was not ok: ${response.status} ${response.statusText}`,
        errorMsg: errorMsg,
        response: response,
      };
    }

    // Handle 204 No Content specifically
    if (response.status === 204) {
      console.log("Received 204 No Content response");
      return { status: 204, message: "Operation successful" };
    }

    // For other successful responses, attempt to parse JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return response.json();
    } else {
      console.log("Response is not JSON, returning raw response");
      return response;
    }
  } catch (error) {
    console.error("Error in apiRequest:", error);
    throw error; // Rethrow the error instead of returning null
  }
};

export const handleTableData = (
  token,
  pageData,
  operation,
  { data, replacements = {}, extraHeaders = {}, extraOptions = {} }
) => {
  let endpoint;
  switch (operation) {
    case "create":
      endpoint = pageData.Endpoints.find((e) => e.method === "post");
      break;
    case "update":
      endpoint =
        pageData.Endpoints.find((e) => e.method === "put") ||
        pageData.Endpoints.find((e) => e.method === "patch");
      replacements = { ...replacements, itemId: data.id }; // Assuming 'itemId' is part of the 'data' for update
      break;
    case "delete":
      //$$SAINT This needs to be looked at on OPENAPI or Python generator
      endpoint = pageData.Endpoints.find((e) => e.method === "delete");

      if (!endpoint) {
        const getEndpoint = pageData.Endpoints.find(
          (e) => e.method === "get" || e.method === "put"
        );
        if (getEndpoint) {
          endpoint = { ...getEndpoint, method: "delete" };
        }
      }
      break;

    default:
      throw new Error(`Unsupported operation: ${operation}`);
  }
  return apiRequest(
    token,
    endpoint,
    replacements,
    data,
    extraHeaders,
    extraOptions,
    pageData
  );
};

// useCustomMutation.js
export const useCustomMutation = (
  queryClient,
  token,
  pageData,
  operation,
  handleTableData,
  {
    onSuccessCallback = () => {},
    onErrorCallback = () => {},
    onMutateCallback = () => {},
    onSettledCallback = () => {},
  } = {} // Provide a default empty object
) => {
  return useMutation({
    mutationFn: (data) => {
      return handleTableData(token, pageData, operation, data);
    },
    onSuccess: (data, variables, context) => {
      if (onSuccessCallback) onSuccessCallback(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (error?.response?.status === 204) {
        // Handle the 204 No Content success case. Not sure why this happens
        onSuccessCallback(response, variables, context);
      } else {
        if (onErrorCallback) onErrorCallback(error);
      }
    },
    onMutate: async (variables) => {
      if (onMutateCallback) await onMutateCallback(variables);
    },
    onSettled: (data, error, variables, context) => {
      if (onSettledCallback) onSettledCallback(data, error, variables, context);
    },
  });
};

export const useApiToken = () => {
  const { getAccessTokenSilently } = useAuth();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return token;
};

export async function fetchDirectly(args, params) {
  let paramsString;

  if (params) {
    if (params.name === "*") {
      paramsString = "";
    } else {
      const searchParams = new URLSearchParams(Object.entries(params));
      paramsString = "?" + searchParams.toString();
    }
  }

  let response = await fetch(
    `${API_BASE}/${
      args?.fullUrl ??
      `v1/sandbox/${args?.sandboxId}/${args?.endpoint}${
        args?.dataId ? "/" + args?.dataId : ""
      }`
    }${paramsString ?? ""}`,
    {
      method: args?.method,
      headers: new Headers({
        Authorization: `Bearer ${args?.token}`,
        "Content-Type": "application/json",
      }),
      body: args.body ? JSON.stringify(args.body) : null,
    }
  );
  if (response?.ok) {
    return response.status === 204 ? [] : await response.json();
  } else {
    let newError = new Error("Something went wrong");
    let error_msg = await response.json();

    if (!Object.hasOwn(error_msg, "desc")) {
      newError.response = { data: { desc: JSON.stringify(error_msg) } };
    } else {
      newError.response = { data: error_msg };
    }

    throw newError;
  }
}

export function getErrorMessage(error, columns) {
  const baseErrorMsg = extractBaseErrorMessage(error);
  const detailedErrorMsg = extractDetailedErrorMessage(error, columns);

  return detailedErrorMsg || baseErrorMsg;
}

function extractBaseErrorMessage(error) {
  let errorData;
  try {
    errorData = error?.response?.data || JSON.parse(error?.errorMsg);
  } catch {
    errorData = error?.message;
  }

  if (!errorData) return null;

  return errorData.desc || errorData.detail || errorData;
}

function extractDetailedErrorMessage(error, columns) {
  let serverErrorAltDetail;
  try {
    serverErrorAltDetail =
      error?.response?.data?.detail || JSON.parse(error?.errorMsg)?.detail;
  } catch (error) {
    console.log("error parsing detail", { serverErrorAltDetail }, error);
  }
  if (!serverErrorAltDetail) {
    let serverErrorAltDesc = error?.response?.data?.desc;
    if (serverErrorAltDesc && typeof serverErrorAltDesc == "string") {
      try {
        const descJson = JSON.parse(serverErrorAltDesc)?.detail;
        serverErrorAltDetail = descJson;
      } catch {}
    }
  }

  if (!serverErrorAltDetail) {
    return null;
  }
  let msg = serverErrorAltDetail[0]?.msg;
  const type = serverErrorAltDetail[0]?.type;
  const loc = serverErrorAltDetail[0]?.loc;

  if (type && loc && loc.length > 0) {
    msg = type === "value_error.any_str.min_length" ? "value required" : msg;
    let suffix = loc[loc.length - 1];
    for (const col of columns) {
      if (col.field === suffix) {
        suffix = col.headerName;
        break;
      }
    }
    msg = `${msg}: ${suffix}`;
    msg = msg.replaceAll("_id", "");
  }
  return msg;
}

export function usePortalQuery({
  schema,
  token,
  replacements = {},
  data = {},
  method = "get",
  extraOptions = { queryParams: "" },
  configs = {
    version: "v1",
    schemas: undefined,
  },
  newModel = false,
}) {
  return useQuery({
    queryKey: [schema, replacements],
    queryFn: () => {
      // In the definitions file, there are often multiple
      // GET endpoints, one for a list, one for a single item.
      // This function finds the endpoint that contains all the
      // replacement keys in the path.
      const replacementKeys = Object.keys(replacements);
      const endpoint = definitions.schemas?.[schema]?.endpoints.find(
        (ep) =>
          ep.method === method.toLowerCase() &&
          ep.path.includes(configs?.version ?? "v1") &&
          !ep.path.includes("/account/me") &&
          (replacementKeys.length > 0
            ? replacementKeys.some((key) => ep.path.includes(`{${key}}`))
            : true)
      );

      let extraOptions = {
        queryParams: configs?.schemas?.GETparams ?? undefined,
      };

      return apiRequest(
        token,
        endpoint,
        replacements,
        data,
        null,
        extraOptions
      );
    },
    staleTime: 30000,
    enabled: !!replacements && !!token && !newModel && !!schema,
    placeholderData: keepPreviousData,
  });
}

export function usePortalMutation({
  queryClient,
  schema,
  token,
  replacements,
  configs,
  method = "put",
  extraHeaders = {},
  onSuccessCallback = () => {},
  onErrorCallback = () => {},
  onMutateCallback = () => {},
  onSettledCallback = () => {},
}) {
  return useMutation({
    mutationFn: ({
      data = {},
      schemaOverride = undefined,
      configsOverride = undefined,
      replacementsOverride = undefined,
    } = {}) => {
      let endpoint;
      // POSTs often don't have replacements.
      const replacementKeys =
        replacementsOverride || replacements
          ? Object.keys(replacementsOverride ?? replacements)
          : undefined;

      if (method === "delete") {
        // For DELETE, use the GET endpoint as a base and modify it
        endpoint = definitions.schemas?.[
          schemaOverride ?? schema
        ]?.endpoints.find(
          (ep) =>
            ep.method === "get" &&
            ep.path.includes(
              configsOverride?.version ?? configs?.version ?? "v1"
            ) &&
            replacementKeys.some((key) => ep.path.includes(`{${key}}`)) &&
            !ep.path.includes("/account/me")
        );
        if (endpoint) {
          endpoint = { ...endpoint, method: "delete" };
        }
      } else {
        endpoint = definitions.schemas?.[
          schemaOverride ?? schema
        ]?.endpoints.find(
          (ep) =>
            ep.method === method &&
            ep.path.includes(
              configsOverride?.version ?? configs?.version ?? "v1"
            ) &&
            !ep.path.includes("/account/me")
        );
      }

      if (!endpoint) {
        throw new Error(`No endpoint found for method: ${method}`);
      }

      let extraOptions = {
        queryParams:
          method === "delete"
            ? configsOverride?.schemas?.DELETEparams ??
              configs?.schemas?.DELETEparams
            : method === "post"
            ? configsOverride?.schemas?.POSTparams ??
              configs?.schemas?.POSTparams
            : configsOverride?.schemas?.PUTparams ??
              configs?.schemas?.PUTparams,
      };

      return apiRequest(
        token,
        endpoint,
        replacementsOverride ?? replacements,
        data,
        extraHeaders,
        extraOptions
      );
    },
    onSuccess: (data, variables, context) => {
      if (onSuccessCallback) onSuccessCallback(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (error?.response?.status === 204) {
        // Handle the 204 No Content success case. Not sure why this happens
        onSuccessCallback(response, variables, context);
      } else {
        if (onErrorCallback) onErrorCallback(error);
      }
    },
    onMutate: async (variables) => {
      if (onMutateCallback) await onMutateCallback(variables);
    },
    onSettled: (data, error, variables, context) => {
      if (onSettledCallback) onSettledCallback(data, error, variables, context);
    },
  });
}

export function validateData(data, columns, rowKey) {
  return columns.reduce((acc, field) => {
    const fieldValue = data[field.field];
    const isEmpty =
      fieldValue === null ||
      fieldValue === undefined ||
      fieldValue.toString().trim() === "";
    const isRequired = field.required && field.field !== rowKey;

    if (isRequired && isEmpty) {
      acc.push(`${field.headerName}: Required`);
    }

    if (field.type === "json" && fieldValue) {
      try {
        JSON.stringify(data[field.field]);
      } catch (error) {
        acc.push(`${field.headerName}: Invalid JSON`);
      }
    }
    return acc;
  }, []);
}

export const fetchTableData = async (
  token,
  idKey,
  get_endpoint,
  replacements,
  searchValue,
  value,
  options = []
) => {
  const queryParams = new URLSearchParams();
  const normalizedValue = Array.isArray(value)
    ? value
    : [value].filter(Boolean);

  // Add idKeys to queryParams if no options are available
  if (
    !options.length &&
    !normalizedValue.some((val) =>
      options.some((option) => option?.id === val?.id)
    )
  ) {
    normalizedValue.forEach((item) => {
      const ids = Array.isArray(item?.id) ? item.id : [item?.id];
      ids.forEach((id) => id && queryParams.append(idKey + "s", id));
    });
  }

  if (searchValue) {
    queryParams.append("name", searchValue);
  }

  // Add fixed query parameters
  queryParams.append("expand", "*");
  queryParams.append("sort_by", idKey);
  queryParams.append("sort_order", "asc");
  queryParams.append("cursor", "0");
  queryParams.append(
    "page_size",
    options.length ? 50 : normalizedValue[0]?.id?.length || 50
  );

  return apiRequest(
    token,
    get_endpoint,
    replacements,
    {},
    {},
    { queryParams: queryParams.toString() }
  );
};

export const fetchDynamicSelectData = async (
  token,
  idKey,
  get_endpoint,
  replacements,
  searchValue,
  value,
  selectedIds = []
) => {
  const queryParams = new URLSearchParams();

  // Add selectedIds to queryParams
  selectedIds.forEach((id) => {
    if (id) {
      queryParams.append(`${idKey}s`, id);
    }
  });

  if (searchValue) {
    queryParams.append("name", searchValue);
  }

  // Add fixed query parameters
  queryParams.append("expand", "*");
  queryParams.append("sort_by", idKey);
  queryParams.append("sort_order", "asc");
  queryParams.append("cursor", "0");
  queryParams.append("page_size", "50");

  return apiRequest(
    token,
    get_endpoint,
    replacements,
    {},
    {},
    { queryParams: queryParams.toString() }
  );
};
