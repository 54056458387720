import Papa from "papaparse";
import { apiRequest, useApiToken } from "../common/apiUtils";

export function capitalizeFirstLetters(string, separator = " ") {
  const words = string.replaceAll("_", " ").split(separator);

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
}

export function makeColumn(columnName, required, key, value) {
  if (typeof value === "object") {
    return {
      field: `${columnName}_${key}`,
      headerName: capitalizeFirstLetters(`${columnName} ${key}`),
      type: "json",
    };
  } else {
    return {
      field: `${columnName}_${key}`,
      headerName: capitalizeFirstLetters(`${columnName} ${key}`),
      flex: 2,
      editable: true,
      hideable: true,
      required: required ?? false,
      minWidth: 150,
      type: typeof value === "number" ? "number" : "text",
    };
  }
}

export function spreadColumns(columns, data) {
  let spreadColumnNames = {};
  return [
    columns.reduce((acc, column) => {
      if (column.type === "parent") {
        Object.entries(data[0][column.field]).forEach(([key, value]) => {
          if (spreadColumnNames[column.field]) {
            spreadColumnNames[column.field].push(`${key}`);
          } else {
            spreadColumnNames[column.field] = [`${key}`];
          }
          acc = [...acc, makeColumn(column.field, column.required, key, value)];
        });
        return acc;
      } else {
        return [...acc, column];
      }
    }, []),
    spreadColumnNames,
  ];
}

export function spreadData(data, spreadColumnNames) {
  let alteredProps = Object.keys(spreadColumnNames);
  return data.map((row) => {
    let newRow = {};
    Object.entries(row).forEach(([key, value]) => {
      if (alteredProps.includes(key)) {
        Object.entries(value).forEach(([k, v]) => {
          newRow[`${key}_${k}`] = v;
        });
      } else {
        newRow[key] = value;
      }
    });
    return newRow;
  });
}

export function collapseData(data, addedColumns) {
  return Object.entries(data).reduce(
    (acc, [key, value]) => {
      let parentKey;
      Object.keys(addedColumns).forEach((column) => {
        if (key.includes(column)) {
          parentKey = column;
        }
      });

      if (parentKey) {
        acc[parentKey][key.replace(`${parentKey}_`, "")] = value;
      } else acc[key] = value;
      return acc;
    },
    {
      ...Object.keys(addedColumns).reduce((acc, key) => {
        acc[key] = {};
        return acc;
      }, {}),
    }
  );
}

export function debounce(func, wait, immediate) {
  let timeout, result;
  const debounced = function () {
    const context = this;
    const args = arguments;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) result = func.apply(context, args);
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    }
    return result;
  };

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
}

export function createColVisibilityModel(keepers, columns) {
  var hiders = columns
    .map((x) => x.field)
    .filter((x) => !keepers.includes(x))
    .reduce((dict, el) => ((dict[el] = false), dict), {});
  return hiders;
}

export const getInitialTableState = (keepers, columns) => ({
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: createColVisibilityModel(keepers, columns),
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
});

export function customFilterFunc(field) {
  return "*";
}

export const showChildForm = () => {
  setTimeout(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, 300);
};

export const retrieveSelectedRows = (setSelectedTableRow) => (item) => {
  setSelectedTableRow(item ? item : undefined);
};

export const retrieveNewItemAsSelectedRow = (setSelectedTableRow) => (item) => {
  setSelectedTableRow(item ? item : undefined);
};

export const onSelectedRowsChanged = (setSelectedTableRow) => (item) => {
  if (Array.isArray(item) && item.length === 0) {
    setSelectedTableRow(undefined);
  }
};

export const getOrderedColumns = (keepers, columns) => {
  const orderedKeepers = keepers.reduce((acc, visibleColumn) => {
    const column = columns.find((column) => column.field === visibleColumn);
    if (column) acc.push(column);
    return acc;
  }, []);

  const remainingColumns = columns.filter(
    (column) => !keepers.includes(column.field)
  );

  return [...orderedKeepers, ...remainingColumns];
};

export function csvToArray(csvString) {
  const lines = csvString.split("\n");
  const headers = lines[0].split(",").map((header) => header.trim());

  // This function will match either "quoted fields" or non-comma, non-space sequences.
  const regex = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^",\s]+)/g;

  return lines
    .slice(1)
    .filter((line) => line) // Filter out empty lines
    .map((line) => {
      const values = [];
      let match;
      while ((match = regex.exec(line)) !== null) {
        // Check if the value was quoted and capture the correct group
        const value = match[1] !== undefined ? match[1] : match[2];
        values.push(value);
      }

      if (headers.length !== values.length) {
        console.error("Mismatched headers and values:", headers, values);
        return {}; // Return an empty object or handle error as appropriate
      }

      return headers.reduce((obj, header, index) => {
        let trimmedValue = values[index].replace(/^"|"$/g, "").trim(); // Remove surrounding quotes
        if (trimmedValue.startsWith("{") || trimmedValue.startsWith("[")) {
          try {
            obj[header] = JSON.parse(trimmedValue);
          } catch (e) {
            console.error("Error parsing JSON value:", trimmedValue, e);
            obj[header] = trimmedValue;
          }
        } else {
          obj[header] = trimmedValue;
        }
        return obj;
      }, {});
    });
}

export async function handleCsvExport(
  selectedData,
  token,
  endpoint,
  sandboxId
) {
  try {
    const data = await fetchData(token, endpoint, sandboxId);
    const filteredData = filterData(data, selectedData);
    const csv = Papa.unparse(filteredData);
    downloadCsv(csv, selectedData.Name);
  } catch (error) {
    console.error("Error during CSV export:", error);
    throw error;
  }
}

async function fetchData(token, endpoint, sandboxId) {
  const queryParams = new URLSearchParams({ page_size: "10000" });
  const extraOptions = { queryParams: queryParams.toString() };
  const response = await apiRequest(
    token,
    endpoint,
    { sandbox_id: sandboxId },
    {},
    {},
    extraOptions
  );
  return response;
}

function filterData(data, selectedData) {
  const excludeColumns = getExcludeColumns(selectedData.Columns);
  return data?.[selectedData?.TableDataArrayName]?.map((row) =>
    Object.fromEntries(
      Object.entries(row).filter(([key]) => !excludeColumns.includes(key))
    )
  );
}

function getExcludeColumns(columns) {
  return Object.entries(columns)
    .filter(([, value]) => value.type === "array" || value.type === "object")
    .map(([key]) => key);
}

function downloadCsv(csv, fileName) {
  const element = document.createElement("a");
  const file = new Blob([csv], { type: "text/csv" });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}s.csv`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export async function handleCsvImport(file, cachedData, selectedPage) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        Papa.parse(evt.target.result, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            const rows = results.data;
            const existingItems =
              cachedData[selectedPage]?.TableData?.[
                cachedData[selectedPage]?.TableDataArrayName
              ] || [];
            let addCount = 0;
            let updateCount = 0;
            const existingItemIds = new Set();

            rows.forEach((row) => {
              const existingItem = existingItems.find(
                (item) =>
                  item[cachedData[selectedPage].RowKey] ===
                  row[cachedData[selectedPage].RowKey]
              );
              if (existingItem) {
                existingItemIds.add(
                  existingItem[cachedData[selectedPage].RowKey]
                );
                if (JSON.stringify(existingItem) !== JSON.stringify(row)) {
                  updateCount++;
                }
              } else {
                addCount++;
              }
            });

            const deleteCount = existingItems.filter(
              (item) =>
                !existingItemIds.has(item[cachedData[selectedPage].RowKey])
            ).length;

            resolve({
              rows,
              recordCounts: {
                add: addCount,
                delete: deleteCount,
                update: updateCount,
              },
            });
          },
          error: (error) => {
            console.error("Error parsing CSV:", error.message);
            reject(error);
          },
        });
      }
    };
  });
}

export function getItemsToDelete(uploadedRows, pageData, rowKey) {
  const uploadedItemIds = new Set(uploadedRows.map((obj) => obj?.[rowKey]));
  return pageData?.TableData?.[pageData?.TableDataArrayName]?.filter(
    (obj) => obj?.[rowKey] && !uploadedItemIds.has(obj?.[rowKey])
  );
}

export function findEndpoint(method) {
  let endpoint = cachedData[selectedPage].Endpoints.find(
    (e) => e.method === method
  );
  return { ...endpoint, method };
}

export function readCookie(name) {
  if (typeof window !== "undefined") {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
}

export function createCookie(name, value, days) {
  let expires;
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + ";path=/;SameSite=Lax";
}

export function eraseCookie(name) {
  createCookie(name, "", -1);
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const updateTableSettings = (pageKey, settingKey, newValue) => {
  const tableSettings = JSON.parse(localStorage.getItem("tableSettings")) || {};
  const currentPageSettings = tableSettings[pageKey] || {};
  const currentValue = currentPageSettings[settingKey];

  // Use a more sophisticated comparison for both arrays and objects
  const hasChanged = !_.isEqual(newValue, currentValue); // Using Lodash's isEqual for deep comparison

  if (hasChanged) {
    // console.log(`TableSettings changed ${settingKey} have changed`, newValue);

    // Update the specific page's settings only if there's a change
    tableSettings[pageKey] = {
      ...currentPageSettings,
      [settingKey]: newValue,
    };

    // Consider debouncing this if updates are frequent
    localStorage.setItem("tableSettings", JSON.stringify(tableSettings));
    // console.log("Saving settings to local storage");
  } else {
    // console.log(`${settingKey} unchanged, not updating local storage`);
  }
};

export const getTableSettings = (pageKey, settingKey) => {
  const tableSettings = JSON.parse(localStorage.getItem("tableSettings")) || {};
  return tableSettings[pageKey]?.[settingKey];
};
