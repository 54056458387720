import {
  TableChartIcon,
  ManageHistoryIcon,
  QueuePlayNextIcon,
  PermDataSettingIcon,
  AdminPanelSettingsIcon,
  RecentActorsIcon,
  LinkIcon,
  ManageAccountsIcon,
  HandShakeIcon,
  ConnectingAirportsIcon,
  KeyIcon,
  EventAvailableIcon,
  StorageIcon,
} from "../../assets/svgs";
import PsychologyIcon from "@mui/icons-material/Psychology";

const sandboxConfigElements = [
  {
    name: "Catalog",
    icon: <TableChartIcon className={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Items",
        route: "items",
      },
      {
        name: "Loot",
        route: "loot",
      },
      {
        name: "Vendors",
        route: "vendors",
      },
      {
        name: "XP Tables Config",
        route: "xp",
      },
      {
        name: "Rule Sets",
        route: "rule-sets",
      },
      {
        name: "Pricing Config",
        route: "pricing-config",
      },
      {
        name: "Entitlement SKUs",
        route: "entitlement-sku",
      },
      {
        name: "External Key Campaigns",
        route: "external-key-campaign",
      },
    ],
  },
  {
    name: "Game Flow",
    icon: <QueuePlayNextIcon className={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Session Templates",
        route: "session-templates",
      },
      {
        name: "Maps",
        route: "maps",
      },
      {
        name: "Map Selection List",
        route: "map-selection-list",
      },

      {
        name: "Instance Request Templates",
        route: "instance-request-templates",
      },
    ],
  },
  {
    name: "Matchmaking",
    icon: <HandShakeIcon className={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Ranks",
        route: "ranks",
      },
      {
        name: "Stride",
        route: "matchmaking-stride",
      },

      {
        name: "Profile",
        route: "matchmaking-profile-list",
      },
      {
        name: "Queues",
        route: "queues",
      },
      {
        name: "Deserter",
        route: "deserterConfig",
      },
    ],
  },
  {
    name: "Advanced Matchmaking",
    icon: <PsychologyIcon style={{ marginRight: "7px" }} />,
    nestedItems: [
      {
        name: "Template Group",
        route: "matchmaking-template-group",
      },
      {
        name: " Rule Set",
        route: "matchmaking-ruleset",
      },
      {
        name: "Profile",
        route: "matchmaking-profile",
      },
      {
        name: "Profile List",
        route: "matchmaking-profile-list-advanced",
      },
    ],
  },
  {
    name: "Crossplay",
    icon: <ConnectingAirportsIcon className={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Crossplay Partition",
        route: "crossplay-partition",
      },
      {
        name: "Crossplay Profile",
        route: "crossplay-profile",
      },
      {
        name: "Crossplay Pool",
        route: "crossplay-pool",
      },
      {
        name: "Crossplay Pool Tag",
        route: "crossplay-pool-tag",
      },
    ],
  },
  {
    name: "Game Config",
    route: "game-config",
    icon: <PermDataSettingIcon className={`inline-block mr-2`} />,
  },
  {
    name: "KV Editor",
    route: "kv-editor",
    icon: <KeyIcon className={`inline-block mr-2`} />,
  },
  {
    name: "Game Events",
    route: "game-events",
    icon: <EventAvailableIcon className={`inline-block mr-2`} />,
  },
  {
    name: "Setting Types",
    route: "setting-types",
    icon: <ManageAccountsIcon className={`inline-block mr-2`} />,
  },
  {
    name: "Custom Endpoints",
    route: "custom-endpoints",
    icon: <LinkIcon className={`inline-block mr-2`} />,
  },
  {
    name: "Roles",
    route: "roles",
    icon: <AdminPanelSettingsIcon className={`inline-block mr-2`} />,
  },
];

export default sandboxConfigElements;
