import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#9d69ff",
    },
    secondary: {
      main: "#74c4ff",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.7)",
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    typography: {
      // Set default color for all Icons
      allVariants: {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export default theme;
