import React, { useState, useMemo, useContext, useEffect, useCallback } from "react";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import EnvironmentSidebar from "./Environment/EnvironmentSidebar";
import Notification from "../../components/common/Notification";
import Modal from "./components/Modal";
import { fetchDirectly } from "../../common/apiUtils";

const configByType = {
  environment: {
    menuItems: ["General", "File", "Vivox"],
    allFields: {
      General: ["Name", "Short Name", "Primary Contact Email", "Sandbox", "Authentication State", "Kick Before Hint"],
      File: [
        "File",
        "Match File TTL Days",
        "Match Developer File TTL Days",
        "Allow Files For Match",
        "Allow Developer Files For Match",
      ],
      Vivox: ["Vivox", "Voip", "Enabled", "Server", "Key Issuer", "Key Lifetime", "Signing Algorithm", "Signing Key"],
    },
    componentPath: "./Environment/",
  },
  sandbox: {
    menuItems: [
      "General",
      "Login",
      "Social",
      "Amazon",
      "Apple",
      "Epic",
      "Google",
      "Nintendo",
      "PlayStation",
      "Steam",
      "Twitch",
      "Xbox",
    ],
    allFields: {
      General: [
        "General",
        "General",
        "Last Modified Account ID",
        "Last Modified Timestamp",
        "Created Timestamp",
        "Allow Client to Update Inventory",
        "Enable Coupons",
        "Coupon Vendor ID",
        "Enable Legacy Setting Types",
      ],
      Login: ["Login", "Login", "Basic Enable Login", "Anon Enable Login"],
      Social: ["Social", "Friend Limit", "Block Limit"],
      Amazon: ["Amazon", "Enable Login", "Client ID", "Scopes"],
      Apple: ["Apple", "Enable Login", "Web Client ID", "Web Auth Key ID"],
      Epic: ["Epic", "Enable Login", "Client ID", "Product ID", "Sandbox ID", "Scopes"],
      Google: ["Google", "Enable Login", "Client ID"],
      Nintendo: ["Nintendo", "Active Environments", "App ID"],
      PlayStation: [
        "PlayStation",
        "Enable Playstation Login",
        "Playstation Environment",
        "Use PS4 Entitlements V1 API",
        "Use PS5 Entitlements V1 API",
        "Use PS4 Auth V1 API",
        "Use PS5 Auth V1 API",
        "PSN Web Client ID",
        "Commerce Partner Name",
        "Title IDs",
        "PS4 Client ID",
        "PS5 Client ID",
        "Enable PS4 V1 Portal Token Details",
        "Enable PS4 V3 Portal Token Details",
        "Enable PS5 V3 Portal Token Details",
        "EU Service Labels",
        "NA Service Labels",
      ],
      Steam: ["Enable Login", "Wallet Refunds Trigger Ban", "Enforce VAC Bans", "Enforce Publisher Bans", "App ID"],
      Twitch: ["Twitch", "Enable Login", "Client ID", "Game ID"],
      Xbox: ["Xbox", "Enable Login", "Web Client ID"],
    },
    componentPath: "./Sandbox/",
  },
};

const ControlPanel = ({ type = "environment" }) => {
  const [activeTab, setActiveTab] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [environment, setEnvironment] = useState(null);
  const [allSearchableItems, setAllSearchableItems] = useState([]);
  const [notificationProps, setNotificationProps] = useState({
    message: "",
    severity: "info",
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null,
    environmentName: "",
    environmentId: null,
    isArchiveAction: false,
  });

  const { currentStateData, permissionData, sandboxCopyIsEnabled, refreshAccount, hasPermission } =
    useContext(AccountContext);

  const { getAccessTokenSilently } = useAuth();

  const isPermitted = useCallback(() => {
    if (!currentStateData || !currentStateData.product || !permissionData) {
      return null; // Return null if we don't have enough data to make a decision
    }
    return hasPermission(
      "environment:config:edit",
      "product",
      currentStateData.product.productId,
      currentStateData,
      permissionData
    );
  }, [currentStateData, permissionData, hasPermission]);

  const handleNotification = (message, severity) => {
    setNotificationProps({ message, severity });
  };

  const fetchEnvironmentData = async () => {
    if (!currentStateData?.product?.productId || !currentStateData?.environment?.environmentId) {
      console.log("Missing product or environment ID, cannot fetch environment data");
      return;
    }

    try {
      const endpoint = `v1/product/${currentStateData.product.productId}/environment/${currentStateData.environment.environmentId}`;
      const response = await fetchDirectly({
        fullUrl: endpoint,
        method: "GET",
        token: await getAccessTokenSilently(),
      });

      // Update the environment state which will automatically update the sidebar
      setEnvironment(response);
      return response;
    } catch (error) {
      console.error("Failed to fetch environment data:", error);
      handleNotification("Failed to fetch environment data", "error");
    }
  };

  const config = configByType[type];

  useEffect(() => {
    setActiveTab(config.menuItems[0]);
    const items = Object.entries(config.allFields).flatMap(([tab, fields]) => fields.map((field) => ({ tab, field })));
    setAllSearchableItems(items);
  }, [type]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchResultClick = (result) => {
    setActiveTab(result.tab);
    setSearchQuery("");
  };

  const searchResults = useMemo(() => {
    if (!searchQuery.trim()) return [];
    const lowerQuery = searchQuery.toLowerCase();
    return allSearchableItems.filter((item) => item.field.toLowerCase().includes(lowerQuery));
  }, [searchQuery, allSearchableItems]);

  const clearNotification = () => {
    setNotificationProps({ message: "", severity: "info" });
  };

  const handleSpinActionConfirmation = (action, environmentName, environmentId) => {
    if (environment.archive && action === "up") {
      handleNotification("Cannot spin up an archived environment. Please unarchive it first.", "warning");
      return;
    }
    setModalState({
      isOpen: true,
      action,
      environmentName,
      environmentId,
      isArchiveAction: false,
    });
  };

  const handleArchiveConfirmation = (environmentName, environmentId, isArchive) => {
    setModalState({
      isOpen: true,
      action: isArchive ? "archive" : "unarchive",
      environmentName,
      environmentId,
      isArchiveAction: true,
    });
  };

  const pollEnvironmentStatus = async (environmentId, attempts = 3, interval = 2000) => {
    for (let i = 0; i < attempts; i++) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      await fetchEnvironmentData();

      if (environment?.state !== "initiating" && environment?.state !== "spinning_down") {
        break;
      }
    }
  };

  const handleModalConfirm = async () => {
    const { action, environmentName, environmentId, isArchiveAction } = modalState;
    setModalState({
      isOpen: false,
      action: null,
      environmentName: "",
      environmentId: null,
      isArchiveAction: false,
    });

    if (isArchiveAction) {
      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${environmentId}`;
        const updatedEnvironment = {
          ...environment,
          archive: action === "archive",
        };
        await fetchDirectly({
          fullUrl: endpoint,
          method: "PUT",
          body: updatedEnvironment,
          token: await getAccessTokenSilently(),
        });
        handleNotification(`Environment ${action === "archive" ? "archived" : "unarchived"} successfully`, "success");
        fetchEnvironmentData();
      } catch (error) {
        console.error(`Error during ${action} action:`, error);
        handleNotification(`Failed to ${action} environment ${environmentName}: ${error.message}`, "error");
      }
    } else {
      if (environment.archive && action === "up") {
        handleNotification("Cannot spin up an archived environment. Please unarchive it first.", "warning");
        return;
      }

      if (!environmentId) {
        console.error("Environment ID is missing");
        handleNotification(
          `Failed to ${action === "up" ? "spin up" : "spin down"} environment: Missing environment ID`,
          "error"
        );
        return;
      }

      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${environmentId}/${
          action === "up" ? "spin-up" : "spin-down"
        }`;

        await fetchDirectly({
          fullUrl: endpoint,
          method: "POST",
          token: await getAccessTokenSilently(),
        });

        handleNotification(
          `Environment ${environmentName} ${action === "up" ? "spun up" : "spun down"} successfully`,
          "success"
        );

        pollEnvironmentStatus(environmentId);
      } catch (error) {
        console.error(`Error during ${action} action:`, error);
        handleNotification(
          `Failed to ${action === "up" ? "spin up" : "spin down"} environment ${environmentName}: ${error.message}`,
          "error"
        );
      }
    }
  };

  const handleModalCancel = () => {
    setModalState({
      isOpen: false,
      action: null,
      environmentName: "",
      environmentId: null,
      isArchiveAction: false,
    });
  };

  const refreshSidebar = async () => {
    await fetchEnvironmentData();
  };

  const renderTabContent = () => {
    const ComponentToRender = React.lazy(() => import(`${config.componentPath}${activeTab}Config`));
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ComponentToRender
          onNotification={handleNotification}
          onSpinActionConfirmation={handleSpinActionConfirmation}
          onArchiveConfirmation={handleArchiveConfirmation}
          environment={environment}
          onRefresh={refreshSidebar}
        />
      </React.Suspense>
    );
  };

  // Add this useEffect hook to fetch environment data when environmentId changes
  useEffect(() => {
    if (currentStateData?.environment?.environmentId) {
      fetchEnvironmentData();
    }
  }, [currentStateData?.environment?.environmentId]);

  return (
    <div className="bg-zinc-900 text-zinc-300 min-h-screen">
      {/* Header with max-width container */}
      <header className="p-4">
        <div className="max-w-[1280px] mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <h1 className="text-lg font-bold">
              {type === "sandbox" ? "GAME CONFIG SETTINGS" : `${type.toUpperCase()} SETTINGS`}
            </h1>
            <div className="w-full md:w-[300px]">
              <input
                type="text"
                placeholder="Search settings..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full bg-zinc-700 text-white text-sm px-3 py-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500"
              />
              {searchQuery && searchResults.length > 0 && (
                <div className="absolute mt-1 w-[300px] bg-zinc-800 rounded-md shadow-lg max-h-60 overflow-auto z-10">
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className="px-3 py-1.5 hover:bg-zinc-700 cursor-pointer"
                      onClick={() => handleSearchResultClick(result)}
                    >
                      <div className="font-medium text-sm">{result.field}</div>
                      <div className="text-xs text-zinc-400">{result.tab}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="max-w-[1280px] mx-auto">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex-1 min-w-0 max-w-[896px]">
            {/* Tab navigation - extend the border full width */}
            <div className="border-b border-zinc-700 w-[1280px]">
              <div className="p-1">
                <div className="flex flex-wrap gap-1">
                  {config.menuItems.map((item) => (
                    <button
                      key={item}
                      onClick={() => setActiveTab(item)}
                      className={`px-4 py-2 text-xs font-medium rounded-sm focus:outline-none ${
                        activeTab === item
                          ? "bg-violet-500 text-white"
                          : "text-zinc-300 hover:bg-zinc-700 hover:text-white"
                      }`}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {/* Tab content */}
            <div className="p-6">
              <div className="max-w-full">{renderTabContent()}</div>
            </div>
          </div>

          {/* Sidebar */}
          {type !== "sandbox" && (
            <aside className="w-full lg:w-[296px]">
              <div className="sticky top-0">
                <EnvironmentSidebar
                  environment={environment}
                  onSpinActionConfirmation={handleSpinActionConfirmation}
                  onArchiveConfirmation={handleArchiveConfirmation}
                  onNotification={handleNotification}
                  onRefresh={fetchEnvironmentData}
                  currentStateData={currentStateData}
                  getAccessTokenSilently={getAccessTokenSilently}
                />
              </div>
            </aside>
          )}
        </div>
      </div>

      {notificationProps.message && (
        <Notification
          message={notificationProps.message}
          severity={notificationProps.severity}
          clearMessage={clearNotification}
        />
      )}
      <Modal
        isOpen={modalState.isOpen}
        onClose={handleModalCancel}
        onConfirm={handleModalConfirm}
        title="Confirm Action"
        message={
          modalState.isArchiveAction
            ? `Are you sure you want to ${modalState.action} the ${modalState.environmentName} environment?`
            : `Are you sure you want to spin ${modalState.action} the ${modalState.environmentName} environment?`
        }
      />
    </div>
  );
};

export default ControlPanel;
