import { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccountContext from "contexts/AccountContext";
import RHLoadingMask from "components/common/RHLoadingMask";
import { DataGrid } from "@mui/x-data-grid";
import { permissionGroupColumns, portalPermissionGroupPermissionsColumns } from "./definitions";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import RHAddOrgPermissionGroupPermission from "./RHAddOrgPermissionGroupPermission";
import RHAddOrgPermissionGroup from "./RHAddOrgPermissionGroup";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";

const useStyles = makeStyles((theme) => {
  return {
    rootRHOrgPermissionGroups: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    tabs: {
      display: "flex",
      flexDirection: "column",
    },
    tabHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    tabContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      margin: theme.spacing(2),
    },
    addToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  };
});

function RHOrgPermissionGroups({ onEditState, hasEditPermissions }) {
  const classes = useStyles();

  const { currentStateData, orgsData } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth();

  const [permissionGroups, setPermissionGroups] = useState([]);
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState();
  const [showAddPermissionGroup, setShowAddPermissionGroup] = useState(false);

  const [permissionGroupPermissions, setPermissionGroupPermissions] = useState([]);
  const [selectedPermissionGroupPermission, setSelectedPermissionGroupPermission] = useState();
  const [showAddPermissionGroupPermission, setShowAddPermissionGroupPermission] = useState(false);

  const { setIsDirty } = useMessageBar();

  const [orgGroupColumnVisibility, setOrgGroupColumnVisibility] = useState({
    org_group_name: true,
    group_id: true,
    org_id: false,
    org_name: true,
  });

  const [orgGroupPermissionColumnVisibility, setOrgGroupPermissionColumnVisibility] = useState({
    group_permission_id: true,
    permission_id: true,
    org_id: false,
    org_name: true,
    product_id: false,
    product_name: true,
    sandbox_id: false,
    sandbox_name: true,
  });

  useEffect(() => {
    currentStateData?.org?.orgId && fetchOrgGroupsData();
  }, [currentStateData]);

  useEffect(() => {
    selectedPermissionGroup && fetchOrgGroupPermissionsData();
  }, [selectedPermissionGroup]);

  function addOrgProductSandboxNames(rows) {
    if (rows && rows.length > 0) {
      const updatedPeople = rows.map((row) => {
        return {
          ...row,
          ["org_name"]: row.org_id && orgsData[row.org_id],
          ["product_name"]: row.product_id && orgsData[row.product_id],
          ["sandbox_name"]: row.sandbox_id && orgsData[row.sandbox_id],
        };
      });
      return updatedPeople;
    } else {
      return [];
    }
  }

  async function fetchOrgGroupsData() {
    try {
      const response = await fetch(`${API_BASE}/v1/permissions-org-group/${currentStateData.org?.orgId}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
      });

      const pg = await response.json();
      let pgData = addOrgProductSandboxNames(pg);

      setPermissionGroups(pgData);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setPermissionGroups([]);
      } else {
        console.error(error);
      }
      setIsLoading(false);
    }
  }

  async function fetchOrgGroupPermissionsData() {
    try {
      const response = await fetch(
        `${API_BASE}/v1/${currentStateData.org?.orgId}/permissions-org-group-permission/${selectedPermissionGroup}`,
        {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        }
      );

      const pgp = await response.json();
      let updatedPgp = addOrgProductSandboxNames(pgp);

      setPermissionGroupPermissions(updatedPgp);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setPermissionGroupPermissions([]);
      } else {
        console.error(error);
      }
      setIsLoading(false);
    }
  }

  async function handleDeletePermissionGroup() {
    try {
      await fetch(`${API_BASE}/v1/permissions-org-group/${currentStateData?.org?.orgId}/${selectedPermissionGroup}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "DELETE",
      });

      fetchOrgGroupsData();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeletePermissionGroupPermission() {
    try {
      await fetch(
        `${API_BASE}/v1/${currentStateData?.org?.orgId}/permissions-org-group-permission/${selectedPermissionGroup}/${selectedPermissionGroupPermission}`,
        {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
          method: "DELETE",
        }
      );
      await fetchOrgGroupPermissionsData();
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddPermissionGroup = () => {
    onEditState(true);
    setIsDirty(true);
    setShowAddPermissionGroup(true);
    setSelectedPermissionGroup(null);
    setSelectedPermissionGroupPermission(null);
  };

  const handleAddPermissionGroupPermission = () => {
    onEditState(true);
    setIsDirty(true);
    setShowAddPermissionGroupPermission(true);
  };

  const handleCancelAddPermissionGroup = () => {
    onEditState(false);
    setIsDirty(false);
    setShowAddPermissionGroup(false);
  };

  const handleCancelAddPermissionGroupPermission = () => {
    onEditState(false);
    setIsDirty(false);
    setShowAddPermissionGroupPermission(false);
  };

  const handleSelectedPermissionGroup = (selection) => {
    setSelectedPermissionGroup(selection[0]);
  };

  const handleSelectedPermissionGroupPermissions = (selection) => {
    setSelectedPermissionGroupPermission(selection[0]);
  };

  const handleOrgGroupPermissionsSave = () => {
    setShowAddPermissionGroupPermission(false);
    setIsDirty(false);
    fetchOrgGroupPermissionsData();
  };

  const handleOrgGroupSave = () => {
    setShowAddPermissionGroup(false);
    setIsDirty(false);
    fetchOrgGroupsData();
  };

  if (isLoading) {
    return <RHLoadingMask />;
  }

  const customPermissionGroupToolbar = () => {
    if (!hasEditPermissions) {
      return (
        <GridToolbarContainer>
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      );
    }
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        {!showAddPermissionGroup && (
          <Button className={classes.buttons} startIcon={<AddIcon />} onClick={handleAddPermissionGroup}>
            Add Permission Group
          </Button>
        )}
        {selectedPermissionGroup && (
          <>
            <Button className={classes.buttons} startIcon={<DeleteIcon />} onClick={handleDeletePermissionGroup}>
              Delete Permission Group
            </Button>
          </>
        )}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const customPermissionGroupPermissionsToolbar = () => {
    if (!hasEditPermissions) {
      return (
        <GridToolbarContainer>
          <GridToolbarQuickFilter />
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </GridToolbarContainer>
      );
    }
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <Button className={classes.buttons} startIcon={<AddIcon />} onClick={handleAddPermissionGroupPermission}>
          Add Permission
        </Button>
        {selectedPermissionGroupPermission && (
          <>
            <Button
              className={classes.buttons}
              startIcon={<DeleteIcon />}
              onClick={handleDeletePermissionGroupPermission}
            >
              Delete Permission
            </Button>
          </>
        )}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const renderPermissionGroupsDataGrid = () => {
    if (showAddPermissionGroup) {
      return (
        <>
          <Box className={classes.addToolbar}>
            <Button className={classes.buttons} variant="contained" onClick={handleCancelAddPermissionGroup}>
              Back to Permission Group
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" color="text.secondary">
              Add Permission Group
            </Typography>
            <RHAddOrgPermissionGroup orgId={currentStateData?.org?.orgId} saveHandler={handleOrgGroupSave} />
          </Box>
        </>
      );
    }
    return (
      <DataGrid
        className={classes.table}
        autoHeight
        rows={permissionGroups}
        columns={permissionGroupColumns}
        getRowId={(row) => row.group_id}
        selectionModel={selectedPermissionGroup}
        onRowSelectionModelChange={handleSelectedPermissionGroup}
        slots={{
          toolbar: customPermissionGroupToolbar,
        }}
        columnVisibilityModel={orgGroupColumnVisibility}
        onColumnVisibilityModelChange={(newModel) => setOrgGroupColumnVisibility(newModel)}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          bgcolor: "background.paper",
          border: "none",
          minWidth: 0,
          p: 1,
        }}
      />
    );
  };

  const renderPermissionGroupPermissionsDataGrid = () => {
    if (showAddPermissionGroupPermission) {
      return (
        <>
          <Box className={classes.addToolbar}>
            <Button className={classes.buttons} variant="contained" onClick={handleCancelAddPermissionGroupPermission}>
              Back to Permissions for Group
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" color="text.secondary">
              Add Permission to Permission Group
            </Typography>
            <RHAddOrgPermissionGroupPermission
              selectedGroup={selectedPermissionGroup}
              orgId={currentStateData?.org?.orgId}
              saveHandler={handleOrgGroupPermissionsSave}
            />
          </Box>
        </>
      );
    }
    return (
      <DataGrid
        className={classes.table}
        autoHeight
        rows={permissionGroupPermissions}
        columns={portalPermissionGroupPermissionsColumns}
        getRowId={(row) => row.group_permission_id}
        selectionModel={selectedPermissionGroupPermission}
        onRowSelectionModelChange={handleSelectedPermissionGroupPermissions}
        columnVisibilityModel={orgGroupPermissionColumnVisibility}
        onColumnVisibilityModelChange={(newModel) => setOrgGroupPermissionColumnVisibility(newModel)}
        slots={{
          toolbar: customPermissionGroupPermissionsToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          bgcolor: "background.paper",
          border: "none",
          minWidth: 0,
          p: 1,
        }}
      />
    );
  };

  return (
    <Box className={classes.rootRHOrgPermissionGroups}>
      {renderPermissionGroupsDataGrid()}
      {selectedPermissionGroup && renderPermissionGroupPermissionsDataGrid()}
    </Box>
  );
}

export default RHOrgPermissionGroups;
