import React from "react";
import { makeStyles } from '@mui/styles';

import { Box, LinearProgress } from "@mui/material";
import splashLogo from "../../assets/rh_module_match_core.png";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
})

export default function RHLoadingMask() {
    const classes = useStyles()

  return (
    <Box className={classes.root}>
        <Box>
            <LinearProgress sx={{ height: 8 }}/>
        </Box>
    </Box>
  )
}