export const columns = [
  {
    field: "os_distribution",
    headerName: "Distribution",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "os_version",
    headerName: "Version",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  }
];

export class NewEntry {
}
