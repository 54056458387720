import RHFieldWrapper from "./RHFieldWrapper";
import Checkbox from "@mui/material/Checkbox";

export default function RHBooleanField(props) {
  const { inactive, ...otherProps } = props;
  const checkboxStyle = {
    marginRight: 0,
    padding: "5px 5px",
    opacity: inactive ? 0.5 : 1,
  };

  return (
    <RHFieldWrapper label={props.label} inactive={inactive} description={props.description} required={props.required}>
      <div style={{ height: "35px", width: "35px" }}>
        <Checkbox {...otherProps} sx={checkboxStyle} />
      </div>
    </RHFieldWrapper>
  );
}
