import { useEffect, useState } from "react";

export default function FlashText({
  message,
  open,
  onClose,
  autoHideDuration = 1500,
  anchorOrigin = { vertical: "top", horizontal: "left" },
}) {
  const [showFlashText, setShowFlashText] = useState(false);
  const [localTimeout, setLocalTimeout] = useState();

  const getPositionClasses = () => {
    const classes = [];

    if (anchorOrigin.vertical === "top") {
      classes.push("-top-full");
    } else if (anchorOrigin.vertical === "bottom") {
      classes.push("bottom-0");
    }

    if (anchorOrigin.horizontal === "left") {
      classes.push("left-0");
    } else if (anchorOrigin.horizontal === "right") {
      classes.push("-right-2");
    }

    return classes.join(" ");
  };

  function doFlashText() {
    if (!localTimeout) {
      setShowFlashText(true);
      const thisTimeout = setTimeout(() => {
        setShowFlashText(false);
        clearTimeout(thisTimeout);
        setLocalTimeout();
        onClose();
      }, autoHideDuration);

      setLocalTimeout(thisTimeout);
    }
  }

  useEffect(() => {
    if (open) {
      doFlashText();
    }
  }, [open]);

  useEffect(() => {
    if (!document.querySelector("style[data-flash-text]")) {
      let style = document.createElement("style");
      style.setAttribute("data-flash-text", "true");
      style.innerHTML = `
        .animate-flashtext {
          animation-name: animateFlashText;
          animation-duration: ${autoHideDuration.toString()}ms;
          animation-timing-function: ease-in;
          animation-fill-mode: both;
        }

        @keyframes animateFlashText {
          0% {
            opacity: 0;
            transform: translateY(3px);
          }
          10% {
            opacity: 0.9;
          }
          50% {
            transform: translateY(0px);
          }
          100% {
            opacity: 0;
            transform: translateY(-12px);
          }
        }
      `;

      document.head.appendChild(style);
    }

    return () => {
      if (document.querySelector("style[data-flash-text]")) {
        document.head.removeChild(
          document.querySelector("style[data-flash-text]")
        );
      }
    };
  }, []);

  return (
    <>
      <div
        className={`absolute p-1 max-w-[300px] font-roboto text-[11px] leading-[1.2] text-stone-800 bg-gray-300 rounded opacity-0 pointer-events-none ${getPositionClasses()} ${
          showFlashText ? "animate-flashtext" : ""
        }`}
      >
        {message}
      </div>
    </>
  );
}
