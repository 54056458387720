import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const DATE_FORMAT = "MM/DD/YYYY hh:mm:ss A";

const DateTimePicker = ({ name, value, onChange, disabled }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    updateInputValue(value);
  }, [value]);

  const updateInputValue = (dateValue) => {
    if (dateValue) {
      const formattedDate = dayjs(dateValue).format(DATE_FORMAT);
      setInputValue(formattedDate);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setError("");
  };

  const handleBlur = () => {
    const parsedDate = dayjs(inputValue, DATE_FORMAT, true);

    if (parsedDate.isValid()) {
      const now = dayjs();
      const isoDate = parsedDate.toISOString();

      updateOnChange(isoDate);

      if (parsedDate.isAfter(now)) {
        setError("Date cannot be in the future");
      } else {
        setError("");
      }
    } else {
      setError("Invalid date format");
      updateOnChange(null);
    }
  };

  const updateOnChange = (dateValue) => {
    onChange({
      target: {
        name: name,
        value: dateValue,
        type: "date",
      },
    });
  };

  const setToNow = () => {
    const now = dayjs();
    updateInputValue(now);
    updateOnChange(now.toISOString());
    setError("");
  };

  const clearDate = () => {
    setInputValue("");
    updateOnChange(null);
    setError("");
  };

  return (
    <div className={disabled ? "opacity-50" : ""}>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            className={`flex-grow mt-1 block rounded-md border-gray bg-gray text-white shadow-sm 
                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 
                        h-12 px-3 ${error ? "border-red-500" : ""}`}
            placeholder="MM/DD/YYYY hh:mm:ss AM/PM"
            disabled={disabled}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          <button
            type="button"
            onClick={setToNow}
            className="px-3 py-1 bg-purple bg-opacity-50 text-white rounded 
                       hover:bg-purple hover:bg-opacity-80"
            disabled={disabled}
          >
            Now
          </button>
          <button
            type="button"
            onClick={clearDate}
            className="px-2 py-1 bg-white bg-opacity-20 text-white rounded 
                       hover:bg-white hover:bg-opacity-50"
            disabled={disabled}
          >
            Reset
          </button>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
    </div>
  );
};

export default DateTimePicker;
