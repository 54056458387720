import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import RHOrgUserManagement from "./RHOrgUserManagement";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import { useEffect, useState } from "react";
import RHOrgPermissions from "./RHOrgPermissions";
import RHOrgPermissionGroups from "./RHOrgPermissionGroups";
import { useContext } from "react";
import AccountContext from "../../../contexts/AccountContext";

const useStyles = makeStyles((theme) => {
  return {
    rootRHOrgAccountAndPermissionManagement: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    tabs: {
      display: "flex",
      flexDirection: "column",
    },
    tabHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    tabContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      margin: theme.spacing(2),
    },
  };
});

function RHOrgAccountAndPermissionManagement() {
  const classes = useStyles();

  const { currentStateData } = useContext(AccountContext);
  const [selectedTab, setSelectedTab] = useState("1");
  const [tabsDisabled, setTabsDisabled] = useState(false);
  const [hasEditPermissions, setHasEditPermissions] = useState(false);

  const setTabDisabledState = (disabled) => {
    setTabsDisabled(disabled);
  };

  const handleTabChange = (event, tabIndex) => {
    setSelectedTab(tabIndex);
  };

  
  function checkPermissions(hasPermissions, needsPermissions) {
    if (hasPermissions === undefined || hasPermissions === null) {
      return false;
    }
    needsPermissions.push("globalAdmin:*:*");
    return hasPermissions.some(permission => needsPermissions.includes(permission));
  }

  useEffect(() => {
    if(currentStateData && currentStateData.org && currentStateData.org.permissions) {
      setHasEditPermissions(checkPermissions(currentStateData?.org?.permissions, ["accountOrg:config:edit"]));
    }
  }, [currentStateData?.org?.permissions]);

  return (
    <Box className={classes.rootRHOrgAccountAndPermissionManagement}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange}>
          <Tab label="Account Management" value="1" disabled={tabsDisabled} />
          <Tab label="Permissions" value="2" disabled={tabsDisabled} />
          <Tab label="Permission Groups" value="3" disabled={tabsDisabled} />
        </TabList>
        <TabPanel value="1">
          <RHOrgUserManagement hasEditPermissions={hasEditPermissions} onEditState={setTabDisabledState} />
        </TabPanel>
        <TabPanel value="2">
          <RHOrgPermissions hasEditPermissions={hasEditPermissions} onEditState={setTabDisabledState} />
        </TabPanel>
        <TabPanel value="3">
          <RHOrgPermissionGroups hasEditPermissions={hasEditPermissions} onEditState={setTabDisabledState} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default RHOrgAccountAndPermissionManagement;
