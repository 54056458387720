import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, TextField, Button } from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import { v4 as uuid } from "uuid";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";

const useStyles = makeStyles((theme) => {
  return {
    rootRHAddOrgPermissionGroup: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    autocomplete: {
      marginBottom: theme.spacing(3),
    },
    actionBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
  };
});

function RHAddOrgPermissionGroup(props) {
  //test
  const classes = useStyles();
  const { orgId, saveHandler } = props;

  const { getAccessTokenSilently } = useAuth();
  const [orgGroupName, setOrgGroupName] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const { showMessage } = useMessageBar();

  async function handleSaveAccountPermissionGroup() {
    try {
      const newPermissionGroup = {
        group_id: uuid(),
        org_group_name: orgGroupName,
        org_id: orgId,
      };

      await fetch(`${API_BASE}/v1/permissions-org-group/${orgId}`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(newPermissionGroup),
      });

      showMessage(`Permission Group ${newPermissionGroup.org_group_name} created`, "success");
      saveHandler();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        //show error with toast
      } else {
        console.error(error);
      }
      showMessage(`Failed to create Permission Group: ${error.message}`, "error");
    }
  }

  useEffect(() => {
    if (orgGroupName !== "") {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [orgGroupName]);

  const handleOrgGroupNameChange = (event) => {
    setOrgGroupName(event.target.value);
  };

  return (
    <Box className={classes.rootRHAddOrgPermissionGroup}>
      <Box>
        <TextField
          label="* Permission Group Name"
          variant="outlined"
          fullWidth
          value={orgGroupName}
          onChange={handleOrgGroupNameChange}
        />
      </Box>
      <Box className={classes.actionBar}>
        <Button variant="contained" color="primary" onClick={handleSaveAccountPermissionGroup} disabled={!enableSave}>
          Save Permission Group
        </Button>
      </Box>
    </Box>
  );
}

export default RHAddOrgPermissionGroup;
