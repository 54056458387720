import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import definitions from "../../../common/definitions.json";
import { useApiToken } from "../../../common/apiUtils";
import SelectedItems from "../SelectedItems";
import SearchInput from "../SearchInput";
import { fetchTableData } from "../../../common/apiUtils";
import { CloseIcon, DropDownArrow } from "../../../assets/svgs";

const SingleDynamicSelect = React.memo((props) => {
  const {
    name,
    label,
    sandboxId,
    pageData,
    selectedItem,
    fullWidth,
    disabled,
    onChange,
    required,
  } = props;

  const token = useApiToken();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownSelection, setDropdownSelection] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState(false);
  const [isInitialFetchInProgress, setIsInitialFetchInProgress] =
    useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [cachedOptions, setCachedOptions] = useState([]);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatId = (id) => {
    return id.length > 20 ? `...${id.slice(-12)}` : id;
  };

  const loadFieldData = useMemo(() => {
    if (!pageData?.DynamicSelectFields || !name) return null;

    const fieldData = pageData.DynamicSelectFields[name];
    if (!fieldData) return null;

    const { tableData, responseDataName, idKey } = fieldData;

    return {
      tableData: tableData,
      responseDataName: responseDataName,
      isDynamic: !!tableData,
      idKey: idKey,
      get_endpoint: tableData
        ? definitions?.schemas[tableData]?.endpoints?.find(
            (endpoint) => endpoint.method === "get"
          )
        : undefined,
    };
  }, [pageData, name, definitions]);

  const isQueryEnabled = useMemo(
    () =>
      !!sandboxId &&
      !!token &&
      loadFieldData?.isDynamic &&
      !!loadFieldData?.get_endpoint,
    [sandboxId, token, loadFieldData]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (props.row_key) {
        setCachedOptions([]);
      }

      if (!props.value?.id) {
        setDropdownSelection(null);
        return;
      }

      if (!token) {
        return;
      }

      const endpoint = loadFieldData?.get_endpoint;
      if (!endpoint) return;

      setIsInitialFetchInProgress(true);
      setIsLoading(true);

      try {
        const response = await fetchTableData(
          token,
          loadFieldData?.idKey,
          endpoint,
          { sandbox_id: sandboxId },
          "",
          props.value,
          []
        );

        const transformedData =
          response[loadFieldData?.responseDataName]?.map((item) => ({
            id: item[loadFieldData?.idKey],
            name: item.name || "Untitled",
          })) || [];

        setCachedOptions(transformedData);
        const matchingItem = transformedData.find(
          (item) => item.id === props.value.id
        );
        if (matchingItem) {
          setDropdownSelection(matchingItem);
          setInputValue(matchingItem.name || matchingItem.id);
          setIsLoading(false);
        }
      } catch (error) {
        setFilteredOptions([]);
      } finally {
        setIsInitialFetchInProgress(false);
      }
    };

    fetchData();
  }, [props.row_key, props.value.id, loadFieldData, token, sandboxId]);

  useEffect(() => {
    if (isDropdownOpen) {
      if (cachedOptions.length > 0) {
      }

      const fetchOptions = async () => {
        if (!token) {
          return;
        }

        const endpoint = loadFieldData?.get_endpoint;
        if (!endpoint) return;

        try {
          const response = await fetchTableData(
            token,
            loadFieldData?.idKey,
            endpoint,
            { sandbox_id: sandboxId, pageSize: 50 },
            "",
            null,
            []
          );

          const transformedData =
            response[loadFieldData?.responseDataName]?.map((item) => ({
              id: item[loadFieldData?.idKey],
              name: item.name || "Untitled",
            })) || [];

          const combinedOptions = [
            ...cachedOptions,
            ...transformedData.filter(
              (option) =>
                !cachedOptions.some((cached) => cached.id === option.id)
            ),
          ];
          setFilteredOptions(combinedOptions);
          setFetchedOptions(transformedData);
        } catch (error) {
          setFilteredOptions([]);
        }
      };

      fetchOptions();
    }
  }, [isDropdownOpen, loadFieldData, token, sandboxId, cachedOptions]);

  useEffect(() => {}, [filteredOptions]);

  const handleFocus = useCallback(() => {
    setIsDropdownOpen(true);
    setIsOpen(true);
  }, []);

  const handleSearch = useCallback(
    (searchValue) => {
      if (isDropdownOpen) {
        const fetchSearchResults = async () => {
          if (!token) {
            return;
          }

          const response = await fetchTableData(
            token,
            loadFieldData?.idKey,
            loadFieldData?.get_endpoint,
            { sandbox_id: sandboxId },
            searchValue,
            null,
            dropdownSelection ? [dropdownSelection.id] : []
          );

          const transformedData =
            response[loadFieldData?.responseDataName]?.map((item) => ({
              id: item[loadFieldData?.idKey],
              name: item.name || "Untitled",
            })) || [];

          const combinedResults = [...cachedOptions, ...transformedData];
          const uniqueResults = combinedResults.reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          setFilteredOptions(uniqueResults);
        };

        fetchSearchResults();
      }
    },
    [
      isDropdownOpen,
      loadFieldData,
      token,
      sandboxId,
      props.value,
      dropdownSelection,
      cachedOptions,
    ]
  );

  const toggleItem = useCallback(
    (id) => {
      const selectedItem =
        cachedOptions.find((option) => option.id === id) ||
        filteredOptions.find((option) => option.id === id);

      setDropdownSelection(selectedItem);
      setInputValue(selectedItem ? selectedItem.name || selectedItem.id : "");
      setIsOpen(false);

      if (onChange) {
        onChange({
          target: { value: selectedItem ? selectedItem.id : null, name },
        });
      }
    },
    [cachedOptions, filteredOptions, name, onChange]
  );

  const removeItem = useCallback(
    (id) => {
      if (!id) {
        return;
      }
      setDropdownSelection(null);
      setInputValue("");

      if (onChange) {
        onChange({ target: { value: null, name } });
      }
    },
    [onChange, name]
  );

  const handleRemove = (id) => {
    if (id) {
      removeItem(id);
    }
  };

  useEffect(() => {
    if (props.value?.id && !isDropdownOpen) {
      const matchingItem = filteredOptions.find(
        (item) => item.id === props.value.id
      );
      if (matchingItem) {
        setInputValue(matchingItem.name || matchingItem.id);
        setDropdownSelection(matchingItem);
      }
    }
  }, [props.value, filteredOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full mb-4 ${fullWidth ? "w-full" : ""} border ${
        disabled ? "border-zinc-600" : "border-gray-500"
      }`}
      onClick={handleFocus}
    >
      <span className="absolute top-[-1rem] left-0 text-gray-400 text-xs z-1 px-1">
        {label}
        {required && <span className="text-red-500">*</span>}
      </span>
      {isLoading ? (
        <div className="h-14 flex items-center justify-center cursor-pointer text-gray-500 animate-pulse">
          Loading...
        </div>
      ) : (
        <div className="h-14 flex items-center justify-between cursor-pointer px-3">
          <div className="flex-grow text-left overflow-hidden">
            {dropdownSelection ? (
              <span
                className={`text-gray-300 ${
                  disabled ? "text-gray-500" : ""
                } block`}
                title={`${
                  dropdownSelection.name !== "Untitled"
                    ? "Name: " + dropdownSelection.name + "\n"
                    : ""
                }${dropdownSelection.id}`}
              >
                {dropdownSelection.name}
                <span className="text-gray-500 italic ml-1">{` (${formatId(
                  dropdownSelection.id
                )})`}</span>
              </span>
            ) : (
              <span
                className={`${
                  disabled ? "text-gray-500" : "text-gray-300"
                } italic block truncate`}
              >
                {disabled ? `No ${label} selected` : `Please select a ${label}`}
              </span>
            )}
          </div>
          <div className="flex items-center ml-2">
            {!disabled && dropdownSelection && (
              <button
                onClick={() => removeItem(dropdownSelection.id)}
                className="mr-2"
              >
                <CloseIcon />
              </button>
            )}
            <DropDownArrow
              className={`${disabled ? "text-gray-500" : "text-gray-300"}`}
            />
          </div>
        </div>
      )}
      {isOpen && !disabled && (
        <div className="relative">
          <ul className="absolute z-[100] w-full mt-1 bg-zinc-900 rounded-md shadow-lg border border-zinc-700">
            <li className="p-1">
              <SearchInput onSearch={handleSearch} />
            </li>
            <div className="max-h-72 overflow-y-auto">
              {filteredOptions.map((option) => (
                <li
                  key={option.id}
                  className={`p-2 text-sm cursor-pointer ${
                    dropdownSelection?.id === option.id
                      ? "bg-zinc-600"
                      : "text-gray-300 hover:bg-zinc-600"
                  }`}
                  onClick={() => toggleItem(option.id)}
                >
                  {option.name}
                  <span className="text-gray-500 italic ml-1">{` (${formatId(
                    option.id
                  )})`}</span>
                </li>
              ))}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
});

export default SingleDynamicSelect;
