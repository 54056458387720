import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import AccountContext from "contexts/AccountContext";
import FlashText from "components/common/FormField/FlashText";

export const API_BASE =
  process.env.NODE_ENV === "production"
    ? window.env.REACT_APP_API_BASE_PATH
    : process.env.REACT_APP_API_BASE_PATH;

function useServerOnlineStatus(serverUrl) {
  const [serverOnline, setServerOnline] = useState(false);

  async function checkServerStatus() {
    try {
      const response = await fetch(serverUrl, { method: "HEAD" });
      setServerOnline(response.ok);
    } catch (error) {
      setServerOnline(false);
    }
  }

  useEffect(() => {
    const updateOnlineStatus = () => {
      if (navigator.onLine) {
        checkServerStatus();
      } else {
        setServerOnline(false);
      }
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    checkServerStatus(); // Initial check

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [serverUrl]);

  return serverOnline;
}

function LayoutFooter({ menuOpen }) {
  const { currentStateData } = useContext(AccountContext);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [entity, setEntity] = useState({});
  const [openFlashText, setOpenFlashText] = useState(false);

  const serverOnline = useServerOnlineStatus(API_BASE);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setOpenFlashText(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleFlashTextClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFlashText(false);
  };

  useEffect(() => {
    const updateClock = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => clearInterval(updateClock);
  }, []);

  useEffect(() => {
    if (currentStateData?.sandbox?.sandboxId) {
      setEntity({
        string: "sandbox",
        id: currentStateData?.sandbox?.sandboxId,
      });
    } else if (currentStateData?.environment?.environmentId) {
      setEntity({
        string: "environment",
        id: currentStateData?.environment?.environmentId,
      });
    } else if (currentStateData?.product?.productId) {
      setEntity({
        string: "product",
        id: currentStateData?.product?.productId,
      });
    } else if (currentStateData?.org?.orgId) {
      setEntity({
        string: "org",
        id: currentStateData?.org?.orgId,
      });
    }
  }, [currentStateData]);

  return (
    <div
      className={`footer-container h-7 bg-[#121212] w-full text-white text-xs font-thin z-10 flex items-center justify-between px-4 ${
        menuOpen ? "lg:w-[calc(100%-264px)] lg:ml-[264px]" : ""
      }`}
    >
      <div className="flex items-center">
        <span
          className={`h-2 w-2 rounded-full ${
            serverOnline ? "bg-green-500" : "bg-red-500"
          } mr-2`}
          title={serverOnline ? "API Online" : "API Offline"}
        ></span>
        <p className="text-fontBase/60 text-xs">
          {serverOnline ? "API Online" : "API Offline"}
        </p>
      </div>
      <div className="hidden sm:block">
        <p
          onClick={() => copyToClipboard(entity?.id)}
          className="tracking-wider text-fontBase/60 text-xs cursor-pointer capitalize"
          title="Copy to clipboard"
        >
          {entity && `${entity?.string} ID: ${entity?.id}`}
        </p>
        <FlashText
          open={openFlashText}
          autoHideDuration={1000}
          onClose={handleFlashTextClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          message={
            <>
              <div className="text-center">{`${entity?.id}`}</div>
              <div className="text-center">{"copied to clipboard!"}</div>
            </>
          }
        />
      </div>
      <div className="">
        <p className="tracking-wider text-fontBase/60 text-xs">
          {dayjs(currentTime).format("dddd, MMMM D YYYY, h:mm A")}
        </p>
      </div>
    </div>
  );
}

export default LayoutFooter;
