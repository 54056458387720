import React from "react";
import { ClearIcon } from "../../../assets/svgs";

const smallerPlaceholderStyle = `
  .smaller-placeholder::placeholder {
    font-size: 0.75rem; /* Equivalent to Tailwind's text-xs */
  }
`;

const CustomSearchBar = ({ value, title, onChange, enableSmartSearch, enabled = false, ...props }) => {
  const enableStyle = enabled ? "flex items-center space-x-2 w-full " : "flex items-center space-x-2 w-full opacity-50";

  const placeholderText = enableSmartSearch ? "Search by Name, Id or Legacy Id..." : "Search by Name...";

  return (
    <>
      <style>{smallerPlaceholderStyle}</style>
      <div className="flex flex-col items-center justify-center space-y-2 w-1/3 min-w-[200px]">
        <div className={enableStyle}>
          <input
            id="search-input"
            type="text"
            title={enabled ? title : "Search Unavailable"}
            disabled={!enabled}
            placeholder={placeholderText}
            className="smaller-placeholder bg-opacity-30 w-full px-2 py-1 rounded-md border border-white border-opacity-10 bg-black text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-purple min-w-[180px]"
            value={value}
            onChange={onChange}
          />
          <button
            onClick={() => onChange({ target: { value: "" } })}
            title="Clear Search"
            className="p-1 rounded-md border border-white border-opacity-10 bg-black bg-opacity-5 hover:bg-opacity-40 focus:outline-none disabled:bg-opacity-20"
            disabled={!value}
          >
            <ClearIcon className="text-white" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomSearchBar;
