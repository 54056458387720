import { useState, useContext, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "components/common/datatable";
import Tooltip from "components/common/FormField/Tooltip";
import { usePortalQuery } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";

export default function GroupPermissionsEditor({
  originalValue,
  fieldGroup,
  onChange,
  rowKey,
  editPermitted,
}) {
  const { getAccessToken } = useAuth();
  const { currentStateData } = useContext(AccountContext);
  const columnHelper = createColumnHelper();

  const [selectedRow, setSelectedRow] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    group_id: false,
  });
  const [initialized, setInitialized] = useState(false);
  const [firstChange, setFirstChange] = useState(false);

  const groupPermissionsQuery = usePortalQuery({
    schema: "GroupResponse",
    token: getAccessToken(),
    configs: {
      version: "v2",
      schemas: { GETparams: { org_id: currentStateData?.org?.orgId } },
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("group_id", {
        header: "Group ID",
      }),
      columnHelper.accessor("org_group_name", {
        header: "Group Name",
        cell: ({ getValue, row }) => (
          <Tooltip
            title={row.original.description || ""}
            className="w-full max-w-[220px]"
          >
            <span>{getValue()}</span>
          </Tooltip>
        ),
      }),
    ],
    []
  );

  /* After initialization, the state is being set from the table on subsequent selection, 
  rather than the table being set from the state that is initially fetched. We do this because
  the selected rows source of truth is from the rowSelectionModel, which is also controlled by 
  the table. We want to control the intitial mutation of the rowSelectionModel and not have it trigger
  us getting a new fieldGroup, which would normally mark the table as dirty. */
  useEffect(() => {
    if (!fieldGroup) return;
    if (!initialized) {
      const selectedRows = fieldGroup[rowKey].reduce((acc, group) => {
        acc[group.group_id] = true;
        return acc;
      }, {});
      setRowSelectionModel(selectedRows);
      setInitialized(true);
    }
    // On cancel, this state will be passed in and we tell the component to reset the firstChange
    // in order to reset the table to its original non-dirty state
    if (
      initialized &&
      fieldGroup?.[rowKey].every((group) => Object.keys(group).length !== 1) &&
      fieldGroup?.[rowKey].length !== Object.keys(rowSelectionModel).length
    ) {
      const selectedRows = fieldGroup[rowKey].reduce((acc, group) => {
        acc[group.group_id] = true;
        return acc;
      }, {});
      setFirstChange(false);
      setRowSelectionModel(selectedRows);
    }
  }, [fieldGroup]);

  useEffect(() => {
    if (initialized) {
      const selectedGroups = Object.keys(rowSelectionModel).map((key) => {
        return {
          group_id: key,
        };
      });
      if (!firstChange) {
        setFirstChange(true);
        onChange({ ...fieldGroup, [rowKey]: selectedGroups }, true);
      } else {
        onChange({ ...fieldGroup, [rowKey]: selectedGroups });
      }
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    if (initialized) setInitialized(false);
    if (originalValue) setFirstChange(false);
  }, [originalValue]);

  return (
    <div className={`${editPermitted ? "" : "pointer-events-none"}`}>
      {groupPermissionsQuery.data && (
        <DataTable
          data={groupPermissionsQuery?.data}
          columns={columns}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          columnsWithSort={[]}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          rowId={"group_id"}
          hideToolbar={true}
          enableMultiRowSelection={true}
        />
      )}
    </div>
  );
}
