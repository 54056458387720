import React from "react";
import { CloseIcon } from "../../../../assets/svgs";

export default function DataTableFilter({ value, onChange }) {
  return (
    <div className="relative">
      <input
        className="px-2 py-1 bg-zinc-800 rounded-sm text-white text-xs w-64 focus:outline-none"
        placeholder="Search..."
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
      {value && (
        <button
          onClick={() => onChange("")}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-zinc-400 hover:text-white "
          title="Clear search"
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
