import { useContext, useState } from "react";

import { makeStyles } from "@mui/styles";
import { Box, Card, CardHeader, Avatar, CardContent, Typography } from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import { useApiToken, API_BASE } from "common/apiUtils";
// import TextField from "@mui/material/TextField";
import ReactJson from "react-json-view";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import TokenCopy from "components/TokenCopy";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      flex: 1,
      backgroundColor: theme.palette.background.paper,
    },
    profileCard: {
      margin: theme.spacing(2),
    },
    treeItem: {
      "& .MuiTreeItem-label": {
        margin: theme.spacing(1),
      },
    },
    jsonPanel: {
      overflow: "scroll",
    },
    emailVerificationContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    resetPasswordButton: {
      width: "300px",
    },
  };
});

export default function RHProfilePage() {
  const classes = useStyles();

  const { user } = useAuth();
  const { permissionData, orgsData } = useContext(AccountContext);
  const [resendEmail, setResendEmail] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const token = useApiToken();
  const headers = new Headers({ Authorization: `Bearer ${token}`, "Content-Type": "application/json" });

  const sendEmailVerification = async () => {
    fetch(`${API_BASE}/v1/account/resendVerificationEmail`, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      method: "POST",
    });
    setResendEmail(true);
  };

  const resetPassword = async () => {
    fetch(`${API_BASE}/v1/account_pwd_reset/${permissionData?.account_id}`, {
      headers,
      method: "POST",
    });
  };

  function validatePassword(password) {
    // Check for at least 8 characters
    if (password.length < 8) {
      return false;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Check for at least one digit
    if (!/\d/.test(password)) {
      return false;
    }

    // Check for at least one special character
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
      return false;
    }

    return true;
  }

  const renderTree = (org) => (
    <TreeItem
      className={classes.treeItem}
      key={org.orgId}
      nodeId={org.orgId}
      label={`Org - ${org.orgName} (${org.shortName})`}
    >
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1" color="text.secondary" sx={{ fontStyle: "italic" }}>
          {`Org Short Name - ${org.shortName}, Org Id - ${org.orgId}`}
        </Typography>
      </Box>
      {org.products.map((product) => (
        <TreeItem
          className={classes.treeItem}
          key={product.productId}
          nodeId={product.productId}
          label={`Product - ${product.productName} (${product.shortName})`}
        >
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" color="text.secondary" sx={{ fontStyle: "italic" }}>
              {`Product Short Name - ${product.shortName}, Product Id - ${product.productId}`}
            </Typography>
          </Box>

          {product.sandboxes.map((sandbox) => (
            <TreeItem
              className={classes.treeItem}
              key={sandbox.sandboxId}
              nodeId={sandbox.sandboxId}
              label={`Sandbox - ${sandbox.sandboxName} (${sandbox.shortName})`}
            >
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1" color="text.secondary" sx={{ fontStyle: "italic" }}>
                  {`Sandbox Short Name - ${sandbox.shortName}, Sandbox Id - ${sandbox.sandboxId}`}
                </Typography>
              </Box>
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeItem>
  );

  const renderEmailVerified = () => {
    if (user?.email_verified) {
      return renderEmailVerifiedUser();
    } else {
      return (
        <Box className={classes.emailVerificationContainer}>
          <Typography variant="body1" color="text.secondary" sx={{ fontStyle: "italic" }}>
            {user?.email + ", Email not verified"}
          </Typography>
          <Button variant="contained" color="primary" disabled={resendEmail} onClick={() => sendEmailVerification()}>
            Resend Verification Email
          </Button>
        </Box>
      );
    }
  };

  const renderEmailVerifiedUser = () => {
    return (
      <Box>
        <Box style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
          <VerifiedUserIcon color="success" style={{ marginRight: "8px" }} />
          <Typography variant="body1">Email: {user?.email}</Typography>
        </Box>
      </Box>
    );
  };

  const resetPasswordUI = () => {
    return (
      <Box style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
        <Button
          className={classes.resetPasswordButton}
          variant="contained"
          color="primary"
          onClick={() => resetPassword()}
        >
          Request Password Reset
        </Button>
      </Box>
    );
  };

  const renderProfileCard = () => {
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        {renderEmailVerified()}
        <Typography variant="body1">Account ID: {permissionData?.account_id}</Typography>
        {resetPasswordUI()}
      </Box>
    );
  };

  const renderOrgTreeView = () => {
    return (
      <>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          <strong>Inferred Orgs, Products, and Sandboxes assigned to {user?.name}:</strong>
        </Typography>
        <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {Object.values(orgsData).map((org) => renderTree(org))}
        </TreeView>
      </>
    );
  };

  const renderPermissionsView = () => {
    return (
      <>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          <strong>Account Permissions:</strong>
        </Typography>
        <ReactJson src={permissionData?.permissions} theme="colors" style={{ overflow: "scroll", height: "450px" }} />
      </>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.profileCard}>
        <CardHeader
          avatar={<Avatar alt={user?.name} src={user?.picture} />}
          title={
            <Typography variant="h5" color="text.secondary">
              {user?.name}
            </Typography>
          }
        />
        <CardContent className={classes.cardContentStyle}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="User Information" />
            <Tab label="Structure" />
            <Tab label="Account Permissions" />
          </Tabs>

          {activeTab === 0 && renderProfileCard()}

          {activeTab === 1 && renderOrgTreeView()}

          {activeTab === 2 && renderPermissionsView()}
        </CardContent>
      </Card>
      <div className="m-4 bg-[linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05))] bg-[#1e1e1e] rounded drop-shadow-lg">
        <TokenCopy />
      </div>
    </Box>
  );
}
