export default {
  pageKey: "userManagement",
  tabs: {
    Users: {
      rowId: "account_id",
      default: true,
      schema: "AccountResponse",
      GETparams: { org_id: "{org_id}" },
      version: "v2",
      editor: {
        "Group Permissions": {
          default: true,
          version: "v2",
          schemas: {
            MUTATE: "AllPermissionsResponse",
            GET: "AllPermissionsResponse",
            POST: "AllPermissionsResponse",
          },
          fields: [
            {
              field: "group_permissions",
              headerName: "Group Permissions",
              type: "json",
            },
          ],
        },
        "User Permissions": {
          version: "v2",
          schemas: {
            MUTATE: "AllPermissionsResponse",
            GET: "AllPermissionsResponse",
            POST: "AllPermissionsResponse",
          },
          fields: [
            {
              field: "account_permissions",
              headerName: "User Permissions",
              type: "json",
            },
          ],
        },
        Info: {
          version: "v2",
          schemas: {
            MUTATE: "AccountExpanded",
            GET: "AccountExpanded",
            POST: "AccountCreateRequest",
            DELETE: "AccountExpanded",
            GETparams: { expand: true },
          },
          fields: [
            {
              field: "account_id",
              headerName: "Account ID",
              type: "metadata",
            },
            {
              field: "account_email",
              headerName: "Email",
              type: "text",
              editable: "lockAfterCreate",
              required: true,
            },
            {
              field: "name",
              headerName: "Name",
              required: true,
            },
            {
              field: "password",
              headerName: "Password",
              required: true,
              editable: "hideAfterCreate",
            },
          ],
        },
      },
    },
    "M2M Users": {
      rowId: "account_id",
      schema: "AccountResponse",
      GETparams: { m2m: true, org_id: "{org_id}" },
      version: "v2",
      editor: {
        "Group Permissions": {
          default: true,
          version: "v2",
          schemas: {
            MUTATE: "AllPermissionsResponse",
            GET: "AllPermissionsResponse",
            POST: "AllPermissionsResponse",
          },
          fields: [
            {
              field: "group_permissions",
              headerName: "Group Permissions",
              type: "json",
            },
          ],
        },
        "User Permissions": {
          version: "v2",
          schemas: {
            MUTATE: "AllPermissionsResponse",
            GET: "AllPermissionsResponse",
            POST: "AllPermissionsResponse",
          },
          fields: [],
        },
        Info: {
          schemas: {
            MUTATE: "ClientCredentials",
            GET: "ClientCredentials",
            POST: "AccountCreateRequest",
            DELETE: "AccountExpanded",
            GETparams: { org_id: "{org_id}" },
            POSTparams: { m2m: true },
          },
          version: "v2",
          fields: [
            {
              field: "account_id",
              headerName: "Account ID",
              type: "metadata",
            },
            {
              field: "description",
              headerName: "Description",
              required: true,
            },
            {
              field: "client_secret",
              headerName: "Client Secret",
              type: "password",
            },
          ],
        },
      },
    },
  },
};
