import { makeStyles } from "@mui/styles";

export const useOrgUserManagementStyles = makeStyles((theme) => {
    return {
      rootConfigPage: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flex: 1,
      },
      table: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      },
      tableHeader: {
        padding: "8px 8px 0px 8px",
      },
      tableHeaderToolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      tableDetailsContainer: {
        marginTop: "2rem",
        padding: "1rem",
        color: theme.palette.text.primary,
        background: theme.palette.background.paper,
        borderRadius: "5px",
      },
      tablesDetailContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      tableDetailView: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginBottom: theme.spacing(2),
      },
      tableDetailViewContents: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      tableDetailViewContentsButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      tableDetailForm: {
        display: "flex",
        gap: theme.spacing(2),
      },
      snackBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      gridButtonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      addAccountContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      addAccountButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      addAccountForum: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      buttons: {
        margin: '8px !important',
      },
      addAccountPermissionsContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      addAccountPermissionsButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      addAccountPermnissionForum: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      expandedAccount: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      accountCardText: {
        paddingBottom: theme.spacing(2),
      },
      editAccountButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
    };
  });


  export const useOrgAccountPermissionsStyles = makeStyles((theme) => {
    return {
      rootConfigPage: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flex: 1,
      },
      table: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      },
      tableHeader: {
        padding: "8px 8px 0px 8px",
      },
      tableHeaderToolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      tableDetailsContainer: {
        marginTop: "2rem",
        padding: "1rem",
        color: theme.palette.text.primary,
        background: theme.palette.background.paper,
        borderRadius: "5px",
      },
      tablesDetailContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      tableDetailView: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginBottom: theme.spacing(2),
      },
      tableDetailViewContents: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      tableDetailViewContentsButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      tableDetailForm: {
        display: "flex",
        gap: theme.spacing(2),
      },
      snackBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      gridButtonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      addAccountContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      addAccountButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      addAccountForum: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      buttons: {
        margin: '8px !important',
      },
      addAccountPermissionsContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      },
      addAccountPermissionsButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
      },
      addAccountPermnissionForum: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      addToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
    };
  });