import { v4 as uuid } from "uuid";
import { useContext, useState, useEffect, useRef, Fragment } from "react";
import { useEnvironmentStyles } from "../environment/styles";
import { fetchDirectly } from "common/apiUtils";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import RHDataOnlyField from "components/common/RHDataOnlyField";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { getErrorMessage, validateData } from "../environment/validation";
import EditDialog from "../environment/EditDialog";

export default function SandboxEdit(props) {
  const { onSave, inputSandbox, isDialog, onDialogClose } = props;
  const { getAccessTokenSilently } = useAuth();
  const classes = useEnvironmentStyles();
  const { currentStateData, permissionData, refreshAccount, hasPermission, sandboxCopyIsEnabled } =
    useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [sandbox, setSandbox] = useState({});
  const initialSandbox = useRef();
  const isAddNew = useRef(props.isAddNew);
  const [resultMsg, setResultMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });
  const [sandboxCopyOpen, setSandboxCopyOpen] = useState({ open: false });

  const columns = [
    { headerName: "Name", field: "name", required: true },
    { headerName: "Short Name", field: "short_name", required: true },
    {
      headerName: "Primary Contact Email",
      field: "primary_contact_email",
      required: true,
      style: { paddingBottom: "0", marginBottom: "20px" },
    },
  ];

  async function fetchData(inputSandbox) {
    if (!inputSandbox) {
      inputSandbox = currentStateData?.sandbox;
    }
    setErrorMsg();
    if (inputSandbox?.sandboxId) {
      try {
        const endpoint = `v1/org/${currentStateData?.org?.orgId}/product/${
          currentStateData?.product?.productId
        }/sandbox/${currentStateData?.sandbox?.sandboxId ?? inputSandbox?.sandboxId}`;
        const response = await fetchDirectly({
          fullUrl: endpoint,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        initialSandbox.current = response;
        setSandbox(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setResultMsg(getErrorMessage(error, columns));
        setIsLoading(false);
      }
    }
  }

  function isPermitted() {
    const sandbox_id = initialSandbox.current?.sandbox_id;
    return hasPermission("sandbox:config:edit", "sandbox", sandbox_id, currentStateData, permissionData);
  }

  const handleEdit = () => {
    setIsDirty(true); // remove to disable buttons until data changed
    setErrorMsg();
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setErrorMsg();
    if (!isAddNew.current) {
      setIsEditMode(false);
    }
    setIsDirty(false);
    setSandbox(initialSandbox.current);
    handleResultClose();
  };

  const handleChange = (event) => {
    let { name, value, type } = event.target;
    if (type === "checkbox") {
      value = event.target.checked;
    }
    let updatedSandbox = setProperty(sandbox, name, value);
    setSandbox(updatedSandbox);
    setIsDirty(true);
  };

  const save = async (sandboxToSave) => {
    setErrorMsg();
    validateData(columns, sandbox);
    let endpoint = `v1/org/${currentStateData?.org?.orgId}/product/${currentStateData?.product?.productId}/sandbox`;
    if (!isAddNew.current) {
      endpoint = `${endpoint}/${sandbox.sandbox_id}`;
    }

    const sandboxRequest = { ...sandboxToSave };
    if (isAddNew) {
      sandboxRequest.sandbox_id = uuid();
    }

    const method = isAddNew.current ? "POST" : "PUT";
    const response = await fetchDirectly({
      token: await getAccessTokenSilently(),
      fullUrl: endpoint,
      method: method,
      body: sandboxRequest,
    });
    await refreshAccount();
    return response;
  };

  const handleSaveAndClose = async (sandboxToSave) => {
    try {
      const response = await save(sandboxToSave);
      setSandbox(response);
      setIsDirty(false);
      setIsEditMode(false);
      if (isDialog) {
        onSave("sandbox", sandbox?.name);
        handleResultClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSave = async (sandboxToSave, fromArchiveHandler) => {
    try {
      const response = await save(sandboxToSave);
      const action = fromArchiveHandler ? (sandboxToSave?.archive ? "archived" : "unarchived") : "saved";
      if (isDialog) {
        setResultMsg(`Sandbox successfully ${action}`);
      } else {
        setSnackBar({
          message: `${sandbox?.name} ${action}`,
          severity: "success",
        });
        setSnackBarState(true);
      }
      setSandbox(response);
      setIsDirty(false);
      setIsEditMode(false);
    } catch (error) {
      if (fromArchiveHandler) {
        setSandbox({ ...sandboxToSave, archive: !sandboxToSave.archive });
      }
      handleError(error);
    }
  };

  const handleArchive = async () => {
    handleSave({ ...sandbox, archive: !sandbox.archive }, true);
  };

  const handleResultClose = async () => {
    if (onDialogClose) {
      onDialogClose();
    }
  };

  function handleSandboxCopyDialogClose(value) {
    setSandboxCopyOpen(false);
  }

  function handleSandboxCopyClick(evt, sandbox) {
    setSandboxCopyOpen({ open: true, sandbox: sandbox });
    evt.preventDefault();
    return;
  }

  const setProperty = (obj, key, value) => {
    return {
      ...obj,
      [key]: value,
    };
  };

  const handleError = (error) => {
    const errorMessage = getErrorMessage(error, columns);
    console.log(error);
    setErrorMsg(errorMessage);
  };

  const renderFormField = (field, i) => {
    return (
      <TextField
        required={field.required}
        label={field.headerName}
        name={field.field}
        value={sandbox?.[field.field] || ""}
        onChange={handleChange}
        disabled={!isEditMode}
        style={{ paddingBottom: "5px", width: "100%" }}
      />
    );
  };

  useEffect(() => {
    if (!currentStateData) return;
    if (isAddNew.current) {
      setIsLoading(false);
      setIsEditMode(true);
      setIsDirty(true); // remove to disable buttons until data changed
      return;
    }

    fetchData(inputSandbox);
  }, [currentStateData]);

  return isLoading ? null : (
    <>
      <Box key="sandbox_form">
        {resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ padding: "20px", marginTop: "50px" }}>
              <Typography variant="h5" color="textPrimary">
                {resultMsg}
              </Typography>
              <Button variant="contained" color="primary" onClick={() => handleResultClose()}>
                Close
              </Button>
            </Box>
          </>
        )}
        {!resultMsg && (
          <>
            <Box className={classes.gameConfigToolbar} sx={{ paddingBottom: "15px" }}>
              <Typography variant="body2"></Typography>
              <Box display="flex" gap={2}>
                {isEditMode ? (
                  <>
                    <Button variant="contained" color="primary" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isDirty}
                      onClick={() => (isDialog ? handleSaveAndClose(sandbox) : handleSave(sandbox))}
                    >
                      {isDialog ? "Save & Close" : "Save"}
                    </Button>
                  </>
                ) : (
                  <>
                    {isPermitted() && (
                      <>
                        <Button variant="contained" color="primary" onClick={() => handleEdit()}>
                          Edit
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleArchive()}>
                          {sandbox.archive ? "Unarchive" : "Archive"}
                        </Button>
                        {sandboxCopyIsEnabled() && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(evt) => handleSandboxCopyClick(evt, sandbox)}
                          >
                            Copy To
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
            {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : <></>}
            <Box className={classes.rootGameConfigPage}>
              <Box>
                <Box>
                  <Box className={classes.gameConfigFormControls}>
                    {!isAddNew.current && (
                      <Box className={classes.tableDetailForm} sx={{ gap: "0px 0px 0px 0px" }}>
                        {isDialog && (
                          <>
                            <div style={{ marginTop: "0px", marginLeft: "-10px", width: "100%" }}>
                              <RHDataOnlyField label="Sandbox ID" name="sandbox_id" value={sandbox?.sandbox_id || ""} />
                            </div>
                            <div style={{ marginTop: "-20px", marginLeft: "-10px", width: "100%" }}>
                              <RHDataOnlyField label="Product ID" name="product_id" value={sandbox?.product_id || ""} />
                            </div>
                          </>
                        )}
                        {!isDialog && (
                          <div style={{ marginTop: "0px", marginLeft: "-10px", width: "100%" }}>
                            <RHDataOnlyField label="Product ID" name="product_id" value={sandbox?.product_id || ""} />
                          </div>
                        )}
                        <div style={{ marginTop: "-20px", marginLeft: "-10px", width: "100%" }}>
                          <RHDataOnlyField
                            label="Last Modified Timestamp"
                            name="last_modified_timestamp"
                            value={sandbox?.last_modified_timestamp || ""}
                          />
                        </div>
                      </Box>
                    )}

                    {columns.map((field, i) => (
                      <Fragment key={field.field + i}>{renderFormField(field, i)}</Fragment>
                    ))}
                  </Box>
                </Box>
              </Box>
              <EditDialog type="sandbox-copy" openRequest={sandboxCopyOpen} onClose={handleSandboxCopyDialogClose} />
              <Snackbar
                className="snackBar"
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={snackBarState}
                message={snackBar.message}
                severity={snackBar.severity}
                key={"bottom-center"}
                autoHideDuration={3000}
                onClose={() => setSnackBarState(false)}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
