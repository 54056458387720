import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    widgetContainer: {
      backgroundColor: "#1E1E1E",
      borderRadius: "4px",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      color: "#FFF",
      display: "flex",
      flexDirection: "column",
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      maxWidth: "368px",
      minWidth: "350px",
      minHeight: "268px",
      overflow: "hidden",
      transition: "height 0.2s ease-in",
      width: "100%",
    },
    widget: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "268px",
      minHeight: "268px",
    },
    widgetHead: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 16px 0",
    },
    widgetName: {
      fontSize: "20px",
      fontWeight: "500",
      margin: "0 0 15px",
    },
    date: {
      fontSize: "14px",
      fontWeight: "500",
      margin: 0,
      opacity: 0.7,
    },
    chart: {
      border: "1px solid rgba(157, 105, 255, 0.50)",
      borderRadius: "4px",
      padding: "9px 9px 6px",
    },
    valueWrapper: {
      alignItems: "center",
      alignSelf: "stretch",
      justifyContent: "center",
      display: "flex",
      flex: `1 0 0`,
      flexDirection: "column",
      fontSize: "48px",
    },
    value: {},
    comparisonRow: {
      alignItems: "center",
      borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
      display: "flex",
      padding: "4px 0",
    },
    comparison: {
      alignItems: "center",
      display: "flex",
      fontSize: "14px",
      margin: 0,
      paddingLeft: "16px",
      width: "50%",
    },
    comparisonValue: {
      alignItems: "center",
      display: "flex",
      fontSize: "14px",
      margin: 0,
      paddingLeft: "20px",
      paddingRight: "16px",
      width: "50%",
    },
    more: {
      alignItems: "center",
      color: "rgba(255,255,255,0.7)",
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "12px",
      fontWeight: "600",
      padding: "10px 18px",
    },
    dropdownArrow: {
      fill: "rgba(255,255,255,0.7)",
      fontSize: "1.5rem",
      height: "22px",
      transition: "fill 0.2s cubic-bezier(0.4, 0, 0.2, 1), transform 0.2s ease-out",
      width: "22px",
    },
    widgetDetail: {
      padding: "0 16px 16px",
    },
    subDropdown: {
      alignItems: "center",
      borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
      cursor: "pointer",
      display: "flex",
      padding: "3px 0 5px",
    },
    subDropdownCell: {
      alignItems: "center",
      display: "flex",
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      width: "50%",
    },
    platDetail: {
      overflow: "hidden",
      transition: "height 0.15s ease-in",
    },
  };
});
