import { formatTimestamp } from "../../../common/dateUtils";
import { v4 as uuid } from "uuid";

//Org configs
export const orgApi = "org";
export const orgTableRowKey = "org_id";
export const orgTableColumns = [
  {
    field: "org_id",
    headerName: "Org Id",
    flex: 1,
    hideable: false,
    editable: false,
    type: "metadata",
    required: true,
  },
  { field: "name", headerName: "Name", flex: 1, hideable: false, required: true },
  { field: "short_name", headerName: "Short Name", flex: 1, hideable: true, editable: true, required: true },
  {
    field: "primary_contact_email",
    headerName: "Primary Email",
    flex: 1,
    hideable: true,
    type: "email",
    required: true,
  },
  { field: "archive", headerName: "Archived", flex: 1, hideable: true, type: "boolean" },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    hideable: true,
    type: "metadata",
  },
];
export const orgTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: {
      last_modified_timestamp: false,
    },
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
};
export class orgTableEntry {
  constructor() {
    this.org_id = uuid();
  }
  name = "";
  short_name = "";
  primary_contact_email = "";
  archive = false;
}

//Product configs
export const productApi = "product";
export const productTableRowKey = "product_id";
export const productTableColumns = [
  { field: "product_id", headerName: "Product Id", flex: 1, hideable: false, type: "metadata", required: true },
  { field: "org_id", headerName: "Org Id", flex: 1, hideable: true, type: "metadata", required: true },
  { field: "name", headerName: "Name", flex: 1, hideable: false, required: true },
  { field: "short_name", headerName: "Short Name", flex: 1, hideable: true, editable: true, required: true },
  {
    field: "primary_contact_email",
    headerName: "Primary Email",
    flex: 1,
    hideable: true,
    type: "email",
    required: true,
  },
  { field: "archive", headerName: "Archived", flex: 1, hideable: true, type: "boolean" },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    hideable: true,
    type: "metadata",
  },
];
export const productTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: {
      org_id: false,
      last_modified_timestamp: false,
    },
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
};
export class productTableEntry {
  constructor(org_id) {
    this.org_id = org_id;
    this.product_id = uuid();
  }
  name = "";
  short_name = "";
  primary_contact_email = "";
  archive = false;
}

//Sandbox configs
export const sandboxApi = "sandbox";
export const sandboxTableRowKey = "sandbox_id";
export const sandboxTableColumns = [
  { field: "sandbox_id", headerName: "Sandbox Id", flex: 1, hideable: false, type: "metadata", required: true },
  { field: "product_id", headerName: "Product Id", flex: 1, hideable: true, type: "metadata", required: true },
  { field: "name", headerName: "Name", flex: 1, hideable: false, required: false },
  { field: "short_name", headerName: "Short Name", flex: 1, hideable: true, editable: true, required: true },
  {
    field: "primary_contact_email",
    headerName: "Primary Email",
    flex: 1,
    hideable: true,
    type: "email",
    required: true,
  },
  { field: "classic_support_tool_url", headerName: "Support Tool URL", flex: 1, hideable: true, type: "url" },
  { field: "archive", headerName: "Archived", flex: 1, hideable: true, type: "boolean" },
  {
    field: "last_modified_timestamp",
    headerName: "Modified",
    flex: 4,
    editable: false,
    valueFormatter: (param) => formatTimestamp(param.value),
    type: "metadata",
  },
];
export const sandboxTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
  columns: {
    columnVisibilityModel: {
      org_id: false,
      product_id: false,
      last_modified_timestamp: false,
    },
  },
  sorting: {
    sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
  },
};
export class sandboxTableEntry {
  constructor(org_id, product_id) {
    this.org_id = org_id;
    this.product_id = product_id;
    this.sandbox_id = uuid();
  }
  name = "";
  short_name = "";
  primary_contact_email = "";
  archive = false;
  classic_support_tool_url = "http://";
}

// Permissions configs
export const permissionsApi = "permissions";
export const permissionsTableRowKey = "permission_id";
export const permissionsTableColumns = [
  { field: "permission_id", headerName: "Permission ID", flex: 1, hideable: false },
  { field: "type", headerName: "Type", flex: 1, hideable: false },
  { field: "scope", headerName: "Scope", flex: 1, hideable: false },
  { field: "access", headerName: "Access", flex: 1, hideable: false },
];
export const permissionsTableInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 100,
    },
  },
  columns: {
    columnVisibilityModel: {},
  },
  sorting: {
    sortModel: [{ field: "permission_id", sort: "asc" }],
  },
};
export class PermissionsTableEntry {
  constructor(type, scope, access) {
    this.type = type;
    this.scope = scope;
    this.access = access;
  }

  get permissionId() {
    return [this.type, this.scope, this.access].join(":");
  }
}
// export class PermissionsTableEntry {
//     constructor(type, scope, access) {
//         this.permissionId = [type, scope, access].join(':');
//     }
//     type = '';
//     scope = '';
//     access = '';
// }
