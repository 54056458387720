import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import RHLoadingMask from "components/common/RHLoadingMask";
import { DataGrid } from "@mui/x-data-grid";
import { portalPermissionsColumns } from "./definitions";
import { useAuth } from "contexts/AuthContext";
import { API_BASE } from "common/apiUtils";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => {
  return {
    rootRHOrgPermissions: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    tabs: {
      display: "flex",
      flexDirection: "column",
    },
    tabHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    tabContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      margin: theme.spacing(2),
    },
  };
});

function RHOrgPermissions({ hasEditPermissions }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [permissions, setPermisisons] = useState([]);
  const [portalPermissionsColumnVisibility, setPortalPermissionsColumnVisibility] = useState({
    display_name: true,
    permission_id: true,
    type: false,
    scope: false,
    access: false,
    description: true,
  });

  const { getAccessTokenSilently } = useAuth();

  async function fetchPermissionsData() {
    try {
      const response = await fetch(`${API_BASE}/v1/portal-permissions`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
      });
      const pp = await response.json();

      setPermisisons(pp);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPermissionsData();
  }, []);

  function customPermissionsToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  if (isLoading) {
    return <RHLoadingMask />;
  }

  return (
    <Box className={classes.rootRHOrgPermissions}>
      <DataGrid
        className={classes.table}
        autoHeight
        rows={permissions}
        columns={portalPermissionsColumns}
        getRowId={(row) => row.permission_id}
        columnVisibilityModel={portalPermissionsColumnVisibility}
        onColumnVisibilityModelChange={(newModel) => {
          setPortalPermissionsColumnVisibility(newModel);
        }}
        slots={{
          toolbar: customPermissionsToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          bgcolor: "background.paper",
          border: "none",
          minWidth: 0,
          p: 1,
        }}
      />
    </Box>
  );
}

export default RHOrgPermissions;
