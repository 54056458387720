import { useContext, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import AccountContext from "../../contexts/AccountContext";
import MenuDropdown from "./MenuDropdown";
import sandboxConfigElements from "./sandboxConfigElements";

const isDev = window.env.REACT_APP_API_BASE_PATH !== "https://developer.rallyhere.gg/api";

export default function SandboxConfigMenu() {
  let all_menu_items_enabled = isDev;
  const location = useLocation();

  const [featureData, setFeatureData] = useState({});
  const { currentStateData } = useContext(AccountContext);

  function toggledOn(route) {
    const product_id = currentStateData?.product?.productId.toUpperCase();
    const isMenuItemEnabled = all_menu_items_enabled || !featureData[product_id]?.includes(route);
    return isMenuItemEnabled;
  }

  async function fetchFeatureData() {
    const featureDataRes = await fetch("/features.json");
    const data = await featureDataRes.json();
    setFeatureData(data);
  }

  useEffect(() => {
    fetchFeatureData();
  }, []);

  return !featureData ? null : (
    <div>
      {sandboxConfigElements.map((elem) => {
        if (elem.nestedItems) {
          return <MenuDropdown key={elem.name + parent} elem={elem} toggledOn={toggledOn} />;
        } else {
          return (
            <Link
              to={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}/sandbox/${currentStateData?.sandbox.shortName}/${elem.route}`}
              className={`menu-link ${toggledOn(elem.route) ? "" : "pointer-events-none opacity-50"}`}
              key={elem.name + elem.route}
            >
              <span
                className={`px-2 py-1 transition-colors w-full rounded ${
                  location.pathname.includes(elem.route)
                    ? "text-white bg-selectedPurple hover:bg-selectedPurple"
                    : "hover:bg-white/10"
                }`}
              >
                {elem.icon}
                <span>{elem.name}</span>
              </span>
            </Link>
          );
        }
      })}
    </div>
  );
}
