import React, { useState, useEffect, useRef } from "react";
import { CloseIcon } from "../../assets/svgs";

const Notification = ({ message, duration = 3000, severity = "info", clearMessage }) => {
  const [show, setShow] = useState(false);
  const timerRef = useRef(null);

  const borderColor = {
    error: "border-red-500",
    success: "border-green-500",
    info: "border-gray-800",
    warning: "border-yellow-400",
  }[severity];

  useEffect(() => {
    if (message) {
      setShow(true);
      timerRef.current = setTimeout(() => {
        setShow(false);
        clearMessage();
      }, duration);
      return () => clearTimeout(timerRef.current);
    }
  }, [message, duration]);

  const handleCloseClick = () => {
    clearTimeout(timerRef.current);
    setShow(false);
    clearMessage();
  };

  if (!show) return null;

  return (
    <div
      className={`fixed bottom-5 left-0 right-0 mx-auto z-50 ${borderColor} bg-gray bg-opacity-98 text-white px-6 py-3 rounded-lg shadow-lg opacity-100 transition-opacity duration-300 border-2`}
      style={{ minWidth: "300px", width: "max-content" }}
    >
      <div className="flex justify-between items-center">
        <div>{message}</div>
        <button
          className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded"
          onClick={handleCloseClick}
        >
          <CloseIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default Notification;
