import { formatTimestamp } from "../../../common/dateUtils";

export const columns = [
  {
    field: "datacenter_contract_id",
    headerName: "Data Center Contract ID",
    required: true,
    minWidth: 200,
    type: "metadata",
  },
  {
    field: "start_date",
    headerName: "Start Date",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 2,
    type: "date",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "end_date",
    headerName: "End Date",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 2,
    type: "date",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "cancel_date",
    headerName: "Cancel Date",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
    type: "date",
    valueFormatter: (param) => formatTimestamp(param.value),
  },
  {
    field: "period_days",
    headerName: "Period Days",
    minWidth: 200,
    flex: 2,
    min: 0,
    type: "number",
  },
  {
    field: "notice_days",
    headerName: "Notice Days",
    minWidth: 200,
    flex: 2,
    min: 0,
    type: "number",
  },
  {
    field: "purchase_order",
    headerName: "Purchase Order",
    minWidth: 150,
    flex: 2,
  },
  {
    field: "payment_term_days",
    headerName: "Payment Term Days",
    minWidth: 200,
    flex: 2,
    min: 0,
    type: "number",
  },
  {
    field: "hosts",
    headerName: "Hosts",
    minWidth: 200,
    flex: 2,
    type: "enhancedautocomplete",
    editable: true,
    multiSelect: true,
    isDynamic: true,
    fullUrl: "v1/armada/host",
    filterOnClient: true,
    idKey: "host_id",
    displayValue: "public_hostname",
    defaultOptions: [],
  },
];

export class NewEntry {
  start_date = new Date().toISOString();
  end_date = new Date().toISOString();
  cancel_date = new Date().toISOString();
  period_days = 0;
  extend_period_days = 0;
  notice_days = 0;
}
