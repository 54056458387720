import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import { API_BASE, useApiToken } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import { useMessageBar } from "contexts/messagebar/RHMessageContext";

const useStyles = makeStyles((theme) => {
  return {
    rootRHAddAccountOrgGroup: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    autocomplete: {
      marginBottom: theme.spacing(3),
    },
    actionBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
  };
});

function RHAddAccountOrgGroup(props) {
  const classes = useStyles();
  // const token = useApiToken();
  const { getAccessTokenSilently } = useAuth();
  // const headers = new Headers({ Authorization: `Bearer ${token}`, "Content-Type": "application/json" });

  const { accountId, orgId, saveHandler } = props;

  const { showMessage, setIsDirty } = useMessageBar();

  const [permissionGroups, setPermissionGroups] = useState([]);
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState([]);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    if (selectedPermissionGroup) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }, [selectedPermissionGroup]);

  async function fetchPermissionGroupsData() {
    if (orgId) {
      try {
        const response = await fetch(`${API_BASE}/v1/permissions-org-group/${orgId}`, {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
          method: "GET",
        });

        const permissionGroups = await response.json();
        setPermissionGroups(permissionGroups);
        setSelectedPermissionGroup(permissionGroups[0]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPermissionGroups([]);
        } else {
          console.error(error);
        }
      }
    }
  }

  async function handleSaveAccountPermissionGroup() {
    try {
      const newAccountPermissionGroup = {
        group_id: selectedPermissionGroup.group_id,
        account_id: accountId,
      };

      await fetch(`${API_BASE}/v1/${orgId}/permissions-org-group-account`, {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}`, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(newAccountPermissionGroup),
      });

      showMessage(
        `Permission Group ${newAccountPermissionGroup.group_id} added to Account ${newAccountPermissionGroup.account_id}`,
        "success"
      );
      setIsDirty(false);
      saveHandler();
      fetchPermissionGroupsData();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setPermissionGroups([]);
      } else {
        console.error(error);
      }
      showMessage(`Failed to add Permission Group to Account: ${error.message}`, "error");
    }
  }
  const handlePermissionGroupSelection = (event, value) => {
    setSelectedPermissionGroup(value);
  };

  useEffect(() => {
    fetchPermissionGroupsData();
  }, []);

  return (
    <Box className={classes.rootRHAddAccountOrgGroup}>
      <Autocomplete
        value={selectedPermissionGroup}
        onChange={handlePermissionGroupSelection}
        id="permission-group-selection"
        options={permissionGroups}
        getOptionLabel={(permission) => permission.org_group_name || ""}
        renderInput={(params) => <TextField {...params} label="Permission Group *" variant="outlined" />}
      />
      <Box className={classes.actionBar}>
        <Button variant="contained" color="primary" onClick={handleSaveAccountPermissionGroup} disabled={!saveEnabled}>
          Save Account Permission Group
        </Button>
      </Box>
    </Box>
  );
}

export default RHAddAccountOrgGroup;
