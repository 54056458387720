import { useRef, useState, useContext, useEffect } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useSandboxStyles } from "../sandbox/styles";
import AccountContext from "../../contexts/AccountContext";
import RHLoadingMask from "../../components/common/RHLoadingMask";
import RHEditableTable from "../../components/common/RHEditableTable";
import { useAuth } from "contexts/AuthContext";
import { fetchDirectly } from "common/apiUtils";
import RHConfirmDialog from "../../components/common/RHConfirmDialog";
import RHProductClientSecretDialog from "./RHProductClientSecretDialog";

export default function RHProductClientPage() {
  const classes = useSandboxStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });
  const policyLookup = useRef();
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const sdata = useRef();

  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();

  const API_NAME = "clients";
  const ROW_KEY = "client_id";

  const COLUMNS = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      required: true,
    },
    {
      field: "client_id",
      headerName: "ID",
      flex: 1,
      alwaysDisabled: true,
      hideable: false,
      type: "metadata",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      required: true,
    },
    {
      field: "policy_name",
      headerName: "Policy",
      flex: 1,
    },
    {
      field: "policy_id",
      headerName: "Policy ID",
      flex: 1,
      editable: true,
      type: "autocomplete",
      valueRef: "policy_name",
      preLoad: true,
      required: true,
      alwaysHidden: true,
    },
  ];

  class clientEntry {
    constructor() {}
  }

  async function refreshData() {
    const resp = await fetchData();
    return resp;
  }

  function mapClientFromApi(c) {
    c.policy_id = c.policy.policy_id;
    if (policyLookup.current) {
      c.policy_name = policyLookup.current[c.policy.policy_id];
    }
    delete c.policy;
    return c;
  }

  async function fetchData() {
    try {
      const client_fetch = new Promise(async (resolve) => {
        const productId = currentStateData?.product?.productId;
        const resp = await fetchDirectly({
          fullUrl: `v1/product/${productId}/client`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        resolve(resp);
      });

      const policy_fetch = new Promise(async (resolve) => {
        const productId = currentStateData?.product?.productId;
        const resp = await fetchDirectly({
          fullUrl: `v1/product/${productId}/policy`,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        resolve(resp);
      });

      const resp = await Promise.all([client_fetch, policy_fetch]).then(
        (responses) => {
          let policies = responses[1].policies;
          const policy_lookup = policies.reduce((acc, val) => {
            acc[val.policy_id] = val.name;
            return acc;
          }, {});

          policyLookup.current = policy_lookup;

          const clients = responses[0].clients.map((x) => mapClientFromApi(x));

          const options = responses[1].policies
            .filter((x) => x.name)
            .map((x) => ({
              title: x.name,
              id: x.policy_id,
            }));
          const col = COLUMNS.find((x) => x.field == "policy_id");
          col.options = options;

          setColumns(COLUMNS);

          responses[0].clients = clients;
          return responses[0];
        }
      );

      setData(resp.clients);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (currentStateData?.product?.productId) {
      fetchData();
    }
  }, [currentStateData]);

  function customFilterFunc(field) {
    return !["created_timestamp", "policy_name"].includes(field.field);
  }

  async function createHandler(apiName, onDataRefresh, sandboxId, editedItem) {
    const prodId = currentStateData?.product?.productId;
    const item = await fetchDirectly({
      fullUrl: `v1/product/${prodId}/client`,
      method: "POST",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    // const item = await create(apiName, onDataRefresh, prodId, editedItem);
    setSelectedRow(mapClientFromApi(item));
    sdata.current = item.client_secret;
    handleDialogClickOpen();
    fetchData();
    return item;
  }

  async function updateHandler(
    apiName,
    onDataRefresh,
    editedItemId,
    sandboxId,
    editedItem
  ) {
    const prodId = currentStateData?.product?.productId;
    const item = await fetchDirectly({
      fullUrl: `v1/product/${prodId}/client/${editedItemId}`,
      method: "PUT",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    fetchData();
    return item;
  }

  async function deleteHandler(apiName, onDataRefresh, editedItemId) {
    const prodId = currentStateData?.product?.productId;
    const item = await fetchDirectly({
      fullUrl: `v1/product/${prodId}/client/${editedItemId}`,
      method: "DELETE",
      token: await getAccessTokenSilently(),
    });
    fetchData();
    return item;
  }

  if (isLoading) {
    return <RHLoadingMask />;
  }

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
    columns: {
      columnVisibilityModel: {
        policy_id: false,
      },
    },
    sorting: {
      sortModel: [{ field: "name", sort: "asc" }],
    },
  };

  function handleDialogClickOpen() {
    setOpen(true);
  }

  function handleDialogClose(value) {
    setOpen(false);
  }

  function handleConfirmDialogClose(value) {
    setDialogProps((prev) => ({ ...prev, open: false }));
  }

  async function handleConfirmRotation(value) {
    handleConfirmDialogClose();
    try {
      const prodId = currentStateData?.product?.productId;
      const client = { ...selectedRow };
      client.policy_id = selectedRow.policy_id;
      client.rotate_secret = true;

      const item = await fetchDirectly({
        fullUrl: `v1/product/${prodId}/client/${client.client_id}`,
        method: "PUT",
        token: await getAccessTokenSilently(),
        body: client,
      });

      sdata.current = item.client_secret;
    } catch (err) {
      sdata.current = null;
    }

    handleDialogClickOpen();
  }

  async function handleRotateKey() {
    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Client Secret Rotation",
        content: `Are you sure you want to rotate the secret? This action cannot be undone. Use extreme caution! \n
                  Any game clients using the old secret will no longer be able to connect.`,
        onConfirm: () => handleConfirmRotation(),
        onCancel: () => handleConfirmDialogClose(),
      });
    }
  }

  function handleDialogClickOpen() {
    setOpen(true);
  }

  function handleRetrieveSelectedRow(item, isEdit) {
    console.log(item);
    setSelectedRow(item);
  }

  const renderRotateKeyBtn = () => (
    <Tooltip title="Generate new client secret" key="additional_rotate_button">
      <Button onClick={handleRotateKey} key="rotate_secret" variant="contained">
        Rotate Secret
      </Button>
    </Tooltip>
  );

  return (
    <>
      <Box className={classes.rootTableContainer}>
        <RHEditableTable
          additionalEditButtons={[renderRotateKeyBtn]}
          apiName={API_NAME}
          title="Client Editor"
          friendlyName="Client"
          friendlyNameKey="name"
          rowKey={ROW_KEY}
          columns={columns}
          tableData={data}
          initialState={initialState}
          onDataRefresh={refreshData}
          createHandler={createHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}
          hasEditPermission={
            currentStateData?.product?.permissions?.includes(
              "globalAdmin:*:*"
            ) ||
            currentStateData?.product?.permissions?.includes(
              "clients:config:edit"
            )
          }
          textFieldFilter={customFilterFunc}
          className={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          retrieveSelectedRows={handleRetrieveSelectedRow}
          retrieveNewItemAsSelectedRow
          editableInline={false}
          newModel={clientEntry}
          newModelArgs={[]}
          isRowEditable={(row) => !row.default_policy}
        />
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
        <RHProductClientSecretDialog
          data={sdata.current}
          open={open}
          onClose={handleDialogClose}
        />
      </Box>
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
