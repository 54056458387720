import { useState, useEffect } from "react";
import RHLoadingMask from "components/common/RHLoadingMask";
import RHEditableTable from "components/common/RHEditableTable";
import { API_BASE } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import {
  permissionsApi,
  permissionsTableRowKey,
  permissionsTableColumns,
  permissionsTableInitialState,
} from "./definitions";
import { usePortalAdminTableStyles } from "./styles";

export default function RHPermissions() {
  const classes = usePortalAdminTableStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const { getAccessTokenSilently } = useAuth();

  const PERMISSIONS_API_NAME = permissionsApi;
  const PERMISSIONS_TABLE_ROW_KEY = permissionsTableRowKey;
  const PERMISSIONS_TABLE_INITIAL_STATE = permissionsTableInitialState;

  function customFilterFunc(field) {
    return field.field !== "permission_id";
  }

  async function fetchData() {
    const response = await fetch(`${API_BASE}/v1/portal-permissions`, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    setData(data);
    setIsLoading(false);
    return;
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <RHLoadingMask />;
  }

  return (
    <RHEditableTable
      title="Portal Permissions"
      friendlyName="Portal Permissions"
      friendlyNameKey="permission_id"
      apiName={PERMISSIONS_API_NAME}
      rowKey={PERMISSIONS_TABLE_ROW_KEY}
      columns={permissionsTableColumns}
      tableData={data}
      initialState={PERMISSIONS_TABLE_INITIAL_STATE}
      hasEditPermission={false}
      textFieldFilter={customFilterFunc}
      className={classes}
      editableInline={false}
    />
  );
}
