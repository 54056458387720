export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 200,
    editable: true,
    flex: 1,
  },
  {
    field: "machine_image_id",
    headerName: "Machine Image ID",
    minWidth: 200,
    type: "metadata",
    flex: 2,
  },
];

export class NewEntry {}
