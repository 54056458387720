import React from "react";

const CustomBooleanInput = ({ id, name, value = false, onChange, required, className, label }) => {
  const handleChange = () => {
    onChange(!value);
  };

  return (
    <label className={`flex items-center cursor-pointer ${className || ""}`}>
      <div className="relative inline-flex items-center">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={value}
          onChange={handleChange}
          className="sr-only peer"
          required={required}
        />
        <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
          {value && (
            <svg
              className="absolute left-1 top-1 w-4 h-4 text-white z-10"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          )}
        </div>
      </div>
    </label>
  );
};

export default CustomBooleanInput;
