import React, { useState, useContext, useCallback } from "react";
import Notification from "../../../components/common/Notification";
import AccountContext from "../../../contexts/AccountContext";
import TerraformConfig from "./TerraformConfig";


const TerraformControlPanel = () => {
  const { currentStateData, permissionData, hasPermission } =
    useContext(AccountContext);
  const [notificationProps, setNotificationProps] = useState({
    message: "",
    severity: "info",
  });


  const clearNotification = () => {
    setNotificationProps({ message: "", severity: "info" });
  };
  const handleNotification = (message, severity) => {
    setNotificationProps({ message, severity });
  };


  const isPermitted = useCallback(() => {
    if (!currentStateData || !permissionData) {
      return null; // Return null if we don't have enough data to make a decision
    }
    if (permissionData.isGlobalAdmin){
      return true;
    }
    return hasPermission(
      currentStateData,
      permissionData
    );
  }, [currentStateData, permissionData, hasPermission]);


  // Modify the rendering logic
  const renderContent = () => {
    const permissionStatus = isPermitted();

    if (permissionStatus === null) {
      return (
        <div className="flex items-center justify-center p-6">
          <div className="text-center p-6 bg-zinc-800 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">Loading...</h2>
            <p className="text-zinc-400">Please wait while we verify your permissions.</p>
          </div>
        </div>
      );
    }

    if (permissionStatus === false) {
      return (
        <div className="flex items-center justify-center p-6">
          <div className="text-center p-6 bg-zinc-800 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">Access Denied</h2>
            <p className="text-zinc-400">You do not have permission to view this page.</p>
          </div>
        </div>
      );
    }
    // Render the main content when the user has permission

    return (
      <>
        <div className="flex flex-col lg:flex-row">
          <main className="flex-grow flex flex-col">
            {/* content */}
            <div className="p-6">
              <div className="max-w-full">
              <TerraformConfig
                onNotification={handleNotification}
              />
              </div>
            </div>
          </main>
        </div>

        {/* Notification */}
        {notificationProps.message && (
          <Notification
            message={notificationProps.message}
            severity={notificationProps.severity}
            clearMessage={clearNotification}
          />
        )}

      </>
    );
  };

  return (
    <div className="bg-zinc-900 text-zinc-300 flex flex-col">
      {/* Header */}
      <header className="bg-zinc-800 p-4 flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-lg font-bold mb-4 md:mb-0">
          Terraform Control Panel
        </h1>
      </header>
      {renderContent()}
    </div>
  );
};

export default TerraformControlPanel;
