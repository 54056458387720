import { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import AccountContext from "contexts/AccountContext";
import { useLayout } from "contexts/LayoutContext";
import { useAuth } from "contexts/AuthContext";
import RightDrawer from "components/common/RightDrawer";
import { apiRequest, getErrorMessage } from "common/apiUtils";
import DataTable from "components/common/datatable";
import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import DropdownMenu from "components/common/DropdownMenu";
import Form from "./Form.js";
import { OpenDrawerIcon } from "assets/svgs";

import userConfigs from "./config.js";
import groupConfigs from "./groupConfig.js";
import definitions from "common/definitions.json";
console.log(definitions);

export default function UserManagement({ view = "users" }) {
  const CONFIG = view === "users" ? userConfigs : groupConfigs;
  const tabs = Object.keys(CONFIG.tabs);
  const defaultTab = useMemo(
    () => Object.keys(CONFIG.tabs).find((key) => CONFIG.tabs[key]?.default),
    [CONFIG]
  );

  const { defaultDrawerWidth } = useLayout();

  const columnHelper = createColumnHelper();

  const { currentStateData, permissionData, hasPermission } =
    useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();
  const [drawerLoadingMessage, setDrawerLoadingMessage] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [newModel, setNewModel] = useState();
  const [columnVisibility, setColumnVisibility] = useState({
    description: true,
  });

  const readPermitted =
    permissionData && currentStateData?.org?.orgId
      ? hasPermission(
          "accountOrg:config:view",
          "org",
          currentStateData?.org?.orgId,
          currentStateData,
          permissionData
        )
      : false;

  const editPermitted =
    permissionData && currentStateData?.org?.orgId
      ? hasPermission(
          "account:config:edit",
          "org",
          currentStateData?.org?.orgId,
          currentStateData,
          permissionData
        ) ||
        hasPermission(
          "accountOrg:config:edit",
          "org",
          currentStateData?.org?.orgId,
          currentStateData,
          permissionData
        )
      : false;

  let isResizing = useRef(false);

  let replacements = currentStateData?.org
    ? Object.entries(currentStateData?.org).reduce((acc, [key, value]) => {
        const idRegex = /[iI]d$/;
        if (idRegex.test(key)) {
          const newKey = key.replace(/([^_])([iI]d)$/, "$1_id");
          acc[newKey] = value;
          acc[newKey.replace(/_id$/, "_identifier")] = value;
        }
        return acc;
      }, {})
    : undefined;

  const columns = useMemo(
    () => [
      columnHelper.accessor("account_email", {
        cell: (info) => info.getValue(),
        header: () => <span>Email</span>,
        minSize: 250,
        maxSize: 300,
      }),
      columnHelper.accessor("description", {
        cell: (info) => info.getValue(),
        header: () => <span>Description</span>,
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.accessor("account_id", {
        cell: (info) => info.getValue(),
        header: () => <span>Account ID</span>,
        size: Number.MAX_SAFE_INTEGER,
      }),
    ],
    []
  );

  const groupColumns = useMemo(
    () => [
      columnHelper.accessor("org_group_name", {
        cell: (info) => info.getValue(),
        header: () => <span>Group Name</span>,
        minSize: 250,
        maxSize: 300,
      }),
      columnHelper.accessor("description", {
        cell: (info) => info.getValue(),
        header: () => <span>Description</span>,
        size: Number.MAX_SAFE_INTEGER,
      }),
      columnHelper.accessor("group_id", {
        cell: (info) => info.getValue(),
        header: () => <span>Group ID</span>,
        size: Number.MAX_SAFE_INTEGER,
      }),
    ],
    []
  );

  const drawerButtonsMap = [
    {
      name: "Close Drawer",
      icon: <OpenDrawerIcon className="rotate-180" />,
      fn: setDrawerWidth,
      args: [0],
    },
  ];

  const buttonsMap = [
    {
      name: "Table Size",
      icon: <DropdownMenu type="TableSize" />,
    },
    {
      name: "Open Drawer",
      icon: <OpenDrawerIcon className="" />,
      fn: setDrawerWidth,
      args: [350],
    },
  ];

  function handleAdd() {
    const newModelObj = {
      fields: CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce(
        (acc, field) => {
          acc[field.field] = "";
          return acc;
        },
        {}
      ),
      tab: "Info",
    };
    setSelectedRow();
    setRowSelectionModel({});
    setNewModel({ ...newModelObj, org_id: currentStateData?.org?.orgId });
  }

  async function fetchUserData(activeTab, replacements, config) {
    const endpoint = definitions.schemas?.[
      config.tabs[activeTab]?.schema
    ]?.endpoints.find((e) => e.method === "get");
    let extraOptions = {
      queryParams: config.tabs[activeTab]?.GETparams ?? undefined,
    };

    return apiRequest(
      await getAccessTokenSilently(),
      endpoint,
      replacements,
      null,
      null,
      extraOptions
    );
  }
  const usersData = useQuery({
    queryKey: [activeTab],
    queryFn: () => fetchUserData(activeTab, replacements, CONFIG),
    enabled: !!replacements?.org_id && Object.hasOwn(CONFIG?.tabs, activeTab),
    onError: (error) => {
      const errorMsg = getErrorMessage(
        error,
        CONFIG?.tabs[activeTab]?.editor?.fields
      );
      setError(errorMsg);
    },
    staleTime: 30000,
  });

  useEffect(() => {
    if (newModel || Object.keys(rowSelectionModel).length > 0) {
      setDrawerWidth(defaultDrawerWidth);
    } else {
      setDrawerWidth(0);
    }
    if (selectedRow) {
      setNewModel();
    }
  }, [selectedRow, newModel, rowSelectionModel]);

  useEffect(() => {
    setDrawerWidth(0);
    setNewModel();
    if (activeTab === "M2M Users") {
      setColumnVisibility({ description: true, account_email: false });
    } else {
      setColumnVisibility({ description: false, account_email: true });
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [view, CONFIG, defaultTab]);

  const loadMessage = usersData?.isError
    ? `Unable to retrieve data. ${usersData?.error?.message}`
    : "Retrieving the latest data, please wait...";

  return (
    <div className="relative flex flex-1 z-10">
      {drawerLoadingMessage && (
        <div className="absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div>
            <LoadingMask size={100} loadMessage={drawerLoadingMessage} />
          </div>
        </div>
      )}
      <div
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
        }}
      >
        <MenuBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          buttons={buttonsMap}
          setSelectedRow={setSelectedRow}
          setRowSelectionModel={setRowSelectionModel}
        />
        {usersData?.isPending && !usersData?.error ? (
          <div className="mt-20 flex-1">
            <LoadingMask loadMessage={loadMessage} />
          </div>
        ) : (
          <DataTable
            data={usersData?.data}
            columns={view === "users" ? columns : groupColumns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            columnsWithSort={
              view === "users"
                ? ["account_id", "account_email"]
                : ["group_id", "org_group_name"]
            }
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setRowSelectionModel={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            defaultSortKey={view === "users" ? "account_id" : "group_id"}
            handleAdd={handleAdd}
            rowId={CONFIG.tabs[activeTab]?.rowId}
            className="flex-1"
            readPermitted={readPermitted}
            editPermitted={editPermitted}
          />
        )}
      </div>
      <RightDrawer
        setWidthFn={setDrawerWidth}
        width={drawerWidth}
        isResizing={isResizing}
      >
        {(!!newModel || selectedRow) && (
          <Form
            item={newModel ?? selectedRow?.original}
            setRowSelectionModel={setRowSelectionModel}
            setDrawerLoadingMessage={setDrawerLoadingMessage}
            newModel={newModel}
            config={CONFIG?.tabs[activeTab]}
            tab={activeTab}
            setSelectedRow={setSelectedRow}
            buttonsMap={drawerButtonsMap}
            readPermitted={readPermitted}
            editPermitted={editPermitted}
          />
        )}
      </RightDrawer>
    </div>
  );
}
