import { useState, useEffect } from "react";
import { useLayout } from "contexts/LayoutContext";
import FlashText from "components/common/FormField/FlashText";
import Tooltip from "components/common/FormField/Tooltip";
import { PersonIcon } from "assets/svgs";
import { apiRequest, useApiToken } from "common/apiUtils";
import definitions from "common/definitions.json";

export default function ReadOnlyField({
  label,
  name,
  value,
  type = "text",
  description,
  hasError,
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);
  const [accountData, setAccountData] = useState({});

  const { tableSize } = useLayout();

  const token = useApiToken();

  useEffect(() => {
    if (name === "last_modified_timestamp") {
      if (value) {
        const valueFormatted = new Date(value);

        setDisplayValue(
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(valueFormatted)
        );
      } else {
        setDisplayValue("");
      }
    } else {
      setDisplayValue(value);
    }

    setAccountData({});
  }, [value, name]);

  const fetchData = async () => {
    if (name === "last_modified_account_id" || name === "account_id") {
      const getEndpoint = definitions.schemas?.AccountExpanded?.endpoints?.find(
        (ep) => ep.method === "get" && !ep.path.includes("/account/me")
      );

      if (getEndpoint) {
        try {
          const replacements = { account_id: value };
          const fetchedData = await apiRequest(
            token,
            getEndpoint,
            replacements
          );
          setAccountData(fetchedData);

          setDisplayValue(
            fetchedData.account_email || fetchedData.account_id || value
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const toggleDisplayValue = () => {
    setDisplayValue((prevValue) =>
      prevValue === accountData.account_email
        ? accountData.account_id
        : accountData.account_email
    );
  };

  const onIconClick = () => {
    if (Object.keys(accountData).length > 0) {
      toggleDisplayValue();
    } else {
      fetchData();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(displayValue)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="mb-3 container-query-formfield relative">
      <div className="cq-wrapper">
        <label
          className={`block font-medium text-zinc-300 font-lato opacity-70 ${
            tableSize === "text-xs" || tableSize === "text-sm"
              ? "text-xs"
              : "text-sm"
          }`}
        >
          <Tooltip title={description} placement="left" disableInteractive>
            <p className="mb-1 cq-label">
              <span>{label}</span>
            </p>
          </Tooltip>
        </label>
        <div className="relative w-full cq-input">
          <input
            readOnly
            onClick={copyToClipboard}
            type={type}
            name={name}
            value={displayValue}
            className={`w-full text-white border border-zinc-600 rounded p-2 focus:outline-none focus:ring-2 focus:ring-violet-500 placeholder-zinc-400 bg-opacity-20 opacity-60 bg-zinc-700 backdrop-filter backdrop-blur-sm ${tableSize} cursor-pointer`}
          />
          {(name === "last_modified_account_id" || name === "account_id") && (
            <Tooltip title="Switch Account Info" placement="left">
              <div
                className={`absolute right-1.5 bg-white/10 rounded-full p-0.5 cursor-pointer hover:bg-white/30 transition-colors bottom-1.5`}
              >
                <PersonIcon
                  fill="#ffffff80"
                  onClick={onIconClick}
                  className="w-5 h-5"
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <FlashText
        open={openSnackbar}
        autoHideDuration={1200}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message={
          <>
            <div className="text-center">{`${displayValue}`}</div>
            <div className="text-center">{"copied to clipboard!"}</div>
          </>
        }
      />
    </div>
  );
}
