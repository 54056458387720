import { useDataContext } from "contexts/DataContext";
import { apiRequest, useApiToken } from "../common/apiUtils";
import definitions from "../common/definitions.json";
import default_sessions from "../common/schemaDefaults/default_session_templates.json";

async function createResource(resourceName, payload, token, schemaObjects, currentStateData) {
  const endpoint = schemaObjects[resourceName]?.endpoints.find((e) => e.method === "post");
  if (!endpoint) {
    throw new Error(`Endpoint for ${resourceName} not defined.`);
  }

  const response = await apiRequest(token, endpoint, { sandbox_id: currentStateData?.sandbox?.sandboxId }, payload);

  const resourceIdMappings = {
    "Crossplay-partition": "crossplay_partition_id",
    "Crossplay-profile": "crossplay_profile_id",
    "Crossplay-pool": "crossplay_pool_id",
    "Crossplay-pool-tag": "crossplay_pool_tag_id",
    "Matchmaking-stride": "match_making_stride_id",
    "Matchmaking-stride-entry": "match_making_stride_entry_id",
    "Session-templates": "session_template_id",
    "Platform-session-templates": "last_modified_timestamp",
    "Rule-sets": "rule_set_id",
  };

  const resourceId = resourceIdMappings[resourceName];
  if (!resourceId) {
    throw new Error(`Resource name ${resourceName} is not handled.`);
  }

  return response[resourceId];
}

async function processPartitions(partitionData, token, schemaObjects, currentStateData) {
  for (const [partitionName, { profiles }] of Object.entries(partitionData)) {
    const crossplay_partition_id = await createResource(
      "Crossplay-partition",
      { name: partitionName },
      token,
      schemaObjects,
      currentStateData
    );

    for (const [profileName, { pools }] of Object.entries(profiles)) {
      const crossplay_profile_id = await createResource(
        "Crossplay-profile",
        { name: profileName, crossplay_partition_id },
        token,
        schemaObjects,
        currentStateData
      );

      for (const [poolName, { tags }] of Object.entries(pools)) {
        const crossplay_pool_id = await createResource(
          "Crossplay-pool",
          { name: poolName, crossplay_profile_id },
          token,
          schemaObjects,
          currentStateData
        );

        for (const tag of tags) {
          await createResource(
            "Crossplay-pool-tag",
            { tag, crossplay_pool_id },
            token,
            schemaObjects,
            currentStateData
          );
        }
      }
    }
  }
}

async function loadCrossplayPartitionDefaults(
  pageName,
  token,
  schemaObjects,
  currentStateData,
  cachedData,
  selectedPage
) {
  const defaultsSchema = cachedData[selectedPage]?.LoadDefaults;
  const getDefaultsEndpoint = definitions?.schemas[defaultsSchema]?.endpoints.find((e) => e.method === "get");

  if (!getDefaultsEndpoint) {
    console.log("No GET endpoints defined for the current page. Proceeding with default logic.");
    return;
  }

  try {
    const initialResponse = await apiRequest(token, getDefaultsEndpoint, {
      sandbox_id: currentStateData?.sandbox?.sandboxId,
    });

    let partitionData = buildPartitionData(initialResponse);
    await processPartitions(partitionData, token, schemaObjects, currentStateData);
    console.log(`Default Values for ${pageName} have been loaded.`);
  } catch (error) {
    console.error("Error during initial API request:", error);
  }
}

// TODO the common mm strides API definition is not in definitions.json after running the generator. Investigate why.
async function loadMatchMakingStrideDefaults(pageName, token, schemaObjects, currentStateData, cachedData, selectedPage) {
  try {
    const defaultsSchema = cachedData[selectedPage]?.LoadDefaults;
    const getDefaultsEndpoint = definitions?.schemas[defaultsSchema]?.endpoints.find((e) => e.method === "get");

    if (!getDefaultsEndpoint) {
      console.log("No GET endpoints defined for the current page. Proceeding with default logic.");
      return;
    }

    const defaultStrides = await apiRequest(token, getDefaultsEndpoint, {});

    if (!defaultStrides) {
      throw new Error(`Unable to load Default Strides`);
    }

    for (const defaultStride of defaultStrides.strides) {
      const match_making_stride_id = await createResource(
          "Matchmaking-stride",
          defaultStride,
          token,
          schemaObjects,
          currentStateData
      );

      for (const entry of defaultStride.entries) {
        await createResource(
            "Matchmaking-stride-entry",
            {...entry, match_making_stride_id: match_making_stride_id},
            token,
            schemaObjects,
            currentStateData
        );
      }
    }

    console.log(`Default Values for ${pageName} have been loaded.`);
  } catch (error) {
    console.error("Error during initial API request:", error);
  }
}

async function loadSessionTemplateDefaults(pageName, token, schemaObjects, currentStateData) {
  try {
    for (const session of default_sessions) {
      const session_template_id = await createResource(
        "Session-templates",
        session,
        token,
        schemaObjects,
        currentStateData
      );
      for (const pst of session.platform_session_templates) {
        await createResource(
          "Platform-session-templates",
          { ...pst, session_template_id: session_template_id },
          token,
          schemaObjects,
          currentStateData
        );
      }
    }

    console.log(` Default Values for ${pageName} have been loaded.`);
  } catch (error) {
    console.error("Error during initial API request:", error);
  }
}

async function loadRuleSetsDefaults(pageName, token, schemaObjects, currentStateData, cachedData, selectedPage) {
  try {
    const defaultsSchema = cachedData[selectedPage]?.LoadDefaults;
    const getDefaultsEndpoint = definitions?.schemas[defaultsSchema]?.endpoints.find((e) => e.method === "get");

    if (!getDefaultsEndpoint) {
      console.log("No GET endpoints defined for the current page. Proceeding with default logic.");
      return;
    }

    const defaultRuleSets = await apiRequest(token, getDefaultsEndpoint, {});

    await createResource(
        selectedPage,
        defaultRuleSets,
        token,
        schemaObjects,
        currentStateData
      );

    console.log(` Default Values for ${pageName} have been loaded.`);
  } catch (error) {
    console.error("Error during API request:", error);
  }
}

function buildPartitionData(initialResponse) {
  let partitionData = {};

  initialResponse.partitions.forEach((partition) => {
    partitionData[partition.name] = { profiles: {} };

    partition.profiles.forEach((profile) => {
      partitionData[partition.name].profiles[profile.name] = { pools: {} };

      profile.pools.forEach((pool) => {
        partitionData[partition.name].profiles[profile.name].pools[pool.name] = { tags: pool.tags };
      });
    });
  });

  return partitionData;
}

export {
  createResource,
  processPartitions,
  loadCrossplayPartitionDefaults,
  loadMatchMakingStrideDefaults,
  loadSessionTemplateDefaults,
  buildPartitionData,
  loadRuleSetsDefaults,
};
