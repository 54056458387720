import { TextField } from "@mui/material";

import RHJsonField from "../../../components/common/RHJsonField";
import RHAutocompleteField from "../../../components/common/RHAutocompleteField";
import RHEnhancedAutocompleteField from "../../../components/common/RHEnhancedAutocompleteField";
import RHBooleanField from "../../../components/common/RHBooleanField";
import RHDataOnlyField from "../../../components/common/RHDataOnlyField";
import RHNumberField from "../../../components/common/RHNumberField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  getGeneralFormStylesSx,
  getGeneralDisabledFormStylesSx,
  getGeneralAlwaysDisabledFormStylesSx,
} from "./tableHelpers";

export default function FormField({
  forceRemountKey,
  selectedItem,
  editedDetails,
  setEditedDetails,
  editDetail,
  field,
  parentKey,
  rowKey,
  newItemCreated,
  sandbox,
}) {
  const handleEditDetailValue = (e, parentKey) => {
    if (Object.hasOwn(e, "$d")) {
      //The datepicker returns a dayjs object, but the API expects a string and the set function expects e.target.
      e.target = {};
      e.target.value = dayjs(e.$d).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      e.target.name = field.field;
      e.target.type = "date";
    }

    let { name, value, checked, type } = e.target;
    setEditedDetails((values) => {
      if (parentKey) {
        return {
          ...values,
          [parentKey]: {
            ...values[parentKey],
            [name.toLowerCase()]: type === "checkbox" ? checked : value,
          },
        };
      } else {
        return {
          ...values,
          [name.toLowerCase()]: type === "checkbox" ? checked : value,
        };
      }
    });
  };

  const valueWithDefault = (val, defaultVal) => {
    // allow empty string value when editing
    if (val == "") return val;
    if (!val && !defaultVal) return "";
    return val || defaultVal;
  };

  function checkDisabled(field) {
    if (field.editable === false) {
      return true;
    }
    if (field.alwaysDisabled) {
      return true;
    }
    if (field.field === rowKey && field.field !== "platform") {
      return !editDetail;
    } else if (newItemCreated) {
      return false;
      //return field.hasOwnProperty("editable") ? !field.editable : false;
    } else {
      if (editDetail && !newItemCreated) {
        if (field.hasOwnProperty("editable")) {
          return !field.editable;
        } else return false;
      } else return true;
    }
  }

  function setAutocompleteValue(field, parentKey) {
    let display, val;
    if (field.readDirectly) {
      return {
        title: "",
        id: valueWithDefault(editedDetails[field.field], selectedItem[field.field]),
      };
    } else {
      if (parentKey) {
        display = valueWithDefault(
          editedDetails[[parentKey][field.valueRef]],
          selectedItem[[parentKey][field.valueRef]],
        );
        val = valueWithDefault(editedDetails[[parentKey][field.field]], selectedItem[[parentKey][field.field]]);
      } else {
        display = valueWithDefault(editedDetails[field.valueRef], selectedItem[field.valueRef]);
        val = valueWithDefault(editedDetails[field.field], selectedItem[field.field]);
      }
      return { title: display, id: val };
    }
  }
  switch (field?.type) {
    case "json":
      return (
        <RHJsonField
          id={field.field + title.replaceAll(" ", "")}
          label={field.headerName}
          name={field.field}
          required={field.required}
          rowKey={selectedItem[rowKey]}
          disabled={checkDisabled(field)}
          value={editedDetails[field.field]}
          isEdit={editDetail}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        />
      );
    case "select":
      return (
        <TextField
          label={field.headerName}
          name={field.field}
          select
          required={field.required}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inputProps={{ maxLength: 64 }}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        >
          {field.options?.map((option, i) => (
            <MenuItem value={option.value} key={field.field + option.label + "MenuItem" + i}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );

    case "autocomplete":
      return (
        <RHAutocompleteField
          name={field.field}
          required={field.required}
          valueRef={field.valueRef}
          label={field.headerName}
          api={field.api}
          lookupId={field.lookupId}
          preLoaded={field.preLoad}
          preLoadedOptions={field.preLoad ? field.options : null}
          value={setAutocompleteValue(field, parentKey)}
          disabled={checkDisabled(field)}
          sandboxId={sandbox?.sandboxId}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          readNameParamLoc={field.readNameParamLoc}
          responseDataName={field.responseDataName}
          readDirectly={field.readDirectly}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        />
      );
    case "enhancedautocomplete":
      return (
        <RHEnhancedAutocompleteField
          key={forceRemountKey}
          name={field.field}
          label={field.headerName}
          required={field.required}
          isDynamic={field.isDynamic}
          advancedSearch={false}
          api={field.api}
          idKey={field.idKey}
          displayValue={field.displayValue}
          responseDataName={field.responseDataName}
          defaultOptions={field.defaultOptions}
          multiSelect={field.multiSelect}
          inputProps={{ maxLength: 64 }}
          fullUrl={field?.fullUrl}
          filterOnClient={field?.filterOnClient}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          row_key={editedDetails?.[rowKey]}
          disabled={checkDisabled(field)}
          sandboxId={sandbox?.sandboxId}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          urlResponseFilter={field?.urlResponseFilter}
        />
      );
    case "boolean":
      return (
        <RHBooleanField
          checked={
            parentKey
              ? valueWithDefault(
                  Boolean(editedDetails[parentKey][field.field]),
                  Boolean(selectedItem[parentKey][field.field]),
                )
              : valueWithDefault(Boolean(editedDetails[field.field]), Boolean(selectedItem[field.field]))
          }
          label={field.headerName}
          name={field.field}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          //React's warning told me to do this. I think it's terrible, but it makes the warning happy.
          inactive={!editDetail ? !newItemCreated : undefined}
        />
      );
    case "number":
      return (
        <RHNumberField
          label={field.headerName}
          name={field.field}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          change={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inactive={!editDetail ? !newItemCreated : undefined}
          required={field.required}
          step={field.step}
          min={field.min}
          max={field.max}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        />
      );
    case "metadata":
      return (
        <RHDataOnlyField
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          disabled={checkDisabled(field)}
          inputProps={{ maxLength: 64 }}
          sx={getGeneralAlwaysDisabledFormStylesSx(field)}
        />
      );
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={field.headerName}
            value={dayjs(
              parentKey
                ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
                : valueWithDefault(editedDetails[field.field], selectedItem[field.field]),
            )}
            onChange={(e) => handleEditDetailValue(e, parentKey)}
            disabled={checkDisabled(field)}
            sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
          />
        </LocalizationProvider>
      );
    default:
      return (
        <TextField
          label={field.headerName}
          name={field.field}
          required={field.required}
          value={
            parentKey
              ? valueWithDefault(editedDetails[parentKey][field.field], selectedItem[parentKey][field.field])
              : valueWithDefault(editedDetails[field.field], selectedItem[field.field])
          }
          disabled={checkDisabled(field)}
          onChange={(e) => handleEditDetailValue(e, parentKey)}
          inputProps={{ maxLength: 256 }}
          sx={checkDisabled(field) ? getGeneralDisabledFormStylesSx(field) : getGeneralFormStylesSx(field)}
        />
      );
  }
}
