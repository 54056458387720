import React from "react";
import { SearchIcon2 } from "../../../../../../assets/svgs";

const SelectSearchInput = ({ onSearch }) => {
  return (
    <div className="relative flex items-center w-full bg-zinc-900 rounded-md">
      <div className="pl-2">
        <SearchIcon2 className="w-4 h-4" /> {/* Render the search icon */}
      </div>
      <input
        type="text"
        placeholder="Search..."
        onChange={(e) => onSearch(e.target.value)}
        className="pl-3 pr-4 py-2 w-full bg-zinc-900 text-gray-300 rounded-md focus:outline-none"
      />
    </div>
  );
};

export default SelectSearchInput;
