import { useState, useEffect } from "react";
import { TextField, FormControl, Autocomplete, Tooltip, Box } from "@mui/material";
import { fetchDirectly } from "common/apiUtils";
import { debounce } from "common/helpers";

// init: fetch only item title from item endpoint

export default function RHAutocompleteField(props) {
  const {
    label,
    sandboxId,
    api,
    lookupId,
    value,
    name,
    valueRef,
    onChange,
    disabled,
    preLoaded,
    sx,
    required,
    responseDataName,
    preLoadedOptions,
    readDirectly,
    description,
  } = props;

  const [options, setOptions] = useState([]);
  const [preloadedOptions, setPreloadedOptions] = useState([]);

  const [val, setVal] = useState({ title: "" });

  async function initializeValue() {
    if (value?.id) {
      const response = await fetchDirectly({
        endpoint: api,
        method: "get",
        sandboxId: sandboxId,
        dataId: value?.id,
      });
      const formattedResponse = {
        title: response[valueRef],
        id: response[lookupId],
      };
      onValueChange({}, formattedResponse);
      setVal(formattedResponse);
    } else {
      setVal(value);
    }
  }

  async function formatFetchedOptions(response) {
    const noDataNode = ["rulesets", "groups", "map_selection_lists", "maps", "partitions", "profiles", "pools"];
    if (noDataNode.includes(responseDataName)) {
      //This was going to be another bad override on the component. Because this lookup is going to disappear in the new UI, i'm doing this for now
      return response?.[responseDataName].map((x) => ({
        title: x?.name ?? x?.[valueRef],
        id: x[lookupId],
      }));
    }
    return responseDataName // temp until all response collection are named "data"
      ? response.data[responseDataName].map((x) => ({
          title: x.name,
          id: x[lookupId],
        }))
      : response.data.map((x) => ({ title: x.name, id: x[lookupId] }));
  }

  async function getServerOptionsDirectly(value) {
    if (value) {
      const response = await fetchDirectly(
        {
          endpoint: api,
          method: "get",
          sandboxId: sandboxId,
        },
        {
          name: value,
        }
      );
      const options = await formatFetchedOptions(response, value);
      if (options.length == 0) {
        onValueChange(null, { id: " ", title: value });
        return;
      }
      setOptions(options);
    }
  }

  async function fetchOptionsDirectly() {
    const response = await fetchDirectly({
      endpoint: api,
      method: "get",
      sandboxId: sandboxId,
    });
    return response;
  }

  const updateServerOptions = debounce((event, value, reason) => {
    if (event && value) {
      getServerOptionsDirectly(value);
    } else {
      setOptions([]);
    }
  }, 200);

  const onValueChange = (event, newValue) => {
    // need to update the ID to save to DB as well as value to show in select input.
    event = { target: {} };
    event.target.name = name;
    event.target.value = newValue?.id;
    event.target.lookupName = valueRef;
    event.target.lookupValue = newValue?.title ?? "";
    onChange(event);
  };

  function showFriendlyValue(options) {
    const friendlyValue = options?.find((option) => option.id === value.id);
    setVal(friendlyValue);
  }

  async function getPreloadedData() {
    if (preLoadedOptions) {
      setPreloadedOptions(preLoadedOptions);
    } else {
      const data = !readDirectly
        ? // ? await readTransient(api, sandboxId)
          await fetchDirectly({ endpoint: api, method: "get", sandboxId: sandboxId })
        : await fetchOptionsDirectly({ endpoint: api, method: "get", sandboxId: sandboxId });
      const options = await formatFetchedOptions(data);
      setPreloadedOptions(options);
      setOptions(options);
      showFriendlyValue(options);
    }
  }

  function updateOptions(event, value) {
    setOptions((prev) => {
      if (value === "") return preloadedOptions;
      return preloadedOptions?.filter((option) => option?.title?.toLowerCase().includes(value.toLowerCase()));
    });
  }

  useEffect(() => {
    if (value?.id !== val?.id && readDirectly) {
      initializeValue();
    } else if (value?.title !== val?.title && value.id !== val?.id) {
      if (preloadedOptions) {
        setVal(value);
        return;
      }
      preLoaded ? showFriendlyValue(preloadedOptions) : getServerOptionsDirectly(value?.title);
    }
  }, [value]);

  useEffect(() => {
    if (preLoaded && !preLoadedOptions) {
      getPreloadedData();
    } else if (preLoadedOptions) {
      setOptions(preLoadedOptions);
    } else if (value?.id) {
      initializeValue();
    } else setVal();
  }, []);

  return (
    <FormControl sx={sx}>
      <Tooltip title={`Search ${label}s - ${description}`}>
        <Autocomplete
          options={options ?? []}
          value={val ?? null}
          onChange={(event, newValue) => onValueChange(event, newValue)}
          onInputChange={
            preLoadedOptions
              ? null
              : preLoaded
                ? (event, value) => updateOptions(event, value)
                : (event, value, reason) => updateServerOptions(event, value, reason)
          }
          getOptionLabel={(option) => option?.title ?? ""}
          disabled={disabled}
          freeSolo
          selectOnFocus
          //clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id + option?.title}>
                {option?.title}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} required={required} label={`${label}`} />}
        />
      </Tooltip>
    </FormControl>
  );
}
