import { Fragment } from "react";

function RHConfirmDialog({ open, onConfirm, onCancel, title, content }) {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-zinc-800 rounded-lg p-6 max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4 text-white">{title}</h2>
        <p className="mb-6 text-white">
          {content.split("\n").map((line, index) => (
            <Fragment key={index}>
              {line}
              {index < content.split("\n").length - 1 && <br />}
            </Fragment>
          ))}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-zinc-600 text-white rounded hover:bg-zinc-700 transition-colors"
          >
            No
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-violet-500 text-white rounded hover:bg-violet-600 transition-colors"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default RHConfirmDialog;
