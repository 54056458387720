import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Link } from '@mui/material';

const useStyles = makeStyles((theme) => {
    return {
        rootApiDocsPage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            flex: 1,
        },
        rootApiDocsPageContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            flexWrap: 'wrap',
        },
        apiDocsTile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '200px',
            backgroundColor: theme.palette.grey[600],
            height: '200px',
            margin: '10px',
        }
    }
})

function capitalizeFirstLetter(str) {
    const firstLetter = str[0].toUpperCase();
    const restOfString = str.slice(1);
    return firstLetter + restOfString;
  }

export default function RHApiDocsPage() {
    const classes = useStyles();

    let baseUrl = "https://platform-dev-api.roguecompany.com/";
    let apis = ['users', 'session', 'friends', 'settings', 'inventory', 'presence', 'notification', 'ad', 'config']

    return (
        <Box className={classes.rootApiDocsPage}>
            <Typography variant="h4" color="textPrimary">API Documentation</Typography>
            <Box className={classes.rootApiDocsPageContent}>
                {
                    apis.map((api) => {
                        return (
                            <Box key={api} className={classes.apiDocsTile} >
                                <Link underline="hover" variant="h4" color="textPrimary" href={baseUrl+api} target="_blank">
                                    {capitalizeFirstLetter(api)}
                                </Link>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}