import React, {useCallback, useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {fetchDirectly} from "../helpers";
import {getErrorMessage} from "../../environment/validation";
import TerraformConfigComponent from "./components/TerraformConfigComponent";

const TerraformConfig = ({ onNotification }) => {
  const { getAccessTokenSilently } = useAuth();
  const [vmSizeData, setVmSizeData] = useState([]);
  const [vmDiskData, setVmDiskData] = useState([]);
  const [vmRegionData, setVmRegionData] = useState([]);
  const [vmResourceGroupData, setVmResourceGroupData] = useState([]);
  const [vmOperatingSystemData, setVmOperatingSystemData] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const title = "Terraform Config";
  const fields = [
    { headerName: "Virtual Machine Size",
      field: "vm_size",
      type: "select",
      options: vmSizeData,
      required: true },
    { headerName: "Virtual Machine Disk",
      field: "vm_disk",
      type: "select",
      options: vmDiskData,
      required: true },
    { headerName: "Virtual Machine Region",
      field: "vm_region",
      type: "select",
      options: vmRegionData,
      required: true },
    { headerName: "Virtual Machine Resource Group",
      field: "vm_resource_group",
      type: "select",
      options: vmResourceGroupData,
      required: true },
    { headerName: "Virtual Machine Operating System",
      field: "vm_operating_system",
      type: "select",
      options: vmOperatingSystemData,
      required: true },
  ];

  const MAX_RETRIES = 3;

  const fetchData = useCallback(async () => {
    let attempts = 0;
    let lastError;

    while (attempts < MAX_RETRIES) {
      try {
        //SIZE
        const sizeData = await fetchDirectly({
          fullUrl: 'virtual_machine_size',
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmSizeData(sizeData.map((x)=> ({
          id: x.virtual_machine_size_id, title: x.name
        })))

        //DISK
        const diskData = await fetchDirectly({
          fullUrl: 'virtual_machine_disk',
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmDiskData(diskData.map((x)=> ({
          id: x.virtual_machine_disk_id, title: `${x.name} - ${x.disk_type} ${x.disk_size}GB`
        })))

        //REGION
        const regionData = await fetchDirectly({
          fullUrl: 'virtual_machine_region',
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmRegionData(regionData.map((x)=> ({
          id: x.virtual_machine_region_id, title: x.name
        })))

        //RESOURCE GROUP
        const resourceGroupData = await fetchDirectly({
          fullUrl: 'virtual_machine_resource_group',
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmResourceGroupData(resourceGroupData.map((x)=> ({
          id: x.virtual_machine_resource_group_id, title: x.name
        })))

        //OPERATING SYSTEM
        const operatingSystemData = await fetchDirectly({
          fullUrl: 'virtual_machine_operating_system',
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVmOperatingSystemData(operatingSystemData.map((x)=> ({
          id: x.virtual_machine_operating_system_id, title:`${x.os_distribution} ${x.os_version}`
        })))

        setIsLoading(false);
        setErrorMsg("");
        return; // Success, exit the function
      } catch (error) {
        lastError = error;
        attempts++;

        if (attempts < MAX_RETRIES) {
          onNotification(`Attempt ${attempts} failed. Retrying...`, "warning");
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Exponential backoff
        }
      }
    }
    // If we've reached this point, all retries have failed
    console.error('lastError', lastError);
    const errorMessage = getErrorMessage(lastError, fields);
    setErrorMsg(errorMessage);
    onNotification(`Failed to fetch data after ${MAX_RETRIES} attempts. Please try again later.`, "error");
    setIsLoading(false);
  }, [
    getAccessTokenSilently,
    onNotification,
    fields
  ]);

  useEffect(()=> {
      fetchData();
    },[])

  const handleDataChange = (newData) => {
     setData(newData);
  };

  const handleSave = async () => {
//Do Nothing until api and action are ready.

    console.log(data)
  };

  return (
    <div className="w-full">
      <TerraformConfigComponent
        title={title}
        fields={fields}
        data={data}
        onDataChange={handleDataChange}
        isLoading={isLoading}
        onSave={handleSave}
        errorMsg={errorMsg}
      />
    </div>
  );
};

export default TerraformConfig;
