import { GridToolbarQuickFilter, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box, Typography, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function TableToolbar({
  handleAddNew,
  handleCancel,
  handleDelete,
  handleEdit,
  handleSave,
  hasEditPermission,
  selectedItem,
  classes,
  apiName,
  hideHeader,
  friendlyName,
  title,
  hideSearchBoxTitle,
  selectedInlineRows,
  activelyEditing,
  editableInline,
  isDirty,
  searchOrCreateText,
  hideButtons,
  tabModel,
  setSelectedTab,
  selectedTab,
}) {
  return (
    <Box className={classes.tableHeader}>
      <Box className={classes.tableHeader}>
        <Box className={classes.tableHeaderToolbar}>
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
            <Box>
              {!hideHeader && <Typography variant="h4">{`${title ?? apiName.toUpperCase()} Editor`}</Typography>}
            </Box>
            {tabModel && (
              <div className="flex mr-auto items-center mb-4 box-border">
                {tabModel.map((tab, i) => (
                  <div
                    className={`first:mr-8 pb-1 cursor-pointer ${
                      selectedTab === tab.tabKey
                        ? "border-b border-b-[rgba(157,105,255,0.5)]"
                        : "border-b border-b-[rgba(157,105,255,0)]"
                    } ${selectedTab}`}
                    key={tab.tabKey + i}
                    onClick={() => setSelectedTab(tab.tabKey)}
                  >
                    {tab.tabLabel}
                  </div>
                ))}
              </div>
            )}
            {!hideSearchBoxTitle && (
              <Typography variant="div" sx={{ my: 1 }}>
                Search by name
              </Typography>
            )}
            <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
              <GridToolbarQuickFilter />
              <GridToolbarFilterButton />
              {hasEditPermission && !editableInline && !hideButtons && (
                <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
                  <Tooltip title="Delete selected row">
                    <span>
                      <Button onClick={handleDelete} variant="outlined" disabled={!selectedItem}>
                        Delete
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={`Create empty ${title ?? apiName.toUpperCase()} row`}>
                    <Button onClick={handleAddNew} variant="contained">{`+ New ${
                      friendlyName ?? apiName.toUpperCase()
                    }`}</Button>
                  </Tooltip>
                </Box>
              )}
              {hasEditPermission && editableInline && !hideButtons && (
                <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
                  {activelyEditing && (
                    <Tooltip title="Cancel edit mode">
                      <Button onClick={handleCancel} variant="outlined">
                        Cancel
                      </Button>
                    </Tooltip>
                  )}
                  {!activelyEditing && selectedItem && (
                    <Tooltip title="Remove selected rows">
                      <span>
                        <Button onClick={handleDelete} variant="outlined" disabled={!selectedInlineRows}>
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {!activelyEditing && (
                    <Tooltip title="Switch to edit mode">
                      <span>
                        <Button onClick={handleEdit} variant="outlined">
                          Edit Mode
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Tooltip title={`Create empty ${title ?? apiName.toUpperCase()} row`}>
                    <Button onClick={handleAddNew} variant="contained">{`+ New ${
                      friendlyName ?? apiName.toUpperCase()
                    }`}</Button>
                  </Tooltip>
                  {isDirty && (
                    <Tooltip title="Save changes">
                      <Button onClick={handleSave} variant="contained">
                        Save
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TableToolbar;
