import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import AccountContext from "../../contexts/AccountContext";
import { EsportsIcon, ArrowUpIcon, ArrowDownIcon } from "../../assets/svgs";
import { generateColor } from "../../common/colorUtils";
import { fetchDirectly } from "../../common/apiUtils";

const TableButton = ({ onClick, children, backgroundGradient }) => (
  <button
    onClick={onClick}
    className="text-white text-xs p-1 rounded-md w-full transition-all duration-300 relative overflow-hidden hover:brightness-110"
  >
    <div
      className="absolute inset-0 z-0"
      style={{
        background: backgroundGradient,
        opacity: 1,
      }}
    ></div>
    <div className="relative z-20 flex items-center">
      <div className="w-1/5 flex justify-center">
        <EsportsIcon className="w-4 h-4 text-white opacity-70" />
      </div>
      <div className="w-4/5 text-center">{children}</div>
    </div>
  </button>
);

const Switch = ({ checked, onChange, label, size = "normal" }) => {
  const sizeClasses = {
    small: {
      switch: "w-8 h-4",
      dot: "w-3 h-3",
      text: "text-xs",
    },
    normal: {
      switch: "w-10 h-6",
      dot: "w-4 h-4",
      text: "text-sm",
    },
  };

  const {
    switch: switchClass,
    dot: dotClass,
    text: textClass,
  } = sizeClasses[size];

  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={onChange}
        />
        <div
          className={`block ${switchClass} rounded-full ${
            checked ? "bg-violet-600" : "bg-zinc-600"
          }`}
        ></div>
        <div
          className={`dot absolute left-0.5 top-0.5 bg-white ${dotClass} rounded-full transition ${
            checked ? "transform translate-x-full" : ""
          }`}
        ></div>
      </div>
      <div className={`ml-2 text-zinc-300 ${textClass}`}>{label}</div>
    </label>
  );
};

const CopyableId = ({ id }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(id);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <div
      className="group relative flex items-center cursor-pointer"
      onClick={handleCopy}
    >
      <span className="mr-2 text-xs">{id}</span>
      <span className="absolute left-full ml-2 px-2 py-1 bg-zinc-700 text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
        {copied ? "Copied!" : "Click to copy"}
      </span>
    </div>
  );
};

const CreateProductForm = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [primaryContactEmail, setPrimaryContactEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      name,
      short_name: shortName,
      primary_contact_email: primaryContactEmail,
    });
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 w-96 bg-zinc-800 shadow-xl transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="h-full flex flex-col">
        <div className="px-4 py-6 bg-zinc-700">
          <h2 className="text-lg font-semibold text-white">
            Create New Product
          </h2>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-zinc-300"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full bg-zinc-700 border border-zinc-600 rounded-md shadow-sm py-2 px-3 text-zinc-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label
                htmlFor="shortName"
                className="block text-sm font-medium text-zinc-300"
              >
                Short Name
              </label>
              <input
                type="text"
                name="shortName"
                id="shortName"
                className="mt-1 block w-full bg-zinc-700 border border-zinc-600 rounded-md shadow-sm py-2 px-3 text-zinc-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                required
              />
            </div>
            <div>
              <label
                htmlFor="primaryContactEmail"
                className="block text-sm font-medium text-zinc-300"
              >
                Primary Contact Email
              </label>
              <input
                type="email"
                name="primaryContactEmail"
                id="primaryContactEmail"
                className="mt-1 block w-full bg-zinc-700 border border-zinc-600 rounded-md shadow-sm py-2 px-3 text-zinc-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={primaryContactEmail}
                onChange={(e) => setPrimaryContactEmail(e.target.value)}
                required
              />
            </div>
          </form>
        </div>
        <div className="px-4 py-4 bg-zinc-700 flex justify-end space-x-3">
          <button
            type="button"
            className="px-4 py-2 bg-zinc-600 text-zinc-300 rounded-md hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default function RHOrgPage() {
  const history = useHistory();
  const location = useLocation();
  const { currentStateData } = useContext(AccountContext);

  const [isCreateProductOpen, setIsCreateProductOpen] = useState(false);
  const [showArchivedProducts, setShowArchivedProducts] = useState(false);
  const [sorting, setSorting] = useState([{ id: "productName", desc: false }]);

  const products = useMemo(
    () =>
      currentStateData?.org?.products?.filter(
        (x) => showArchivedProducts || !x?.archive
      ) || [],
    [currentStateData?.org?.products, showArchivedProducts]
  );

  const handleRowClick = useMemo(
    () => (shortName) => {
      history.push(`${location.pathname}/product/${shortName}`);
    },
    [history, location.pathname]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "productName",
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          >
            Product Name
            <span className="ml-2">
              {{
                asc: <ArrowUpIcon width="12px" />,
                desc: <ArrowDownIcon width="12px" />,
              }[column.getIsSorted()] ?? null}
            </span>
          </div>
        ),
        cell: ({ row }) => (
          <TableButton
            onClick={() => handleRowClick(row.original.shortName)}
            backgroundGradient={generateColor(row.original.productId)}
          >
            {row.original.productName}
          </TableButton>
        ),
      },
      {
        accessorKey: "shortName",
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          >
            Short Name
            <span className="ml-2">
              {{
                asc: <ArrowUpIcon width="12px" />,
                desc: <ArrowDownIcon width="12px" />,
              }[column.getIsSorted()] ?? null}
            </span>
          </div>
        ),
        cell: ({ row }) => (
          <span className="text-xs">{row.original.shortName}</span>
        ),
      },
      {
        accessorKey: "productId",
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          >
            Product ID
            <span className="ml-2">
              {{
                asc: <ArrowUpIcon width="12px" />,
                desc: <ArrowDownIcon width="12px" />,
              }[column.getIsSorted()] ?? null}
            </span>
          </div>
        ),
        cell: ({ row }) => <CopyableId id={row.original.productId} />,
      },
      {
        accessorKey: "activeEnvironments",
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          >
            Environments
          </div>
        ),
        cell: ({ row }) => {
          const activeEnvCount =
            row.original.environments?.filter((env) => !env.archive).length ||
            0;
          return <div className="text-center text-xs">{activeEnvCount}</div>;
        },
      },
    ],
    [handleRowClick]
  );

  const table = useReactTable({
    data: products,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleCreateProduct = async (productData) => {
    try {
      const response = await fetchDirectly(
        `/v1/org/${currentStateData.org.id}/product`,
        {
          method: "POST",
          body: JSON.stringify(productData),
        }
      );

      if (response.ok) {
        const newProduct = await response.json();
        // Update the products list (you might need to refetch the entire list or update the context)
        setIsCreateProductOpen(false);
        // Optionally, you can refresh the page or update the products list here
      } else {
        console.error("Failed to create product");
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const handleAddProduct = () => {
    // Implement the logic to add a new product
    console.log("Add new product");
    // You might want to open a modal or navigate to a new product creation page
  };

  if (!products.length) return null;

  return (
    <div className="bg-zinc-900 min-h-screen text-zinc-100 text-sm p-4">
      <div className="max-w-7xl mx-auto">
        <div className="bg-zinc-800 rounded-lg p-2 md:p-6 mb-8 shadow-lg">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-lg font-semibold">Products</h2>
            <div className="flex flex-col items-end">
              <Switch
                checked={showArchivedProducts}
                onChange={() => setShowArchivedProducts(!showArchivedProducts)}
                label="Show Archived"
                size="small"
              />
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-zinc-700">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-6 py-2 text-left text-xs font-medium text-zinc-300 cursor-pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-zinc-800 divide-y divide-gray-700">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="transition-colors duration-200">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-6 py-2 whitespace-nowrap">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CreateProductForm
        isOpen={isCreateProductOpen}
        onClose={() => setIsCreateProductOpen(false)}
        onSubmit={handleCreateProduct}
      />
    </div>
  );
}
