import React from "react";

const SkeletonLoader = ({ type = "input", className = "" }) => {
  const baseClasses = "bg-zinc-700 bg-opacity-50 rounded animate-pulse";

  switch (type) {
    case "input":
      return (
        <div className={`${className}`}>
          <div className={`h-4 w-1/4 mb-2 ${baseClasses}`}></div>
          <div className={`h-10 ${baseClasses}`}></div>
        </div>
      );
    case "checkbox":
      return (
        <div className={`flex items-center ${className}`}>
          <div className={`h-5 w-5 mr-2 ${baseClasses}`}></div>
          <div className={`h-4 w-1/4 ${baseClasses}`}></div>
        </div>
      );
    case "select":
      return (
        <div className={`${className}`}>
          <div className={`h-4 w-1/4 mb-2 ${baseClasses}`}></div>
          <div className={`h-10 ${baseClasses}`}></div>
        </div>
      );
    default:
      return null;
  }
};

export default SkeletonLoader;
