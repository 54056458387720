import { useState, useEffect } from "react";
import { fetchDirectly } from "../helpers";
import ArmadaTable from "../ArmadaTable";
import Snackbar from "@mui/material/Snackbar";
import { useAuth } from "contexts/AuthContext";
import { columns, NewEntry } from "./colDefs";

export default function ArmadaSimpleProductAssignment() {
  const ROW_KEY = "simple_product_assignment_id";
  const ENDPOINT = "simple_product_assignment";
  const { getAccessTokenSilently } = useAuth();

  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const [data, setData] = useState();

  async function readData() {
    let response = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "GET",
      token: await getAccessTokenSilently(),
    });
    setData(response);
  }

  async function updateHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "PATCH",
      body: editedItem,
      dataId: editedItem[ROW_KEY],
      token: await getAccessTokenSilently(),
    });
    await readData();
    return item;
  }

  async function createHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "POST",
      body: editedItem,
      token: await getAccessTokenSilently(),
    });
    await readData();
    return item;
  }

 async function deleteHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.simple_product_assignment_id,
    });
    await readData();
    return item;
  }

  function createVendorColVisibilityModel() {
    var keepers = [ROW_KEY, "name", "count", "host_id"];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((dict, el) => ((dict[el] = false), dict), {});
    return hiders;
  }

  function customFilterFunc(field) {
    return ![].includes(field.field);
  }

  useEffect(() => {
    readData();
  }, []);

  return !data ? null : (
    <>
      <ArmadaTable
        title="Simple Product Assignment"
        // friendlyName="Datacenter Location"
        friendlyNameKey="name"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data && data}
        textFieldFilter={customFilterFunc}
        setSnackBarState={setSnackBarState}
        // retrieveSelectedRows={setSelectedRow}
        setSnackBar={setSnackBar}
        newModel={NewEntry}
        // newModelArgs={[selectedSandbox.sandboxId]}
        updateHandler={updateHandler}
        createHandler={createHandler}
        deleteHandler={deleteHandler}
        //server-side actions
        // rowCount={rowCountVendor}
        // filterMode="server"
        // onFilterChanged={onFilterChangedVendor}
        // paginationMode="server"
        // onPaginationChanged={onPaginationChangedVendor}
        // paginationModel={paginationModelVendors}
        // sortingMode="server"
        // hideHeader="true"
        // onSortModelChanged={onSortModelChangedVendor}
        // version={2}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: createVendorColVisibilityModel(),
          },
          sorting: {
            sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
          },
        }}
      />
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
