import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  const commonItemBoxStyles = {
    display: "flex",
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    width: "300px",
    backgroundColor: "red",
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[600],
  };

  return {
    rootPermissions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
    },
    menuWrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      width: "100%",
      alignItems: "start",
      margin: theme.spacing(2),
    },
    itemBox: {
      ...commonItemBoxStyles,
      cursor: "pointer",
    },
    disabledItemBox: {
      ...commonItemBoxStyles,
      opacity: 0.5,
      cursor: "not-allowed",
    },
  };
});

export default function RHPortalAdmin() {
  const classes = useStyles();

  const history = useHistory();
  const portalAdminMenu = [
    {
      name: "Admin Dashboard",
      route: "/admin/dashboard",
      enabled: true,
    },
    {
      name: "Organization Structure",
      route: "/admin/orgstructure",
      enabled: true,
    },
    {
      name: "Portal Permissions",
      route: "/admin/permissions",
      enabled: true,
    },
    {
      name: "Users",
      route: "/admin/users",
      enabled: false,
    },
    {
      name: "User Org Assignment",
      route: "/admin/userorgassignment",
      enabled: false,
    },
  ];

  const navigateToMenuItem = (menuItem) => {
    if (menuItem.enabled) {
      history.push(menuItem.route);
    }
  };

  return (
    <Box className={classes.rootPortalAdmin}>
      <Typography variant="h4" color="textPrimary">
        Portal Admin
      </Typography>
      <Box className={classes.menuWrapper}>
        {portalAdminMenu.map((menuItem) => (
          <Box
            key={menuItem.name}
            className={menuItem.enabled ? classes.itemBox : classes.disabledItemBox}
            onClick={() => navigateToMenuItem(menuItem)}
          >
            <Typography variant="h5" color="textPrimary">
              {menuItem.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
