import RHFieldWrapper from "./RHFieldWrapper";

export default function RHNumberField(props) {
  const { value, inactive, change, label, name, disabled, required, description, hasError, inputType, fullWidth } =
    props;

  const handleInputChange = (e) => {
    const { value } = e.target;

    const regex = inputType === "number" ? /^-?\d*\.?\d*$/ : /^-?\d+$/;

    const isValueValid = regex.test(value);
    if (value === "" || isValueValid) {
      change(e);
    }
  };

  return (
    <RHFieldWrapper
      label={label}
      className="flex items-center justify-center"
      inactive={inactive}
      disabled={disabled}
      required={required}
      description={description}
      fullWidth={fullWidth}
    >
      <input
        type="text"
        value={value}
        name={name}
        onChange={handleInputChange} // Updated to use onChange
        className={`${inactive || disabled ? "text-white/50" : "text-white/70"} ${
          hasError ? "border border-red-400" : ""
        } bg-transparent outline-none font-roboto text-base text-center w-full`}
      />
    </RHFieldWrapper>
  );
}
