export const columns = [
  {
    field: "description",
    headerName: "Description",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "geographic_location_id",
    headerName: "Geographic Location ID",
    minWidth: 200,
    type: "metadata",
    flex: 2,
  },
];

export class NewEntry {
  description = "";
}
