import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useContext } from "react";
import AccountContext from "../../contexts/AccountContext";
import BreadcrumbInfo from "./BreadcrumbInfo";
import UserMenu from "./UserMenu";

function AppBar({ onToggleMenu, menuOpen }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const { currentStateData } = useContext(AccountContext);
  const menuRef = useRef(null);

  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(user?.name || user?.email?.split("@")[0] || "User");
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleHomeClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [];
    if (currentStateData?.org) {
      breadcrumbs.push({
        name: currentStateData.org.orgName,
        link: `/org/${currentStateData.org.shortName}`,
      });
    }
    if (currentStateData?.product) {
      breadcrumbs.push({
        name: currentStateData.product.productName,
        link: `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}`,
      });
    }
    if (currentStateData?.sandbox) {
      breadcrumbs.push({
        name: currentStateData.sandbox.sandboxName,
        link: `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/sandbox/${currentStateData.sandbox.shortName}`,
      });
    }
    if (currentStateData?.environment) {
      breadcrumbs.push({
        name: currentStateData.environment.environmentName,
        link: `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/environment/${currentStateData.environment.shortName}`,
      });
    }
    return breadcrumbs;
  };

  const getCurrentStateInfo = () => {
    if (currentStateData?.sandbox) {
      return {
        type: "Sandbox",
        id: currentStateData.sandbox.sandboxId,
        name: currentStateData.sandbox.sandboxName,
        shortName: currentStateData.sandbox.shortName,
      };
    } else if (currentStateData?.environment) {
      return {
        type: "Environment",
        id: currentStateData.environment.environmentId,
        name: currentStateData.environment.environmentName,
        shortName: currentStateData.environment.shortName,
        uniqueName: currentStateData.environment.uniqueName,
        url: currentStateData.environment.baseUrl,
        rhbaseurl: currentStateData.environment.rhBaseUrl
      };
    } else if (currentStateData?.product) {
      return {
        type: "Product",
        id: currentStateData.product.productId,
        name: currentStateData.product.productName,
        shortName: currentStateData.product.shortName,
        uniqueName: currentStateData.product.uniqueName,
      };
    } else if (currentStateData?.org) {
      return {
        type: "Organization",
        id: currentStateData.org.orgId,
        name: currentStateData.org.orgName,
        shortName: currentStateData.org.shortName,
      };
    }
    return null;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <header className="h-[50px] bg-zinc-900 shadow-md z-[100] sticky top-0 w-full border-b border-zinc-700">
      <div
        className={`h-full flex items-center justify-between transition-all duration-300 px-4`}
      >
        <div className="flex items-center space-x-4">
          <button
            onClick={onToggleMenu}
            className="text-zinc-300 hover:text-white transition-colors focus:outline-none"
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <nav className="flex items-center space-x-2 text-sm">
            <Link
              to="/"
              onClick={handleHomeClick}
              className="text-zinc-300 hover:text-white transition-colors flex items-center"
            >
              Home
            </Link>
            {getBreadcrumbs().map((breadcrumb, index, array) => (
              <div key={index} className="flex items-center">
                <svg
                  className="w-3 h-3 mx-2 text-zinc-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <Link
                  to={breadcrumb.link}
                  className={`${
                    index === array.length - 1
                      ? "text-violet-400 hover:text-violet-300"
                      : "text-white hover:text-zinc-300"
                  } transition-colors font-medium`}
                >
                  {capitalizeFirstLetter(breadcrumb.name)}
                </Link>
              </div>
            ))}
          </nav>
        </div>
        <div className="flex items-center space-x-2">
          <BreadcrumbInfo info={getCurrentStateInfo()} />
          <UserMenu />
        </div>
      </div>
    </header>
  );
}

export default AppBar;
