import { useState } from "react";
import { readCookie } from "common/helpers";

export default function TokenCopy() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [localTimeout, setLocalTimeout] = useState();

  function flashTooltip() {
    if (!localTimeout) {
      setShowTooltip(true);
      const thisTimeout = setTimeout(() => {
        setShowTooltip(false);
        clearTimeout(thisTimeout);
        setLocalTimeout();
      }, 1500);

      setLocalTimeout(thisTimeout);
    }
  }

  function copyToken() {
    navigator.clipboard.writeText(readCookie("authSession"));
    flashTooltip();
  }

  return (
    <div className="font-roboto color-rh text-base p-8 mb-2 rounded-sm">
      <h3 className="text-2xl font-bold">API Token</h3>
      <p className="text-sm font-regular mb-3">This token expires in 2 hours.</p>
      <div className="relative overflow-visible pr-12 inline-block">
        <button
          onClick={copyToken}
          className="bg-[#9d69ff] hover:bg-[#6d49b2] text-sm uppercase rounded font-roboto font-medium px-4 py-3 text-white transition-all tracking-snug duration-375"
        >
          <span>Copy Token</span>
        </button>
        <div
          className={`absolute p-1 max-w-[100px] font-roboto text-[11px] leading-[1.2] text-stone-800 bg-stone-300 rounded -right-5 top-0 opacity-0 pointer-events-none ${
            showTooltip ? "animate-tooltip" : ""
          }`}
        >
          Token copied to clipboard!
        </div>
      </div>
    </div>
  );
}
