function getErrorMessage(error, columns) {
  const extractBaseErrorMessage = (error) => {
    const errorData = error?.response?.data;
    if (!errorData) {
      return error?.message || null;
    }
    return errorData.desc || errorData.detail || errorData;
  };

  const extractDetailedErrorMessage = (error, columns) => {
    const serverErrorAltDetail = error?.response?.data?.detail;
    if (!serverErrorAltDetail) return null;

    let msg = serverErrorAltDetail[0]?.msg;
    const type = serverErrorAltDetail[0]?.type;
    const loc = serverErrorAltDetail[0]?.loc;
    if (type && loc && loc.length > 0) {
      msg = type === "value_error.any_str.min_length" ? "value required" : msg;
      let suffix = loc[loc.length - 1];
      for (const col of columns) {
        if (col.field === suffix) {
          suffix = col.headerName;
          break;
        }
      }
      msg = `${msg}: ${suffix}`;
      msg = msg.replaceAll("_id", "");
    }

    return msg;
  };

  const errorData = error?.response?.data;
  if (errorData?.desc && !(errorData.desc instanceof Object)) {
    // uhh, why is special parsing needed here?
    try {
      const parsedErr = JSON.parse(errorData.desc);
      if (parsedErr) {
        error.response.data = parsedErr;
      }
    } catch {}
  }
  const baseErrorMsg = extractBaseErrorMessage(error);
  const detailedErrorMsg = extractDetailedErrorMessage(error, columns);

  return detailedErrorMsg || baseErrorMsg;
}

function validateData(columns, editedDetails) {
  const errors = columns.reduce((acc, field) => {
    const fieldValue = editedDetails[field.field];
    const isEmpty = fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === "";
    const isRequired = field.required;

    if (isRequired && isEmpty) {
      acc.push(`${field.headerName}: Required`);
    }
    return acc;
  }, []);

  if (errors.length > 0) {
    let newError = new Error("Validation Error");
    newError.response = {
      data: {
        desc: `${errors.join(", ")}`,
      },
    };
    throw newError;
  }
}

export { getErrorMessage, validateData };
