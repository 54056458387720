export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getErrorMessage(error) {
  let serverMsg = error?.response?.data?.desc;
  const serverErrorAltDetail = error?.response?.data?.detail;
  if (serverErrorAltDetail) {
    let msg = serverErrorAltDetail[0]?.msg;
    const type = serverErrorAltDetail[0]?.type;
    const loc = serverErrorAltDetail[0]?.loc;
    if (type && loc.length > 0) {
      msg = type === "value_error.any_str.min_length" ? "value required" : msg;
      let suffix = loc[loc.length - 1];
      for (const col of columns) {
        if (col.field == suffix) {
          suffix = col.headerName;
        }
      }
      msg = `${msg}: ${suffix}`;
      msg = msg.replaceAll("_id", "");
    }
    serverMsg = msg;
  }
  return serverMsg;
}

export function isFullWidthField(field) {
  return field.type === "json" || (field.type === "enhancedautocomplete" && field.multiSelect === true);
}

export function getGeneralFormStylesSx(field, isChild) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    margin: isChild ? "10px" : "0",
    maxWidth: isFullWidthField(field) ? "none" : isChild ? "338px" : "360px",
    minWidth: "250px",
    width: field.width ?? "100%",
    marginBottom: "15px",
  };
}

export function getGeneralDisabledFormStylesSx(field) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    maxWidth: isFullWidthField(field) ? "none" : "360px",
    minWidth: "250px",
    //fontStyle: "italic", // Make text italic
    width: field.width ?? "100%",
    //"& input": {
    //  backgroundColor: "#00000030", // Apply background color to input elements
    //},
  };
}

export function getGeneralAlwaysDisabledFormStylesSx(field) {
  return {
    boxSizing: "border-box",
    height: isFullWidthField(field) ? "auto" : "56px",
    maxWidth: isFullWidthField(field) ? "none" : "360px",
    minWidth: "250px",
    fontStyle: "italic", // Make text italic
    width: field.width ?? "100%",
    "& input": {
      backgroundColor: "#00000030", // Apply background color to input elements
    },
  };
}

export function scrollToBottom() {
  let timeout = setTimeout(() => {
    clearTimeout(timeout);
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, 100);
}
