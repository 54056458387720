export default {
  pageKey: "groupManagement",
  tabs: {
    Groups: {
      rowId: "group_id",
      default: true,
      schema: "GroupResponse",
      GETparams: { org_id: "{org_id}" },
      version: "v2",
      editor: {
        Permissions: {
          default: true,
          version: "v2",
          GETparams: { group_id: "{group_id}" },
          schemas: {
            MUTATE: "IndividualPermissionResponse",
            GET: "IndividualPermissionResponse",
          },
          fields: [],
        },
        Info: {
          default: true,
          version: "v2",
          GETparams: { group_id: "{group_id}" },
          schemas: {
            MUTATE: "GroupUpdateRequest",
            GET: "GroupResponse",
            POST: "GroupResponse",
            DELETE: "GroupResponse",
          },
          fields: [
            {
              field: "group_id",
              headerName: "Group ID",
              type: "metadata",
            },
            {
              field: "org_group_name",
              headerName: "Name",
              required: true,
            },
            {
              field: "description",
              headerName: "Description",
            },
          ],
        },
      },
    },
  },
};
