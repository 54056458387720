import { useEffect, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import RHFieldWrapper from "./RHFieldWrapper";
import { Alert, Box, Grid } from "@mui/material";

export default function RHJsonField(props) {
  const { label, name, value, disabled, onChange, required, sx, isFullWidthField, description } = props;

  const [errorMsg, setErrorMsg] = useState();
  const [transientValue, setTransientValue] = useState();

  const validateJSON = (data) => {
    try {
      JSON.parse(data);
    } catch (err) {
      setErrorMsg(`Not valid JSON. Value will not be updated. ${err.message}`);
      return;
    }

    var evt = {
      target: {
        name: name,
        value: JSON.parse(data),
      },
    };

    setErrorMsg();
    onChange(evt);
  };

  useEffect(() => {
    setTransientValue(JSON.stringify(value, null, 2));
  }, [value]);

  return (
    <Box sx={sx}>
      <Grid container justifyContent="flex-end">
        <Box>
          {errorMsg ? (
            <Alert
              icon={false}
              severity="error"
              sx={{
                fontSize: "75%",
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
              }}
            >
              {errorMsg}
            </Alert>
          ) : null}
        </Box>
      </Grid>
      <RHFieldWrapper
        label={label}
        inactive={disabled}
        fullWidth={true}
        autoInactiveChild
        required={required}
        descripton={description}
      >
        <CodeMirror
          value={transientValue}
          options={{
            mode: "javascript",
            theme: "pastel-on-dark",
            json: true,
            lineNumbers: true,
            readOnly: disabled ? "nocursor" : false,
            extraKeys: {
              Enter: (cm) => {
                cm.replaceSelection("\n");
              },
            },
          }}
          onBeforeChange={(editor, data, value) => {
            setTransientValue(value);
          }}
          onChange={(editor, data, value) => validateJSON(value)}
        />
      </RHFieldWrapper>
    </Box>
  );
}
