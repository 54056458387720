import * as React from "react";
import splashLogo from "../../assets/rally-here-R-logo-400x400.png";
import { CircularProgress } from "assets/svgs";
import ErrorIcon from "@mui/icons-material/Error"; // Import Error Icon from Material UI Icons

export default function DynamicLoadingMask({
  loadMessage = "Looking for data...",
  showError = false,
  className,
  size = 50,
}) {
  // const iconSize = 50; // Maintain consistent icon size for loading and error

  return (
    <div
      className={`flex flex-col items-center justify-center bg-transparent text-center h-full text-white gap-2 ${className}`}
    >
      <div className="relative flex items-center justify-center">
        {showError ? (
          <ErrorIcon
            className={`text-red-500`}
            style={{ fontSize: size + 5 }}
          />
        ) : (
          <>
            <CircularProgress size={size + 50} />
            <div
              className={`absolute rounded-full overflow-hidden flex items-center justify-center`}
              style={{ width: size + 20, height: size + 20 }}
            >
              <img src={splashLogo} alt="Logo" className="w-full h-full" />
            </div>
          </>
        )}
      </div>
      <p
        className="text-center font-normal leading-snug"
        style={{
          color: showError ? "var(--error-contrast-text)" : "white",
          lineHeight: 2,
        }}
      >
        {loadMessage}
      </p>
    </div>
  );
}
