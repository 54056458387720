import { makeStyles } from "@mui/styles";

export const useTableStyles = makeStyles((theme) => {
  return {
    rootConfigPage: {
      width: "100%",
      "& button": {
        marginLeft: "1rem",
      },
      /*minHeight: "775px",*/
    },
    table: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
    },
    tableHeader: {
      padding: "8px 8px 0px 8px",
    },
    tableHeaderToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableDetailsContainer: {
      marginTop: "2rem",
      padding: "1rem",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      borderRadius: "5px",
    },
    tablesDetailContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
    },
    tableDetailView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    tableDetailViewContentsButtonBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    tableDetailForm: {
      display: "flex",
      flexWrap: "wrap",
      gap: "15px 20px",
    },
    snackBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  };
});

export const useKvStyles = makeStyles((theme) => {
  return {
    rootConfigPage: {
      flexDirection: "column",
      width: "100%",
      "& button": {
        marginLeft: "1rem",
      },
    },
    table: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
    },
    tableHeader: {
      padding: "8px 8px 0px 8px",
    },
    tableHeaderToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableDetailsContainer: {
      marginTop: "2rem",
      padding: "1rem",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      borderRadius: "5px",
    },
    tablesDetailContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
    },
    tableDetailView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    tableDetailViewContents: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    tableDetailViewContentsButtonBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    tableDetailForm: {
      display: "flex",
      gap: theme.spacing(2),
    },
    snackBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  };
});

export const useXpStyles = makeStyles((theme) => {
  return {
    rootXPTableConfigPage: {
      // display: 'flex',
      flexDirection: "column",
      width: "100%",
      "& button": {
        marginLeft: "1rem",
      },
    },
    table: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
    },
    tableHeader: {
      padding: "8px 8px 0px 8px",
    },
    tableHeaderToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    xpTableDetailsContainer: {
      marginTop: "2rem",
      padding: "1rem",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      borderRadius: "5px",
    },
    nestedTableDetailContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
    },
    xpTableDetailView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    nestedTableView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    xpTableDetailViewContents: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    xpTableDetailViewContentsButtonBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    xpTableDetailForm: {
      display: "flex",
      // flexDirection: 'row',
      gap: theme.spacing(2),
    },
    snackBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  };
});

export const useRHEditTableStyles = makeStyles((theme) => {
  return {
    rootTableContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      padding: theme.spacing(2),
    },
    table: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
    },
    tableHeader: {
      padding: "8px 8px 0px 8px",
    },
    tableHeaderToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableDetailsContainer: {
      marginTop: "2rem",
      padding: "1rem",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      borderRadius: "5px",
    },
    tablesDetailContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
    },
    tableDetailView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    tableDetailViewContents: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    tableDetailViewContentsButtonBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    tableDetailForm: {
      marginRight: theme.spacing(2),
    },
    tableDetailFormItem: {
      marginRight: theme.spacing(2),
    },
    snackBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    editTableSwitch: {},
  };
});
